import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Container from "@mui/material/Container";

// accepte un tableaux contenant alias et url des pages à afficher dans le fil
export default function Ariane({ arianeItem }) {
  return (
    <React.Fragment>
      {arianeItem && (
        <div className="ariane">
          <Container maxWidth="xl">
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<NavigateNextIcon fontSize="small" />}
            >
              {arianeItem.map((term) => (
                <Link
                  underline="hover"
                  key={"term" + term[0] + term[1]}
                  href={term[0]}
                >
                  {term[1]}
                </Link>
              ))}
            </Breadcrumbs>
          </Container>
        </div>
      )}
    </React.Fragment>
  );
}
