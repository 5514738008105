// barre proposant une recherche et des filtres sur la pages produits

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import API_URL from "../../Api";

export default function SearchProductBar() {
  const [overlay, setOverlay] = useState(false);

  // expression recherchée
  const [search, setSearch] = useState();
  // tout les produit dispos
  const [products, setProducts] = useState([]);
  // état de chargement
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (search) {
      fetch(
        API_URL +
          `/api/produits?populate[0]=nom&populate[1]=slug&filters[$or][0][nom][$containsi]=${search}&filters[$or][1][alias][$containsi]=${search}&filters[$or][2][ref][$containsi]=${search}`,
        {
          method: "GET",
          headers: {
            Accept: "Application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((response) => {
          setProducts(response.data);
          setIsLoading(false);
        });
    }
  }, [search]);

  // traitement de la recherche lorsque l'utilisateur entre une nouvelle recherche
  function searchTreat(search) {
    // on lance la recherche au bout de 2 caractère proposés
    if (search.length > 1) {
      setSearch(search);
      setOverlay(true);
    } else {
      setOverlay(false);
    }
  }

  return (
    <div className="searchProductBarBox">
      <input
        className="searchProductBarInput"
        onInput={(e) => searchTreat(e.target.value)}
        onKeyUp={(e) => searchTreat(e.target.value)}
        onChange={(e) => searchTreat(e.target.value)}
        type="search"
        placeholder="Rechercher un produit, une référence, une nuance..."
      ></input>
      {!isLoading && overlay && (
        <div className="searchProductResult">
          {products.length > 0 && (
            <ul>
              {products.map((result) => (
                <li key={"searchProductResult" + result.id}>
                  <Link to={`/produit/${result.attributes.slug}`} />
                  <p>{result.attributes.nom}</p>
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
}
