import React, { useState } from "react";
import { MetaTags } from "react-meta-tags";
import API_URL from "../Api";
import ArrowButton from "../components/Atom/ArrowButton";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Ariane from "../components/molecules/Ariane";
import BandeauLocalisation from "../components/molecules/BandeauLocalisation";
import Banner from "../components/molecules/Banner";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import SubFooter from "../components/molecules/SubFooter";

export default function Contact() {
  const [nom, setNom] = useState();
  const [prenom, setPrenom] = useState();
  const [societe, setSociete] = useState();
  const [societeCreate, setSocieteCreate] = useState(false);
  const [siret, setSiret] = useState();
  const [email, setEmail] = useState();
  const [telephone, setTelephone] = useState();
  const [adresse, setAdresse] = useState();
  const [codePostal, setCodePostal] = useState();
  const [ville, setVille] = useState();
  const [sujet, setSujet] = useState();
  const [clauseCommerce, setClauseCommerce] = useState(false);
  const [client, setClient] = useState(false);

  // feedback soumission form
  const [sendState, setSendState] = useState(false);

  function submitContact(e) {
    e.preventDefault();

    const data = {
      nom: nom.toUpperCase(),
      prenom: prenom,
      societe: societe,
      societe_en_crea: societeCreate,
      siret: siret,
      email: email,
      tel: telephone,
      adresse: adresse,
      code_post: codePostal,
      ville: ville,
      sujet: sujet,
      clause_commercial: clauseCommerce,
      client: client,
    };

    const formData = new FormData();
    formData.append("data", JSON.stringify(data));

    // enregistrement de la candidature
    fetch(`${API_URL}/api/contacts`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        setNom("");
        setPrenom("");
        setSociete("");
        setSocieteCreate(false);
        setSiret("");
        setEmail("");
        setTelephone("");
        setAdresse("");
        setCodePostal("");
        setVille("");
        setSujet("");
        setClauseCommerce(false);
        setSendState(true);
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  const arianeItem = [
    ["/", "Tilt'Équipement"],
    ["/contact", "Nous contacter"],
  ];

  return (
    <React.Fragment>
      <MetaTags>
        <title>Tilt’ Équipement - Contact</title>
        <meta property="og:title" content={"Tilt’ Équipement - Contact"} />
        <meta property="title" content={"Tilt’ Équipement - Contact"} />
        <meta
          name="description"
          content={
            "Prenez contact avec nos équipes via notre formulaire pour toute demande d’information ou de rendez-vous. Pour toute demande de devis, rendez-vous sur notre formulaire dédié."
          }
        />
        <meta
          name="og:description"
          content={
            "Prenez contact avec nos équipes via notre formulaire pour toute demande d’information ou de rendez-vous. Pour toute demande de devis, rendez-vous sur notre formulaire dédié."
          }
        />
        <meta name="keywords" content={"Tilt'Équipement, Contact"} />
        <meta name="og:url" content={"https://tilt-equipement.fr/contact"} />
      </MetaTags>
      <Ariane arianeItem={arianeItem} />
      <Banner
        left
        title="Nous contacter"
        content="Vous souhaitez avoir plus d’informations concernant nos services, ou bien prendre un rendez-vous ? Pour toute demande de devis contactez-nous via le formulaire dédié."
        ArrowScrollShow
      />
      <Container maxWidth="xl" className="pb64">
        <Grid container>
          <Grid item lg={12}>
            <div className="contactContainer">
              {!sendState ? (
                <form
                  className="contactForm"
                  onSubmit={(e) => submitContact(e)}
                >
                  <div className="contactField">
                    <label>
                      RENSEIGNEZ VOS COORDONNÉES <span>*</span>
                    </label>
                    <div className="pt32">
                      <label>ÊTES-VOUS DÉJÀ CLIENT ?</label>
                      <div className="devisRadio">
                        <div>
                          <input
                            type="radio"
                            checked={client}
                            onChange={(e) => setClient(true)}
                          />
                          <label htmlFor="oui">Oui</label>
                        </div>
                        <div>
                          <input
                            type="radio"
                            checked={!client}
                            onChange={(e) => setClient(false)}
                          />
                          <label htmlFor="non">Non</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="contactField">
                    <label>
                      NOM <span>*</span>
                    </label>
                    <input
                      type="text"
                      value={nom}
                      onChange={(e) => setNom(e.target.value)}
                      required
                    ></input>
                  </div>
                  <div className="contactField">
                    <label>
                      PRÉNOM <span>*</span>
                    </label>
                    <input
                      type="text"
                      value={prenom}
                      onChange={(e) => setPrenom(e.target.value)}
                      required
                    ></input>
                  </div>
                  <div className="contactField">
                    <label>
                      SOCIÉTÉ <span>*</span>
                    </label>
                    <input
                      type="text"
                      value={societe}
                      onChange={(e) => setSociete(e.target.value)}
                      required
                    ></input>
                    <div className="contactCheckBox">
                      <input
                        type="checkbox"
                        value={societeCreate}
                        onChange={(e) => setSocieteCreate(!societeCreate)}
                        checked={societeCreate}
                      ></input>
                      <label>Société en cours de création</label>
                    </div>
                  </div>
                  <div className="contactField">
                    <label>N° DE SIRET</label>
                    <input
                      type="number"
                      value={siret}
                      onChange={(e) => setSiret(e.target.value)}
                    ></input>
                  </div>
                  <div className="contactField">
                    <label>
                      EMAIL <span>*</span>
                    </label>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    ></input>
                  </div>
                  <div className="contactField">
                    <label>
                      TÉLÉPHONE <span>*</span>
                    </label>
                    <input
                      type="number"
                      value={telephone}
                      onChange={(e) => setTelephone(e.target.value)}
                      required
                    ></input>
                  </div>
                  <div className="contactField">
                    <label>ADRESSE POSTALE</label>
                    <input
                      type="text"
                      value={adresse}
                      onChange={(e) => setAdresse(e.target.value)}
                    ></input>
                  </div>
                  <div className="contactField">
                    <label>CODE POSTAL</label>
                    <input
                      type="number"
                      value={codePostal}
                      onChange={(e) => setCodePostal(e.target.value)}
                    ></input>
                  </div>
                  <div className="contactField">
                    <label>VILLE</label>
                    <input
                      type="text"
                      value={ville}
                      onChange={(e) => setVille(e.target.value)}
                    ></input>
                  </div>
                  <div className="contactField">
                    <label>
                      VOTRE DEMANDE CONCERNE <span>*</span>
                    </label>
                    <textarea
                      cols="33"
                      value={sujet}
                      onChange={(e) => setSujet(e.target.value)}
                      required
                    ></textarea>
                  </div>
                  <div className="contactField">
                    <div className="contactCheckBox">
                      <input
                        type="checkbox"
                        value={clauseCommerce}
                        onChange={(e) => setClauseCommerce(!clauseCommerce)}
                        // required
                        checked={clauseCommerce}
                      ></input>
                      <label>
                        En soumettant ce formulaire j’accepte que les
                        informations saisies soient exploitées dans le cadre de
                        la gestion de la relation commerciale
                      </label>
                    </div>
                  </div>
                  <div className="contactField" id="contactFieldSubmit">
                    <input type={"submit"} value={"Envoyer mon message"} />
                    <span>
                      <ExpandCircleDownIcon />
                    </span>
                  </div>
                </form>
              ) : (
                <div className="successSending">
                  <h2>Votre message a été envoyé avec succès !</h2>
                  <p>
                    L'équipe commerciale de Tilt' Équipement vous recontactera
                    rapidement
                  </p>
                  <ArrowButton
                    color={"grey"}
                    href={"../"}
                    content="Retour à l'accueil"
                  />
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
      <SubFooter
        titleLeft="Créez votre agencement"
        descriptionLeft="Demandez votre devis et concevons ensemble votre projet de demain."
        hrefLeft={"../devis"}
        contentbtnLeft="Demande de devis"
        citation
      />
      <BandeauLocalisation />
    </React.Fragment>
  );
}
