import React, { useState } from "react";
import { Container, Grid } from "@mui/material";
import Banner from "../components/molecules/Banner";
import Ariane from "../components/molecules/Ariane";
import { MetaTags } from "react-meta-tags";

export default function MentionsLegal() {
  const [arianeItem] = useState([
    ["/", "Tilt'Équipement"],
    ["/mentions-legales", "Mentions légales"],
  ]);

  return (
    <React.Fragment>
      <MetaTags>
        <title>Mentions légales - Tilt' Équipement</title>
        <meta
          property="title"
          content={"Mentions légales - Tilt' Équipement"}
        />
        <meta
          name="description"
          content={"Consultez les mentions légales Tilt' Équipement"}
        />
        <meta name="keywords" content={"Tilt'Équipement, mentions légales"} />
      </MetaTags>
      <Ariane arianeItem={arianeItem} />
      <Banner title="Mentions légales" />
      <Container maxWidth="lg" className="pb120">
        <Grid container>
          <Grid item lg={10} xs={12}>
            <p>
              Nom du site :{" "}
              <a href="https://www.tilt-equipement.com/">
                www.tilt-equipement.com
              </a>
            </p>
            <p>Directeur de la publication : M Racineux Mickael</p>
            <p>Email : commercial@tilt-equipement.com</p>
            <hr />
            <p>Adresse : 10 rue Jeanne Vivez Château-Gontier</p>
            <p>53200 - Château-Gontier-sur-Mayenne</p>
            <p>Tél : 02 43 07 85 29</p>
            <p>Forme juridique : SARL</p>
            <p>Raison sociale : TILT EQUIPEMENT</p>
            <p>SIRET : 42950064800010</p>
            <p>Capital : 8 000,00 €</p>
            <p>TVA Intracommunautaire : FR31429500648</p>
            <hr />
            <p style={{ marginBottom: "20px" }}>
              Hébergement : Le site est hébergé par la société Titan Datacenters
              France Adresse : 1210 Chem. de la Plaine, 06250 Mougins Téléphone
              : +33 9 88 99 88 88 Crédits : Agence RouleMarcel
            </p>
            <p style={{ marginBottom: "20px" }}>
              L'utilisateur du site Internet reconnaît disposer de la compétence
              et des moyens nécessaires pour accéder et utiliser ce site. Il
              reconnaît également avoir pris connaissance de la présente notice
              légale et s'engage à la respecter.
            </p>
            <p style={{ marginBottom: "20px" }}>
              Pour retrouver l'ensemble des informations relatives à
              l'utilisation et au paramétrage des cookies, veuillez cliquer ici
              : <a href="">« En savoir plus et paramétrer les cookies »</a>.
            </p>
            <p style={{ marginBottom: "20px" }}>
              L'utilisateur dispose d'un droit d'accès, de modification, de
              rectification et de suppression des données qui le concernent
              (article 34 de la loi " Informatique et Libertés "). Pour
              l'exercer, l'utilisateur peut s'adresser auprès du Directeur de la
              publication visé plus haut.
            </p>
            <p style={{ marginBottom: "20px" }}>
              Toute représentation totale ou partielle de ce site par quelque
              procédé que ce soit, sans l'autorisation expresse de l'exploitant
              du site Internet est interdite et constituerait une contrefaçon
              sanctionnée par les article L 335-2 et suivants du Code de la
              propriété intellectuelle.
            </p>
            <p style={{ marginBottom: "20px" }}>
              Il en est de même des éventuelles bases de données figurant sur le
              site Internet qui sont protégées par les dispositions de la loi du
              11 juillet 1998 portant transposition dans le Code de la propriété
              intellectuelle (CPI) de la directive européenne du 11 mars 1996
              relative à la protection juridique des bases de données.
            </p>
            <p style={{ marginBottom: "20px" }}>
              Les marques de l'exploitant du site Internet et de ses
              partenaires, ainsi que les logos figurant sur le site sont des
              éléments protégés par les dispositions du droit de la propriété
              intellectuelle et ne peuvent faire l'objet, sans consentement du
              Directeur de la publication, d'aucune reproduction ni
              représentation partielle ou totale.
            </p>
            <p style={{ marginBottom: "20px" }}>
              Les liens hypertextes mis en place dans le cadre du site Internet
              en direction d'autres ressources présentes sur le réseau de
              l'Internet, et notamment vers ses partenaires ont fait l'objet
              d'une autorisation préalable expresse et écrite.
            </p>
            <p style={{ marginBottom: "20px" }}>
              Les utilisateurs visiteurs du site Internet ne peuvent mettre en
              place un hyperlien en direction de ces sites sans l'autorisation
              expresse et préalable de l'exploitant du site Internet.
            </p>
            <p style={{ marginBottom: "20px" }}>
              Conformément aux articles L.612-1 et suivants issus de
              l'ordonnance n°2016-301 du 14 mars 2016, vous avez la possibilité
              en cas de litige de recourir gratuitement au Médiateur de la
              Consommation relevant de notre secteur d'économie en vous
              reportant au site de la Commission d'Evaluation et de contrôle de
              la médiation de la consommation chargée d'établir et de mettre à
              jour la liste des médiateurs
              (http://www.economie.gouv.fr/mediation-conso).
            </p>
            <p style={{ marginBottom: "20px" }}>
              Le présent site est la propriété de Tilt' Équipement, réalisé par
              RouleMarcel, établissement situé au 42 rue du général lizé, 49100
              Angers - contact@roulemarcel.fr
            </p>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
