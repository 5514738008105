import React, { useEffect, useState } from "react";
import API_URL from "../Api";
import BannerRealisation from "../components/molecules/BannerRealisation";
import SubFooter from "../components/molecules/SubFooter";
import AddIcon from "@mui/icons-material/Add";
import Ariane from "../components/molecules/Ariane";
import Back from "../components/molecules/Back";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import MetaTags from "react-meta-tags";
import { IconButton } from "@mui/material";
import { useParams } from "react-router-dom";
import ProposeRealisations from "../components/realisations/ProposeRealisations";
import CircularProgress from "@mui/material/CircularProgress";
import ImageOptimizer from "../components/utils/ImageOptimizer";

export default function Realisation() {
  const slug = useParams().slug;
  const [isLoading, setIsLoading] = useState(true);
  const [realisation, setRealisation] = useState([]);
  const [couvSizing, setCouvSizing] = useState();
  const [arianeItem, setArianeItem] = useState([]);

  // gestion de la popup d'affichage du produit associé à un pins
  // const [productId, setProductId] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const [popupIsLoading, setPopupIsLoading] = useState(true);
  const [popupContent, setPopupContent] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetch(`${API_URL}/api/realisations?populate=*&filters[slug][$eq]=${slug}`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setRealisation(response.data[0]);

        setArianeItem([
          ["/", "Tilt'Équipement"],
          ["/realisations", "Réalisations"],
          ["/" + response.data[0].id, response.data[0].attributes.Titre],
        ]);
        setIsLoading(false);

        if (
          Object.values(
            response.data[0].attributes.couverture.data.attributes.formats
          )[0].width >
          Object.values(
            response.data[0].attributes.couverture.data.attributes.formats
          )[0].height
        ) {
          setCouvSizing("height");
        } else {
          setCouvSizing("width");
        }
      });
  }, [slug]);

  function onPopup(productId) {
    // setProductId(productId);
    setShowPopup(true);
    // on fetch le produit séléctionné
    fetch(API_URL + "/api/produits/" + productId + "?populate=*", {
      method: "GET",
      headers: {
        Accept: "Application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setPopupContent(response.data);
        setPopupIsLoading(false);
      })
      .catch((err) => {
        setPopupIsLoading(true);
      });
  }

  return (
    <React.Fragment>
      {!isLoading ? (
        <React.Fragment>
          <MetaTags>
            {realisation.attributes.metaTitre ? (
              <title>
                {"Tilt’ Équipement - " + realisation.attributes.metaTitre}
              </title>
            ) : (
              <title>
                {"Tilt’ Équipement - " + realisation.attributes.Titre}
              </title>
            )}
            {realisation.attributes.metaTitre ? (
              <>
                <meta
                  property="og:title"
                  content={
                    "Tilt' Équipement - " + realisation.attributes.metaTitre
                  }
                />
                <meta
                  property="title"
                  content={
                    "Tilt' Équipement - " + realisation.attributes.metaTitre
                  }
                />
              </>
            ) : (
              <>
                <meta
                  property="og:title"
                  content={"Tilt' Équipement - " + realisation.attributes.Titre}
                />
                <meta
                  property="title"
                  content={"Tilt' Équipement - " + realisation.attributes.Titre}
                />
              </>
            )}
            {realisation.attributes.metaDescription ? (
              <>
                <meta
                  name="description"
                  content={realisation.attributes.metaDescription}
                />
                <meta
                  name="og:description"
                  content={realisation.attributes.metaDescription}
                />
              </>
            ) : (
              <>
                <meta
                  name="description"
                  content={realisation.attributes.description}
                />
                <meta
                  name="og:description"
                  content={realisation.attributes.description}
                />
              </>
            )}
            <meta
              name="keywords"
              content={
                "Tilt'Équipement, Réalisations, Réalisation, " +
                realisation.attributes.Titre
              }
            />
            <meta
              property="og:image"
              content={
                API_URL + realisation.attributes.assets.data[0].attributes.url
              }
            />
            <meta
              name="og:url"
              content={
                "https://tilt-equipement.fr/realisation/" +
                realisation.attributes.slug
              }
            />
          </MetaTags>
          <Ariane arianeItem={arianeItem} />
          <Back href="/realisations" />
          <BannerRealisation
            pretitre={realisation.attributes.univer.data.attributes.titre}
            title={realisation.attributes.Titre}
            content={realisation.attributes.description}
            realisation={realisation}
          />
          <Container maxWidth="lg">
            <Grid
              container
              spacing={2}
              className="containerItemImageTextRealisation pt90 pb90"
            >
              <Grid
                item
                lg={4}
                md={12}
                xs={12}
                className="containItemImageRealisation"
              >
                {realisation.attributes.assets.data[0] && (
                  <React.Fragment>
                    {realisation.attributes.assets.data.map((item) => (
                      <div className="itemImageRealisation">
                        <ImageOptimizer formats={item.attributes.formats} />
                        {item.attributes.caption && (
                          <p>{item.attributes.caption}</p>
                        )}
                      </div>
                    ))}
                  </React.Fragment>
                )}
              </Grid>
              {realisation.attributes.details && (
                <Grid
                  item
                  lg={8}
                  md={12}
                  xs={12}
                  className="containItemTextRealisation"
                >
                  <div className="itemTextRealisation">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: realisation.attributes.details,
                      }}
                    ></p>
                  </div>
                </Grid>
              )}
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className="itemCouvertureRealisationContainer">
                  <img
                    style={{
                      height: couvSizing === "height" && "100%",
                      width: couvSizing === "width" && "100%",
                    }}
                    src={
                      API_URL +
                      realisation.attributes.couverture.data.attributes.formats
                        .medium.url
                    }
                    alt={realisation.attributes.Titre}
                  />
                  <div className="itemCouvertureRealisationPinsBox">
                    {realisation.attributes.Pins && (
                      <div
                        className="itemCouvertureRealisationPinsCanvas"
                        style={{
                          width:
                            Object.values(
                              realisation.attributes.couverture.data.attributes
                                .formats
                            )[0].width + "px",
                        }}
                      >
                        {realisation.attributes.Pins.data && (
                          <React.Fragment>
                            {JSON.parse(realisation.attributes.Pins.data).map(
                              (pins) => (
                                <div
                                  className="itemCouvertureRealisationPins"
                                  onClick={() => onPopup(pins.id)}
                                  style={{
                                    top: pins.top + "%",
                                    left: pins.left + "%",
                                  }}
                                  key={
                                    "itemCouvertureRealisationPins" +
                                    pins.left +
                                    " " +
                                    pins.top
                                  }
                                  // onClick={() => onPopup(pins.id)}
                                >
                                  <IconButton
                                    color="primary"
                                    aria-label="add to shopping cart"
                                  >
                                    <AddIcon />
                                  </IconButton>
                                </div>
                              )
                            )}
                          </React.Fragment>
                        )}
                      </div>
                    )}
                  </div>
                  {popupContent && (
                    <div
                      className={
                        showPopup
                          ? "pinsPopupOverlay onPopup"
                          : "pinsPopupOverlay"
                      }
                    >
                      <div className="pinsPopup">
                        <div className="pinsPopupHeader">
                          <IconButton
                            aria-label="close"
                            onClick={() => setShowPopup(false)}
                          >
                            <CloseIcon />
                          </IconButton>
                        </div>
                        <div className="pinsPopupContent">
                          {!popupIsLoading && (
                            <React.Fragment>
                              <p className="pinsPopupContentTitle">
                                {popupContent.attributes.nom}
                              </p>
                              {popupContent.attributes.images.data && (
                                <img
                                  className="pinsPopupContentImg"
                                  src={
                                    API_URL +
                                    popupContent.attributes.images.data[0]
                                      .attributes.formats.small.url
                                  }
                                  alt={"Carrousel"}
                                  loading="lazy"
                                />
                              )}
                            </React.Fragment>
                          )}
                        </div>
                        <div className="pinsPopupAction">
                          {!popupIsLoading && (
                            <React.Fragment>
                              <a
                                href={
                                  "../produit/" + popupContent.attributes.slug
                                }
                              >
                                Découvrir
                              </a>
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
          </Container>
          <SubFooter
            titleLeft="Créez votre agencement"
            descriptionLeft="Demandez votre devis et concevons ensemble votre projet de demain."
            contentbtnLeft="Demande de devis"
            hrefLeft={"../devis?realisations=" + realisation.attributes.slug}
            isRight
            titleRight="Nos catalogues"
            descriptionRight="Tous nos produits au fil des pages"
            contentbtnRight="Découvrir"
            hrefRight={"../catalogues"}
          />
          <ProposeRealisations currentRealisation={realisation} />
        </React.Fragment>
      ) : (
        <Grid spacing={1} container className="productViewContainer pt48 pb120">
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className="productViewSkelet"
          >
            <div style={{ height: "430px" }}></div>
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className="productViewSkelet"
          >
            <div style={{ height: "430px" }}></div>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}
