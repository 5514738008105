import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import API_URL from "../../Api";
import ImageOptimizer from "../utils/ImageOptimizer";
import DefaultLogo from "../../assets/image_produit_defaut_tiltequipement.svg";

export default function AssociateProductCard({ product }) {
  function changeProduct() {
    window.location.href = `/produit/${product.attributes.slug}`;
  }

  return (
    <React.Fragment>
      <Link className="associateProductsLink" onClick={changeProduct}>
        <div className="associateProductsCardInner">
          {product.attributes.images.data ? (
            <ImageOptimizer
              formats={product.attributes.images.data[0].attributes.formats}
            />
          ) : (
            <img src={DefaultLogo} alt="Absence de visuel pour ce produit" />
          )}
          {product.attributes.sous_category.data && (
            <span>
              {product.attributes.sous_category.data.attributes.titre.toUpperCase()}
            </span>
          )}
          <p>{product.attributes.nom}</p>
        </div>
      </Link>
    </React.Fragment>
  );
}
