import React, { useEffect, useState } from "react";
// import AddIcon from "@mui/icons-material/Add";
import API_URL from "../Api";
import ArrowButton from "../components/Atom/ArrowButton";
import playIcon from "../assets/play.svg";
import BesoinAide from "../components/molecules/BesoinAide";
import ProposeProducts from "../components/products/ProposeProducts";
import SRBanner from "../components/molecules/SRBanner";
import ShowUnivers from "../components/univers/ShowUnivers";
import SubFooter from "../components/molecules/SubFooter";
import { AnimationOnScroll } from "react-animation-on-scroll";
import ShowInspirations from "../components/inspirations/ShowInspirations";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Missions from "../components/molecules/Missions";
import ScrollDown from "../components/molecules/ScrollDown";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import { MetaTags } from "react-meta-tags";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import ImageOptimizer from "../components/utils/ImageOptimizer";
import { Container, Grid } from "@mui/material";

export default function Home() {
  const [realisationsIsLoading, setRealisationsIsLoading] = useState(true);
  const [realisationShowed, setRealisationShowed] = useState();

  // iframe video
  const [showVideo, setShowVideo] = useState(false);
  const [videoId, setVideoId] = useState();

  // gestion de la popup d'affichage du produit associé à un pins
  // const [productId, setProductId] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const [popupIsLoading, setPopupIsLoading] = useState(true);
  const [popupContent, setPopupContent] = useState(true);

  useEffect(() => {
    // on fetch les réalisations
    fetch(API_URL + "/api/page-d-accueil?populate=*", {
      method: "GET",
      headers: {
        Accept: "Application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        // on définit une réalisation à montrer de façon aléatoire dans les réalisations disponibles
        setRealisationShowed(response.data.attributes.carrousel.data);
        setRealisationsIsLoading(false);
        console.log(response);
      });
  }, []);

  // iframe video
  function handleIframe(url) {
    setVideoId(url.split("=")[1]);
    setShowVideo(true);
  }

  function closeIframe() {
    setVideoId();
    setShowVideo(false);
  }

  // function onPopup(productId) {
  //   // setProductId(productId);
  //   setShowPopup(true);
  //   // on fetch le produit séléctionné
  //   fetch(API_URL + "/api/produits/" + productId + "?populate=*", {
  //     method: "GET",
  //     headers: {
  //       Accept: "Application/json",
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((response) => {
  //       setPopupContent(response.data);
  //       setPopupIsLoading(false);
  //     })
  //     .catch((err) => {
  //       setPopupIsLoading(true);
  //     });
  // }

  return (
    <React.Fragment>
      <MetaTags>
        <title>
          Tilt’ Équipement - Agencement de magasin et équipement professionnel
        </title>
        <meta
          property="og:title"
          content={
            "Tilt’ Équipement - Agencement de magasin et équipement professionnel"
          }
        />
        <meta
          name="description"
          content={
            "Tilt’ Équipement accompagne les professionnels de tous secteurs d’activité, en France et à l’étranger, dans leur projet d’agencement et d’aménagement."
          }
        />
        <meta
          name="keywords"
          content={
            "Tilt'Équipement, Accueil, agencement, équipement professionnel, gondoles, rayonnage galvanisé, comptoirs, vestiaires, mobiliers de bureau"
          }
        />
      </MetaTags>
      <div className="homeContainer">
        <div className="homePresentationBanner">
          <div className="containGridHome">
            <Container maxWidth="xl">
              <Grid container>
                <Grid item lg={6} md={10} sm={12} xs={12}>
                  <AnimationOnScroll
                    animateIn="animate__fadeIn"
                    className="homePresentationBannerTopInfoText"
                  >
                    <p className="pretitre">Votre spécialiste</p>
                    <h1>
                      <span>Agencement </span>de magasin
                      <br />
                      et équipement professionel
                    </h1>
                    <p>
                      Tilt’ Équipement accompagne les professionnels de tous
                      secteurs d’activité partout en France et à l’étranger
                      depuis près de 25 ans.
                    </p>
                    <div className="homePresentationBannerSubInfo">
                      <ArrowButton
                        content={"Nos réalisations"}
                        href={"/realisations"}
                        // color="grey"
                      />
                      {/* <div
                    className="whoAreWeBtn"
                    onClick={() =>
                      handleIframe("https://www.youtube.com/watch?v=hSWld4TpXnc")
                    }
                  >
                    <div className="playIcon">
                      <img src={playIcon} alt={"Carrousel"} loading="lazy" />
                    </div>
                    <p>Qui sommes nous en vidéo ?</p>
                  </div> */}
                    </div>
                  </AnimationOnScroll>
                </Grid>
              </Grid>
            </Container>
            <div className="homePresentationBannerTopCarrousel">
              <Swiper
                slidesPerView={2}
                spaceBetween={30}
                pagination={{
                  clickable: true,
                }}
                loop={true}
                modules={[Autoplay, Pagination, Navigation]}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
                className="heroSwiper"
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  425: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                  },
                }}
              >
                {!realisationsIsLoading && (
                  <React.Fragment>
                    {realisationShowed.map((realisation) => (
                      <SwiperSlide
                        style={{ width: "100%" }}
                        key={"16vdf_" + realisation.attributes.name}
                      >
                        <img
                          alt={realisation.attributes.name}
                          src={API_URL + realisation.attributes.url}
                        />
                        {/* <ImageOptimizer
                        formats={realisation.attributes.formats}
                      /> */}
                      </SwiperSlide>
                    ))}
                  </React.Fragment>
                )}
              </Swiper>
            </div>
          </div>
          <ScrollDown />
        </div>
        <ProposeProducts />
        <SRBanner />
        <ShowUnivers />
        <ShowInspirations />
        <SubFooter
          titleLeft="Créez votre agencement"
          descriptionLeft="Demandez votre devis et concevons ensemble votre projet de demain."
          hrefLeft={"../devis"}
          contentbtnLeft="Demande de devis"
          citation
        />
        <Missions />
        <BesoinAide />
        <div
          className={
            showVideo && videoId
              ? "videoIframeOverlay showIframe"
              : "videoIframeOverlay "
          }
          onClick={(e) => closeIframe(e)}
        >
          <iframe
            src={
              "https://www.youtube.com/embed/" +
              videoId +
              "?autoplay=1&modestbranding=1&color=white&rel=0"
            }
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <div className="closeVideoIframe" onClick={closeIframe}>
            <CloseIcon />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
