import React, { useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import Layout from "./layouts/Layout";
import ReactGA from "react-ga4";
import { getCookieValue } from "./components/utils/CheckCookie";

function App() {
  useEffect(() => {
    const cookieConsent = getCookieValue("cookieConsent");
    // utilisation des cookies GA si l'utilisateur a accepté les cookies
    if (cookieConsent === "accepted") {
      ReactGA.initialize("G-R4Z81EJ3XT");
      ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname + window.location.search,
        title: window.location.pathname,
      });
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Layout />
    </ThemeProvider>
  );
}

export default App;
