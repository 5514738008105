import React, { useEffect, useState } from "react";
import API_URL from "../Api";
import Ariane from "../components/molecules/Ariane";
import Banner from "../components/molecules/Banner";
import Container from "@mui/material/Container";
import { MetaTags } from "react-meta-tags";
import ImageOptimizer from "../components/utils/ImageOptimizer";
import { Grid } from "@mui/material";

export default function Univers() {
  const [univers, setUnivers] = useState();
  const [universIsLoading, setUniversIsLoading] = useState(true);

  useEffect(() => {
    // on fetch les univers
    fetch(
      API_URL +
        "/api/univers?sort[0]=rank%3Aasc&populate[0]=slug&populate[1]=images&populate[2]=titre&populate[3]=description",
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        setUnivers(response.data);
        setUniversIsLoading(false);
      });
  }, []);

  const arianeItem = [
    ["/", "Tilt'Équipement"],
    ["/univers", "Univers"],
  ];

  return (
    <React.Fragment>
      <Ariane arianeItem={arianeItem} />
      <MetaTags>
        <title>Tilt’ Équipement - Univers</title>
        <meta property="og:title" content={"Tilt’ Équipement - Univers"} />
        <meta property="title" content={"Tilt’ Équipement - Univers"} />
        <meta
          name="description"
          content={
            "Tilt’ Équipement accompagne des secteurs d’activité variés et accorde une importance particulière à vous conseiller des solutions adaptées. Découvrez l’expertise de Tilt’ Équipement dans divers univers."
          }
        />
        <meta
          name="og:description"
          content={
            "Tilt’ Équipement accompagne des secteurs d’activité variés et accorde une importance particulière à vous conseiller des solutions adaptées. Découvrez l’expertise de Tilt’ Équipement dans divers univers."
          }
        />
        <meta name="keywords" content={"Tilt'Équipement, Univers"} />
        <meta name="og:url" content={"https://tilt-equipement.fr/univers"} />
      </MetaTags>
      <Banner
        title={"Univers"}
        content={
          "Tilt’ Équipement accompagne des secteurs d’activité variés et accorde une importance particulière à vous conseiller des solutions adaptées. Découvrez, dans ces quelques pages, l’expertise que Tilt’ Équipement a déjà apportée."
        }
      />
      <Container maxWidth="xl">
        <div className="universContainer">
          <div className="universBox">
            {!universIsLoading ? (
              <React.Fragment>
                {univers.map((univer) => (
                  <div className="universCard" key={"universCard" + univer.id}>
                    <a href={"univer/" + univer.attributes.slug}>
                      {univer.attributes.images.data[0].attributes.formats && (
                        <ImageOptimizer
                          formats={
                            univer.attributes.images.data[0].attributes.formats
                          }
                          requireFormat={"medium"}
                        />
                      )}
                      <div>
                        <p className="univerCardTitle">
                          {univer.attributes.titre}
                        </p>
                        <p className="univerCardDescription">
                          {univer.attributes.description}
                        </p>
                      </div>
                    </a>
                  </div>
                ))}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="productViewSkelet">
                  <div style={{ height: "100%" }}></div>
                </div>
                <div className="productViewSkelet">
                  <div style={{ height: "100%" }}></div>
                </div>
                <div className="productViewSkelet">
                  <div style={{ height: "100%" }}></div>
                </div>
                <div className="productViewSkelet">
                  <div style={{ height: "100%" }}></div>
                </div>
                <div className="productViewSkelet">
                  <div style={{ height: "100%" }}></div>
                </div>
                <div className="productViewSkelet">
                  <div style={{ height: "100%" }}></div>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
}
