import React, { useState } from "react";
import { Container, Grid } from "@mui/material";
import Banner from "../components/molecules/Banner";
import Ariane from "../components/molecules/Ariane";
import { MetaTags } from "react-meta-tags";

export default function PolitiqueConfidentialite() {
  const [arianeItem] = useState([
    ["/", "Tilt'Équipement"],
    ["/poltique-confidentialite", "Politique de confidentialité"],
  ]);

  return (
    <React.Fragment>
      <MetaTags>
        <title>Politique de confidentialité - Tilt' Équipement</title>
        <meta
          property="title"
          content={"Politique de confidentialité - Tilt' Équipement"}
        />
        <meta
          name="description"
          content={"Consultez la politique de confidentialité Tilt' Équipement"}
        />
        <meta
          name="keywords"
          content={"Tilt'Équipement, politique de confidentialité"}
        />
      </MetaTags>
      <Ariane arianeItem={arianeItem} />
      <Banner title="Politique de confidentialité" />
      <Container maxWidth="lg" className="pb120">
        <Grid container>
          <Grid item lg={10} xs={12}>
            <p style={{ marginBottom: "20px" }}>
              La présente politique de confidentialité est fondée sur le
              Règlement UE 2016/679 relatif à la protection des données (RGPD)
              ainsi que sur votre consentement éclairé. Elle a pour objectif de
              vous informer sur le traitement de vos données à caractère
              personnel.
            </p>
            <p style={{ marginBottom: "20px" }}>
              Cette politique de confidentialité ne s'applique qu'aux
              renseignements recueillis par le présent site, bien que ce dernier
              puisse contenir des liens vers d'autres sites externes. Vous
              comprenez bien que nous ne pouvons vous garantir que ces sites
              appliquent la même politique que nous en matière de respect de la
              vie privée, c'est pourquoi nous vous engageons à prendre
              connaissance des politiques de confidentialité des sites web que
              vous seriez amené(e) à visiter à partir de celui-ci avant de
              remettre vos données personnelles.
            </p>
            <p style={{ marginBottom: "20px" }}>
              Nous nous réservons la possibilité de modifier à tout moment cette
              politique de confidentialité, ces modifications entrant en vigueur
              immédiatement. Or, à chaque nouvelle utilisation du présent site,
              vous vous soumettez à la politique de confidentialité en vigueur.
            </p>
            <p style={{ marginBottom: "20px" }}>
              Aussi, nous vous invitons à relire régulièrement cette page afin
              de maintenir votre consentement.
            </p>
            <h3>Quels renseignements sont collectés sur ce site ?</h3>
            <p style={{ marginBottom: "20px" }}>
              Lorsque vous visitez un site internet, vous demeurez anonyme. Les
              informations collectées au cours de votre navigations servent à
              analyser votre comportement sur ce site et l'usage que vous en
              avez afin d'améliorer votre expérience utilisateur.
            </p>
            <p style={{ marginBottom: "20px" }}>
              Les autres données personnelles que nous collectons, tels que vos
              nom, prénom, adresse postale, numéro de téléphone ou adresse mail,
              ne sont collectées que si vous acceptez de nous les fournir, par
              exemple lorsque vous adhérez à notre newsletter, dans le cadre de
              la gestion de votre demande de devis ou si vous souhaitez être
              recontacté(e).
            </p>
            <p style={{ marginBottom: "20px" }}>
              Nous nous engageons par ailleurs à ne recueillir que les
              informations strictement nécessaires au traitement de votre
              demande.
            </p>
            <h3>Pourquoi collectons-nous vos données personnelles ?</h3>
            <ul style={{ marginBottom: "20px" }}>
              <p style={{ marginBottom: "20px" }}>
                Nous collectons vos données personnelles afin de :
              </p>
              <li>
                Gestion de la relation client (devis, commande, achat, demande
                de renseignements …)
              </li>
              <li>Suivre notre information (newsletter, …)</li>
            </ul>
            <h3>
              Comment vos données à caractère personnel sont-elles utilisées ?
            </h3>
            <p style={{ marginBottom: "20px" }}>
              Nous nous engageons à respecter la stricte confidentialité de vos
              données personnelles et de les utiliser uniquement pour les
              finalités pour lesquelles vous avez accepté de nous les
              communiquer. Toutefois, elles ne seront pas conservées plus
              longtemps que nécessaire, soit durant toute la durée de notre
              collaboration augmentée du délai de prescription légale. Après
              quoi, elles seront détruites.
            </p>
            <p style={{ marginBottom: "20px" }}>
              Nous pouvons être amenés à les transférer à des tiers tels que
              notre prestataire informatique, notre hébergeur, … Nous vous
              garantissons cependant qu'elles seront transférées selon un
              protocole sécurisé et traitées par ces derniers avec le plus grand
              soin et le maximum de sécurité.
            </p>
            <h3>Quels sont vos droits ?</h3>
            <p style={{ marginBottom: "20px" }}>
              Vous avez le droit de ne pas vouloir nous communiquer de données
              personnelles. Dans ce cas, vous pourrez naviguer sur ce site,
              cependant vous n'aurez pas accès à l'ensemble de ses
              fonctionnalités (adhérer à la newsletter par exemple, demander à
              être recontacté(e), …)
            </p>
            <p style={{ marginBottom: "20px" }}>
              Vous avez un droit d'accès, de correction et de retrait de vos
              données personnelles. Vous pouvez exercer ce droit, par exemple en
              les mettant à jour, en nous demandant de les rectifier, de les
              compléter, de les mettre à jour, de les verrouiller ou en nous
              demandant à ce que vos renseignements ne figurent plus sur notre
              liste de diffusion, en nous contactant à l'adresse suivante et en
              joignant à votre demande une copie d'une pièce d'identité en cours
              de validité (carte d'identité, passeport ou permis de conduire).
            </p>
            <p style={{ marginBottom: "20px" }}>
              Vous avez le droit d'introduire une réclamation auprès de la CNIL
              si vous l'estimez nécessaire. Dans ce cas, nous vous invitons à
              consulter leur site internet aux fins de connaître les modalités
              d'exécution <a href="www.cnil.fr">(www.cnil.fr)</a>.
            </p>
            <p style={{ marginBottom: "20px" }}>
              Vous avez également un droit à la portabilité de vos données à
              caractère personnel. Vous pouvez, à la même adresse que
              précédemment, demander à ce que l'on vous restitue sur un support
              utilisable toutes les données personnelles que vous nous avez
              fournies en vue par exemple de les transférer à un tiers.
            </p>
            <p style={{ marginBottom: "20px" }}>
              Enfin, vous avez un droit d'opposition au traitement de vos
              données pour des motifs légitimes ainsi qu'un droit d'opposition à
              ce que ces données soient utilisées à d'autres fins que celles
              auxquelles vous avez consenti(e). Vous pouvez user de ce droit en
              contactant le responsable de traitement à l'adresse rappelée ici
            </p>
            <h3>Et si j'ai encore des questions ?</h3>
            <p style={{ marginBottom: "20px" }}>
              Pour toutes questions concernant cette politique de
              confidentialité ou vos données à caractère personnel, n'hésitez
              pas à nous contacter à ou par courrier :
            </p>
            <i style={{ display: "block", marginBottom: "5px" }}>
              Tilt Équipement
            </i>
            <i style={{ display: "block", marginBottom: "5px" }}>
              10 Rue Jeanne Vivez
            </i>
            <i style={{ display: "block", marginBottom: "5px" }}>
              53200 Château-Gontier-sur-Mayenne
            </i>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
