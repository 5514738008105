import React from "react";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import ScrollDown from "../molecules/ScrollDown";
import ArrowButton from "../Atom/ArrowButton";

export default function Banner({ title, content, left, ArrowScrollShow, button, href }) {
  return (
    <section
      className={content ? "banner" : "banner bannerNoContent"}
      style={left ? { textAlign: "left" } : { textAlign: "center" }}
    >
      <Container maxWidth="xl" className="h100">
        <Grid
          container
          justifyContent={left ? "start" : "center"}
          className="h100"
          alignItems="center"
        >
          <Grid item lg={10} xs={12}>
            <h1>{title && title}</h1>
            {content && (
              <p style={left ? { textAlign: "left" } : { textAlign: "center" }}>
                {content}
              </p>
            )}
            {button && (
              <ArrowButton
                content={"Candidature spontanée"}
                color="grey"
                href={href}
                style={{marginTop: "32px"}}
              />
            )}
          </Grid>
        </Grid>
      </Container>
      {ArrowScrollShow ? <ScrollDown /> : null}
    </section>
  );
}
