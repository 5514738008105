import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { useParams } from "react-router-dom";
import API_URL from "../Api";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AddToFavorites from "../components/molecules/AddToFavorites";
import Ariane from "../components/molecules/Ariane";
import Banner from "../components/molecules/Banner";
import SubFooter from "../components/molecules/SubFooter";
import background from "../assets/mozaique-univers-1.png";
// import ProposeArticles from "../components/actualites/ProposeArticles";
import ImageOptimizer from "../components/utils/ImageOptimizer";

export default function Actualite() {
  const slug = useParams().slug;
  const [isLoading, setIsLoading] = useState(true);
  const [actualite, setActualite] = useState([]);
  const [arianeItem, setArianeItem] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetch(`${API_URL}/api/actualites?populate=*&filters[slug][$eq]=${slug}`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setActualite(response.data[0]);
        setArianeItem([
          ["/", "Tilt'Équipement"],
          ["/actualites", "Actualités"],
          ["/" + response.data[0].id, response.data[0].attributes.titre],
        ]);
        setIsLoading(false);
      });
  }, [slug]);

  return (
    <main className="uneactualite">
      {!isLoading && (
        <React.Fragment>
          <MetaTags>
            {actualite.attributes.metaTitre ? (
              <title>
                {"Tilt’ Équipement - " + actualite.attributes.metaTitre}
              </title>
            ) : (
              <title>
                {"Tilt’ Équipement - " + actualite.attributes.titre}
              </title>
            )}
            {actualite.attributes.metaTitre ? (
              <>
                <meta
                  property="og:title"
                  content={
                    actualite.attributes.metaTitre + " - Tilt' Équipement"
                  }
                />
                <meta
                  property="title"
                  content={
                    actualite.attributes.metaTitre + " - Tilt' Équipement"
                  }
                />
              </>
            ) : (
              <>
                <meta
                  property="og:title"
                  content={actualite.attributes.titre + " - Tilt' Équipement"}
                />
                <meta
                  property="title"
                  content={actualite.attributes.titre + " - Tilt' Équipement"}
                />
              </>
            )}
            {actualite.attributes.metaDescription ? (
              <>
                <meta
                  name="description"
                  content={actualite.attributes.metaDescription}
                />
                <meta
                  name="og:description"
                  content={actualite.attributes.metaDescription}
                />
              </>
            ) : (
              <>
                <meta name="description" content={actualite.attributes.titre} />
                <meta
                  name="og:description"
                  content={actualite.attributes.titre}
                />
              </>
            )}
            {actualite.attributes.images.data && (
              <meta
                property="og:image"
                content={
                  API_URL + actualite.attributes.images.data[0].attributes.url
                }
              />
            )}
            <meta
              name="keywords"
              content={
                "Tilt'Équipement, Actualités, Actualité, " +
                actualite.attributes.titre
              }
            />
            <meta
              name="og:url"
              content={
                "https://tilt-equipement.fr/actualite/" +
                actualite.attributes.slug
              }
            />
          </MetaTags>
          <Ariane arianeItem={arianeItem} />
          <Banner title={actualite.attributes.titre} />
        </React.Fragment>
      )}
      {!isLoading && (
        <React.Fragment>
          <Container maxWidht="xl" className="actualiteContainer pb90">
            <Grid container>
              <Grid item xs={12}>
                <div className="actualiteDate">
                  {actualite.attributes.publication ? (
                    <p>
                      {new Date(
                        actualite.attributes.publication
                      ).toLocaleDateString("fr-FR")}
                    </p>
                  ) : (
                    <p>
                      {new Date(
                        actualite.attributes.publishedAt
                      ).toLocaleDateString("fr-FR")}
                    </p>
                  )}
                  <div className="actualiteAddToFav">
                    <AddToFavorites label={"actualites"} content={actualite} />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div
                  className="actualiteDescription pt32"
                  dangerouslySetInnerHTML={{
                    __html: actualite.attributes.description,
                  }}
                ></div>
                <div className="actualiteImage">
                  <ImageOptimizer
                    formats={
                      actualite.attributes.images.data[0].attributes.formats
                    }
                  />
                </div>
              </Grid>
            </Grid>
          </Container>
          {/* <ProposeArticles currentActu={actualite} /> */}
        </React.Fragment>
      )}
      <SubFooter
        titleLeft="Vos univers"
        descriptionLeft="Tilt’ Équipement accompagne des secteurs d’activité variés et accorde une importance particulière à vous conseiller des solutions adaptées."
        hrefLeft={"../univers"}
        contentbtnLeft="Découvrir"
        isRight
        titleRight="Nos inspirations"
        descriptionRight="Parcourez notre galerie et trouvez les projets qui vous inspirent"
        hrefRight={"../inspirations"}
        contentbtnRight="Découvrir"
        backgroundCustom={background}
      />
    </main>
  );
}
