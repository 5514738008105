import React, { useEffect, useState } from "react";
import Ariane from "../components/molecules/Ariane";
import API_URL from "../Api";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Banner from "../components/molecules/Banner";
import { MetaTags } from "react-meta-tags";

export default function Emploies() {
  const [isLoading, setIsLoading] = useState(true);
  const [offres, setOffres] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [selectedContract, setSelectedContract] = useState();
  const [works, setWorks] = useState([]);
  const [selectedWork, setSelectedWork] = useState();
  const [location, setLocation] = useState([]);

  useEffect(() => {
    fetch(`${API_URL}/api/offres?populate=*`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setIsLoading(false);
        setOffres(response.data);
        for (const item of response.data) {
          if (!contracts.includes(item.attributes.contrat)) {
            contracts.push(item.attributes.contrat);
          }
          setSelectedContract(contracts[0]);
          if (!location.includes(item.attributes.localisation.toUpperCase())) {
            location.push(item.attributes.localisation.toUpperCase());
          }
          if (!works.includes(item.attributes.titre)) {
            works.push(item.attributes.titre);
          }
          setSelectedWork(works[0]);
        }
      });
  }, []);

  const arianeItem = [
    ["/", "Tilt'Équipement"],
    ["/realisations", "Nous rejoindre"],
  ];

  return (
    <React.Fragment>
      <Ariane arianeItem={arianeItem} />
      <MetaTags>
        <title>Tilt’ Équipement - Nous rejoindre</title>
        <meta
          property="og:title"
          content={"Tilt’ Équipement - Nous rejoindre"}
        />
        <meta property="title" content={"Tilt’ Équipement - Nous rejoindre"} />
        <meta
          name="description"
          content={
            "Consultez nos offres d’emploi, postulez ou transmettez votre candidature spontanée."
          }
        />
        <meta
          name="og:description"
          content={
            "Consultez nos offres d’emploi, postulez ou transmettez votre candidature spontanée."
          }
        />
        <meta name="keywords" content={"Tilt'Équipement, Emploies, Offres"} />
        <meta name="og:url" content={"https://tilt-equipement.fr/emploies"} />
      </MetaTags>
      {!isLoading && (
        <Banner
          left
          title={"Nous rejoindre"}
          content={
            "Consultez nos offres d’emploi ci-dessous et postulez directement, ou transmettez-nous votre candidature spontanée si aucune offre ne correspond à vos attentes."
          }
          ArrowScrollShow
          button
          href="/candidature"
        />
      )}
      <Container maxWidth="xl" className="emploiesContainer">
        <Grid container className="pt120 pb120">
          {!isLoading && (
            <Grid item xs={12}>
              {works[0] ? (
                <div className="emploiesBox">
                  <div className="emploiesFilter">
                    <div className="emploiesFilterCategory">
                      <p>Métier/Catégorie ?</p>
                      <select onChange={(e) => setSelectedWork(e.target.value)}>
                        {!isLoading && (
                          <React.Fragment>
                            {works.map((work) => (
                              <option key={"selectedWork" + work} value={work}>
                                {work}
                              </option>
                            ))}
                          </React.Fragment>
                        )}
                      </select>
                    </div>
                    <div className="emploiesFilterContract">
                      {!isLoading && (
                        <React.Fragment>
                          {contracts.map((contract) => (
                            <div
                              key={"emploiesFilterContractPins" + contract}
                              className={
                                selectedContract === contract
                                  ? "emploiesFilterContractPins selectedContract"
                                  : "emploiesFilterContractPins"
                              }
                              onClick={() => setSelectedContract(contract)}
                            >
                              {contract}
                            </div>
                          ))}
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                  <div className="emploiesList">
                    {!isLoading && (
                      <React.Fragment>
                        {offres.map((offre) => (
                          <React.Fragment key={"emploiesCard" + offre.id}>
                            {offre.attributes.contrat === selectedContract &&
                              offre.attributes.titre === selectedWork && (
                                <div className="emploiesCard">
                                  <a href={"emploie/" + offre.attributes.slug}>
                                    <div>
                                      <p className="emploiesTitle">
                                        {offre.attributes.titre}
                                      </p>
                                    </div>
                                    <div className="emploiesContracrtAndLocation">
                                      <span className="emploiesContract">
                                        {offre.attributes.contrat}
                                      </span>
                                      <span className="emploiesLocation">
                                        {offre.attributes.localisation}
                                      </span>
                                    </div>
                                    <div>
                                      <p className="emploiesDate">
                                        {new Date(
                                          offre.attributes.publishedAt
                                        ).toLocaleDateString("fr-FR")}
                                      </p>
                                    </div>
                                  </a>
                                </div>
                              )}
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    )}
                  </div>
                </div>
              ) : (
                <p>Aucun poste n'est à pourvoir pour le moment.</p>
              )}
            </Grid>
          )}
        </Grid>
      </Container>
    </React.Fragment>
  );
}
