import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import CardInspiration from "../../components/molecules/CardInspiration";
import API_URL from "../../Api";
import { AnimationOnScroll } from "react-animation-on-scroll";

export default function ShowInspirations() {
  const [isLoading, setIsLoading] = useState(true);
  const [inspirations, setInspirations] = useState([]);

  useEffect(() => {
    fetch(
      `${API_URL}/api/inspirations?populate[0]=slug&populate[1]=images&populate[2]=titre&randomSort=true&pagination[page]=1&pagination[pageSize]=4`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        setInspirations(response.data);
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="showInspirations pt120 pb120">
      <Container maxWidth="xl">
        <AnimationOnScroll animateIn="animate__fadeInDown">
          <h2 className="h2 textcenter pt120 color1">
            Imaginez votre futur projet
          </h2>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeIn">
          <p className="pretitre textcenter">
            Quantifiez votre approche budgétaire grâce à nos inspirations
          </p>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeIn">
          <a href="/inspirations" className="links">
            En découvrir plus
          </a>
        </AnimationOnScroll>
      </Container>
      <Container maxWidth="xl" className="contain-grid-show-inspiration pt64">
        <Grid container spacing={1}>
          {!isLoading && (
            <React.Fragment>
              {inspirations.map((inspiration) => (
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={6}
                  xs={12}
                  className="item-grid-show-inspiration"
                  key={"item-grid-show-inspiration" + inspiration.id}
                >
                  <AnimationOnScroll animateIn="animate__fadeIn">
                    <CardInspiration inspiration={inspiration} />
                  </AnimationOnScroll>
                </Grid>
              ))}
            </React.Fragment>
          )}
        </Grid>
      </Container>
    </div>
  );
}
