import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Snackbar from "@mui/material/Snackbar";
// import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";

export default function AddToFavorites({ label, content }) {
  // const [favorites, setFavorites] = useState();
  // const [isFavorites, setIsFavorites] = useState(false);
  const [isFav, setIsFav] = useState(false);
  const [openDialogAddFav, setOpenDialogAddFav] = useState(false);
  const [dialogContent, setDialogContent] = useState("");

  useEffect(() => {
    // SI AUCUN FAVORIS EN STORAGE, ON EN CRÉE UN
    if (!localStorage.tiltFav) {
      localStorage.setItem(
        "tiltFav",
        JSON.stringify({
          products: [],
          realisations: [],
          inspirations: [],
          actualites: [],
          sousunivers: [],
        })
      );
    }

    if (
      !JSON.parse(localStorage.tiltFav).products ||
      !JSON.parse(localStorage.tiltFav).realisations ||
      !JSON.parse(localStorage.tiltFav).inspirations ||
      !JSON.parse(localStorage.tiltFav).actualites ||
      !JSON.parse(localStorage.tiltFav).sousunivers
    ) {
      localStorage.setItem(
        "tiltFav",
        JSON.stringify({
          products: [],
          realisations: [],
          inspirations: [],
          actualites: [],
          sousunivers: [],
        })
      );
    }

    // VERRIFIE SI L'ARTICLE ACTIF EST DEJA EN FAVORIS OU NON
    // SI OUI, ON PASSE NOTRE FLAG SUR TRUE
    switch (label) {
      case "products":
        if (
          JSON.parse(localStorage.tiltFav).products?.find(
            (element) => element.id === content.id
          )
        ) {
          setIsFav(true);
        }
        break;
      case "realisations":
        if (
          JSON.parse(localStorage.tiltFav).realisations?.find(
            (element) => element.id === content.id
          )
        ) {
          setIsFav(true);
        }
        break;
      case "inspirations":
        if (
          JSON.parse(localStorage.tiltFav).inspirations?.find(
            (element) => element.id === content.id
          )
        ) {
          setIsFav(true);
        }
        break;
      case "actualites":
        if (
          JSON.parse(localStorage.tiltFav).actualites?.find(
            (element) => element.id === content.id
          )
        ) {
          setIsFav(true);
        }
        break;
      case "sous univer":
        if (
          JSON.parse(localStorage.tiltFav).sousunivers?.find(
            (element) => element.id === content.id
          )
        ) {
          setIsFav(true);
        }
        break;
      default:
    }
  }, [isFav]);

  function handleFavorites(e) {
    e.preventDefault();
    let actualFav;
    if (!isFav) {
      // si cet article n'est aps déjà en favoris
      // on l'ajoute ou il faut
      switch (label) {
        case "products":
          actualFav = JSON.parse(localStorage.tiltFav);
          actualFav.products.push(content);
          localStorage.setItem("tiltFav", JSON.stringify(actualFav));
          setIsFav(true);
          break;
        case "realisations":
          actualFav = JSON.parse(localStorage.tiltFav);
          actualFav.realisations.push(content);
          localStorage.setItem("tiltFav", JSON.stringify(actualFav));
          setIsFav(true);
          break;
        case "inspirations":
          actualFav = JSON.parse(localStorage.tiltFav);
          actualFav.inspirations.push(content);
          localStorage.setItem("tiltFav", JSON.stringify(actualFav));
          setIsFav(true);
          break;
        case "actualites":
          actualFav = JSON.parse(localStorage.tiltFav);
          actualFav.actualites.push(content);
          localStorage.setItem("tiltFav", JSON.stringify(actualFav));
          setIsFav(true);
          break;
        case "sous univer":
          actualFav = JSON.parse(localStorage.tiltFav);
          actualFav.sousunivers.push(content);
          localStorage.setItem("tiltFav", JSON.stringify(actualFav));
          setIsFav(true);
          break;
        default:
      }
      setOpenDialogAddFav(false);
      setOpenDialogAddFav(true);
      setDialogContent("Ajouté aux favoris");
    } else {
      // si cet article est déjà en favoris
      // on le supprime ou il faut
      switch (label) {
        case "products":
          actualFav = JSON.parse(localStorage.tiltFav);
          actualFav.products = actualFav.products.filter(
            (element) => element.id !== content.id
          );
          localStorage.setItem("tiltFav", JSON.stringify(actualFav));
          setIsFav(false);
          break;
        case "realisations":
          actualFav = JSON.parse(localStorage.tiltFav);
          actualFav.realisations = actualFav.realisations.filter(
            (element) => element.id !== content.id
          );
          localStorage.setItem("tiltFav", JSON.stringify(actualFav));
          setIsFav(false);
          break;
        case "inspirations":
          actualFav = JSON.parse(localStorage.tiltFav);
          actualFav.inspirations = actualFav.inspirations.filter(
            (element) => element.id !== content.id
          );
          localStorage.setItem("tiltFav", JSON.stringify(actualFav));
          setIsFav(false);
          break;
        case "actualites":
          actualFav = JSON.parse(localStorage.tiltFav);
          actualFav.actualites = actualFav.actualites.filter(
            (element) => element.id !== content.id
          );
          localStorage.setItem("tiltFav", JSON.stringify(actualFav));
          setIsFav(false);
          break;
        case "sous univer":
          actualFav = JSON.parse(localStorage.tiltFav);
          actualFav.sousunivers = actualFav.sousunivers.filter(
            (element) => element.id !== content.id
          );
          localStorage.setItem("tiltFav", JSON.stringify(actualFav));
          setIsFav(false);
          break;
        default:
      }
      setOpenDialogAddFav(false);
      setOpenDialogAddFav(true);
      setDialogContent("Supprimé des favoris");
    }
  }

  function handleCloseDialog(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    setOpenDialogAddFav(false);
  }

  const action = (
    <Button
      color="secondary"
      href="../favoris"
      size="small"
      aria-label="Voir mes favoris"
    >
      voir
    </Button>
  );

  return (
    <div>
      <IconButton
        title="Ajouter à ma liste d'envie"
        aria-label="Ajouter à ma liste d'envie"
        onClick={handleFavorites}
        className={isFav ? "IconButtonFavActive" : "IconButtonFav"}
      >
        {!isFav ? <FavoriteBorderIcon /> : <FavoriteIcon />}
      </IconButton>
      <Snackbar
        open={openDialogAddFav}
        autoHideDuration={6000}
        onClose={handleCloseDialog}
        message={dialogContent}
        action={action}
        sx={{ zIndex: 2000 }}
        className="containSnackbar"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
    </div>
  );
}
