import React from "react";
import Pagination from "@mui/material/Pagination";

export default function PaginationProducts({
  pageCount,
  page,
  setPage,
  pageSize,
  setPageSize,
}) {
  function handlePagination(e) {
    window.scrollTo(0, 480);
    setPage(e.target.textContent);
  }
  return (
    <React.Fragment>
      <div
        className="pagination pt64"
        style={{
          justifyContent: pageSize && setPageSize ? "space-evenly" : "center",
        }}
      >
        <Pagination
          onClick={(e) => handlePagination(e)}
          count={pageCount}
          variant="outlined"
        />
        {pageSize && setPageSize && (
          <select
            className="pageSizeSelect"
            defaultValue={pageSize}
            onChange={(e) => setPageSize(e.target.value)}
          >
            <option value={9}>9</option>
            <option value={18}>18</option>
            <option value={32}>32</option>
          </select>
        )}
      </div>
    </React.Fragment>
  );
}
