import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";

export default function CookiesBanner() {
  const [showModal, setShowModal] = useState(true);

  function acceptCookies() {
    // Enregistrer la préférence d'acceptation des cookies dans les cookies du navigateur
    document.cookie = "cookieConsent=accepted; max-age=31536000"; // Exemple : expiration après un an (en secondes)
    setShowModal(false);
    document.documentElement.classList.remove("noScroll");
  }

  function rejectCookies() {
    // Enregistrer la préférence de refus des cookies dans les cookies du navigateur
    document.cookie = "cookieConsent=rejected; max-age=31536000";
    setShowModal(false);
    document.documentElement.classList.remove("noScroll");
  }

  // window.addEventListener("scroll", function (e) {
  //   acceptCookies();
  // });

  // Vérifier si la bannière doit être affichée en fonction de la présence d'un cookie de consentement
  const shouldShowBanner =
    showModal && document.cookie.indexOf("cookieConsent") === -1;

  // if (shouldShowBanner) {
  //   document.documentElement.classList.add("noScroll");
  // }

  return (
    <>
      {shouldShowBanner && (
        <div className="cookieOverlay">
          <div className="cookieModal">
            {/* <h3>Cookies</h3> */}
            <p>
              Ce site utilise des cookies pour améliorer votre expérience. En
              continuant à utiliser ce site, vous acceptez notre politique de
              cookies.
            </p>
            <div>
              {/* <button>En savoir plus</button> */}
              <button onClick={() => acceptCookies()}>Accepter</button>
              <button onClick={() => rejectCookies()}>Refuser</button>
            </div>
            {/* <IconButton
              className="closeCookieBtn"
              aria-label="fermer"
              onClick={() => acceptCookies()}
            >
              <CloseIcon />
            </IconButton> */}
          </div>
        </div>
      )}
    </>
  );
}
