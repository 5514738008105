import React from 'react';
import { SvgIcon } from '@mui/material';

export const ExpoIcon = () => {
  return (
    <SvgIcon viewBox={'0 0 39.866 24.53'}>
      <path id="Tracé_30233" dataname="Tracé 30233" d="M17.9,103.281c-.385-.369-.777-.732-1.154-1.109a.817.817,0,0,1-.112-1.228.8.8,0,0,1,1.227.095q1.271,1.257,2.529,2.527a.81.81,0,0,1,.01,1.324q-1.256,1.272-2.527,2.529a.807.807,0,1,1-1.122-1.126c.4-.4.821-.772,1.233-1.157l-.069-.18c-.339,0-.679.015-1.017,0A46.991,46.991,0,0,1,5.948,103.3a13.386,13.386,0,0,1-4.224-1.909,5.833,5.833,0,0,1-.966-.874A2.68,2.68,0,0,1,.836,96.7a8.829,8.829,0,0,1,3.215-2.03c.4-.168.812-.315,1.226-.448a.794.794,0,1,1,.533,1.493c-.762.319-1.543.6-2.28.969a6.17,6.17,0,0,0-1.353.939,1.2,1.2,0,0,0,0,2.067,8.112,8.112,0,0,0,1.787,1.143,21.523,21.523,0,0,0,6.3,1.784c2.4.328,4.819.515,7.23.761a3.424,3.424,0,0,0,.346,0l.065-.1" transform="translate(0 -83.224)" stroke="#fff" strokeWidth="0.5"/>
      <path id="Tracé_30234" dataname="Tracé 30234" d="M192.925,104.746c-.073,0-.145,0-.218,0a.813.813,0,0,1-.854-.756.8.8,0,0,1,.777-.826c1.547-.145,3.1-.241,4.639-.432a28.235,28.235,0,0,0,7.632-1.842,10.1,10.1,0,0,0,2.274-1.336c.89-.7.854-1.43.016-2.194a9.721,9.721,0,0,0-3.325-1.761,2.726,2.726,0,0,1-.48-.2.76.76,0,0,1-.306-.95.743.743,0,0,1,.873-.485,10.578,10.578,0,0,1,4.581,2.484,2.7,2.7,0,0,1,.823,2.5,3.266,3.266,0,0,1-1.176,1.837,11.989,11.989,0,0,1-4.366,2.19,41.032,41.032,0,0,1-8.674,1.593c-.738.066-1.477.116-2.215.174" transform="translate(-169.544 -83.015)" stroke="#fff" strokeWidth="0.5"/>
      <path id="Tracé_30235" dataname="Tracé 30235" d="M148.583,31.474c.773,0,1.483,0,2.193,0a1.877,1.877,0,0,1,2.1,2.1c0,.625,0,1.25,0,1.875a1.83,1.83,0,0,1-1.907,1.928q-1.024.009-2.049,0a1.825,1.825,0,0,1-1.935-1.9c-.02-1.133-.005-2.267-.005-3.4,0-.945,0-1.89,0-2.834a1.878,1.878,0,0,1,2.053-2.063c.959,0,1.918,0,2.877,0,.613,0,.977.321.963.823-.013.487-.355.766-.964.77-.93.006-1.86.018-2.79-.005-.424-.011-.56.144-.543.56.028.694.008,1.39.008,2.153m0,1.608c0,.828,0,1.581,0,2.333,0,.287.139.38.4.377q.955-.009,1.911,0c.273,0,.4-.122.4-.394,0-.42,0-.84,0-1.26-.008-1.23.132-1.043-1.065-1.056-.542-.006-1.085,0-1.641,0" transform="translate(-129.884 -24.011)" stroke="#fff" strokeWidth="0.5"/>
      <path id="Tracé_30236" dataname="Tracé 30236" d="M211.83,32.268c0-.886-.007-1.773,0-2.659a2.334,2.334,0,0,1,2.385-2.409,9.222,9.222,0,0,1,1.649.073,2.19,2.19,0,0,1,1.829,2.1c.033,1.961.039,3.924,0,5.884A2.257,2.257,0,0,1,215.5,37.39c-.479.017-.959.016-1.438,0a2.321,2.321,0,0,1-2.23-2.245c0-.044,0-.087,0-.131q0-1.373,0-2.746m4.292.032c0-.9.007-1.8,0-2.7a.72.72,0,0,0-.741-.794,9.4,9.4,0,0,0-1.218,0,.727.727,0,0,0-.741.8q-.012,2.7,0,5.4a.751.751,0,0,0,.788.8c.319.016.639.006.958,0,.691-.006.954-.263.957-.943,0-.857,0-1.713,0-2.57" transform="translate(-187.198 -24.033)" stroke="#fff" strokeWidth="0.5"/>
      <path id="Tracé_30237" dataname="Tracé 30237" d="M86.6,33.089H83.52c-.145,0-.292.008-.435-.006a.819.819,0,0,1-.794-.821.782.782,0,0,1,.794-.767c.958-.024,1.917-.011,2.875-.014h.617c0-.842.015-1.65-.021-2.454,0-.09-.3-.235-.46-.239-.943-.023-1.888,0-2.832-.019a.945.945,0,0,1-.64-.177,1.285,1.285,0,0,1-.285-.8.636.636,0,0,1,.654-.592c1.219-.017,2.442-.057,3.655.034A1.711,1.711,0,0,1,88.19,29.01q.03,3.268,0,6.537a1.813,1.813,0,0,1-1.871,1.832c-1.031.009-2.062.005-3.093,0-.576,0-.942-.322-.935-.8s.369-.777.957-.779c.944,0,1.888-.017,2.832.007.41.01.535-.15.525-.537-.02-.709-.006-1.418-.006-2.179" transform="translate(-72.722 -24.018)" stroke="#fff" strokeWidth="0.5"/>
      <path id="Tracé_30238" dataname="Tracé 30238" d="M266.731,4.3A2.152,2.152,0,1,1,268.9,2.153,2.161,2.161,0,0,1,266.731,4.3m.024-1.585a.546.546,0,0,0,.529-.575.545.545,0,0,0-.593-.552.523.523,0,0,0-.509.589.542.542,0,0,0,.574.537" transform="translate(-233.806 0)" stroke="#fff" strokeWidth="0.5"/>
    </SvgIcon>
  );
};

export default ExpoIcon;
