import React from "react";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import gondoleImg from "../../assets/gondole_double_face_fond_grille.png";
import caddieImg from "../../assets/sh273-chariot-libre-service-avec-porte-bebe.png";
import chaisseImg from "../../assets/chaise-nut.png";
import caisseImg from "../../assets/meuble-de-caisse.png";
import brocheImg from "../../assets/sh054-Broche-double-avecPE-200.png";
import boisImg from "../../assets/agencement-bois-metal-tete-gondole.png";

export default function BannerProduct({ title, content }) {
  return (
    <section
      className={content ? "banner bannerProduct" : "banner bannerNoContent"}
    >
      <Container maxWidth="xl" className="">
        <Grid container justifyContent="center">
          <Grid item lg={10} xs={12} style={{ textAlign: "center" }}>
            <h1>{title && title}</h1>
            {content && <p>{content}</p>}
          </Grid>
        </Grid>
      </Container>
      <div className="containerImgFloating floating_1">
        <img src={gondoleImg} alt="gondole" width="240" height="260" />
      </div>
      <div className="containerImgFloating floating_2">
        <img src={caddieImg} alt="caddie" width="240" height="260" />
      </div>
      <div className="containerImgFloating floating_3">
        <img src={chaisseImg} alt="chaise" width="240" height="260" />
      </div>
      <div className="containerImgFloating floating_4">
        <img src={caisseImg} alt="caisse" width="240" height="260" />
      </div>
      <div className="containerImgFloating floating_5">
        <img src={brocheImg} alt="broche" width="240" height="260" />
      </div>
      <div className="containerImgFloating floating_6">
        <img src={boisImg} alt="bois" width="240" height="260" />
      </div>
    </section>
  );
}
