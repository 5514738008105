import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import API_URL from "../../Api";
import Checkbox from "@mui/material/Checkbox";

// ce composant :
// - récupère la famille et la valeur du filtre d'affichage de produit séléctionné dans l'accordéon
// - consulte le controller associé à la demande
// retourne un tableau de produit correpondant à la recherche
export default function ProductsFilters({
  setIsLoading,
  filter,
  filterMaster,
  setFilterMaster,
}) {
  // const [universList, setUniversList] = useState(null);
  // const [universIsLoading, setUniversIsLoading] = useState(true);

  // const [sousUniversList, setSousUniversList] = useState(null);
  // const [sousUniversIsLoading, setSousUniversIsLoading] = useState(true);

  const [categoriesList, setCategoriesList] = useState(null);
  const [categoriesIsLoading, setCategoriesIsLoading] = useState(true);
  const [expandedCategoriesIsLoading, setExpandedCategoriesIsLoading] =
    useState(true);

  const [sousCategoriesList, setSousCategoriesList] = useState(null);
  const [sousCategoriesIsLoading, setSousCategoriesIsLoading] = useState(true);
  const [expandedSousCategoriesIsLoading, setExpandedSousCategoriesIsLoading] =
    useState(true);

  const [productsTypesList, setProductsTypesList] = useState(null);
  const [productsTypesListLoading, setProductsTypesListIsLoading] =
    useState(true);
  const [expandedProductsTypesIsLoading, setExpandedProductsTypesIsLoading] =
    useState(true);

  const [expandedDispo, setExpandedDispo] = useState(true);

  // const [nuancesList, setNuancesList] = useState(null);
  // const [nuancesIsLoading, setNuancesIsLoading] = useState(true);

  // affichage des sous categories si leur catégorie parente est séléctionnée
  // const [showSousCategories, setShowSousCategories] = useState({
  //   state: false,
  //   id: null,
  // });
  // // affichage des types de produit si leur sous catégorie parente est séléctionnée
  // const [showProductsType, setShowProductsType] = useState({
  //   state: false,
  //   id: null,
  // });

  // modification du filtre actuel
  function handleFilter(filter, content, label) {
    setIsLoading(true);
    // if (filter === "univers") {
    //   // liste de tout les sous-univers disponibles
    //   fetch(
    //     API_URL +
    //       `/api/sous-univers?populate=*&filters[univer][titre][$eq]=${label}`,
    //     {
    //       method: "GET",
    //       headers: {
    //         Accept: "Application/json",
    //       },
    //     }
    //   )
    //     .then((res) => res.json())
    //     .then((response) => {
    //       setSousUniversList(response.data);
    //       setSousUniversIsLoading(false);
    //     });
    // }

    let actualFilters = filterMaster;

    if (
      filterMaster.find(
        (element) => element.label === label && element.filter === filter
      )
    ) {
      
      // si le filtre séléctionné est déjà présent dans les filtres actifs, alors il s'agit d'une suppression de ce filtre (même label)
      // on supprime le filtre existant si concerné (ex : si un filtre univers est présent et qu'on veut en ajouter un nouveau)
      setFilterMaster((filterMaster) =>
        filterMaster.filter((obj) => obj.filter !== filter)
      );
      actualFilters = actualFilters.filter((obj) => obj.filter !== filter);

      // si on supprime un filtre univers, alors il faut supprimer les filtres sous univers qui en découle
      if (filter === "univers") {
        setFilterMaster((filterMaster) =>
          filterMaster.filter((obj) => obj.filter !== "sous_univers")
        );
        actualFilters = actualFilters.filter(
          (obj) => obj.filter !== "sous_univers"
        );
      }

      // si on supprime un filtre categorie, alors il faut supprimer les filtres sous catégorie et type de produit qui en découle
      if (filter === "category") {
        setFilterMaster((filterMaster) =>
          filterMaster.filter((obj) => obj.filter !== "sous_category")
        );
        actualFilters = actualFilters.filter(
          (obj) => obj.filter !== "sous_category"
        );
        setFilterMaster((filterMaster) =>
          filterMaster.filter((obj) => obj.filter !== "types_de_produit")
        );
        actualFilters = actualFilters.filter(
          (obj) => obj.filter !== "types_de_produit"
        );
      }

      // si on supprime un filtre sous_categorie, alors il faut supprimer les filtres type de produit qui en découle
      if (filter === "category") {
        setFilterMaster((filterMaster) =>
          filterMaster.filter((obj) => obj.filter !== "types_de_produit")
        );
        actualFilters = actualFilters.filter(
          (obj) => obj.filter !== "types_de_produit"
        );
      }

      localStorage.setItem("tiltFilterProduct", JSON.stringify(actualFilters));
    } else {
      // sinon, il s'agit simplement de l'ajout d'un nouveau filtre
      // on supprime le filtre existant si concerné (ex : si un filtre univers est présent et qu'on veut en ajouter un nouveau)
      setFilterMaster((filterMaster) =>
        filterMaster.filter((obj) => obj.filter !== filter)
      );
      actualFilters = actualFilters.filter((obj) => obj.filter !== filter);
      // si on modifie un filtre univers, alors il faut supprimer les filtres sous univers qui en découle
      if (filter === "univers") {
        setFilterMaster((filterMaster) =>
          filterMaster.filter((obj) => obj.filter !== "sous_univers")
        );
        actualFilters = actualFilters.filter(
          (obj) => obj.filter !== "sous_univers"
        );
      }
      // si on modifie un filtre categorie, alors il faut supprimer les filtres sous catégorie et type de produit qui en découle
      if (filter === "category") {
        setFilterMaster((filterMaster) =>
          filterMaster.filter((obj) => obj.filter !== "sous_category")
        );
        actualFilters = actualFilters.filter(
          (obj) => obj.filter !== "sous_category"
        );
        setFilterMaster((filterMaster) =>
          filterMaster.filter((obj) => obj.filter !== "types_de_produit")
        );
        actualFilters = actualFilters.filter(
          (obj) => obj.filter !== "types_de_produit"
        );
      }
      // si on modifie un filtre sous_categorie, alors il faut supprimer les filtres type de produit qui en découle
      if (filter === "category") {
        setFilterMaster((filterMaster) =>
          filterMaster.filter((obj) => obj.filter !== "types_de_produit")
        );
        actualFilters = actualFilters.filter(
          (obj) => obj.filter !== "types_de_produit"
        );
      }

      // ajoute le nouveau filtre
      setFilterMaster((filterMaster) => [
        ...filterMaster,
        { content: content, filter: filter, label: label },
      ]);
      actualFilters = [
        ...actualFilters,
        { content: content, filter: filter, label: label },
      ];
      
      localStorage.setItem("tiltFilterProduct", JSON.stringify(actualFilters));
    }
  }

  useEffect(() => {
    // liste de tout les univers disponibles
    // fetch(API_URL + "/api/univers?populate=*", {
    //   method: "GET",
    //   headers: {
    //     Accept: "Application/json",
    //   },
    // })
    //   .then((res) => res.json())
    //   .then((response) => {
    //     setUniversList(response.data);
    //     setUniversIsLoading(false);
    //   });

    // if (filterMaster.find((element) => element.filter === "univers")) {
    //   // liste de tout les sous-univers disponibles
    //   fetch(
    //     API_URL +
    //       `/api/sous-univers?populate=*&filters[univer][titre][$eq]=${
    //         filterMaster.find((element) => element.filter === "univers").label
    //       }`,
    //     {
    //       method: "GET",
    //       headers: {
    //         Accept: "Application/json",
    //       },
    //     }
    //   )
    //     .then((res) => res.json())
    //     .then((response) => {
    //       setSousUniversList(response.data);
    //       setSousUniversIsLoading(false);
    //     });
    // }
    // liste de toutes les catégories disponibles
    fetch(API_URL + "/api/categories?sort[0]=rank%3Aasc", {
      method: "GET",
      headers: {
        Accept: "Application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setCategoriesList(response.data);
        setCategoriesIsLoading(false);
      });

    // liste de toutes les sous-catégories disponibles
    if (filterMaster.find((element) => element.filter === "category")) {
      fetch(
        API_URL +
          `/api/sous-categories?sort[0]=rank%3Aasc&populate[0]=titre&sort[0]=titre%3Aasc&filters[category][titre][$eqi]=${
            filterMaster.find((element) => element.filter === "category").label
          }`,
        {
          method: "GET",
          headers: {
            Accept: "Application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((response) => {
          setSousCategoriesList(response.data);
          setSousCategoriesIsLoading(false);
        });
    }

    // liste de toutes les sous-catégories disponibles
    if (filterMaster.find((element) => element.filter === "sous_category")) {
      fetch(
        API_URL +
          `/api/produit-types?sort[0]=rank%3Aasc&populate[0]=titre&sort[0]=titre%3Aasc&filters[sous_category][titre][$eq]=${
            filterMaster.find((element) => element.filter === "sous_category")
              .label
          }`,
        {
          method: "GET",
          headers: {
            Accept: "Application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((response) => {
          setProductsTypesList(response.data);
          setProductsTypesListIsLoading(false);
        });
    }

    // // liste de toutes les nuances disponibles
    // fetch(API_URL + "/api/nuances", {
    //   method: "GET",
    //   headers: {
    //     Accept: "Application/json",
    //   },
    // })
    //   .then((res) => res.json())
    //   .then((response) => {
    //     setNuancesIsLoading(false);
    //     setNuancesList(response.data);
    //   });

    // if (filterMaster.length > 0) {
    //   let categoryFilter = filterMaster.find(
    //     (element) => element.filter === "category"
    //   );
    //   if (categoryFilter !== undefined) {
    //     setShowSousCategories({
    //       state: true,
    //       id: filter.content,
    //     });
    //   } else {
    //     setShowSousCategories({
    //       state: false,
    //       id: null,
    //     });
    //     setFilterMaster(
    //       filterMaster.filter(function (obj) {
    //         return obj.filter !== "sous_category";
    //       })
    //     );
    //   }

    //   let sousCategoryFilter = filterMaster.find(
    //     (element) => element.filter === "sous_category"
    //   );
    //   if (sousCategoryFilter) {
    //     setShowProductsType({
    //       state: true,
    //       id: sousCategoryFilter.content,
    //     });
    //   } else {
    //     // si aucune sous catégorie n'est séléctionnée, alors on n'affiche pas les types de produits et on supprime ceux qui ont été séléctionnée
    //     setShowProductsType({
    //       state: false,
    //       id: null,
    //     });
    //     setFilterMaster(
    //       filterMaster.filter(function (obj) {
    //         return obj.filter !== "type_produit";
    //       })
    //     );
    //   }
    // } else {
    //   setShowSousCategories({
    //     state: false,
    //     id: null,
    //   });
    //   setShowProductsType({
    //     state: false,
    //     id: null,
    //   });
    // }
  }, [filterMaster]);

  return (
    <div>
      {/* <Accordion className="accordionFilter">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>UNIVERS</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ul className="accordionFilterList">
            {!universIsLoading && (
              <React.Fragment>
                {universList.map((univers) => (
                  <li
                    key={"unver" + univers.id}
                    onClick={() =>
                      handleFilter(
                        "univers",
                        univers.id,
                        univers.attributes.titre
                      )
                    }
                  >
                    <div className="accordionFilterParent">
                      <p>{univers.attributes.titre}</p>
                      {filterMaster.find(
                        (element) => element.label === univers.attributes.titre
                      ) ? (
                        <Checkbox checked={true} />
                      ) : (
                        <Checkbox checked={false} />
                      )}
                    </div>
                  </li>
                ))}
              </React.Fragment>
            )}
          </ul>
        </AccordionDetails>
      </Accordion> */}
      {/* {!sousUniversIsLoading &&
        filterMaster.find((element) => element.filter === "univers") && (
          <Accordion className="accordionFilter">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>SOUS UNIVERS</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul className="accordionFilterList">
                <React.Fragment>
                  {sousUniversList.map((sousUnivers) => (
                    <React.Fragment>
                      {sousUnivers.attributes.univer &&
                        sousUnivers.attributes.univer.data.id ===
                          filterMaster.find(
                            (element) => element.filter === "univers"
                          ).content && (
                          <li
                            key={"sous_univer_" + sousUnivers.id}
                            onClick={() =>
                              handleFilter(
                                "sous_univers",
                                sousUnivers.id,
                                sousUnivers.attributes.titre
                              )
                            }
                          >
                            <div className="accordionFilterParent">
                              <p>{sousUnivers.attributes.titre}</p>
                              {filterMaster.find(
                                (element) =>
                                  element.label === sousUnivers.attributes.titre
                              ) ? (
                                <Checkbox checked={true} />
                              ) : (
                                <Checkbox checked={false} />
                              )}
                            </div>
                          </li>
                        )}
                    </React.Fragment>
                  ))}
                </React.Fragment>
              </ul>
            </AccordionDetails>
          </Accordion>
        )} */}
      <Accordion
        className="accordionFilter"
        expanded={expandedCategoriesIsLoading}
        onChange={() =>
          setExpandedCategoriesIsLoading(!expandedCategoriesIsLoading)
        }
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography sx={{ fontWeight: "bold" }}>CATÉGORIES</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ul className="accordionFilterList">
            {!categoriesIsLoading && (
              <React.Fragment>
                {categoriesList.map((categorie) => (
                  <li
                    key={"categorie" + categorie.id}
                    onClick={() =>
                      handleFilter(
                        "category",
                        categorie.id,
                        categorie.attributes.titre
                      )
                    }
                  >
                    <div className="accordionFilterParent">
                      <p>{categorie.attributes.titre}</p>
                      {filterMaster.find(
                        (element) =>
                          element.label === categorie.attributes.titre
                      ) ? (
                        <Checkbox checked={true} />
                      ) : (
                        <Checkbox checked={false} />
                      )}
                    </div>
                  </li>
                ))}
              </React.Fragment>
            )}
          </ul>
        </AccordionDetails>
      </Accordion>
      {!sousCategoriesIsLoading &&
        filterMaster.find((element) => element.filter === "category") && (
          <Accordion
            expanded={expandedSousCategoriesIsLoading}
            onChange={() =>
              setExpandedSousCategoriesIsLoading(
                !expandedSousCategoriesIsLoading
              )
            }
            className="accordionFilter"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography sx={{ fontWeight: "bold" }}>
                SOUS-CATÉGORIES
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul className="accordionFilterList">
                <React.Fragment>
                  {sousCategoriesList.map((sousCategorie) => (
                    <React.Fragment>
                      <li
                        key={"sous-categorie" + sousCategorie.attributes.titre}
                        onClick={() =>
                          handleFilter(
                            "sous_category",
                            sousCategorie.id,
                            sousCategorie.attributes.titre
                          )
                        }
                      >
                        <div className="accordionFilterParent">
                          <p>{sousCategorie.attributes.titre}</p>
                          {filterMaster.find(
                            (element) =>
                              element.label === sousCategorie.attributes.titre
                          ) ? (
                            <Checkbox checked={true} />
                          ) : (
                            <Checkbox checked={false} />
                          )}
                        </div>
                      </li>
                    </React.Fragment>
                  ))}
                </React.Fragment>
              </ul>
            </AccordionDetails>
          </Accordion>
        )}
      {!productsTypesListLoading &&
        filterMaster.find((element) => element.filter === "sous_category") && (
          <Accordion
            className="accordionFilter"
            expanded={expandedProductsTypesIsLoading}
            onChange={() =>
              setExpandedProductsTypesIsLoading(!expandedProductsTypesIsLoading)
            }
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography sx={{ fontWeight: "bold" }}>
                TYPES DE PRODUITS
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul className="accordionFilterList">
                <React.Fragment>
                  {productsTypesList.map((productType) => (
                    <React.Fragment>
                      <li
                        key={"type-de-produit-" + productType.titre}
                        onClick={() =>
                          handleFilter(
                            "types_de_produit",
                            productType.id,
                            productType.attributes.titre
                          )
                        }
                      >
                        <div className="accordionFilterParent">
                          <p>{productType.attributes.titre}</p>
                          {filterMaster.find(
                            (element) =>
                              element.label === productType.attributes.titre
                          ) ? (
                            <Checkbox checked={true} />
                          ) : (
                            <Checkbox checked={false} />
                          )}
                        </div>
                      </li>
                    </React.Fragment>
                  ))}
                </React.Fragment>
              </ul>
            </AccordionDetails>
          </Accordion>
        )}
      {/* <Accordion className="accordionFilter">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{ fontWeight: "bold" }}>NUANCES</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ul className="accordionFilterList">
            {!nuancesIsLoading && (
              <React.Fragment>
                {nuancesList.map((nuance) => (
                  <li
                    key={"nuances" + nuance.id}
                    onClick={() =>
                      handleFilter("nuances", nuance.id, nuance.attributes.nom)
                    }
                  >
                    <div className="accordionFilterParent">
                      <p>{nuance.attributes.nom}</p>
                      {filterMaster.find(
                        (element) => element.label === nuance.attributes.nom
                      ) ? (
                        <Checkbox checked={true} />
                      ) : (
                        <Checkbox checked={false} />
                      )}
                    </div>
                  </li>
                ))}
              </React.Fragment>
            )}
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion className="accordionFilter">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{ fontWeight: "bold" }}>DIMENSIONS</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Accordion className="accordionFilterChildren">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ fontWeight: "bold" }}>LONGUEUR</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul className="accordionFilterList">
                <li
                  onClick={() => handleFilter("longueur", [0, 49], "0 - 49 cm")}
                >
                  <div className="accordionFilterParent">
                    <p>0 - 49 cm</p>
                    <input
                      type="checkbox"
                      checked={filterMaster.find(
                        (element) =>
                          element.filter === "longueur" &&
                          element.label === "0 - 49 cm"
                      )}
                    />
                  </div>
                </li>
                <li
                  onClick={() =>
                    handleFilter("longueur", [50, 99], "50 - 99 cm")
                  }
                >
                  <div className="accordionFilterParent">
                    <p>50 - 99 cm</p>
                    <input
                      type="checkbox"
                      checked={filterMaster.find(
                        (element) =>
                          element.filter === "longueur" &&
                          element.label === "50 - 99 cm"
                      )}
                    />
                  </div>
                </li>
                <li
                  onClick={() =>
                    handleFilter("longueur", [100, 149], "100 - 149 cm")
                  }
                >
                  <div className="accordionFilterParent">
                    <p>100 - 149 cm</p>
                    <input
                      type="checkbox"
                      checked={filterMaster.find(
                        (element) =>
                          element.filter === "longueur" &&
                          element.label === "100 - 149 cm"
                      )}
                    />
                  </div>
                </li>
                <li
                  onClick={() =>
                    handleFilter("longueur", [150, 200], "150 - 200 cm")
                  }
                >
                  <div className="accordionFilterParent">
                    <p>150 - 200 cm</p>
                    <input
                      type="checkbox"
                      checked={filterMaster.find(
                        (element) =>
                          element.filter === "longueur" &&
                          element.label === "150 - 200 cm"
                      )}
                    />
                  </div>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion className="accordionFilterChildren">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ fontWeight: "bold" }}>LARGEUR</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul className="accordionFilterSizePicker">
                <li
                  onClick={() => handleFilter("largeur", [0, 49], "0 - 49 cm")}
                >
                  <div className="accordionFilterParent">
                    <label>0 - 49 cm</label>
                    <input
                      type="checkbox"
                      checked={filterMaster.find(
                        (element) =>
                          element.label === "0 - 49 cm" &&
                          element.filter === "largeur"
                      )}
                    />
                  </div>
                </li>
                <li
                  onClick={() =>
                    handleFilter("largeur", [50, 99], "50 - 99 cm")
                  }
                >
                  <div className="accordionFilterParent">
                    <label>50 - 99 cm</label>
                    <input
                      type="checkbox"
                      checked={filterMaster.find(
                        (element) =>
                          element.label === "50 - 99 cm" &&
                          element.filter === "largeur"
                      )}
                    />
                  </div>
                </li>
                <li
                  onClick={() =>
                    handleFilter("largeur", [100, 149], "100 - 149 cm")
                  }
                >
                  <div className="accordionFilterParent">
                    <label>100 - 149 cm</label>
                    <input
                      type="checkbox"
                      checked={filterMaster.find(
                        (element) =>
                          element.label === "100 - 149 cm" &&
                          element.filter === "largeur"
                      )}
                    />
                  </div>
                </li>
                <li
                  onClick={() =>
                    handleFilter("largeur", [150, 200], "150 - 200 cm")
                  }
                >
                  <div className="accordionFilterParent">
                    <label>150 - 200 cm</label>
                    <input
                      type="checkbox"
                      checked={filterMaster.find(
                        (element) =>
                          element.label === "150 - 200 cm" &&
                          element.filter === "largeur"
                      )}
                    />
                  </div>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion className="accordionFilterChildren">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ fontWeight: "bold" }}>HAUTEUR</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul className="accordionFilterSizePicker">
                <li
                  onClick={() => handleFilter("hauteur", [0, 49], "0 - 49 cm")}
                >
                  <div className="accordionFilterParent">
                    <label>0 - 49 cm</label>
                    <input
                      type="checkbox"
                      checked={filterMaster.find(
                        (element) =>
                          element.label === "0 - 49 cm" &&
                          element.filter === "hauteur"
                      )}
                    />
                  </div>
                </li>
                <li
                  onClick={() =>
                    handleFilter("hauteur", [50, 99], "50 - 99 cm")
                  }
                >
                  <div className="accordionFilterParent">
                    <label>50 - 99 cm</label>
                    <input
                      type="checkbox"
                      checked={filterMaster.find(
                        (element) =>
                          element.label === "50 - 99 cm" &&
                          element.filter === "hauteur"
                      )}
                    />
                  </div>
                </li>
                <li
                  onClick={() =>
                    handleFilter("hauteur", [100, 149], "100 - 149 cm")
                  }
                >
                  <div className="accordionFilterParent">
                    <label>100 - 149 cm</label>
                    <input
                      type="checkbox"
                      checked={filterMaster.find(
                        (element) =>
                          element.label === "100 - 149 cm" &&
                          element.filter === "hauteur"
                      )}
                    />
                  </div>
                </li>
                <li
                  onClick={() =>
                    handleFilter("hauteur", [150, 200], "150 - 200 cm")
                  }
                >
                  <div className="accordionFilterParent">
                    <label>150 - 200 cm</label>
                    <input
                      type="checkbox"
                      checked={filterMaster.find(
                        (element) =>
                          element.label === "150 - 200 cm" &&
                          element.filter === "hauteur"
                      )}
                    />
                  </div>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion className="accordionFilterChildren">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ fontWeight: "bold" }}>PROFONDEUR</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul className="accordionFilterSizePicker">
                <li
                  onClick={() =>
                    handleFilter("profondeur", [0, 49], "0 - 49 cm")
                  }
                >
                  <div className="accordionFilterParent">
                    <label>0 - 49 cm</label>
                    <input
                      type="checkbox"
                      checked={filterMaster.find(
                        (element) =>
                          element.label === "0 - 49 cm" &&
                          element.filter === "profondeur"
                      )}
                    />
                  </div>
                </li>
                <li
                  onClick={() =>
                    handleFilter("profondeur", [50, 99], "50 - 99 cm")
                  }
                >
                  <div className="accordionFilterParent">
                    <label>50 - 99 cm</label>
                    <input
                      type="checkbox"
                      checked={filterMaster.find(
                        (element) =>
                          element.label === "50 - 99 cm" &&
                          element.filter === "profondeur"
                      )}
                    />
                  </div>
                </li>
                <li
                  onClick={() =>
                    handleFilter("profondeur", [100, 149], "100 - 149 cm")
                  }
                >
                  <div className="accordionFilterParent">
                    <label>100 - 149 cm</label>
                    <input
                      type="checkbox"
                      checked={filterMaster.find(
                        (element) =>
                          element.label === "100 - 149 cm" &&
                          element.filter === "profondeur"
                      )}
                    />
                  </div>
                </li>
                <li
                  onClick={() =>
                    handleFilter("profondeur", [150, 200], "150 - 200 cm")
                  }
                >
                  <div className="accordionFilterParent">
                    <label>150 - 200 cm</label>
                    <input
                      type="checkbox"
                      checked={filterMaster.find(
                        (element) =>
                          element.label === "150 - 200 cm" &&
                          element.filter === "profondeur"
                      )}
                    />
                  </div>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion className="accordionFilterChildren">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ fontWeight: "bold" }}>DIAMETRE</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul className="accordionFilterSizePicker">
                <li>
                  <div className="accordionFilterParent">
                    <label>0 - 49 cm</label>
                    <input
                      type="checkbox"
                      onClick={() =>
                        handleFilter("diametre", [0, 49], "0 - 49 cm")
                      }
                      checked={filterMaster.find(
                        (element) =>
                          element.label === "0 - 49 cm" &&
                          element.filter === "diametre"
                      )}
                    />
                  </div>
                </li>
                <li>
                  <div className="accordionFilterParent">
                    <label>50 - 99 cm</label>
                    <input
                      type="checkbox"
                      onClick={() =>
                        handleFilter("diametre", [50, 99], "50 - 99 cm")
                      }
                      checked={filterMaster.find(
                        (element) =>
                          element.label === "50 - 99 cm" &&
                          element.filter === "diametre"
                      )}
                    />
                  </div>
                </li>
                <li>
                  <div className="accordionFilterParent">
                    <label>100 - 149 cm</label>
                    <input
                      type="checkbox"
                      onClick={() =>
                        handleFilter("diametre", [100, 149], "100 - 149 cm")
                      }
                      checked={filterMaster.find(
                        (element) =>
                          element.label === "100 - 149 cm" &&
                          element.filter === "diametre"
                      )}
                    />
                  </div>
                </li>
                <li>
                  <div className="accordionFilterParent">
                    <label>150 - 200 cm</label>
                    <input
                      type="checkbox"
                      onClick={() =>
                        handleFilter("diametre", [150, 200], "150 - 200 cm")
                      }
                      checked={filterMaster.find(
                        (element) =>
                          element.label === "150 - 200 cm" &&
                          element.filter === "diametre"
                      )}
                    />
                  </div>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion className="accordionFilterChildren">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ fontWeight: "bold" }}>ÉPAISSEUR</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul className="accordionFilterSizePicker">
                <li
                  onClick={() =>
                    handleFilter("epaisseur", [0, 49], "0 - 49 cm")
                  }
                >
                  <div className="accordionFilterParent">
                    <label>0 - 49 cm</label>
                    <input
                      type="checkbox"
                      checked={filterMaster.find(
                        (element) =>
                          element.label === "0 - 49 cm" &&
                          element.filter === "epaisseur"
                      )}
                    />
                  </div>
                </li>
                <li
                  onClick={() =>
                    handleFilter("epaisseur", [50, 99], "50 - 99 cm")
                  }
                >
                  <div className="accordionFilterParent">
                    <label>50 - 99 cm</label>
                    <input
                      type="checkbox"
                      checked={filterMaster.find(
                        (element) =>
                          element.label === "50 - 99 cm" &&
                          element.filter === "epaisseur"
                      )}
                    />
                  </div>
                </li>
                <li
                  onClick={() =>
                    handleFilter("epaisseur", [100, 149], "100 - 149 cm")
                  }
                >
                  <div className="accordionFilterParent">
                    <label>100 - 149 cm</label>
                    <input
                      type="checkbox"
                      checked={filterMaster.find(
                        (element) =>
                          element.label === "100 - 149 cm" &&
                          element.filter === "epaisseur"
                      )}
                    />
                  </div>
                </li>
                <li
                  onClick={() =>
                    handleFilter("epaisseur", [150, 200], "150 - 200 cm")
                  }
                >
                  <div className="accordionFilterParent">
                    <label>150 - 200 cm</label>
                    <input
                      type="checkbox"
                      checked={filterMaster.find(
                        (element) =>
                          element.label === "150 - 200 cm" &&
                          element.filter === "epaisseur"
                      )}
                    />
                  </div>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion className="accordionFilterChildren">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ fontWeight: "bold" }}>POIDS</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul className="accordionFilterSizePicker">
                <li>
                  <div className="accordionFilterParent">
                    <label>0 - 49 kg</label>
                    <input
                      type="checkbox"
                      onClick={() =>
                        handleFilter("poids", [0, 49], "0 - 49 kg")
                      }
                      checked={filterMaster.find(
                        (element) =>
                          element.label === "0 - 49 kg" &&
                          element.filter === "poids"
                      )}
                    />
                  </div>
                </li>
                <li>
                  <div className="accordionFilterParent">
                    <label>50 - 99 kg</label>
                    <input
                      type="checkbox"
                      onClick={() =>
                        handleFilter("poids", [50, 99], "50 - 99 kg")
                      }
                      checked={filterMaster.find(
                        (element) =>
                          element.label === "50 - 99 kg" &&
                          element.filter === "poids"
                      )}
                    />
                  </div>
                </li>
                <li>
                  <div className="accordionFilterParent">
                    <label>100 - 149 kg</label>
                    <input
                      type="checkbox"
                      onClick={() =>
                        handleFilter("poids", [100, 149], "100 - 149 kg")
                      }
                      checked={filterMaster.find(
                        (element) =>
                          element.label === "100 - 149 kg" &&
                          element.filter === "poids"
                      )}
                    />
                  </div>
                </li>
                <li>
                  <div className="accordionFilterParent">
                    <label>150 - 200 kg</label>
                    <input
                      type="checkbox"
                      onClick={() =>
                        handleFilter("poids", [150, 200], "150 - 200 kg")
                      }
                      checked={filterMaster.find(
                        (element) =>
                          element.label === "150 - 200 kg" &&
                          element.filter === "poids"
                      )}
                    />
                  </div>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion className="accordionFilterChildren">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ fontWeight: "bold" }}>CHARGE</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul className="accordionFilterSizePicker">
                <li
                  onClick={() => handleFilter("charge", [0, 49], "0 - 49 kg")}
                >
                  <div className="accordionFilterParent">
                    <label>0 - 49 kg</label>
                    <input
                      type="checkbox"
                      checked={filterMaster.find(
                        (element) =>
                          element.label === "0 - 49 kg" &&
                          element.filter === "charge"
                      )}
                    />
                  </div>
                </li>
                <li
                  onClick={() => handleFilter("charge", [50, 99], "50 - 99 kg")}
                >
                  <div className="accordionFilterParent">
                    <label>50 - 99 kg</label>
                    <input
                      type="checkbox"
                      checked={filterMaster.find(
                        (element) =>
                          element.label === "50 - 99 kg" &&
                          element.filter === "charge"
                      )}
                    />
                  </div>
                </li>
                <li
                  onClick={() =>
                    handleFilter("charge", [100, 149], "100 - 149 kg")
                  }
                >
                  <div className="accordionFilterParent">
                    <label>100 - 149 kg</label>
                    <input
                      type="checkbox"
                      checked={filterMaster.find(
                        (element) =>
                          element.label === "100 - 149 kg" &&
                          element.filter === "charge"
                      )}
                    />
                  </div>
                </li>
                <li
                  onClick={() =>
                    handleFilter("charge", [150, 200], "150 - 200 kg")
                  }
                >
                  <div className="accordionFilterParent">
                    <label>150 - 200 kg</label>
                    <input
                      type="checkbox"
                      checked={filterMaster.find(
                        (element) =>
                          element.label === "150 - 200 kg" &&
                          element.filter === "charge"
                      )}
                    />
                  </div>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
        </AccordionDetails>
      </Accordion> */}
      <Accordion
        className="accordionFilter"
        expanded={expandedDispo}
        onChange={() => setExpandedDispo(!expandedDispo)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{ fontWeight: "bold" }}>DISPONIBILITÉ</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ul className="accordionFilterList">
            <li onClick={() => handleFilter("dispo", "En stock", "En stock")}>
              <div className="accordionFilterParent">
                <p>En stock</p>
                {filterMaster.find(
                  (element) => element.label === "En stock"
                ) ? (
                  <Checkbox checked={true} />
                ) : (
                  <Checkbox checked={false} />
                )}
              </div>
            </li>
            <li
              onClick={() =>
                handleFilter("dispo", "Sur commande", "Sur commande")
              }
            >
              <div className="accordionFilterParent">
                <p>Sur commande</p>
                {filterMaster.find(
                  (element) => element.label === "Sur commande"
                ) ? (
                  <Checkbox checked={true} />
                ) : (
                  <Checkbox checked={false} />
                )}
              </div>
            </li>
            <li
              onClick={() =>
                handleFilter(
                  "dispo",
                  "Sous 4 semaines environ",
                  "Sous 4 semaines environ"
                )
              }
            >
              <div className="accordionFilterParent">
                <p>Sous 4 semaines environ</p>
                {filterMaster.find(
                  (element) => element.label === "Sous 4 semaines environ"
                ) ? (
                  <Checkbox checked={true} />
                ) : (
                  <Checkbox checked={false} />
                )}
              </div>
            </li>
          </ul>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
