import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import nomenclature from "../assets/gondole-eclate.png";
import ArrowButton from "../components/Atom/ArrowButton";
import { Container, Grid, Link } from "@mui/material";
import { Navigation, Pagination } from "swiper";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Swiper, SwiperSlide } from "swiper/react";
import API_URL from "../Api";
import ImageOptimizer from "../components/utils/ImageOptimizer";
import SubFooter from "../components/molecules/SubFooter";
import pdf from "../assets/caracteristiques-techniques-gondole-sr-l-evolutif.pdf";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

// import guillemet from "../assets/guillemet.png";
// import { Container, Grid, Paper } from "@mui/material";

export default function SREvolutif() {
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState();

  const [realisations, setRealisations] = useState();
  const [realisationsIsLoading, setRealisationsIsLoading] = useState(true);

  const [srevolutif, setSrevolutif] = useState([]);
  const [srevolutifIsLoading, setSrevolutifIsLoading] = useState(true);

  const [open, setOpen] = React.useState(false);
  const [currentItem, setcurrentItem] = useState();
  function handleOpen(item) {
    setcurrentItem(item);
    setOpen(true);
  }
  const handleClose = () => setOpen(false);

  function shuffle(Array) {
    return Array.sort(() => Math.random() - 0.5);
  }

  useEffect(() => {
    fetch(
      `${API_URL}/api/produits?populate[0]=slug&populate[1]=images&populate[2]=category&populate[3]=nom&populate[4]=univers&filters[nom][$containsi]=SR`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        // mélange le tableau
        setProducts(shuffle(response.data));
        // si plus de 10 item => rogne le tableau à 10 items
        if (response.length > 10) {
          setProducts(products.slice(9));
        }
        setIsLoading(false);
      });

    fetch(`${API_URL}/api/realisations?populate=*`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        // mélange le tableau
        // si plus de 10 item => rogne le tableau à 10 items
        // on supprime la proposition comportant le même nom que l'inspiration active
        setRealisations(shuffle(response.data.slice(0, 9)));
        setRealisationsIsLoading(false);
      });

    fetch(`${API_URL}/api/sr-evolutif?populate=*`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setSrevolutif(response.data);
        setSrevolutifIsLoading(false);
      });
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: '100%',
    maxWidth: 600,
    maxHeight: "90vh",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 4,
    p: 4,
    overflow: "auto",
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>SR l'évolutif® - Tilt' Équipement</title>
        <meta
          property="og:title"
          content={"SR l'évolutif® - Tilt' Équipement"}
        />
        <meta property="title" content={"SR l'évolutif® - Tilt' Équipement"} />
        <meta
          name="description"
          content={
            "SR l'évolutif® est une gamme de gondoles et accessoires déposée en 2008 par Tilt'Équipement."
          }
        />
        <meta
          name="og:description"
          content={
            "SR L'évolutif® est une gamme de gondoles et accessoires déposée en 2008 par Tilt'Équipement."
          }
        />
        <meta name="keywords" content={"Tilt'Équipement, SR l'évolutif®"} />
        <meta
          name="og:url"
          content={"https://tilt-equipement.fr/sr-evolutif"}
        />
      </MetaTags>
      <div className="SRContainer">
        <section className="SRIntroBox">
          <div className="SRIntro">
            <h1>SR l'évolutif®</h1>
            <h2>Concevez votre gondole pour toutes les surfaces</h2>
            <p>
              <span>SR l'évolutif®</span> est une marque de gondoles et
              accessoires déposée en <span>2008</span> par{" "}
              <span>Tilt' Équipement.</span>
            </p>
          </div>
          <div className="SRIntroImg">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </section>
        <section className="SRConception">
          <div className="SRConceptionLeft">
            <div className="content-img_gondoleSr">
              <img src={nomenclature} alt="nomenclature éclatée" />
              {!srevolutifIsLoading && (
                <>
                  {srevolutif.attributes.partie_gondole.map((item) => (
                    <button
                      key={"numberGondole" + item.titre}
                      onClick={() => handleOpen(item)}
                      className={"numberGondoleSr pinNumber" + item.numero}
                    >
                      {item.numero}
                    </button>
                  ))}
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      {currentItem && (
                        <div>
                          <h3>{currentItem.titre}</h3>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: currentItem.description,
                            }}
                          ></div>
                        </div>
                      )}
                    </Box>
                  </Modal>
                </>
              )}
            </div>
          </div>
          <div className="SRConceptionRight">
            <div className="SRConceptionRightPres">
              <h2>Les caractéristiques de nos gondoles SR l'évolutif®</h2>
              <p>
                Cette gamme est le fruit d’un{" "}
                <b>savoir-faire de plus de 20 ans</b>.
              </p>
              <ul>
                <li>
                  - Un <b>rapport qualité/prix</b> compétitif
                </li>
                <li>
                  - Des <b>produits</b> modulables et faciles à monter
                </li>
                <li>
                  - De <b>nombreuses dimensions</b> et <b>coloris</b> en
                  <b> stock</b>
                </li>
                <li>
                  - Une large gamme d’accessoires <b>compatibles</b>
                </li>
              </ul>
            </div>
            <div className="SRConceptionRightDl">
              <ArrowButton
                content={"Télécharger le pdf des caractéristiques"}
                color="grey"
                target="_blank"
                href={pdf}
              />
            </div>
            <div className="SRConceptionRightNotice">
              <div style={{ marginRight: "16px" }}>
                <b>SR</b>
                <p>
                  Les initiales de Serge <br /> Racineux (Fondateur)
                </p>
              </div>
              <div>
                <b>l'évolutif®</b>
                <p>
                  En référence à la capacité d’adaptation de nos gammes sur le
                  marché
                </p>
              </div>
            </div>
          </div>
        </section>
        <div className="SRProduct">
          {!isLoading && products.length > 0 && (
            <section className="proposeProductsContainer pb120">
              <Container maxWidth="xl">
                <Grid container spacing={1}>
                  <Grid item lg={3} md={3} sm={12} xs={12}>
                    <AnimationOnScroll animateIn="animate__fadeIn">
                      <h2 className="h2 pt120">
                        <span>
                          <a href="/produits">Produit</a>
                        </span>
                        <br />
                        <a href="/produits">SR l'évolutif®</a>
                      </h2>
                      <a
                        href="/produits"
                        className="links proposeProductsShowMore"
                      >
                        En découvrir plus
                      </a>
                    </AnimationOnScroll>
                  </Grid>
                  <Grid item lg={9} md={9} sm={12} xs={12}>
                    <Swiper
                      slidesPerView="auto"
                      spaceBetween={15}
                      navigation={true}
                      modules={[Pagination, Navigation]}
                      className="proposeProductSwiper"
                      breakpoints={{
                        "@0.00": {
                          slidesPerView: 1,
                          spaceBetween: 10,
                        },
                        "@0.50": {
                          slidesPerView: 2,
                          spaceBetween: 15,
                        },
                        "@0.75": {
                          slidesPerView: 3,
                          spaceBetween: 15,
                        },
                        "@1.00": {
                          slidesPerView: 3,
                          spaceBetween: 15,
                        },
                        "@1.50": {
                          slidesPerView: 4,
                          spaceBetween: 15,
                        },
                      }}
                    >
                      {!isLoading && (
                        <React.Fragment>
                          {products.map((product) => (
                            <React.Fragment>
                              {product.attributes.univers.data && (
                                <SwiperSlide
                                  key={product.id + " proposeProductsCardCase"}
                                  className="proposeProductsCardCase"
                                >
                                  <AnimationOnScroll animateIn="animate__fadeIn">
                                    <Link
                                      underline="none"
                                      href={
                                        "../produit/" + product.attributes.slug
                                      }
                                      className="proposeProductsLink"
                                      color="inherit"
                                    >
                                      <div className="proposeProductsCard">
                                        {product.attributes.images.data && (
                                          <ImageOptimizer
                                            formats={
                                              product.attributes.images.data[0]
                                                .attributes.formats
                                            }
                                          />
                                        )}
                                        <span>
                                          {product.attributes.category.data &&
                                            product.attributes.category.data
                                              .attributes.titre}
                                        </span>
                                        <p>
                                          {product.attributes.nom.slice(0, 40) +
                                            "..."}
                                        </p>
                                      </div>
                                    </Link>
                                  </AnimationOnScroll>
                                </SwiperSlide>
                              )}
                            </React.Fragment>
                          ))}
                        </React.Fragment>
                      )}
                    </Swiper>
                  </Grid>
                </Grid>
              </Container>
            </section>
          )}
        </div>
        <SubFooter
          titleLeft="Créez votre agencement"
          descriptionLeft="Demandez votre devis et concevons ensemble votre projet de demain."
          hrefLeft={"../devis"}
          contentbtnLeft="Demande de devis"
        />
        {!realisationsIsLoading && realisations.length > 0 && (
          <Grid
            container
            className="proposeRealisationContainer"
            sx={{ paddingLeft: "50px" }}
          >
            <Grid item lg={12}>
              <h2>Découvrez nos réalisations</h2>
            </Grid>
            <Swiper
              className="proposeRealisationCarousel"
              slidesPerView="auto"
              spaceBetween={15}
              navigation={true}
              modules={[Navigation]}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                },
                425: {
                  slidesPerView: 1,
                },
                576: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
                981: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
                1440: {
                  slidesPerView: 5,
                  spaceBetween: 10,
                },
              }}
            >
              {realisations.map((realisation) => (
                <SwiperSlide
                  key={realisation.id + " proposeRealisationCardCase"}
                  className="proposeRealisationCard"
                >
                  <AnimationOnScroll animateIn="animate__fadeIn">
                    <Link
                      underline="none"
                      href={"../realisation/" + realisation.attributes.slug}
                      color="inherit"
                    >
                      <div className="actuCardImg">
                        <ImageOptimizer
                          formats={
                            realisation.attributes.couverture.data.attributes
                              .formats
                          }
                        />
                      </div>
                      <div className="proposeRealisationCardNotice">
                        <span className="pretitre">
                          {realisation.attributes.univer.data.attributes.titre}
                        </span>
                        <p>{realisation.attributes.Titre}</p>
                      </div>
                    </Link>
                  </AnimationOnScroll>
                </SwiperSlide>
              ))}
            </Swiper>
          </Grid>
        )}
      </div>
    </React.Fragment>
  );
}
