import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#00833E",
    },
    secondary: {
      main: "#EEF5F1",
    },
    white: {
      main: "#FFFFFF",
    },
    grey: {
      main: "#22252c",
    },
    success: {
      main: "#009357",
    },
    error: {
      main: "#d91f26",
    },
  },
  typography: {
    h1: {
      fontSize: 32,
      fontWeight: 700,
      color: "#51413e",
      textTransform: "uppercase",
      "&::after": {
        content: '""',
        width: 55,
        height: 10,
        marginTop: 10,
        backgroundColor: "#f1dd00",
        display: "block",
      },
    },
    h2: {
      fontSize: 26,
      fontWeight: 700,
      color: "#51413e",
    },
    body1: {
      fontFamily: "Hellix-Regular",
      fontSize: 14,
      color: "#777777",
    },
    body3: {
      fontFamily: "Hellix-Regular",
      fontSize: 16,
      color: "#FFFFFF",
      textTransform: 'uppercase',
      letterSpacing: 1.6,
    },
    action1: {
      fontFamily: "Hellix-Regular",
      fontSize: 14,
      color: "#FFFFFF",
      textTransform: 'uppercase',
      letterSpacing: 1.6,
      textDecoration: 'underline',
    },
  },
  spacing: 14,
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
});

export default theme;
