import axios from "axios";
import API_URL from "../Api";

// produits
export const GET_PRODUCTS = "GET_PRODUCTS";

// récupération des produits les plus récent (chargés 5 par 5 (numb))
export const getProducts = () => {
  return (dispatch) => {
    return axios
      .get(`${API_URL}api/produits`)
      .then((res) => {
        // ne remplie le tableau qu'avec le nombre de post souhaité pour le infinite scroll
        dispatch({ type: GET_PRODUCTS, payload: res.data.data });
      })
      // .catch((err) => console.log(err));
  };
};
