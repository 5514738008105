import { Container, Grid } from "@mui/material";
import React from "react";

export default function ProductViewSkelet() {
  return (
    <Container maxWidth="xl">
      <Grid spacing={1} container className="productViewContainer pt48 pb120">
        <Grid item lg={7} md={7} sm={12} xs={12} className="productViewSkelet">
          <div style={{ height: "640px" }}></div>
        </Grid>
        <Grid item lg={5} md={5} sm={12} xs={12} className="productViewSkelet">
          <div style={{ height: "640px" }}></div>
        </Grid>
      </Grid>
    </Container>
  );
}
