import React, { useState } from "react";
import Ariane from "../components/molecules/Ariane";
import srlevolutif from "../assets/sr_levolutif_logo.png";
import map from "../assets/map_draw.svg";
import batiment_1 from "../assets/tilt_equipement_agencement_magasin_1.jpg";
import batiment_2 from "../assets/tilt_equipement_agencement_magasin_2.jpg";
import devanture from "../assets/devanture_stick.png";
import guillemet from "../assets/guillemet.png";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import ScrollDown from "../components/molecules/ScrollDown";
import SubFooter from "../components/molecules/SubFooter";
import background from "../assets/agencement-magasin-tilt-equipement-rendez-vous-client-conseil-6.jpg";
import { MetaTags } from "react-meta-tags";

export default function Entreprise() {
  const [arianeItem] = useState([
    ["/", "Tilt'Équipement"],
    ["/entreprise", "L'entreprise"],
  ]);

  return (
    <React.Fragment>
      <React.Fragment>
        <Ariane arianeItem={arianeItem} />
        <MetaTags>
          <title>Tilt’ Équipement - L’entreprise</title>
          <meta
            property="og:title"
            content={"Tilt’ Équipement - L’entreprise"}
          />
          <meta property="title" content={"Tilt’ Équipement - L’entreprise"} />
          <meta
            name="description"
            content={
              "Créée en 2000, Tilt’ Équipement est une entreprise familiale qui a su se développer pour répondre aux attentes du marché de l’agencement, avec notamment la création de sa propre marque de gondole SR l’évolutif."
            }
          />
          <meta
            name="og:description"
            content={
              "Créée en 2000, Tilt’ Équipement est une entreprise familiale qui a su se développer pour répondre aux attentes du marché de l’agencement, avec notamment la création de sa propre marque de gondole SR l’évolutif."
            }
          />
          <meta name="keywords" content={"Tilt'Équipement, Produits"} />
          <meta
            name="og:url"
            content={"https://tilt-equipement.fr/entreprise"}
          />
        </MetaTags>
        <section className="bannerEntreprise" style={{ textAlign: "left" }}>
          <Container maxWidth="xl" className="h100 pb90">
            <Grid
              container
              justifyContent="start"
              className="h100"
              alignItems="center"
            >
              <Grid item lg={10} xs={12}>
                <h1>
                  Agenceur de magasins et fabricant de meubles pour les
                  professionnels
                </h1>
                <h2>Une entreprise familiale</h2>
                <p style={{ textAlign: "left" }}>
                  La société Tilt’ Équipement a été créée en 2000. Son
                  fondateur, Serge Racineux, a débuté en tant que vendeur de
                  gondole d’occasion. Cette expertise lui a permis de comprendre
                  les attentes du marché et de créer{" "}
                  <strong>sa propre marque de gondole SR l’évolutif®</strong>{" "}
                  avec une large gamme d’accessoires et de mobilier.
                  Aujourd’hui, son fils Mickael Racineux, perpétue les valeurs
                  de l’entreprise et continue de développer des produits
                  compétitifs pour être un acteur majeur du secteur.
                </p>
              </Grid>
            </Grid>
          </Container>
          <ScrollDown />
        </section>

        <div className="entrepriseContainer">
          <div className="entrepriseBox">
            <section className="entrepriseMap pb120">
              <Container maxWidth="xl">
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <h3>
                      « Un rayonnement national et des exports en Europe et à
                      l’international »
                    </h3>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <img src={map} alt={"carte de l'europe"} loading="lazy" />
                  </Grid>
                </Grid>
              </Container>
            </section>

            <section className="entrepriseTimeline pb120">
              <Container maxWidth="xl">
                <Grid container>
                  <Grid item xs={12}>
                    <div className="line">
                      <div className="dot-1"></div>
                      <div className="step-1">
                        <p className="bold">2000</p>
                        <p>
                          Création de la société{" "}
                          <strong>Tilt’ Équipement</strong>
                          <br />
                          par Serge <strong>Racineux</strong>
                        </p>
                      </div>
                      <div className="dot-2"></div>
                      <div className="step-2">
                        <p className="bold">2008</p>
                        <p>
                          Création de la marque de gondole
                          <br />
                          <strong>SR l’évolutif®</strong>, marque déposée
                        </p>
                        <img
                          src={srlevolutif}
                          alt={"SR l'évolutif"}
                          loading="lazy"
                        />
                      </div>
                      <div className="dot-3"></div>
                      <div className="step-3">
                        <p className="bold">2018</p>
                        <p>
                          Acquisition d’un nouveau site à<br />
                          Château-Gontier-sur-Mayenne <br />
                          <strong>18 000 m2 de stockage</strong>
                        </p>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Container>
            </section>
            <section className="entrepriseInfos pb120">
              <Container maxWidth="xl">
                <Grid container className="entreprisePics">
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <img src={batiment_1} alt={"Devanture"} loading="lazy" />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <img src={batiment_2} alt={"Devanture"} loading="lazy" />
                  </Grid>
                  <Grid item xs={12}>
                    <div className="entrepriseNumber">
                      <div>
                        <p className="bold">Près de 25 ans</p>
                        <p>d'expérience</p>
                      </div>
                      <div>
                        <p className="bold">18 000 m²</p>
                        <p>de stockage</p>
                      </div>
                      <div>
                        <p className="bold">10 M€</p>
                        <p>de chiffres d'affaires</p>
                      </div>
                      <div>
                        <p className="bold">+ 4 500</p>
                        <p>références en stock</p>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Container>
            </section>
            <section className="entrepriseCitation pt120 pb120 mb90">
              <Container maxWidth="xl">
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <img src={devanture} alt={"devanture"} loading="lazy" />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <div id="citation">
                      <img src={guillemet} alt={"devanture"} loading="lazy" />
                      <p>
                        « Plus qu’un fournisseur, nous sommes{" "}
                        <strong>Partenaire</strong>. Nous entretenons des
                        rapports simples avec nos clients en étant à leur
                        écoute, pour trouver ensemble, les solutions adaptées à
                        leurs besoins. »
                      </p>
                      <span>Mickaël Racineux - Dirigeant</span>
                    </div>
                  </Grid>
                </Grid>
              </Container>
            </section>
          </div>
        </div>

        <SubFooter
          titleLeft="Travailler en toute sérénité et en toute simplicité"
          hrefLeft="../services"
          contentbtnLeft="Découvrir nos services"
          backgroundCustom={background}
          isRight
          titleRight="Nos showrooms"
          descriptionRight="Un accès libre à nos 800 m² de salles d'exposition"
          contentbtnRight="Découvrir"
          hrefRight="../visite-virtuelle"
        />
      </React.Fragment>
    </React.Fragment>
  );
}
