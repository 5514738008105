import React, { useEffect, useState } from "react";
import Ariane from "../components/molecules/Ariane";
import API_URL from "../Api";
import Banner from "../components/molecules/Banner";
import ArrowButton from "../components/Atom/ArrowButton";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useParams } from "react-router-dom";
import MetaTags from "react-meta-tags";

export default function Emploie() {
  const slug = useParams().slug;
  const [isLoading, setIsLoading] = useState(true);
  const [arianeItem, setArianeItem] = useState([]);
  const [offre, setOffre] = useState([]);

  useEffect(() => {
    fetch(`${API_URL}/api/offres?populate=*&filters[slug][$eq]=${slug}`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setIsLoading(false);
        setOffre(response.data[0]);
        setArianeItem([
          ["/", "Tilt'Équipement"],
          ["/inspirations", "Inspiration"],
          ["", response.data[0].attributes.titre],
        ]);
      });
  }, [slug]);

  return (
    <React.Fragment>
      {!isLoading && (
        <MetaTags>
          <title>{offre.attributes.titre + " - Tilt' Équipement"}</title>
          {offre.attributes.metaTitre ? (
            <>
              <meta
                property="og:title"
                content={offre.attributes.metaTitre + " - Tilt' Équipement"}
              />
              <meta
                property="title"
                content={offre.attributes.metaTitre + " - Tilt' Équipement"}
              />
            </>
          ) : (
            <>
              <meta
                property="og:title"
                content={offre.attributes.titre + " - Tilt' Équipement"}
              />
              <meta
                property="title"
                content={offre.attributes.titre + " - Tilt' Équipement"}
              />
            </>
          )}
          {offre.attributes.metaDescription && (
            <>
              <meta
                name="description"
                content={offre.attributes.metaDescription}
              />
              <meta
                name="og:description"
                content={offre.attributes.metaDescription}
              />
            </>
          )}
          <meta
            name="keywords"
            content={
              "Tilt'Équipement, Emploies, Emploie, Contrats, Contrat, " +
              offre.attributes.titre
            }
          />
          <meta
            name="og:url"
            content={
              "https://tilt-equipement.fr/emploie/" + offre.attributes.slug
            }
          />
        </MetaTags>
      )}
      <Ariane arianeItem={arianeItem} />
      {!isLoading && <Banner title={offre.attributes.titre} />}
      <Container className="emploieContainer pt120 pb120">
        <Grid container>
          <Grid item xs={12}>
            <div className="emploieBox">
              {!isLoading && (
                <React.Fragment>
                  {offre.attributes.description && (
                    <p>{offre.attributes.description}</p>
                  )}
                  {offre.attributes.missions && (
                    <React.Fragment>
                      <h4>• Missions</h4>
                      <p>{offre.attributes.missions}</p>
                    </React.Fragment>
                  )}
                  {offre.attributes.competences_requises && (
                    <React.Fragment>
                      <h4>• Compétences requises</h4>
                      <p>{offre.attributes.competences_requises}</p>
                    </React.Fragment>
                  )}
                  {offre.attributes.qualites_recherchees && (
                    <React.Fragment>
                      <h4>• Qualités recherchées</h4>
                      <p>{offre.attributes.qualites_recherchees}</p>
                    </React.Fragment>
                  )}
                  {offre.attributes.informations_complementaires && (
                    <React.Fragment>
                      <h4>• Informations complémentaires</h4>
                      <p>{offre.attributes.informations_complementaires}</p>
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
              {!isLoading && (
                <div className="emploieBtn pt48">
                  <ArrowButton
                    color={"grey"}
                    content={"Je postule"}
                    href={"../candidature/" + offre.attributes.slug}
                  />
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
