import React, { useState } from "react";
import Burger from "../components/molecules/Burger";
import Footer from "../components/molecules/Footer";
import Header from "../components/molecules/Header";
import ScrollTop from "../components/molecules/ScrollTop";
import Routes from "../components/routes";
import InstallPWA from "../components/molecules/InstallPWA";
import CookiesBanner from "../components/utils/CookiesBanner";
import ModalInfo from "../components/molecules/ModalInfo";

export default function Layout() {
  const [burger, setBurger] = useState(false);

  // Obtient l'URL actuelle
  const currentURL = window.location.href;
  // Vérifie si l'URL contient "/produits"
  const isOnProduitsPage = currentURL.includes("/produits");
  // si on est pas sur le page produit, il faut passer l'étape à 1 pour arriver sur la page des catégories à la prochaine visite de la page produit
  if (!isOnProduitsPage) {
    localStorage.setItem(
      "tiltStepProduct",
      JSON.stringify({
        ...JSON.parse(localStorage.getItem("tiltStepProduct")),
        step: 1,
      })
    );
    localStorage.setItem("tiltFilterProduct", JSON.stringify([]));
  }

  return (
    <React.Fragment>
      <Burger burger={burger} setBurger={setBurger} />
      <Header burger={burger} setBurger={setBurger} />
      {/* <InstallPWA /> */}
      <ScrollTop />
      <ModalInfo />
      <Routes />
      <CookiesBanner />
      <Footer />
    </React.Fragment>
  );
}
