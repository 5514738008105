import React, { useEffect, useState } from "react";
import API_URL from "../../Api";
// import Banner from "../molecules/Banner";
// import PicturePopUp from "../molecules/PicturePopUp";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import Back from "../molecules/Back";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AssociateProducts from "./AssociateProducts";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import tools from "../../assets/tools.svg";
// import newLink from "../../assets/new_link.svg";
import video from "../../assets/video.svg";
// import youtubeBlack from "../../assets/youtube_black.svg";
import Ariane from "../molecules/Ariane";
import CloseIcon from "@mui/icons-material/Close";
import AddToFavorites from "../molecules/AddToFavorites";
import SizeUnit from "./SizeUnit";
import PoundUnit from "./PoundUnit";
import Magnifier from "react-magnifier";
import InfoIcon from "@mui/icons-material/Info";
import ArrowButton from "../Atom/ArrowButton";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ImageOptimizer from "../utils/ImageOptimizer";
import Modal from "@mui/material/Modal";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DefaultLogo from "../../assets/image_produit_defaut_tiltequipement.svg";

export default function ProductView({ product }) {
  const [primaryURL, setPrimaryURL] = useState(null);
  const [primaryPic, setPrimaryPic] = useState(null);

  // iframe video
  const [showVideo, setShowVideo] = useState(false);
  const [videoId, setVideoId] = useState();

  // zoom box image
  const [zoomBox, setZoomBox] = useState({
    open: false,
    images: {
      ...product.attributes.images.data,
    },
  });

  const [showNoticeAndTutos, setShowNoticeAndTutos] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (
      product.attributes.notices.data[0] ||
      product.attributes.tutos.data[0]
    ) {
      setShowNoticeAndTutos(true);
    }
    if (product.attributes.images.data && product.attributes.images.data[0]) {
      setPrimaryPic(
        // Object.values(product.attributes.images.data[0].attributes.formats)[0].url
        product.attributes.images.data[0].attributes
      );
    }
  }, [product.description, product.images]);

  var images = [];
  if (product.attributes.images.data) {
    for (const image of product.attributes.images.data) {
      images.push(
        Object.values(product.attributes.images.data[0].attributes.formats)[0]
      );
    }
  }

  // iframe video
  function handleIframe(url) {
    setVideoId(url.split("/").reverse()[0]);
    setShowVideo(true);
  }

  function closeIframe() {
    setVideoId();
    setShowVideo(false);
  }

  const arianeItem = [
    ["/", "Tilt'Équipement"],
    ["/produits", "Produits"],
    ["/" + product.id, product.attributes.nom],
  ];

  // - Enregistre la bonne version de la valeur métrique (reelle...) dans un tableaux
  // - Convertis la valeur en fonction de l'unité demandée
  // - Lie les valeurs avec une virgule
  const sizeRenderer = (arrayValue, wichTypeSize) => {
    let renderArray = [];
    for (let value of arrayValue) {
      switch (wichTypeSize) {
        case "reelle":
          renderArray.push(sizeConvertor(value.unite, value.reelle));
          break;
        case "hors_tout":
          renderArray.push(sizeConvertor(value.unite, value.hors_tout));
          break;
        case "utile":
          renderArray.push(sizeConvertor(value.unite, value.utile));
          break;
        default:
          renderArray.push(sizeConvertor(value.unite, value.reelle));
      }
    }
    renderArray.sort(function (a, b) {
      return a - b;
    });
    return renderArray.join(", ") + " " + arrayValue[0].unite;
  };

  function sizeConvertor(unite, value) {
    switch (unite) {
      case "mm":
        return value;
      case "cm":
        return value / 10;
      case "m":
        return value / 1000;
      default:
        return value;
    }
  }

  // - Enregistre la bonne version de la valeur métrique (reelle...) dans un tableaux
  // - Convertis la valeur en fonction de l'unité demandée
  // - Lie les valeurs avec une virgule
  const poundRenderer = (arrayValue, wichTypeSize) => {
    let renderArray = [];
    for (let value of arrayValue) {
      switch (wichTypeSize) {
        case "reelle":
          renderArray.push(poundConvertor(value.unite, value.reelle));
          break;
        case "hors_tout":
          renderArray.push(poundConvertor(value.unite, value.hors_tout));
          break;
        case "utile":
          renderArray.push(poundConvertor(value.unite, value.utile));
          break;
        default:
          renderArray.push(poundConvertor(value.unite, value.reelle));
      }
    }
    renderArray.sort(function (a, b) {
      return a - b;
    });
    return renderArray.join(", ") + " " + arrayValue[0].unite;
  };

  function poundConvertor(unite, value) {
    switch (unite) {
      case "g":
        return value;
      case "kg":
        return value / 1000;
      case "tonnes":
        return value / 1000000;
      default:
        return value;
    }
  }

  return (
    <React.Fragment>
      {/* <PicturePopUp
        url={
          API_URL + product.attributes.image.data.attributes.formats.medium.url
        }
      /> */}
      <Ariane arianeItem={arianeItem} />
      <Back href="../produits" />
      <Container maxWidth="xl" className="productViewContainer pt48 pb120">
        <Grid container spacing={2} className="positionRelative">
          <Grid item lg={7} md={7} sm={12} xs={12}>
            <div className="productViewPictures">
              <div
                className="productViewPrimaryPictures"
                onClick={() => setZoomBox({ ...zoomBox, open: true })}
              >
                {primaryPic ? (
                  // <Magnifier src={API_URL + primaryURL} />
                  <ImageOptimizer formats={primaryPic.formats} />
                ) : (
                  <img
                    src={DefaultLogo}
                    alt="Absence de visuel pour ce produit"
                  />
                )}
              </div>
              {product.attributes.images.data && (
                <Swiper
                  className="productViewSlider"
                  spaceBetween={10}
                  slidesPerView={4}
                  navigation={true}
                  modules={[Navigation]}
                  breakpoints={{
                    320: {
                      slidesPerView: 1,
                    },
                    425: {
                      slidesPerView: 2,
                    },
                    576: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    768: {
                      slidesPerView: 3,
                      spaceBetween: 10,
                    },
                    981: {
                      slidesPerView: 4,
                      spaceBetween: 10,
                    },
                    1024: {
                      slidesPerView: 4,
                      spaceBetween: 10,
                    },
                    1440: {
                      slidesPerView: 4,
                      spaceBetween: 10,
                    },
                  }}
                >
                  {product.attributes.images.data.map((image) => (
                    <SwiperSlide
                      key={"product_image_" + image.attributes.name}
                      className="productViewSliderPictures"
                    >
                      <div onClick={() => setPrimaryPic(image.attributes)}>
                        <ImageOptimizer formats={image.attributes.formats} />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              )}
            </div>
          </Grid>
          <Grid item lg={5} md={5} sm={12}>
            <div className="productViewInfos">
              {/* nom du produit */}
              <div className="dFlexProductFav">
                <h2>{product.attributes.nom}</h2>
                <div className="productAddToFavorites">
                  <AddToFavorites label={"products"} content={product} />
                </div>
              </div>
              {/* référence du produit */}
              {product.attributes.ref && !product.attributes.show_ref && (
                <p className="sub_category_title">
                  {"Ref : " + product.attributes.ref}
                </p>
              )}
              {/* {product.attributes.dispo ? (
                <p className="sub_category_title">
                  Produit disponible/en stock : Oui
                </p>
              ) : (
                <p className="sub_category_title">
                  Produit disponible/en stock : Non
                </p>
              )} */}
              {product.attributes.description && (
                <section className="productViewBlock">
                  <div
                    id="productViewDescription"
                    dangerouslySetInnerHTML={{
                      __html: product.attributes.description,
                    }}
                  ></div>
                </section>
              )}
              {/* nuances */}
              <section className="productViewBlock productViewBlockColor">
                {/* {product.attributes.nuances.data[0] && (
                  <div className="productViewColorPicker">
                    <p>Coloris standards</p>
                    <section>
                      {product.attributes.nuances.data.map((nuance) => (
                        <div
                          key={"ProductColor_hy" + nuance.attributes.nom}
                          className="productViewColorGomette"
                        >
                          <div className="productViewColorName">
                            {nuance.attributes.nom}
                          </div>
                          {nuance.attributes.code && (
                            <div
                              className="productViewColorViewer"
                              style={{
                                backgroundColor: nuance.attributes.code,
                                marginTop: "10px",
                              }}
                            ></div>
                          )}
                          {nuance.attributes.visuel.data && (
                            <div
                              className="productViewMatiereViewer"
                              style={{
                                backgroundImage: `url(${
                                  API_URL +
                                  nuance.attributes.visuel.data.attributes.url
                                })`,
                              }}
                            ></div>
                          )}
                        </div>
                      ))}
                    </section>
                  </div>
                )} */}
                {product.attributes.parties_modifiables[0] && (
                  <div className="productViewColorPicker productViewColorPickerSummary">
                    {product.attributes.parties_modifiables.map(
                      (partie_modifiable) => (
                        <div
                          className="productSecondarColor"
                          key={"Nuance_fr" + partie_modifiable.nom}
                        >
                          {partie_modifiable.nom &&
                            partie_modifiable.nuances.data[0] && (
                              <p className="productSecondarPart">
                                {partie_modifiable.nom}
                              </p>
                            )}
                          <section>
                            {partie_modifiable.nuances.data.map((nuance) => (
                              <div
                                key={"ProductColor_vr" + nuance.attributes.nom}
                                className="productViewColorGomette"
                              >
                                <div className="productViewColorName">
                                  {nuance.attributes.nom}
                                </div>
                                {nuance.attributes.code && (
                                  <div
                                    className="productViewColorViewer"
                                    style={{
                                      backgroundColor: nuance.attributes.code,
                                    }}
                                  ></div>
                                )}
                                {nuance.attributes.visuel.data && (
                                  <div
                                    className="productViewMatiereViewer"
                                    style={{
                                      backgroundImage: `url(${
                                        API_URL +
                                        Object.values(
                                          nuance.attributes.visuel.data
                                            .attributes.formats
                                        )[0].url
                                      })`,
                                    }}
                                  ></div>
                                )}
                              </div>
                            ))}
                          </section>
                        </div>
                      )
                    )}
                  </div>
                )}
              </section>
              {/* choix de la dimension */}
              {product.attributes.declinaisons.data[0] && (
                <section className="productViewBlock">
                  <Accordion className="productViewSizePickerSummary" expanded={true}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className=""
                      sx={{ padding: 0 }}
                    >
                      <Typography>Autres dimensions</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {product.attributes.declinaisons.data.map(
                        (declinaison) => (
                          <React.Fragment>
                            <a
                              key={"size_bh" + declinaison.id}
                              href={"../produit/" + declinaison.attributes.slug}
                              // style={{ display: "block" }}
                              style={
                                declinaison.attributes.nom ===
                                product.attributes.nom
                                  ? {
                                      display: "block",
                                      color: "#00833e",
                                      fontWeight: "bolder ",
                                    }
                                  : { display: "block" }
                              }
                              className="declinaisonLink"
                            >
                              {/* {declinaison.attributes.hauteur[0] &&
                              "H : " + declinaison.attributes.hauteur[0].reelle}
                            {declinaison.attributes.largeur[0] &&
                              "l : " + declinaison.attributes.largeur[0].reelle}
                            {declinaison.attributes.longueur[0] &&
                              "L : " +
                                declinaison.attributes.longueur[0].reelle}
                            mm */}
                              {declinaison.attributes.nom}
                            </a>
                          </React.Fragment>
                        )
                      )}
                    </AccordionDetails>
                  </Accordion>
                </section>
              )}
              {/* description du produit */}
              {/* {product.attributes.parties_modifiables[0] && (
                <section className="productViewBlock">
                  <Accordion className="productViewDescriptionSummary">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className=""
                      sx={{ padding: 0 }}
                    >
                      <Typography>
                        Description complémentaire du produit
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {/* description des parties modifiables 
                      {product.attributes.parties_modifiables.map(
                        (partie_modifiable) => (
                          <React.Fragment
                            key={
                              "partie_modifiable_frg_" + partie_modifiable.nom
                            }
                          >
                            <div className="productViewDescription">
                              <p className="titrePartieModifiable">
                                {partie_modifiable.nom.charAt(0).toUpperCase() +
                                  partie_modifiable.nom.slice(1)}
                              </p>
                              <p>{partie_modifiable.description}</p>
                            </div>
                          </React.Fragment>
                        )
                      )}
                    </AccordionDetails>
                  </Accordion>
                </section>
              )} */}
              {/* option du produit */}
              {product.attributes.options && (
                <section className="productViewBlock">
                  <Accordion
                    className="productViewDescriptionSummary"
                    expanded={true}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className=""
                      sx={{ padding: 0 }}
                    >
                      <Typography>Option du produit</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div
                        className=""
                        id="productViewDetails"
                        dangerouslySetInnerHTML={{
                          __html: product.attributes.options,
                        }}
                      ></div>
                    </AccordionDetails>
                  </Accordion>
                </section>
              )}
              {/* Demande de devis pour ce produit */}
              <div className="productViewDevisDemande">
                <ArrowButton
                  color={"grey"}
                  href={"../devis?produit=" + product.attributes.slug}
                  content="Demander un devis pour ce produit"
                />
              </div>
            </div>
          </Grid>

          <Grid item lg={7} md={7} sm={12} xs={12}>
            <div className="productViewMoreInfos">
              <Accordion className="productViewSecondaryAccordion infospratiques">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="productViewSecondarySummary"
                  sx={{ padding: 0 }}
                >
                  <Typography>Infos techniques</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer
                    component={Paper}
                    className="productViewSecondaryTableInfos"
                  >
                    <Table aria-label="simple table">
                      <TableBody>
                        {/* {product.attributes.ref &&
                          product.attributes.show_ref && (
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Référence
                              </TableCell>
                              <TableCell align="right">
                                {product.attributes.ref}
                              </TableCell>
                            </TableRow>
                          )} */}
                        {/* -------------------------------------------------- */}
                        {/* LONGUEURS DU PRODUIT */}
                        {/* -------------------------------------------------- */}
                        <React.Fragment>
                          {Object.values(product.attributes.longueur).find(
                            (element) => element.reelle
                          ) && (
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Longueur
                              </TableCell>
                              <TableCell align="right">
                                {sizeRenderer(
                                  product.attributes.longueur,
                                  "reelle"
                                )}
                              </TableCell>
                            </TableRow>
                          )}
                          {Object.values(product.attributes.longueur).find(
                            (element) => element.hors_tout
                          ) && (
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Longueur hors tout
                                <div className="utileNotice">
                                  <Tooltip
                                    arrow
                                    title="Encombrement maximal de l'article"
                                  >
                                    <IconButton>
                                      <InfoIcon />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              </TableCell>
                              <TableCell align="right">
                                {sizeRenderer(
                                  product.attributes.longueur,
                                  "hors_tout"
                                )}
                              </TableCell>
                            </TableRow>
                          )}
                          {Object.values(product.attributes.longueur).find(
                            (element) => element.utile
                          ) && (
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Longueur utile
                                <div className="utileNotice">
                                  <Tooltip
                                    arrow
                                    title="Espace dont vous disposez pour placer vos produits"
                                  >
                                    <IconButton>
                                      <InfoIcon />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              </TableCell>
                              <TableCell align="right">
                                {sizeRenderer(
                                  product.attributes.longueur,
                                  "utile"
                                )}
                              </TableCell>
                            </TableRow>
                          )}
                        </React.Fragment>
                        {/* -------------------------------------------------- */}
                        {/* PROFONDEURS DU PRODUIT */}
                        {/* -------------------------------------------------- */}
                        <React.Fragment>
                          {Object.values(product.attributes.profondeur).find(
                            (element) => element.reelle
                          ) && (
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Profondeur
                              </TableCell>
                              <TableCell align="right">
                                {sizeRenderer(
                                  product.attributes.profondeur,
                                  "reelle"
                                )}
                              </TableCell>
                            </TableRow>
                          )}
                          {Object.values(product.attributes.profondeur).find(
                            (element) => element.hors_tout
                          ) && (
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Profondeur hors tout
                                <div className="utileNotice">
                                  <Tooltip
                                    arrow
                                    title="Encombrement maximal de l'article"
                                  >
                                    <IconButton>
                                      <InfoIcon />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              </TableCell>
                              <TableCell align="right">
                                {sizeRenderer(
                                  product.attributes.profondeur,
                                  "hors_tout"
                                )}
                              </TableCell>
                            </TableRow>
                          )}
                          {Object.values(product.attributes.profondeur).find(
                            (element) => element.utile
                          ) && (
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Profondeur utile
                                <div className="utileNotice">
                                  <Tooltip
                                    arrow
                                    title="Espace dont vous disposez pour placer vos produits"
                                  >
                                    <IconButton>
                                      <InfoIcon />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              </TableCell>
                              <TableCell align="right">
                                {sizeRenderer(
                                  product.attributes.profondeur,
                                  "utile"
                                )}
                              </TableCell>
                            </TableRow>
                          )}
                        </React.Fragment>
                        {/* -------------------------------------------------- */}
                        {/* HAUTEURS DU PRODUIT */}
                        {/* -------------------------------------------------- */}
                        <React.Fragment>
                          {Object.values(product.attributes.hauteur).find(
                            (element) => element.reelle
                          ) && (
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Hauteur
                              </TableCell>
                              <TableCell align="right">
                                {sizeRenderer(
                                  product.attributes.hauteur,
                                  "reelle"
                                )}
                              </TableCell>
                            </TableRow>
                          )}
                          {Object.values(product.attributes.hauteur).find(
                            (element) => element.hors_tout
                          ) && (
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Hauteur hors tout
                                <div className="utileNotice">
                                  <Tooltip
                                    arrow
                                    title="Encombrement maximal de l'article"
                                  >
                                    <IconButton>
                                      <InfoIcon />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              </TableCell>
                              <TableCell align="right">
                                {sizeRenderer(
                                  product.attributes.hauteur,
                                  "hors_tout"
                                )}
                              </TableCell>
                            </TableRow>
                          )}
                          {Object.values(product.attributes.hauteur).find(
                            (element) => element.utile
                          ) && (
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Hauteur utile
                                <div className="utileNotice">
                                  <Tooltip
                                    arrow
                                    title="Espace dont vous disposez pour placer vos produits"
                                  >
                                    <IconButton>
                                      <InfoIcon />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              </TableCell>
                              <TableCell align="right">
                                {sizeRenderer(
                                  product.attributes.hauteur,
                                  "utile"
                                )}
                              </TableCell>
                            </TableRow>
                          )}
                        </React.Fragment>
                        {/* -------------------------------------------------- */}
                        {/* LARGEUR DU PRODUIT */}
                        {/* -------------------------------------------------- */}
                        {product.attributes.largeur[0] && (
                          <React.Fragment>
                            {Object.values(product.attributes.largeur).find(
                              (element) => element.reelle
                            ) && (
                              <TableRow>
                                <TableCell component="th" scope="row">
                                  Largeur
                                </TableCell>
                                <TableCell align="right">
                                  {sizeRenderer(
                                    product.attributes.largeur,
                                    "reelle"
                                  )}
                                </TableCell>
                              </TableRow>
                            )}
                            {Object.values(product.attributes.largeur).find(
                              (element) => element.hors_tout
                            ) && (
                              <TableRow>
                                <TableCell component="th" scope="row">
                                  Largeur hors tout
                                  <div className="utileNotice">
                                    <Tooltip
                                      arrow
                                      title="Encombrement maximal de l'article"
                                    >
                                      <IconButton>
                                        <InfoIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                </TableCell>
                                <TableCell align="right">
                                  {sizeRenderer(
                                    product.attributes.largeur,
                                    "hors_tout"
                                  )}
                                </TableCell>
                              </TableRow>
                            )}
                            {Object.values(product.attributes.largeur).find(
                              (element) => element.utile
                            ) && (
                              <TableRow>
                                <TableCell component="th" scope="row">
                                  Largeur utile
                                  <div className="utileNotice">
                                    <Tooltip
                                      arrow
                                      title="Espace dont vous disposez pour placer vos produits"
                                    >
                                      <IconButton>
                                        <InfoIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                </TableCell>
                                <TableCell align="right">
                                  {sizeRenderer(
                                    product.attributes.largeur,
                                    "utile"
                                  )}
                                </TableCell>
                              </TableRow>
                            )}
                          </React.Fragment>
                        )}
                        {/* -------------------------------------------------- */}
                        {/* DIAMÉTRE DU PRODUIT */}
                        {/* -------------------------------------------------- */}
                        {product.attributes.diametre[0] && (
                          <React.Fragment>
                            {Object.values(product.attributes.diametre).find(
                              (element) => element.reelle
                            ) && (
                              <TableRow>
                                <TableCell component="th" scope="row">
                                  Diamètre
                                </TableCell>
                                <TableCell align="right">
                                  {sizeRenderer(
                                    product.attributes.diametre,
                                    "reelle"
                                  )}
                                </TableCell>
                              </TableRow>
                            )}
                            {Object.values(product.attributes.diametre).find(
                              (element) => element.hors_tout
                            ) && (
                              <TableRow>
                                <TableCell component="th" scope="row">
                                  Diamètre hors tout
                                  <div className="utileNotice">
                                    <Tooltip
                                      arrow
                                      title="Encombrement maximal de l'article"
                                    >
                                      <IconButton>
                                        <InfoIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                </TableCell>
                                <TableCell align="right">
                                  {sizeRenderer(
                                    product.attributes.diametre,
                                    "hors_tout"
                                  )}
                                </TableCell>
                              </TableRow>
                            )}
                            {Object.values(product.attributes.diametre).find(
                              (element) => element.utile
                            ) && (
                              <TableRow>
                                <TableCell component="th" scope="row">
                                  Diamètre utile
                                  <div className="utileNotice">
                                    <Tooltip
                                      arrow
                                      title="Espace dont vous disposez pour placer vos produits"
                                    >
                                      <IconButton>
                                        <InfoIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                </TableCell>
                                <TableCell align="right">
                                  {sizeRenderer(
                                    product.attributes.diametre,
                                    "utile"
                                  )}
                                </TableCell>
                              </TableRow>
                            )}
                          </React.Fragment>
                        )}
                        {/* -------------------------------------------------- */}
                        {/* CHARGES DU PRODUIT */}
                        {/* -------------------------------------------------- */}
                        {product.attributes.charge[0] && (
                          <React.Fragment>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Charge
                              </TableCell>
                              <TableCell align="right">
                                {poundRenderer(
                                  product.attributes.charge,
                                  "reelle"
                                )}
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        )}
                        {/* -------------------------------------------------- */}
                        {/* POIDS DU PRODUIT */}
                        {/* -------------------------------------------------- */}
                        {product.attributes.poids[0] && (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Poids
                            </TableCell>
                            <TableCell align="right">
                              {poundRenderer(
                                product.attributes.poids,
                                "reelle"
                              )}
                            </TableCell>
                          </TableRow>
                        )}
                        {/* -------------------------------------------------- */}
                        {/* ATTRIBUTS SUPPLEMENTAIRES */}
                        {/* -------------------------------------------------- */}
                        {product.attributes.attribut_sup[0] && (
                          <React.Fragment>
                            {product.attributes.attribut_sup.map((attribut) => (
                              <TableRow>
                                <TableCell component="th" scope="row">
                                  {attribut.titre}
                                </TableCell>
                                <TableCell align="right">
                                  {attribut.contenu}
                                </TableCell>
                              </TableRow>
                            ))}
                          </React.Fragment>
                        )}
                        {/* -------------------------------------------------- */}
                        {/* DISPONIBILITE */}
                        {/* -------------------------------------------------- */}
                        {product.attributes.dispo &&
                          product.attributes.dispo !== "1" && (
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Disponiblité
                              </TableCell>
                              <TableCell align="right">
                                {product.attributes.dispo}
                              </TableCell>
                            </TableRow>
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
              {/* -------------------------------------------------- */}
              {/* NOTICES ET TUTOS */}
              {/* -------------------------------------------------- */}
              {showNoticeAndTutos && (
                <Accordion className="productViewSecondaryAccordion noticemontagetuto">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    sx={{ padding: 0 }}
                  >
                    <Typography>Notices de montage et tutos</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="productViewSecondaryTableInfosFullWhite">
                      {/* NOTICES */}
                      {product.attributes.notices.data[0] && (
                        <div className="noticeTutosCard">
                          <div className="noticeTutosCardHeader">
                            <img src={tools} alt={"Notice"} loading="lazy" />
                            <strong>Notice de montage</strong>
                          </div>
                          <div className="noticeTutosCardsLine">
                            {product.attributes.notices.data.map((notice) => (
                              <a
                                href={
                                  API_URL +
                                  notice.attributes.fichier.data[0].attributes
                                    .url
                                }
                                download={notice.attributes.titre}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <ImageOptimizer
                                  formats={
                                    notice.attributes.couverture.data.attributes
                                      .formats
                                  }
                                />
                                <p>
                                  {notice.attributes.titre.slice(0, 40) + "..."}
                                </p>
                              </a>
                            ))}
                          </div>
                        </div>
                      )}
                      {/* TUTOS */}
                      {product.attributes.tutos.data[0] && (
                        <div className="noticeTutosCard">
                          <div className="noticeTutosCardHeader">
                            <img src={video} alt={"Notice"} loading="lazy" />
                            <strong>Tutos</strong>
                          </div>
                          <div className="noticeTutosCardsLine">
                            {product.attributes.tutos.data.map((tuto) => (
                              <div
                                className="tutoCallBtn"
                                onClick={() =>
                                  handleIframe(tuto.attributes.url)
                                }
                              >
                                <div className="tutoPlayerIconProduct">
                                  {tuto.attributes.vignette.data ? (
                                    <ImageOptimizer
                                      formats={
                                        tuto.attributes.vignette.data.attributes
                                          .formats
                                      }
                                    />
                                  ) : (
                                    <PlayArrowIcon />
                                  )}
                                </div>
                                <p>
                                  {tuto.attributes.titre.slice(0, 40) + "..."}
                                </p>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </AccordionDetails>
                </Accordion>
              )}
              {/* -------------------------------------------------- */}
              {/* CATALOGUES */}
              {/* -------------------------------------------------- */}
              {product.attributes.catalogues.data[0] && (
                <Accordion className="productViewSecondaryAccordion">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    sx={{ padding: 0 }}
                  >
                    <Typography>Nos catalogues</Typography>
                  </AccordionSummary>
                  <AccordionDetails className="catalogueListImg">
                    {product.attributes.catalogues.data.map((catalogue) => (
                      <a
                        href={
                          API_URL +
                          catalogue.attributes.fichier.data[0].attributes.url
                        }
                        target="_blank"
                        rel="noreferrer"
                        style={{ minWidth: "150px" }}
                      >
                        <ImageOptimizer
                          formats={
                            catalogue.attributes.couverture.data.attributes
                              .formats
                          }
                        />
                        <p>{catalogue.attributes.titre}</p>
                      </a>
                    ))}
                  </AccordionDetails>
                </Accordion>
              )}
              {/* -------------------------------------------------- */}
              {/* NUANCIERS */}
              {/* -------------------------------------------------- */}
              {product.attributes.nuanciers.data[0] && (
                <Accordion className="productViewSecondaryAccordion">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    sx={{ padding: 0 }}
                  >
                    <Typography>Nuanciers</Typography>
                  </AccordionSummary>
                  <AccordionDetails className="catalogueListImg">
                    {product.attributes.nuanciers.data.map((nuancier) => (
                      <a
                        href={
                          API_URL +
                          nuancier.attributes.fichier.data.attributes.url
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        {nuancier.attributes.couverture.data && (
                          <ImageOptimizer
                            formats={
                              nuancier.attributes.couverture.data.attributes
                                .formats
                            }
                          />
                        )}
                        <p>{nuancier.attributes.titre}</p>
                      </a>
                    ))}
                  </AccordionDetails>
                </Accordion>
              )}
              {/* -------------------------------------------------- */}
              {/* DETAILS ET CONSEIL D'ENTRETIEN */}
              {/* -------------------------------------------------- */}
              {product.attributes.detail && (
                <Accordion className="productViewSecondaryAccordion">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    sx={{ padding: 0 }}
                  >
                    <Typography>{product.attributes.detail.titre}</Typography>
                  </AccordionSummary>
                  <AccordionDetails className="detailEtConseilBox">
                    <p className="detailEtConseil">
                      {product.attributes.detail.contenu}
                    </p>
                  </AccordionDetails>
                </Accordion>
              )}
            </div>
          </Grid>
        </Grid>
        {/* utilisation des produits associé (par catégorie sur cette page) */}
        {product.attributes.produits_associes.data[0] && (
          <AssociateProducts
            products={product.attributes.produits_associes.data}
          />
        )}
        {primaryPic && (
          <Modal
            open={zoomBox.open}
            onClose={() => setZoomBox({ ...zoomBox, open: false })}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <div
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="zoomBox"
            >
              <IconButton
                aria-label="fermer"
                className="ButtonSwiperClose"
                onClick={() => setZoomBox({ ...zoomBox, open: false })}
              >
                <CloseIcon />
              </IconButton>
              <Swiper
                spaceBetween={0}
                slidesPerView={1}
                navigation={true}
                modules={[Navigation]}
              >
                {product.attributes.images.data.map((image) => (
                  <SwiperSlide
                    key={"product_image_zoombox_" + image.attributes.name}
                  >
                    <ImageOptimizer formats={image.attributes.formats} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </Modal>
        )}
        <div
          className={
            showVideo && videoId
              ? "videoIframeOverlay showIframe"
              : "videoIframeOverlay "
          }
          onClick={(e) => closeIframe(e)}
        >
          <iframe
            src={
              "https://www.youtube.com/embed/" +
              videoId +
              "?autoplay=1&modestbranding=1&color=white&rel=0"
            }
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <div className="closeVideoIframe" onClick={closeIframe}>
            <CloseIcon />
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
}
