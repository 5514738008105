import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import required modules
import API_URL from "../Api";
import ArrowButton from "../components/Atom/ArrowButton";
import { Button } from "@mui/material";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import Ariane from "../components/molecules/Ariane";
import MetaTags from "react-meta-tags";
import Back from "../components/molecules/Back";
import AddToFavorites from "../components/molecules/AddToFavorites";
// import ProposeProducts from "../components/products/ProposeProducts";
import CircularProgress from "@mui/material/CircularProgress";
import InspirationProducts from "../components/inspirations/InspirationProducts";
import ImageOptimizer from "../components/utils/ImageOptimizer";
// import ProposeInspirations from "../components/inspirations/ProposeInspirations";

export default function Inspiration() {
  const slug = useParams().slug;
  const [isLoading, setIsLoading] = useState(true);
  const [inspiration, setInspiration] = useState([]);
  const [arianeItem, setArianeItem] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetch(
      `${API_URL}/api/inspirations?populate=*&filters[slug][$eq]=${slug}&populate[0]=images&populate[1]=image_hero&populate[2]=approche&populate[3]=image_bas_de_page&populate[4]=produits.images&populate[5]=produits.univers`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        setInspiration(response.data[0]);
        setArianeItem([
          ["/", "Tilt'Équipement"],
          ["/inspirations", "Inspiration"],
          ["/" + response.data[0].id, response.data[0].attributes.titre],
        ]);
        setIsLoading(false);
      });
  }, [slug]);

  function scrollToBudget() {
    var element = document.getElementById("budget");
    element.scrollIntoView({ block: "end" });
  }

  return (
    <React.Fragment>
      {!isLoading ? (
        <React.Fragment>
          {!isLoading && (
            <MetaTags>
              {inspiration.attributes.metaTitre ? (
                <title>
                  {"Tilt’ Équipement - " + inspiration.attributes.metaTitre}
                </title>
              ) : (
                <title>
                  {"Tilt’ Équipement - " + inspiration.attributes.titre}
                </title>
              )}
              {inspiration.attributes.metaTitre ? (
                <>
                  <meta
                    property="og:title"
                    content={
                      "Tilt' Équipement - " + inspiration.attributes.metaTitre
                    }
                  />
                  <meta
                    property="title"
                    content={
                      "Tilt' Équipement - " + inspiration.attributes.metaTitre
                    }
                  />
                </>
              ) : (
                <>
                  <meta
                    property="og:title"
                    content={
                      "Tilt' Équipement - " + inspiration.attributes.titre
                    }
                  />
                  <meta
                    property="title"
                    content={
                      "Tilt' Équipement - " + inspiration.attributes.titre
                    }
                  />
                </>
              )}
              {inspiration.attributes.metaDescription && (
                <>
                  <meta
                    name="description"
                    content={inspiration.attributes.metaDescription}
                  />
                  <meta
                    name="og:description"
                    content={inspiration.attributes.metaDescription}
                  />
                </>
              )}
              {inspiration.attributes.images.data && (
                <meta
                  property="og:image"
                  content={
                    API_URL +
                    inspiration.attributes.images.data[0].attributes.url
                  }
                />
              )}
              <meta
                name="keywords"
                content={
                  "Tilt'Équipement, Inspirations, Inspiration, " +
                  inspiration.attributes.titre
                }
              />
              <meta
                name="og:url"
                content={
                  "https://tilt-equipement.fr/inspiration/" +
                  inspiration.attributes.slug
                }
              />
            </MetaTags>
          )}
          <Ariane arianeItem={arianeItem} />
          <Back href="/inspirations" />
          <div className="inspirationBannerPrimary">
            <AnimationOnScroll
              animateIn="animate__fadeIn"
              className="inspirationBanner"
            >
              <div className="inspirationBannerPrimary">
                <Container maxWidth="xl">
                  <Grid container className="inspirationBannerLeftContainer">
                    <Grid
                      item
                      lg={10}
                      md={12}
                      sm={12}
                      xs={12}
                      className="inspirationBannerLeft"
                    >
                      {!isLoading && (
                        <div className="position-relative">
                          <div className="inspirationBannerAddToFavContainer">
                            <h1>{inspiration.attributes.titre}</h1>
                            <div className="inspirationBannerAddToFav">
                              <AddToFavorites
                                label={"inspirations"}
                                content={inspiration}
                              />
                            </div>
                          </div>
                          <p>{inspiration.attributes.description}</p>
                          {inspiration.attributes.approche_titre_1 && (
                            <div>
                              <Button
                                className="arrowButton"
                                variant="contained"
                                color="primary"
                                endIcon={<ExpandCircleDownIcon />}
                                onClick={scrollToBudget}
                                aria-label="Découvrir notre approche budgétaire"
                              >
                                Découvrir notre approche budgétaire
                              </Button>
                            </div>
                          )}
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </Container>
                <div className="inspirationBannerRight">
                  <ImageOptimizer
                    formats={
                      inspiration.attributes.image_hero.data.attributes.formats
                    }
                  />
                </div>
              </div>
            </AnimationOnScroll>
          </div>
          {!isLoading && inspiration.attributes.images.data && (
            <Container maxWidth="xl" className="pt90">
              <Grid container spacing={1}>
                {inspiration.attributes.images.data.map((item) => (
                  <Grid
                    item
                    lg={4}
                    md={4}
                    sm={6}
                    xs={12}
                    key={"48fj_" + item.id}
                    className="moreImageInspiration"
                  >
                    <ImageOptimizer formats={item.attributes.formats} />
                  </Grid>
                ))}
              </Grid>
            </Container>
          )}
          {!isLoading && inspiration.attributes.infos_pratiques && (
            <AnimationOnScroll
              animateIn="animate__fadeIn"
              className="inspirationInfoPratiqueBox"
            >
              <Container
                maxWidth="xl"
                className="inspirationInfoPratique pt90 pb90"
                id="inspirationInfoPratique"
              >
                <h3 className="pb24">Quelques infos pratiques</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: inspiration.attributes.infos_pratiques,
                  }}
                ></p>
              </Container>
            </AnimationOnScroll>
          )}
          {!isLoading && inspiration.attributes.images.data[0] && (
            <React.Fragment>
              {inspiration.attributes.approche[0] && (
                <div id="budget" className="inspirationApprocheBudget">
                  <div className="inspirationApprocheBudgetLeft">
                    {inspiration.attributes.image_bas_de_page.data ? (
                      <ImageOptimizer
                        formats={
                          inspiration.attributes.image_bas_de_page.data
                            .attributes.formats
                        }
                      />
                    ) : (
                      <ImageOptimizer
                        formats={
                          inspiration.attributes.image_hero.data.attributes
                            .formats
                        }
                      />
                    )}
                  </div>
                  <Container
                    maxWidth="xl"
                    className="inspirationApprocheBudgetRight"
                  >
                    <Grid container>
                      <Grid item>
                        <h3 className="pb24">Approche budgétaire</h3>
                        <div className="inspirationApprocheBudgetList">
                          <ul>
                            {inspiration.attributes.approche.map((item) => (
                              <li>
                                {item.titre && (
                                  <div className="inspirationApprocheBudgetTop">
                                    <p>
                                      {item.numero_pins && (
                                        <span>{item.numero_pins}</span>
                                      )}
                                      {item.titre}
                                    </p>
                                  </div>
                                )}
                                <div className="inspirationApprocheBudgetBottom">
                                  <p>{item.description}</p>
                                </div>
                              </li>
                            ))}
                          </ul>
                          {inspiration.attributes.total_approche && (
                            <React.Fragment>
                              <div className="inspirationApprocheBudgetTotal">
                                <div>
                                  <p className="h5">
                                    {inspiration.attributes.titre_total_approche
                                      ? inspiration.attributes
                                          .titre_total_approche + " "
                                      : "L’ensemble à partir de "}
                                    {inspiration.attributes.total_approche &&
                                      new Intl.NumberFormat().format(
                                        inspiration.attributes.total_approche
                                      ) + " €*"}
                                  </p>
                                </div>
                              </div>
                              <p className="minip">
                                * Les prix de vente indiqués sont susceptibles
                                d'évoluer en fonction de la variation des coûts
                                des matières premières et de l'énergie.
                              </p>
                            </React.Fragment>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </Container>
                </div>
              )}
            </React.Fragment>
          )}
          <div className="subFooter inspirationDevis pt120 pb90">
            <div className="subFooterIndex">
              <h2>Créez votre agencement</h2>
              <p>
                Demandez votre devis et concevons ensemble votre projet de
                demain.
              </p>
              <ArrowButton
                href={"../devis?inspirations=" + inspiration.attributes.slug}
                color={"grey"}
                content={"Demande de devis"}
              />
            </div>
          </div>
          {/* <ProposeProducts /> */}
          <InspirationProducts
            products={inspiration.attributes.produits.data}
          />
          {/* {!isLoading && inspiration.attributes.univer.data && (
        <ProposeInspirations currentInspiration={inspiration} />
      )} */}
        </React.Fragment>
      ) : (
        <Grid spacing={1} container className="productViewContainer pt48 pb120">
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className="productViewSkelet"
          >
            <div style={{ height: "690px" }}></div>
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className="productViewSkelet"
          >
            <div style={{ height: "690px" }}></div>
          </Grid>
          <Grid
            item
            lg={4}
            md={4}
            sm={12}
            xs={12}
            className="productViewSkelet"
          >
            <div style={{ height: "320px" }}></div>
          </Grid>
          <Grid
            item
            lg={4}
            md={4}
            sm={12}
            xs={12}
            className="productViewSkelet"
          >
            <div style={{ height: "320px" }}></div>
          </Grid>
          <Grid
            item
            lg={4}
            md={4}
            sm={12}
            xs={12}
            className="productViewSkelet"
          >
            <div style={{ height: "320px" }}></div>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}
