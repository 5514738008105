import React from "react";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
export default function BannerInspiration({ title, content }) {
  return (
    <section className="banner-inspiration">
      <Container maxWidth="xl" className="position-banner-inspiration">
        <Grid container>
          <Grid item xs={12}>
            {content && <p className="pretitre">{content}</p>}
            <h1>{title && title}</h1>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}
