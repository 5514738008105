import { GET_PRODUCTS } from "../actions/products.actions";

// etat par défaut
const initialState = {};

export default function productsReducer(state = initialState, action) {
  // si l'action a comme type :
  switch (action.type) {
    case GET_PRODUCTS:
      return action.payload;
    default:
      return state;
  }
}
