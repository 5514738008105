import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import API_URL from "../Api";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Ariane from "../components/molecules/Ariane";
import PaginationProducts from "../components/products/PaginationProducts";
import Banner from "../components/molecules/Banner";
import ArrowButton from "../components/Atom/ArrowButton";
import AddToFavorites from "../components/molecules/AddToFavorites";
import ImageOptimizer from "../components/utils/ImageOptimizer";

export default function Actualites() {
  const [isLoading, setIsLoading] = useState(true);
  const [firstActualite, setFirstActualite] = useState();
  const [actualites, setActualites] = useState();

  // page active
  const [page, setPage] = useState(1);
  // nombre de page
  const [pageCount, setPageCount] = useState();
  // nombre de produits par page
  const [pageSize, setPageSize] = useState();
  // total de produits retournés
  const [total, setTotal] = useState();

  // expression recherchée
  const [search, setSearch] = useState("");

  useEffect(() => {
    // on fetch les actualités
    fetch(
      API_URL +
        "/api/actualites?populate[0]=titre&populate[1]=description&populate[2]=slug&populate[3]=images&sort[0]=createdAt%3Adesc",
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        setFirstActualite(response.data[0]);
      });

    let url =
      API_URL +
      "/api/actualites?populate[0]=titre&populate[1]=description&populate[2]=slug&populate[3]=couverture&populate[4]=images&populate[5]=univers&populate[6]=publication&populate[7]=publishedAt";
    url =
      API_URL +
      `/api/actualites?populate[0]=titre&populate[1]=description&populate[2]=slug&populate[3]=couverture&populate[4]=images&populate[5]=univers&populate[6]=publication&populate[7]=publishedAt&sort[0]=createdAt%3Adesc&filters[$or][0][titre][$containsi]=${search}&filters[$or][1][description][$containsi]=${search}`;
    url += `&pagination[page]=${page}`;
    url += `&pagination[pageSize]=9`;
    url += `&pagination[withCount]=true`;
    // on fetch les actualités
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "Application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setActualites(response.data);
        setPage(response.meta.pagination.page);
        setPageCount(response.meta.pagination.pageCount);
        setPageSize(response.meta.pagination.pageSize);
        setTotal(response.meta.pagination.total);
        setIsLoading(false);
      });
  }, [search, page]);

  const arianeItem = [
    ["/", "Tilt'Équipement"],
    ["/actualites", "Actualités"],
  ];

  return (
    <React.Fragment>
      <MetaTags>
        <title>Tilt’ Équipement - Actualités</title>
        <meta property="og:title" content={"Tilt’ Équipement - Actualités"} />
        <meta property="title" content={"Tilt’ Équipement - Actualités"} />
        <meta
          name="description"
          content={
            "Découvrez les actualités de l’entreprise Tilt’ Équipement, avec des informations sur les produits ainsi que des conseils d’agencement et d’utilisation."
          }
        />
        <meta
          name="og:description"
          content={
            "Découvrez les actualités de l’entreprise Tilt’ Équipement, avec des informations sur les produits ainsi que des conseils d’agencement et d’utilisation."
          }
        />
        <meta
          name="keywords"
          content={"Tilt'Équipement, Actualités, Actualité"}
        />
        <meta name="og:url" content={"https://tilt-equipement.fr/actualites"} />
      </MetaTags>
      <Ariane arianeItem={arianeItem} />
      <Banner title={"Actualités"} />
      <div className="actusContainer pt120 pb120">
        <Container maxWidth="xl">
          {!isLoading && firstActualite && (
            <Grid container>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <div className="lastActuText">
                  <div>
                    <div className="containBannerAddToFav">
                      <p className="pretitre color1">Dernière actualité</p>
                      <div className="bannerAddToFav">
                        <AddToFavorites
                          label={"actualites"}
                          content={firstActualite}
                        />
                      </div>
                    </div>
                    <h2>{firstActualite.attributes.titre}</h2>
                    <p
                      className="firstRealisationLeftDescription"
                      dangerouslySetInnerHTML={{
                        __html:
                          firstActualite.attributes.description.slice(0, 200) +
                          "...",
                      }}
                    ></p>
                    <ArrowButton
                      href={"/actualite/" + firstActualite.attributes.slug}
                      content="Découvrir"
                    />
                  </div>
                </div>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <div className="lastActuImage">
                  <ImageOptimizer
                    formats={
                      firstActualite.attributes.images.data[0].attributes
                        .formats
                    }
                  />
                </div>
              </Grid>
            </Grid>
          )}
          <div className="allActus pt120">
            <Container maxWidth="xl">
              <div className="filterActus">
                <input
                  onInput={(e) => setSearch(e.target.value)}
                  type="search"
                  placeholder="Rechercher parmi les articles..."
                />
              </div>
              <Grid
                container
                className=""
                spacing={1}
                // justifyContent="center"
                // alignItems="center"
              >
                {!isLoading && actualites[0] ? (
                  <React.Fragment>
                    {actualites.map((actualite) => (
                      <Grid
                        item
                        lg={4}
                        md={4}
                        sm={6}
                        xs={12}
                        className="actuCard pt32 pb32"
                        key={"UniversGaleryCard" + actualite.id}
                      >
                        <div className="bannerAddToFav">
                          <AddToFavorites
                            label={"actualites"}
                            content={actualite}
                          />
                        </div>
                        <a href={"../actualite/" + actualite.attributes.slug}>
                          <div className="actuCardImg">
                            {actualite.attributes.couverture.data ? (
                              <ImageOptimizer
                                formats={
                                  actualite.attributes.couverture.data
                                    .attributes.formats
                                }
                              />
                            ) : (
                              <ImageOptimizer
                                formats={
                                  actualite.attributes.images.data[0].attributes
                                    .formats
                                }
                              />
                            )}
                          </div>
                          <div className="actuCardText">
                            <div className="flex pt24 pb16">
                              {actualite.attributes.univers.data && (
                                <p className="pretitre color1">
                                  {
                                    actualite.attributes.univers.data.attributes
                                      .titre
                                  }
                                </p>
                              )}
                              {actualite.attributes.publication ? (
                                <p className="actuCardTextDate">
                                  {new Date(
                                    actualite.attributes.publication
                                  ).toLocaleDateString("fr-FR")}
                                </p>
                              ) : (
                                <p className="actuCardTextDate">
                                  {new Date(
                                    actualite.attributes.publishedAt
                                  ).toLocaleDateString("fr-FR")}
                                </p>
                              )}
                            </div>
                            <p className="actuCardTextTitre">
                              {actualite.attributes.titre}
                            </p>
                          </div>
                        </a>
                      </Grid>
                    ))}
                  </React.Fragment>
                ) : (
                  <p>Aucune actualité disponible...</p>
                )}
              </Grid>
              <PaginationProducts
                pageCount={pageCount}
                page={page}
                setPage={setPage}
              />
            </Container>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
}
