import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import { AnimationOnScroll } from "react-animation-on-scroll";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Pagination, Navigation } from "swiper";
import ImageOptimizer from "../utils/ImageOptimizer";

export default function InspirationProducts({ products }) {
  return (
    <React.Fragment>
      {products.length > 0 && (
        <section className="proposeProductsContainer pt120 pb120">
          <Container maxWidth="xl">
            <Grid container spacing={1}>
              <Grid item lg={3} md={3} sm={12} xs={12}>
                <AnimationOnScroll animateIn="animate__fadeIn">
                  <h2 className="h2 pt120">
                    <span>Produits</span>
                    <br />
                    'recommandés'
                  </h2>
                </AnimationOnScroll>
              </Grid>
              <Grid item lg={9} md={9} sm={12} xs={12}>
                <Swiper
                  slidesPerView="auto"
                  spaceBetween={15}
                  navigation={true}
                  modules={[Pagination, Navigation]}
                  className="proposeProductSwiper"
                  breakpoints={{
                    "@0.00": {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    "@0.75": {
                      slidesPerView: 3,
                      spaceBetween: 15,
                    },
                    "@1.00": {
                      slidesPerView: 3,
                      spaceBetween: 15,
                    },
                    "@1.50": {
                      slidesPerView: 4,
                      spaceBetween: 15,
                    },
                  }}
                >
                  {products.map((product) => (
                    <React.Fragment>
                      <SwiperSlide
                        key={product.id + " proposeProductsInspiProduct"}
                        className="proposeProductsCardCase"
                      >
                        <AnimationOnScroll animateIn="animate__fadeIn">
                          <Link
                            underline="none"
                            href={"../produit/" + product.attributes.slug}
                            className="proposeProductsLink"
                            color="inherit"
                          >
                            <div className="proposeProductsCard">
                              {product.attributes.images.data && (
                                <ImageOptimizer
                                  formats={
                                    product.attributes.images.data[0].attributes
                                      .formats
                                  }
                                />
                              )}
                              {/* <span>
                                {product.attributes.univers.data[0] &&
                                  product.attributes.univers.data[0].attributes
                                    .titre}
                              </span> */}
                              <p>
                                {product.attributes.nom.slice(0, 40) + "..."}
                              </p>
                            </div>
                          </Link>
                        </AnimationOnScroll>
                      </SwiperSlide>
                    </React.Fragment>
                  ))}
                </Swiper>
              </Grid>
            </Grid>
          </Container>
        </section>
      )}
    </React.Fragment>
  );
}
