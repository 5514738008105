import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { AnimationOnScroll } from "react-animation-on-scroll";
import ArrowButton from "../Atom/ArrowButton";

export default function SRBanner() {
  return (
    <AnimationOnScroll animateIn="animate__fadeIn">
      <section className="srBanner pt120 pb120">
        <Container maxWidth="xl" className="position-banner-srevolutif">
          <Grid container justifyContent="center">
            <Grid item lg={10} xs={12}>
              <h2 className="textcenter">Notre marque SR l'évolutif®</h2>
              <p className="textcenter">
                Tilt’ Équipement a créé la marque de gondole{" "}
                <strong>SR l’évolutif®</strong>, un produit de qualité à prix
                compétitif pour équiper tous les points de vente et de toutes
                surfaces. Ses nombreux accessoires permettent de présenter tout
                type de produits. Avec son stock permanent, Tilt’ Équipement
                répond rapidement à vos besoins sans minimum de commande.
              </p>
              <ArrowButton
                content={"Découvrir notre marque"}
                color="grey"
                href={"/sr-evolutif"}
              />
            </Grid>
          </Grid>
        </Container>
      </section>
    </AnimationOnScroll>
  );
}
