import React, { useEffect, useState } from "react";
import API_URL from "../Api";
import Ariane from "../components/molecules/Ariane";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import axios from "axios";
import { MetaTags } from "react-meta-tags";
import { Link } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import CloseIcon from "@mui/icons-material/Close";
import ImageOptimizer from "../components/utils/ImageOptimizer";
import imagedefault from "../../src/assets/image_produit_defaut_tiltequipement.svg";

export default function Distributeur() {
  const [password, setPassword] = useState();
  const [email, setEmail] = useState();
  const [isSigned, setIsSigned] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [arianeItem, setArianeItem] = useState([]);

  const [catalogues, setCatalogues] = useState();
  const [cataloguesIsLoading, setCataloguesIsLoading] = useState(true);
  const [notices, setNotices] = useState();
  const [noticesIsLoading, setNoticesIsLoading] = useState(true);
  const [tutos, setTutos] = useState();
  const [tutosIsLoading, setTutosIsLoading] = useState(true);

  // affichage de l'iframe de vidéo
  const [showVideo, setShowVideo] = useState(false);
  const [videoId, setVideoId] = useState();

  useEffect(() => {
    if (
      window.localStorage.getItem("authToken") !== null &&
      window.localStorage.getItem("authToken") !== undefined
    ) {
      setIsSigned(true);
    } else {
      setIsSigned(false);
    }
    // recup les catalogues fournisseurs
    fetch(
      `${API_URL}/api/catalogues?sort[0]=rank%3Aasc&populate=*&filters[cible][$eq]=revendeur`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        setCatalogues(response.data);
        setCataloguesIsLoading(false);
        setArianeItem([
          ["/", "Accueil fournisseur"],
          ["/espace-revendeur", "Espace revendeur"],
        ]);
      });

    // recup les notices fournisseurs
    fetch(
      `${API_URL}/api/notices?sort[0]=rank%3Aasc&populate=*&filters[cible][$eq]=revendeur`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        setNotices(response.data);
        setNoticesIsLoading(false);
      });

    // recup les tutos fournisseurs
    fetch(
      `${API_URL}/api/tutos?sort[0]=rank%3Aasc&populate=*&filters[cible][$eq]=revendeur`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        setTutos(response.data);
        setTutosIsLoading(false);
      });
  }, []);

  // iframe video
  function handleIframe(url) {
    setVideoId(url.split("/").reverse()[0]);
    setShowVideo(true);
  }

  function closeIframe() {
    setVideoId();
    setShowVideo(false);
  }

  function handleSubmit(event) {
    event.preventDefault();
    axios
      .post(`${API_URL}/api/auth/local`, {
        identifier: email,
        password: password,
      })
      .then((response) => {
        setSuccess(true);
        setError(false);
        setIsSigned(true);
        window.localStorage.setItem("authToken", response.data.jwt);
        window.localStorage.setItem("username", response.data.user.username);
      })
      .catch((error) => {
        setSuccess(false);
        setError(true);
        // console.log("Une erreur est apparue :", error.response);
      });
  }

  function logOut() {
    localStorage.removeItem("authToken");
    localStorage.removeItem("username");
    setIsSigned(false);
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Votre espace revendeur</title>
        <meta
          property="og:title"
          content={"Tilt’ Équipement - Espace revendeur"}
        />
        <meta
          property="title"
          content={"Tilt’ Équipement - Espace revendeur"}
        />
        <meta
          name="description"
          content={
            "Accédez à un espace dédié aux revendeurs, avec un accès personnel à toute la documentation."
          }
        />
        <meta
          name="og:description"
          content={
            "Accédez à un espace dédié aux revendeurs, avec un accès personnel à toute la documentation."
          }
        />
        <meta name="keywords" content={"Tilt'Équipement, espace revendeur"} />
        <meta
          name="og:url"
          content={"https://tilt-equipement.fr/espace-revendeur"}
        />
      </MetaTags>
      <Ariane arianeItem={arianeItem} />
      <div
        className={
          isSigned ? "distributeurContainerSigned" : "distributeurContainer"
        }
      >
        <div className="distributeurBox">
          <div className="distributeurForm">
            <form onSubmit={handleSubmit}>
              {isSigned ? (
                <Container maxWidth="xl" className="loginBox">
                  <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <div className="inputButton">
                        <input
                          type={"button"}
                          onClick={logOut}
                          value={"Me déconnecter"}
                        />
                        <span>
                          <ExpandCircleDownIcon />
                        </span>
                      </div>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12}>
                      {success && (
                        <div className="gridContainerConnexionInformation pb64">
                          <h1>Bonjour {window.localStorage.username} !</h1>
                        </div>
                      )}
                      <Grid container>
                        {isSigned && (
                          <React.Fragment>
                            {!cataloguesIsLoading &&
                            !noticesIsLoading &&
                            !tutosIsLoading ? (
                              <Grid item xs={12}>
                                <div className="cataloguesTypeBox">
                                  <Grid container spacing={2}>
                                    {!cataloguesIsLoading && (
                                      <React.Fragment>
                                        {catalogues.find(
                                          (catalogue) =>
                                            catalogue.attributes.type ===
                                            "mise en avant"
                                        ) && (
                                          <Grid item xs={12}>
                                            <div
                                              className="cataloguesTypeBox"
                                              style={{
                                                padding: "0 0 0 100px",
                                                position: "relative",
                                              }}
                                            >
                                              <h3
                                                style={{
                                                  position: "absolute",
                                                  left: "-100px",
                                                  transform: "rotate(-90deg)",
                                                  top: "40%",
                                                }}
                                              >
                                                Catalogue en avant
                                              </h3>
                                              <Grid
                                                container
                                                className="cataloguesGrid"
                                                spacing={2}
                                              >
                                                {catalogues.map((catalogue) => (
                                                  <React.Fragment>
                                                    {catalogue.attributes
                                                      .type ===
                                                      "mise en avant" && (
                                                      <Grid
                                                        item
                                                        lg={4}
                                                        md={4}
                                                        sm={6}
                                                        xs={12}
                                                        className="pt24 pb24"
                                                      >
                                                        <Link
                                                          href={
                                                            API_URL +
                                                            catalogue.attributes
                                                              .fichier.data[0]
                                                              .attributes.url
                                                          }
                                                          target="_blank"
                                                          rel="noreferrer"
                                                        >
                                                          <div>
                                                            <ImageOptimizer
                                                              formats={
                                                                catalogue
                                                                  .attributes
                                                                  .couverture
                                                                  .data
                                                                  .attributes
                                                                  .formats
                                                              }
                                                            />
                                                            <span>
                                                              {catalogue.attributes.soustitre.toUpperCase()}
                                                            </span>
                                                            <p
                                                              className="bold"
                                                              style={{
                                                                maxWidth:
                                                                  "320px",
                                                              }}
                                                            >
                                                              {
                                                                catalogue
                                                                  .attributes
                                                                  .titre
                                                              }
                                                            </p>
                                                            <div className="infoMajCatalogue">
                                                              {catalogue
                                                                .attributes
                                                                .date_maj && (
                                                                <p>
                                                                  {"MÀJ : " +
                                                                    new Date(
                                                                      catalogue.attributes.date_maj
                                                                    ).toLocaleDateString(
                                                                      "fr-FR"
                                                                    )}
                                                                </p>
                                                              )}
                                                              {catalogue
                                                                .attributes
                                                                .note_maj && (
                                                                <p className="majp">
                                                                  "
                                                                  {
                                                                    catalogue
                                                                      .attributes
                                                                      .note_maj
                                                                  }
                                                                  "
                                                                </p>
                                                              )}
                                                            </div>
                                                          </div>
                                                        </Link>
                                                      </Grid>
                                                    )}
                                                  </React.Fragment>
                                                ))}
                                              </Grid>
                                            </div>
                                          </Grid>
                                        )}
                                      </React.Fragment>
                                    )}
                                    {!cataloguesIsLoading && (
                                      <React.Fragment>
                                        {catalogues.find(
                                          (catalogue) =>
                                            catalogue.attributes.type ===
                                            "général"
                                        ) && (
                                          <Grid item xs={12}>
                                            <div
                                              className="cataloguesTypeBox"
                                              style={{
                                                padding: "0 0 0 100px",
                                                position: "relative",
                                              }}
                                            >
                                              <h3
                                                style={{
                                                  position: "absolute",
                                                  left: "-100px",
                                                  transform: "rotate(-90deg)",
                                                  top: "40%",
                                                }}
                                              >
                                                Catalogue généraux
                                              </h3>
                                              <Grid
                                                container
                                                className="cataloguesGrid"
                                                spacing={2}
                                              >
                                                {catalogues.map((catalogue) => (
                                                  <React.Fragment>
                                                    {catalogue.attributes
                                                      .type === "général" && (
                                                      <Grid
                                                        item
                                                        lg={4}
                                                        md={4}
                                                        sm={6}
                                                        xs={12}
                                                        className="pt24 pb24"
                                                      >
                                                        <Link
                                                          href={
                                                            API_URL +
                                                            catalogue.attributes
                                                              .fichier.data[0]
                                                              .attributes.url
                                                          }
                                                          target="_blank"
                                                          rel="noreferrer"
                                                        >
                                                          <div>
                                                            <ImageOptimizer
                                                              formats={
                                                                catalogue
                                                                  .attributes
                                                                  .couverture
                                                                  .data
                                                                  .attributes
                                                                  .formats
                                                              }
                                                            />
                                                            <span>
                                                              {catalogue.attributes.soustitre.toUpperCase()}
                                                            </span>
                                                            <p
                                                              className="bold"
                                                              style={{
                                                                maxWidth:
                                                                  "320px",
                                                              }}
                                                            >
                                                              {
                                                                catalogue
                                                                  .attributes
                                                                  .titre
                                                              }
                                                            </p>
                                                            <div className="infoMajCatalogue">
                                                              {catalogue
                                                                .attributes
                                                                .date_maj && (
                                                                <p>
                                                                  {"MÀJ : " +
                                                                    new Date(
                                                                      catalogue.attributes.date_maj
                                                                    ).toLocaleDateString(
                                                                      "fr-FR"
                                                                    )}
                                                                </p>
                                                              )}
                                                              {catalogue
                                                                .attributes
                                                                .note_maj && (
                                                                <p className="majp">
                                                                  "
                                                                  {
                                                                    catalogue
                                                                      .attributes
                                                                      .note_maj
                                                                  }
                                                                  "
                                                                </p>
                                                              )}
                                                            </div>
                                                          </div>
                                                        </Link>
                                                      </Grid>
                                                    )}
                                                  </React.Fragment>
                                                ))}
                                              </Grid>
                                            </div>
                                          </Grid>
                                        )}
                                      </React.Fragment>
                                    )}
                                    {!cataloguesIsLoading && (
                                      <React.Fragment>
                                        {catalogues.find(
                                          (catalogue) =>
                                            catalogue.attributes.type ===
                                            "produits"
                                        ) && (
                                          <Grid item xs={12}>
                                            <div
                                              className="cataloguesTypeBox"
                                              style={{
                                                padding: "0 0 0 100px",
                                                position: "relative",
                                              }}
                                            >
                                              <h3
                                                style={{
                                                  position: "absolute",
                                                  left: "-100px",
                                                  transform: "rotate(-90deg)",
                                                  top: "40%",
                                                }}
                                              >
                                                Catalogue produits
                                              </h3>
                                              <Grid
                                                container
                                                className="cataloguesGrid"
                                                spacing={2}
                                              >
                                                {catalogues.map((catalogue) => (
                                                  <React.Fragment>
                                                    {catalogue.attributes
                                                      .type === "produits" && (
                                                      <Grid
                                                        item
                                                        lg={4}
                                                        md={4}
                                                        sm={6}
                                                        xs={12}
                                                        className="pt24 pb24"
                                                      >
                                                        <Link
                                                          href={
                                                            API_URL +
                                                            catalogue.attributes
                                                              .fichier.data[0]
                                                              .attributes.url
                                                          }
                                                          target="_blank"
                                                          rel="noreferrer"
                                                        >
                                                          <div>
                                                            <ImageOptimizer
                                                              formats={
                                                                catalogue
                                                                  .attributes
                                                                  .couverture
                                                                  .data
                                                                  .attributes
                                                                  .formats
                                                              }
                                                            />
                                                            <span>
                                                              {catalogue.attributes.soustitre.toUpperCase()}
                                                            </span>
                                                            <p
                                                              className="bold"
                                                              style={{
                                                                maxWidth:
                                                                  "320px",
                                                              }}
                                                            >
                                                              {
                                                                catalogue
                                                                  .attributes
                                                                  .titre
                                                              }
                                                            </p>
                                                            <div className="infoMajCatalogue">
                                                              {catalogue
                                                                .attributes
                                                                .date_maj && (
                                                                <p>
                                                                  {"MÀJ : " +
                                                                    new Date(
                                                                      catalogue.attributes.date_maj
                                                                    ).toLocaleDateString(
                                                                      "fr-FR"
                                                                    )}
                                                                </p>
                                                              )}
                                                              {catalogue
                                                                .attributes
                                                                .note_maj && (
                                                                <p className="majp">
                                                                  "
                                                                  {
                                                                    catalogue
                                                                      .attributes
                                                                      .note_maj
                                                                  }
                                                                  "
                                                                </p>
                                                              )}
                                                            </div>
                                                          </div>
                                                        </Link>
                                                      </Grid>
                                                    )}
                                                  </React.Fragment>
                                                ))}
                                              </Grid>
                                            </div>
                                          </Grid>
                                        )}
                                      </React.Fragment>
                                    )}
                                    {!cataloguesIsLoading && (
                                      <React.Fragment>
                                        {catalogues.find(
                                          (catalogue) =>
                                            catalogue.attributes.type ===
                                            "tarifs"
                                        ) && (
                                          <Grid item xs={12}>
                                            <div
                                              className="cataloguesTypeBox"
                                              style={{
                                                padding: "0 0 0 100px",
                                                position: "relative",
                                              }}
                                            >
                                              <h3
                                                style={{
                                                  position: "absolute",
                                                  left: "-100px",
                                                  transform: "rotate(-90deg)",
                                                  top: "40%",
                                                }}
                                              >
                                                Catalogue tarifs
                                              </h3>
                                              <Grid
                                                container
                                                className="cataloguesGrid"
                                                spacing={2}
                                              >
                                                {catalogues.map((catalogue) => (
                                                  <React.Fragment>
                                                    {catalogue.attributes
                                                      .type === "tarifs" && (
                                                      <Grid
                                                        item
                                                        lg={4}
                                                        md={4}
                                                        sm={6}
                                                        xs={12}
                                                        className="pt24 pb24"
                                                      >
                                                        <Link
                                                          href={
                                                            API_URL +
                                                            catalogue.attributes
                                                              .fichier.data[0]
                                                              .attributes.url
                                                          }
                                                          target="_blank"
                                                          rel="noreferrer"
                                                        >
                                                          <div>
                                                            <ImageOptimizer
                                                              formats={
                                                                catalogue
                                                                  .attributes
                                                                  .couverture
                                                                  .data
                                                                  .attributes
                                                                  .formats
                                                              }
                                                            />
                                                            <span>
                                                              {catalogue.attributes.soustitre.toUpperCase()}
                                                            </span>
                                                            <p
                                                              className="bold"
                                                              style={{
                                                                maxWidth:
                                                                  "320px",
                                                              }}
                                                            >
                                                              {
                                                                catalogue
                                                                  .attributes
                                                                  .titre
                                                              }
                                                            </p>
                                                            <div className="infoMajCatalogue">
                                                              {catalogue
                                                                .attributes
                                                                .date_maj && (
                                                                <p>
                                                                  {"MÀJ : " +
                                                                    new Date(
                                                                      catalogue.attributes.date_maj
                                                                    ).toLocaleDateString(
                                                                      "fr-FR"
                                                                    )}
                                                                </p>
                                                              )}
                                                              {catalogue
                                                                .attributes
                                                                .note_maj && (
                                                                <p className="majp">
                                                                  "
                                                                  {
                                                                    catalogue
                                                                      .attributes
                                                                      .note_maj
                                                                  }
                                                                  "
                                                                </p>
                                                              )}
                                                            </div>
                                                          </div>
                                                        </Link>
                                                      </Grid>
                                                    )}
                                                  </React.Fragment>
                                                ))}
                                              </Grid>
                                            </div>
                                          </Grid>
                                        )}
                                      </React.Fragment>
                                    )}
                                    {!cataloguesIsLoading && (
                                      <React.Fragment>
                                        {catalogues.find(
                                          (catalogue) =>
                                            catalogue.attributes.type ===
                                            "univers"
                                        ) && (
                                          <Grid item xs={12}>
                                            <div
                                              className="cataloguesTypeBox"
                                              style={{
                                                padding: "0 0 0 100px",
                                                position: "relative",
                                              }}
                                            >
                                              <h3
                                                style={{
                                                  position: "absolute",
                                                  left: "-100px",
                                                  transform: "rotate(-90deg)",
                                                  top: "40%",
                                                }}
                                              >
                                                Catalogue univers
                                              </h3>
                                              <Grid
                                                container
                                                className="cataloguesGrid"
                                                spacing={2}
                                              >
                                                {catalogues.map((catalogue) => (
                                                  <React.Fragment>
                                                    {catalogue.attributes
                                                      .type === "univers" && (
                                                      <Grid
                                                        item
                                                        lg={4}
                                                        md={4}
                                                        sm={6}
                                                        xs={12}
                                                        className="pt24 pb24"
                                                      >
                                                        <Link
                                                          href={
                                                            API_URL +
                                                            catalogue.attributes
                                                              .fichier.data[0]
                                                              .attributes.url
                                                          }
                                                          target="_blank"
                                                          rel="noreferrer"
                                                        >
                                                          <div>
                                                            <ImageOptimizer
                                                              formats={
                                                                catalogue
                                                                  .attributes
                                                                  .couverture
                                                                  .data
                                                                  .attributes
                                                                  .formats
                                                              }
                                                            />
                                                            <span>
                                                              {catalogue.attributes.soustitre.toUpperCase()}
                                                            </span>
                                                            <p
                                                              className="bold"
                                                              style={{
                                                                maxWidth:
                                                                  "320px",
                                                              }}
                                                            >
                                                              {
                                                                catalogue
                                                                  .attributes
                                                                  .titre
                                                              }
                                                            </p>
                                                            <div className="infoMajCatalogue">
                                                              {catalogue
                                                                .attributes
                                                                .date_maj && (
                                                                <p>
                                                                  {"MÀJ : " +
                                                                    new Date(
                                                                      catalogue.attributes.date_maj
                                                                    ).toLocaleDateString(
                                                                      "fr-FR"
                                                                    )}
                                                                </p>
                                                              )}
                                                              {catalogue
                                                                .attributes
                                                                .note_maj && (
                                                                <p className="majp">
                                                                  "
                                                                  {
                                                                    catalogue
                                                                      .attributes
                                                                      .note_maj
                                                                  }
                                                                  "
                                                                </p>
                                                              )}
                                                            </div>
                                                          </div>
                                                        </Link>
                                                      </Grid>
                                                    )}
                                                  </React.Fragment>
                                                ))}
                                              </Grid>
                                            </div>
                                          </Grid>
                                        )}
                                      </React.Fragment>
                                    )}
                                  </Grid>
                                </div>
                                <div
                                  className="cataloguesTypeBox"
                                  style={{
                                    padding: "120px 0px 0px 100px",
                                    position: "relative",
                                  }}
                                >
                                  <h3
                                    style={{
                                      position: "absolute",
                                      left: "-100px",
                                      transform: "rotate(-90deg)",
                                      top: "40%",
                                    }}
                                  >
                                    Notice de montage
                                  </h3>
                                  <Grid
                                    container
                                    spacing={2}
                                    className="cataloguesGrid"
                                  >
                                    {notices.map((notice) => (
                                      <React.Fragment>
                                        <Grid item lg={4} md={4} sm={6} xs={12}>
                                          <Link
                                            href={
                                              API_URL +
                                              notice.attributes.fichier.data[0]
                                                .attributes.url
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            <div>
                                              {notice.attributes.couverture
                                                .data ? (
                                                <ImageOptimizer
                                                  formats={
                                                    notice.attributes.couverture
                                                      .data.attributes.formats
                                                  }
                                                />
                                              ) : (
                                                <img
                                                  src={imagedefault}
                                                  alt="Image par défaut de Tilt Equipement"
                                                />
                                              )}
                                              <p
                                                className="bold"
                                                style={{ maxWidth: "320px" }}
                                              >
                                                {notice.attributes.titre}
                                              </p>
                                            </div>
                                          </Link>
                                        </Grid>
                                      </React.Fragment>
                                    ))}
                                  </Grid>
                                </div>
                                <div
                                  className="NoticesTypeBoxTuto"
                                  style={{
                                    padding: "120px 0px 120px 100px",
                                    position: "relative",
                                  }}
                                >
                                  <h3
                                    style={{
                                      position: "absolute",
                                      left: "0",
                                      transform: "rotate(-90deg)",
                                      top: "40%",
                                    }}
                                  >
                                    Tutos
                                  </h3>
                                  <Grid
                                    container
                                    spacing={2}
                                    className="NoticesGrid"
                                  >
                                    {tutos.map((tuto) => (
                                      <React.Fragment>
                                        <Grid
                                          item
                                          lg={4}
                                          md={4}
                                          sm={6}
                                          xs={12}
                                          key={
                                            "tuto" +
                                            tuto.attributes.titre +
                                            tuto.id
                                          }
                                          onClick={() =>
                                            handleIframe(tuto.attributes.url)
                                          }
                                        >
                                          {tuto.attributes.vignette.data ? (
                                            <ImageOptimizer
                                              formats={
                                                tuto.attributes.vignette.data
                                                  .attributes.formats
                                              }
                                              requireFormat="small"
                                            />
                                          ) : (
                                            <div className="tutoPlayerIcon">
                                              <PlayArrowIcon />
                                            </div>
                                          )}
                                          <span>TUTOS</span>
                                          <p style={{ maxWidth: "320px" }}>
                                            {tuto.attributes.titre.slice(
                                              0,
                                              30
                                            ) + "..."}
                                          </p>
                                        </Grid>
                                      </React.Fragment>
                                    ))}
                                  </Grid>
                                </div>{" "}
                              </Grid>
                            ) : (
                              <React.Fragment>
                                <div className="distributeurCatalogueCard"></div>
                                <div className="distributeurCatalogueCard"></div>
                                <div className="distributeurCatalogueCard"></div>
                                <div className="distributeurCatalogueCard"></div>
                                <div className="distributeurCatalogueCard"></div>
                                <div className="distributeurCatalogueCard"></div>
                                <div className="distributeurCatalogueCard"></div>
                                <div className="distributeurCatalogueCard"></div>
                                <div className="distributeurCatalogueCard"></div>
                              </React.Fragment>
                            )}
                          </React.Fragment>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Container>
              ) : (
                <Container maxWidth="xl" className="loginBox">
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{ height: "100%" }}
                  >
                    <Grid item lg={8} md={6} sm={12} className="h100">
                      <div className="gridContainerConnexionInformation">
                        <p className="pretitre color1">Connexion</p>
                        <h1>Votre espace revendeur</h1>
                      </div>
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} className="h100">
                      <div className="gridContainerConnexion">
                        <h2>Connexion</h2>
                        <div>
                          <input
                            name="email"
                            placeholder="E-mail"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            type="email"
                            required
                          />
                        </div>
                        <div>
                          <input
                            name="password"
                            placeholder="Code d'accès"
                            value={password}
                            onChange={(event) =>
                              setPassword(event.target.value)
                            }
                            type="password"
                            required
                          />
                          {error && (
                            <p className="error">
                              Erreur lors de l'identification
                            </p>
                          )}
                        </div>
                        <div className="inputButton">
                          <input type={"submit"} value={"Me connecter"} />
                          <span>
                            <ExpandCircleDownIcon />
                          </span>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Container>
              )}
            </form>
          </div>
        </div>
      </div>
      <div
        className={
          showVideo && videoId
            ? "videoIframeOverlay showIframe"
            : "videoIframeOverlay "
        }
        onClick={(e) => closeIframe(e)}
      >
        <iframe
          src={
            "https://www.youtube.com/embed/" +
            videoId +
            "?autoplay=1&modestbranding=1&color=white&rel=0"
          }
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
        <div className="closeVideoIframe" onClick={closeIframe}>
          <CloseIcon />
        </div>
      </div>
    </React.Fragment>
  );
}
