import React, { useEffect, useState } from "react";
import API_URL from "../Api";
import Ariane from "../components/molecules/Ariane";
import Banner from "../components/molecules/Banner";
import { useParams } from "react-router-dom";
import { MetaTags } from "react-meta-tags";
import ArrowButton from "../components/Atom/ArrowButton";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
// import ReCAPTCHA from "react-google-recaptcha";

export default function Candidature() {
  const slug = useParams().slug;
  const [arianeItem, setArianeItem] = useState([]);

  const [nom, setNom] = useState();
  const [prenom, setPrenom] = useState();
  const [email, setEmail] = useState();
  const [telephone, setTelephone] = useState();
  const [sujet, setSujet] = useState();
  const [curiculum, setCuriculum] = useState(null);
  const [motivation, setMotivation] = useState();
  const [clause, setClause] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [offre, setOffre] = useState([]);
  const queryParameters = new URLSearchParams(window.location.search);

  // feedback soumission form
  const [sendState, setSendState] = useState(false);

  // feedback soumission form
  const [alertContent, setAlertContent] = useState();
  const [alertColor, setAlertColor] = useState();

  useEffect(() => {
    if (slug) {
      fetch(`${API_URL}/api/offres?populate=*&filters[slug][$eq]=${slug}`, {
        method: "GET",
        headers: {
          Accept: "Application/json",
        },
      })
        .then((res) => res.json())
        .then((response) => {
          setOffre(response.data[0]);
          setSujet(response.data[0].attributes.titre);
          if (slug) {
            setArianeItem([
              ["/", "Tilt'Équipement"],
              ["/emploies", "Nous rejoindre"],
              [
                "/emploie/" + response.data[0].attributes.slug,
                response.data[0].attributes.titre,
              ],
              ["", "Postuler"],
            ]);
          } else {
            setArianeItem([
              ["/", "Tilt'Équipement"],
              ["/emploies", "Nous rejoindre"],
              ["/emploies/" + "Candidature spontanée"],
              ["", "Postuler"],
            ]);
          }
          setIsLoading(false);
        });
    }
  }, []);

  function submitCandidature(e) {
    e.preventDefault();

    const data = {
      nom: nom.toUpperCase(),
      prenom: prenom,
      email: email,
      telephone: telephone,
      sujet: sujet,
      lettre: motivation,
      clause_commerciale: clause,
    };

    const formData = new FormData();
    formData.append("files.cv", curiculum, curiculum.name);

    formData.append("data", JSON.stringify(data));

    // enregistrement de la candidature
    fetch(`${API_URL}/api/candidatures`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        setNom("");
        setPrenom("");
        setEmail("");
        setTelephone("");
        setSujet("");
        setCuriculum();
        setMotivation("");
        setSendState(true);
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  return (
    <main className="mainCandidature">
      <MetaTags>
        <title>Tilt’ Équipement - Nous rejoindre</title>
        <meta
          property="og:title"
          content={"Tilt’ Équipement - Nous rejoindre"}
        />
        <meta property="title" content={"Tilt’ Équipement - Nous rejoindre"} />
        <meta
          name="description"
          content={
            "Consultez nos offres d’emploi, postulez ou transmettez votre candidature spontanée. "
          }
        />
        <meta
          name="og:description"
          content={
            "Consultez nos offres d’emploi, postulez ou transmettez votre candidature spontanée. "
          }
        />
        <meta
          name="keywords"
          content={
            "Tilt'Équipement, Candidature, Recrutement, Recrute, Emploies"
          }
        />
        <meta
          name="og:url"
          content={"https://tilt-equipement.fr/candidature"}
        />
      </MetaTags>
      <Ariane arianeItem={arianeItem} />
      <Banner
        left
        title={"Nous rejoindre"}
        content={
          "Vous souhaitez nous rejoindre ? Candidature via le formulaire ci-dessous."
        }
      />
      <Container maxWidth="xl" className="candidatureContainer">
        <Grid container className="pt90 pb120">
          <Grid item xs={12}>
            {!sendState ? (
              <div className="candidatureBox">
                <form
                  className="candidatureForm"
                  onSubmit={(e) => submitCandidature(e)}
                >
                  <div className="candidatureField">
                    <label htmlFor="nom">
                      NOM <span>*</span>
                    </label>
                    <input
                      value={nom}
                      required
                      onChange={(e) => setNom(e.target.value)}
                    />
                  </div>
                  <div className="candidatureField">
                    <label htmlFor="nom">
                      PRÉNOM <span>*</span>
                    </label>
                    <input
                      value={prenom}
                      required
                      onChange={(e) => setPrenom(e.target.value)}
                    />
                  </div>
                  <div className="candidatureField">
                    <label htmlFor="nom">
                      EMAIL <span>*</span>
                    </label>
                    <input
                      value={email}
                      type="email"
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="candidatureField">
                    <label htmlFor="nom">
                      TÉLÉPHONE <span>*</span>
                    </label>
                    <input
                      value={telephone}
                      type="number"
                      required
                      onChange={(e) => setTelephone(e.target.value)}
                    />
                  </div>
                  <div className="candidatureField">
                    <label htmlFor="nom">
                      SUJET DE LA DEMANDE <span>*</span>
                    </label>
                    {!isLoading ? (
                      <input
                        required
                        value={offre && offre.attributes.titre}
                        disabled={offre && offre.attributes.titre}
                        onChange={(e) => setSujet(e.target.value)}
                      />
                    ) : (
                      <input
                        required
                        onChange={(e) => setSujet(e.target.value)}
                      />
                    )}
                  </div>
                  <div className="candidatureField">
                    <label htmlFor="motivations">
                      VOS MOTIVATIONS <span>*</span>
                    </label>
                    <textarea
                      value={motivation}
                      rows="5"
                      required
                      onChange={(e) => setMotivation(e.target.value)}
                    />
                  </div>
                  <div className="candidatureField">
                    <label htmlFor="CV">
                      VOTRE CV <span>*</span>
                    </label>
                    <div className="candidatureFieldFile">
                      <input
                        type="file"
                        required
                        onChange={(e) => setCuriculum(e.target.files[0])}
                      />
                      <div className="candidatureFieldFileCard">
                        {!curiculum ? (
                          <p>Cliquer pour télécharger votre fichier.</p>
                        ) : (
                          <p>{curiculum.name}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="candidatureField">
                    <div className="candidatureFieldCheckBox">
                      <input
                        type="checkbox"
                        value={clause}
                        onChange={(e) => setClause(!clause)}
                        // required
                        checked={clause}
                      ></input>
                      <label>
                        En soumettant ce formulaire j’accepte que les
                        informations saisies soient exploitées dans le cadre de
                        la gestion de la relation commerciale
                      </label>
                    </div>
                  </div>
                  {/* <div className="candidatureField">
                    <ReCAPTCHA
                      sitekey="Your client site key"
                      // onChange={onChange}
                    />
                  </div> */}
                  <div className="candidatureField" id="contactFieldSubmit">
                    <input type={"submit"} value={"Envoyer ma candidature"} />
                    <span>
                      <ExpandCircleDownIcon />
                    </span>
                  </div>
                </form>
              </div>
            ) : (
              <div className="successSending">
                <h2>Votre candidature a été envoyé avec succès !</h2>
                <p>
                  Le service recrutement Tilt’Equipement vous recontactera aussi
                  tôt que possible
                </p>
                <ArrowButton href={"../"} content="Retour à l'accueil" />
              </div>
            )}
          </Grid>
        </Grid>
      </Container>
    </main>
  );
}
