import React from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export default function Burger({ burger, setBurger }) {
  function handleCloseBurger(e) {
    e.stopPropagation();
    setBurger(!burger);
    document.documentElement.classList.remove("noScroll");
  }

  return (
    <div
      className={burger ? "burgerBackdrop openBackdrop" : "burgerBackdrop"}
      onClick={(e) => handleCloseBurger(e)}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={burger ? "burger openUp" : "burger closeUp"}
      >
        <div className="headerBurger">
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
            onClick={(e) => handleCloseBurger(e)}
          >
            <CloseIcon />
          </IconButton>
          {/* <p>MENU</p> */}
        </div>
        <div className="burgerContent">
          <div className="burgerLink burgerLinkHideDesktop">
            <a href="/inspirations">Inspirations</a>
          </div>
          <div className="burgerLink burgerLinkHideDesktop">
            <a href="/realisations">Réalisations</a>
          </div>
          <div className="burgerLink burgerLinkHideDesktop">
            <a href="/univers">Univers</a>
          </div>
          <div className="burgerLink burgerLinkHideDesktop">
            <a href="/produits">Produits</a>
          </div>
          <div className="burgerLink">
            <a href="../entreprise">L'entreprise</a>
          </div>
          <div className="burgerLink">
            <a href="../services">Services</a>
          </div>
          <div className="burgerLink">
            <a href="../actualites">Actualités</a>
          </div>
          <div className="burgerLink">
            <a href="../catalogues">Catalogues</a>
          </div>
          <div className="burgerLink">
            <a href="../notices-et-tutos">Notices de montage et tutos</a>
          </div>
          <div className="burgerLink">
            <a href="../visite-virtuelle">Visite virtuelle</a>
          </div>
          <div className="burgerLink">
            <a href="../contact">Contact</a>
          </div>
        </div>
      </div>
    </div>
  );
}
