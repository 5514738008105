import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import API_URL from "../../Api";
import { AnimationOnScroll } from "react-animation-on-scroll";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Pagination, Navigation } from "swiper";
import ImageOptimizer from "../utils/ImageOptimizer";

// propose des réalisations du même univers que le produit actif
export default function ProposeRealisations({ currentRealisation }) {
  const [isLoading, setIsLoading] = useState(true);
  const [realisations, setRealisations] = useState();

  function shuffle(Array) {
    return Array.sort(() => Math.random() - 0.5);
  }

  //
  useEffect(() => {
    fetch(
      `${API_URL}/api/realisations?populate=*&filters[univer][titre][$ne]=${currentRealisation.attributes.univer.data.attributes.titre}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        // mélange le tableau
        // si plus de 10 item => rogne le tableau à 10 items
        // on supprime la proposition comportant le même nom que l'inspiration active
        setRealisations(shuffle(response.data.slice(0, 9)));
        setIsLoading(false);
      });
  }, [currentRealisation]);

  return (
    <React.Fragment>
      {!isLoading && realisations.length > 0 && (
        <Grid container className="proposeRealisationContainer">
          <Grid item lg={12}>
            <h2>Ces sujets pourraient vous plaire</h2>
          </Grid>
          <Swiper
            className="proposeRealisationCarousel"
            slidesPerView="auto"
            spaceBetween={15}
            navigation={true}
            modules={[Navigation]}
            breakpoints={{
              320: {
                slidesPerView: 1,
              },
              425: {
                slidesPerView: 1,
              },
              576: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              981: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              1440: {
                slidesPerView: 5,
                spaceBetween: 10,
              },
            }}
          >
            {realisations.map((realisation) => (
              <SwiperSlide
                key={realisation.id + " proposeRealisationCardCase"}
                className="proposeRealisationCard"
              >
                <AnimationOnScroll animateIn="animate__fadeIn">
                  <Link
                    underline="none"
                    href={"../realisation/" + realisation.attributes.slug}
                    color="inherit"
                  >
                    <div className="actuCardImg">
                      <ImageOptimizer
                        formats={
                          realisation.attributes.couverture.data.attributes
                            .formats
                        }
                      />
                    </div>
                    <div className="proposeRealisationCardNotice">
                      <span className="pretitre">
                        {realisation.attributes.univer.data.attributes.titre}
                      </span>
                      <p>{realisation.attributes.Titre}</p>
                    </div>
                  </Link>
                </AnimationOnScroll>
              </SwiperSlide>
            ))}
          </Swiper>
        </Grid>
      )}
    </React.Fragment>
  );
}
