import React, { useEffect, useRef, useState } from "react";
import Ariane from "../components/molecules/Ariane";
import MetaTags from "react-meta-tags";
import Banner from "../components/molecules/Banner";
import { Container, Grid } from "@mui/material";
import Link from "@mui/material/Link";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import vectorTilt from "../assets/vector_batiment.png";
import niveauIcon from "../assets/icon_niveau.svg";
import personIcon from "../assets/icon_homme.svg";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  overflow: "scroll",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  height: "100%",
  display: "block",
};

export default function Showroom() {
  const arianeItem = [
    ["/", "Tilt'Équipement"],
    ["/visite-virtuelle", "Visite virtuelle"],
  ];
  const [showroomFilterOpen, setOpen] = React.useState(true);

  const [step, setStep] = useState(1);

  const openShowroomFilter = () => {
    setOpen(!showroomFilterOpen);
  };

  const refToScroll = useRef(null);

  const [open, setOpenModal] = useState(false);
  const [modalURL, setModalURL] = useState(
    "https://my.matterport.com/show/?m=SLYaGf1nAyN&play=1&brand=0&lang=fr&dh=0&sm=3&sr=-1.57&sp=19.77%2C61.06%2C12.67&sf=1%2C0&sz=18.18&pin-pos=1.11%2C1.3%2C3.5"
  );

  const [modal, setModal] = useState({});

  // function handleOpen(URL) {
  //   setModalURL(URL);
  // }

  useEffect(() => {
    if (
      modalURL !==
      "https://my.matterport.com/show/?m=SLYaGf1nAyN&play=1&brand=0&lang=fr&dh=0&sm=3&sr=-1.57&sp=19.77%2C61.06%2C12.67&sf=1%2C0&sz=18.18&pin-pos=1.11%2C1.3%2C3.5"
    )
      if (refToScroll.current) {
        refToScroll.current.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
  }, [modalURL]);

  const handleClose = () => setOpen(false);

  return (
    <React.Fragment>
      <MetaTags>
        <title>Tilt’ Équipement - Visite virtuelle</title>
        <meta
          property="og:title"
          content={"Tilt’ Équipement - Visite virtuelle"}
        />
        <meta
          property="title"
          content={"Tilt’ Équipement - Visite virtuelle"}
        />
        <meta
          name="description"
          content={
            "Entrez dans les locaux de Tilt’ Équipement à Château-Gontier et découvrez nos showrooms dédiés à l’agencement, au mobilier, aux espaces d’accueil ainsi qu’aux vestiaires d’entreprises."
          }
        />
        <meta
          name="og:description"
          content={
            "Entrez dans les locaux de Tilt’ Équipement à Château-Gontier et découvrez nos showrooms dédiés à l’agencement, au mobilier, aux espaces d’accueil ainsi qu’aux vestiaires d’entreprises."
          }
        />
        <meta
          name="keywords"
          content={"Tilt'Équipement, Visite virtuelle, Showroom"}
        />
        <meta
          name="og:url"
          content={"https://tilt-equipement.fr/visite-virtuelle"}
        />
      </MetaTags>
      <Ariane arianeItem={arianeItem} />
      <Banner title={"Visite virtuelle"} />
      <Container maxWidth="xl" sx={{ marginBottom: "140px" }}>
        <Grid container className="pt90">
          <Grid
            item
            xs={12}
            className="gridVectoPlanShowroom"
            style={{ position: "relative", height: "400px" }}
          >
            <div className="vectorPlanShowroom">
              <div className="containShowroomLink showroomLinkTop">
                <div className="showroomLink showroomLink1">
                  <Link
                    className="link1"
                    onClick={() =>
                      setModalURL(
                        "https://my.matterport.com/show/?m=SLYaGf1nAyN&play=1&brand=0&lang=fr&dh=0&sr=-2.82%2C.25&ss=145&tag=KnO0A7bEmLI&pin-pos=34.5%2C3.72%2C22.74"
                      )
                    }
                  >
                    Showroom « Vestiaire »
                    <span>Niveau 2</span>
                  </Link>
                </div>
                <div className="showroomLink showroomLink2">
                  <Link
                    className="link2"
                    onClick={() =>
                      setModalURL(
                        "https://my.matterport.com/show/?m=SLYaGf1nAyN&play=1&brand=0&lang=fr&dh=0&sr=-.74%2C-.85&ss=113&pin-pos=23.85%2C3.37%2C14.96"
                      )
                    }
                  >
                    Showroom « Mobilier »
                    <span>Niveau 2</span>
                  </Link>
                </div>
              </div>
              <div className="divImgShowroom">
                <img
                  src={vectorTilt}
                  alt="Représentation vectorierl du batiment"
                  // width="240"
                  // height="260"
                />
              </div>
              <div className="containShowroomLink showroomLinkBottom">
                <div className="showroomLink showroomLink3">
                  <Link
                    className="link3"
                    onClick={() =>
                      setModalURL(
                        "https://my.matterport.com/show/?m=SLYaGf1nAyN&play=1&brand=0&lang=fr&dh=0&sr=-2.21%2C-1.16&ss=33&pin-pos=15.44%2C.88%2C16.75"
                      )
                    }
                  >
                    Showroom « Agencement »
                    <span>Niveau 1</span>
                  </Link>
                </div>
                <div className="showroomLink showroomLink4">
                  <Link
                    className="link4"
                    onClick={() =>
                      setModalURL(
                        "https://my.matterport.com/show/?m=SLYaGf1nAyN&play=1&brand=0&lang=fr&dh=0&sr=-2.8%2C.87&ss=11&pin-pos=-1.69%2C1.71%2C8.97"
                      )
                    }
                  >
                    Showroom « Accueil »
                    <span>Niveau 1</span>
                  </Link>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} className="">
            <section className="filterShowroom">
              <List
                sx={{
                  width: "100%",
                  maxWidth: 420,
                  bgcolor: "background.paper",
                }}
                className="containerBtnShowroom"
                component="nav"
                aria-labelledby="nested-list-subheader"
              >
                <ListItemButton
                  className="btnShowroomFilters"
                  onClick={openShowroomFilter}
                >
                  <ListItemIcon className="">
                    <img
                      className="imgShowroomIcon"
                      src={niveauIcon}
                      alt={"Tilt'Équipement"}
                      loading="lazy"
                    />
                  </ListItemIcon>
                  <div className="dFlex">
                    <ListItemText primary="Choix du Niveau" />
                    {showroomFilterOpen ? <ExpandLess /> : <ExpandMore />}
                  </div>
                </ListItemButton>
                <Collapse
                  in={showroomFilterOpen}
                  timeout="auto"
                  unmountOnExit
                  className="showroomCollapse"
                >
                  <List component="div" disablePadding className="dFlex">
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() =>
                        setModalURL(
                          "https://my.matterport.com/show/?m=SLYaGf1nAyN&play=1&brand=0&lang=fr&dh=0&sm=3&sr=-1.57&sp=19.77%2C61.06%2C12.67&sf=1%2C0&sz=18.18&pin-pos=1.11%2C1.3%2C3.5"
                        )
                      }
                    >
                      <ListItemText primary="Niveau 1" />
                    </ListItemButton>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() =>
                        setModalURL(
                          "https://my.matterport.com/show/?m=SLYaGf1nAyN&play=1&brand=0&lang=fr&dh=0&sm=3&sr=-1.57&sp=19.77%2C61.06%2C12.67&sf=0%2C1&sz=18.18&pin-pos=30.96%2C4.33%2C15.72"
                        )
                      }
                    >
                      <ListItemText primary="Niveau 2" />
                    </ListItemButton>
                  </List>
                </Collapse>
                <ListItemButton className="btnShowroomFilters">
                  <ListItemIcon>
                    <img
                      className="imgShowroomIcon"
                      src={personIcon}
                      alt={"Tilt'Équipement"}
                      loading="lazy"
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Explorer l’espace"
                    onClick={() =>
                      setModalURL(
                        "https://my.matterport.com/show/?m=SLYaGf1nAyN&play=1&brand=0&lang=fr&dh=0&sr=-2.71%2C1.03&ss=4&pin-pos=-.79%2C1.09%2C4.19"
                      )
                    }
                  />
                </ListItemButton>
              </List>
            </section>
          </Grid>
        </Grid>
      </Container>
      <div
        style={{
          marginTop: "-600px",
          paddingTop: "600px",
        }}
      ></div>
      <Container
        maxWidth="xl"
        sx={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "120px",
        }}
      >
        <iframe
          ref={refToScroll}
          title="matter port"
          width="100%"
          height="600"
          src={modalURL}
          frameborder="0"
          allowFullScreen
          allow="vr"
          allowtransparency="true"
          style={{ background: "#FFF" }}
        ></iframe>
      </Container>
    </React.Fragment>
  );
}
