import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import API_URL from "../../Api";
import AddToFavorites from "../molecules/AddToFavorites";
import ImageOptimizer from "../utils/ImageOptimizer";
import DefaultLogo from "../../assets/image_produit_defaut_tiltequipement.svg";

export default function ProductCard({ product }) {
  // useEffect(() => {
  //   document.getElementById("productCardDescription" + product.id).innerHTML =
  //     product.attributes.description.slice(0, 200) + "...";
  // }, [product.attributes.description, product.id]);

  return (
    <div className="productCard">
      {/* <Link to={`/produit/${product.attributes.slug}`}> */}
      <Link to={`/produit/${product.attributes.slug}`}>
        <div className="productCardAddFav">
          <AddToFavorites label={"products"} content={product} />
        </div>
        {product.attributes.images && product.attributes.images.data ? (
          <ImageOptimizer
            formats={product.attributes.images.data[0].attributes.formats}
          />
        ) : (
          // <div className="noPictureProductCard"></div>
           <img src={DefaultLogo} alt="Absence de visuel pour ce produit" />
        )}
        <div>
          {product.attributes.types_de_produit &&
            product.attributes.types_de_produit.data && (
              <p className="titleProductCard">
                {product.attributes.types_de_produit.data.attributes.titre}
              </p>
            )}
          <p className="contentProductCard">{product.attributes.nom}</p>
        </div>
      </Link>
    </div>
  );
}
