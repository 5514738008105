import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ModalInfo() {
  const [open, setOpen] = React.useState(true);

  React.useEffect(() => {
    const hasSeenModal = localStorage.getItem("hasSeenModal");

    if (hasSeenModal) {
      setOpen(false);
    }
  }, []);

  const handleClose = (mode) => {
    if (mode === "definitif") {
      localStorage.setItem("hasSeenModal", "true");
    }
    setOpen(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={() => handleClose()}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => handleClose()}
        >
          Chers clients,
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            La société Tilt'Équipement sera fermée pour congés du 7 au 18 août.
          </Typography>
          <Typography gutterBottom>
            Vos demandes seront traitées dès le retour des équipes, à partir du
            21 août.
          </Typography>
          <br />
          <Typography gutterBottom>Bonnes vacances à tous !</Typography>
          <Typography gutterBottom>L'équipe de Tilt'Équipement</Typography>
        </DialogContent>
        <DialogActions>
          <button
            autoFocus
            onClick={() => handleClose("definitif")}
            style={{
              color: "#00833e",
              background: "transparent",
              textTransform: "uppercase",
              fontSize: "15px",
              cursor: "pointer",
              padding: "10px 0",
            }}
          >
            Ne plus afficher ce message
          </button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
