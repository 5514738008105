import React, { useEffect, useState } from "react";
import BannerInspiration from "../components/molecules/BannerInspiration";
import CardInspiration from "../components/molecules/CardInspiration";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Ariane from "../components/molecules/Ariane";
import API_URL from "../Api";
import { MetaTags } from "react-meta-tags";
import PaginationProducts from "../components/products/PaginationProducts";
import { AnimationOnScroll } from "react-animation-on-scroll";

export default function Inspirations() {
  const [isLoading, setIsLoading] = useState(true);
  const [inspirations, setInspirations] = useState([]);

  // page active
  const [page, setPage] = useState(1);
  // nombre de page
  const [pageCount, setPageCount] = useState();
  // nombre de produits par page
  // const [pageSize, setPageSize] = useState();
  // total de produits retournés
  // const [total, setTotal] = useState();

  useEffect(() => {
    fetch(
      `${API_URL}/api/inspirations?sort[0]=rank%3Aasc&populate[0]=images&populate[1]=slug&populate[2]=titre&pagination[page]=${page}&pagination[pageSize]=9&pagination[withCount]=true`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        // window.scroll({
        //   top: 320,
        //   left: 100,
        //   behavior: "smooth",
        // });
        setInspirations(response.data);
        setPage(response.meta.pagination.page);
        setPageCount(response.meta.pagination.pageCount);
        // setPageSize(response.meta.pagination.pageSize);
        // setTotal(response.meta.pagination.total);
        setIsLoading(false);
      });
  }, [page]);

  const arianeItem = [
    ["/", "Tilt'Équipement"],
    ["/produits", "Inspirations"],
  ];

  return (
    <React.Fragment>
      <MetaTags>
        <title>Tilt’ Équipement - Inspirations</title>
        <meta property="og:title" content={"Inspirations - Tilt' Équipement"} />
        <meta property="title" content={"Inspirations - Tilt' Équipement"} />
        <meta
          name="description"
          content={
            "Imaginez votre futur projet grâce à nos inspirations et quantifiez votre approche budgétaire pour votre projet d’aménagement."
          }
        />
        <meta
          name="og:description"
          content={
            "Imaginez votre futur projet grâce à nos inspirations et quantifiez votre approche budgétaire pour votre projet d’aménagement."
          }
        />
        <meta name="keywords" content={"Tilt'Équipement, Inspirations"} />
        <meta
          name="og:url"
          content={"https://tilt-equipement.fr/inspirations"}
        />
      </MetaTags>
      <Ariane arianeItem={arianeItem} />
      <BannerInspiration
        title={"Imaginez votre futur projet"}
        content={
          "Quantifiez votre approche budgétaire grâce à nos inspirations"
        }
      />
      <Container maxWidth="xl" className="pt120 pb120">
        <Grid container spacing={1} className="contain-grid-inspiration">
          {/* Boucle ici */}
          {!isLoading ? (
            <React.Fragment>
              {inspirations.map((inspiration) => (
                <Grid
                  item
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  className="item-grid-inspiration"
                  key={"item-grid-inspiration" + inspiration.id}
                >
                  <AnimationOnScroll animateIn="animate__fadeIn">
                    <CardInspiration inspiration={inspiration} />
                  </AnimationOnScroll>
                </Grid>
              ))}
            </React.Fragment>
          ) : (
            <Grid
              spacing={1}
              container
              className="productViewContainer pt48 pb120"
            >
              <Grid
                item
                lg={4}
                md={4}
                sm={6}
                xs={12}
                className="productViewSkelet"
              >
                <div style={{ height: "320px" }}></div>
              </Grid>
              <Grid
                item
                lg={4}
                md={4}
                sm={6}
                xs={12}
                className="productViewSkelet"
              >
                <div style={{ height: "320px" }}></div>
              </Grid>
              <Grid
                item
                lg={4}
                md={4}
                sm={6}
                xs={12}
                className="productViewSkelet"
              >
                <div style={{ height: "320px" }}></div>
              </Grid>
              <Grid
                item
                lg={4}
                md={4}
                sm={6}
                xs={12}
                className="productViewSkelet"
              >
                <div style={{ height: "320px" }}></div>
              </Grid>
              <Grid
                item
                lg={4}
                md={4}
                sm={6}
                xs={12}
                className="productViewSkelet"
              >
                <div style={{ height: "320px" }}></div>
              </Grid>
              <Grid
                item
                lg={4}
                md={4}
                sm={6}
                xs={12}
                className="productViewSkelet"
              >
                <div style={{ height: "320px" }}></div>
              </Grid>
            </Grid>
          )}
        </Grid>
        {!isLoading && (
          <PaginationProducts
            pageCount={pageCount}
            page={page}
            setPage={setPage}
          />
        )}
      </Container>
    </React.Fragment>
  );
}
