import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { useSearchParams } from "react-router-dom";
import API_URL from "../Api";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Ariane from "../components/molecules/Ariane";
import BandeauLocalisation from "../components/molecules/BandeauLocalisation";
import Banner from "../components/molecules/Banner";
import ArrowButton from "../components/Atom/ArrowButton";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";

export default function Devis() {
  const [univers, setUnivers] = useState();
  const [universIsLoading, setUniversIsLoading] = useState(true);
  const [category, setCategory] = useState();
  const [categoryIsLoading, setCategoryIsLoading] = useState(true);

  // savoir depuis quel page le client demande le devis
  const [categorie, setCategorie] = useState("");
  const [page, setPage] = useState("");
  const [pageUrl, setPageUrl] = useState([]);

  // image de l'article intéréssé
  const [imageUrl, setImageUrl] = useState("");

  const [client, setClient] = useState(false);
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [societe, setSociete] = useState("");
  const [societeCreate, setSocieteCreate] = useState(false);
  const [siret, setSiret] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");
  const [adresse, setAdresse] = useState("");
  const [codePostal, setCodePostal] = useState("");
  const [ville, setVille] = useState("");
  const [secteur, setSecteur] = useState("");
  const [categoryProduct, setCategoryProduct] = useState([]);
  const [sujet, setSujet] = useState("");
  const [file, setFile] = useState("");
  const [delais, setDelais] = useState("");
  const [clauseCommerce, setClauseCommerce] = useState(false);

  // feedback soumission form
  const [sendState, setSendState] = useState(false);

  const [arianeItem, setArianeItem] = useState([
    ["/", "Tilt'Équipement"],
    ["/devis", "Devis"],
  ]);

  // A MODIFIER UNE FOIS EN PROD
  // const frontUrl = "localhost:3000/";
  const frontUrl = "https://www.tilt-equipement.com/";

  // si la demande de devis viens d'une page spécifique
  // on récupère les potentiels paramètre en url
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    // liste de tout les sous-univers disponibles
    fetch(API_URL + "/api/sous-univers?populate=*", {
      method: "GET",
      headers: {
        Accept: "Application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setUnivers(response.data);
        setUniversIsLoading(false);
      });

    // liste de toutes les sous-catégories disponibles
    fetch(API_URL + "/api/categories?sort[0]=rank%3Aasc", {
      method: "GET",
      headers: {
        Accept: "Application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setCategory(response.data);
        setCategoryIsLoading(false);
      });

    // si l'url comporte un parametre "produit", alors la demande viens de la page d'un produit en particulier, il faut donc récupèrer ses infos
    if (searchParams.get("produit")) {
      fetch(
        `${API_URL}/api/produits?filters[slug][$eq]=${searchParams.get(
          "produit"
        )}&populate=*`,
        {
          method: "GET",
          headers: {
            Accept: "Application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((response) => {
          setImageUrl(
            response.data[0].attributes.images.data[0].attributes.url
          );
          if (response.data[0].attributes.category.data) {
            setCategoryProduct([
              response.data[0].attributes.category.data.attributes.titre,
            ]);
          }
          setSujet(
            `Bonjour, votre produit "${response.data[0].attributes.nom.trim()}" m'intéresse particulièrement. Je souhaiterai obtenir un devis personnalisé.`
          );
          if (response.data[0].attributes.univers.data) {
            setSecteur(
              response.data[0].attributes.univers.data[0].attributes.titre
            );
          }
          setCategorie("Produit");
          setPage(response.data[0].attributes.nom.trim());
          setPageUrl([
            {
              page: response.data[0].attributes.nom.trim(),
              categorie: "Produit",
              url: frontUrl + "produit/" + response.data[0].attributes.slug,
            },
          ]);
        });
    }

    // si l'url comporte un parametre "favoris", alors la demande viens de la page favoris. C'est un demande de devis global des favoris d'un utilisateur
    if (searchParams.get("favoris")) {
      setCategorie("Favoris");
      setCategoryProduct(["Favoris"]);
      let sujet = `Bonjour, je souhaite obtenir un devis comprenant mes favoris.\n\n`;

      if (JSON.parse(localStorage.tiltFav).products.length > 0) {
        let favProductsList = "";
        favProductsList += "Produits : \n";
        for (const product of JSON.parse(localStorage.tiltFav).products) {
          favProductsList += "- " + product.attributes.nom + "\n";
          setPageUrl((pageUrl) => [
            ...pageUrl,
            {
              page: product.attributes.nom.trim(),
              categorie: "Produit",
              url: frontUrl + product.attributes.slug,
            },
          ]);
        }
        sujet += favProductsList + "\n";
      }

      if (JSON.parse(localStorage.tiltFav).realisations.length > 0) {
        let favRealisationsList = "";
        favRealisationsList += "Réalisations : \n";
        for (const realisation of JSON.parse(localStorage.tiltFav)
          .realisations) {
          favRealisationsList += "- " + realisation.attributes.Titre + "\n";
          setPageUrl((pageUrl) => [
            ...pageUrl,
            {
              page: realisation.attributes.titre.trim(),
              categorie: "Réalisations",
              url: frontUrl + realisation.attributes.slug,
            },
          ]);
        }
        sujet += favRealisationsList + "\n";
      }

      if (JSON.parse(localStorage.tiltFav).inspirations.length > 0) {
        let favInspirationsList = "";
        favInspirationsList += "Inspirations : \n";
        for (const inspiration of JSON.parse(localStorage.tiltFav)
          .inspirations) {
          favInspirationsList += "- " + inspiration.attributes.titre + "\n";
          setPageUrl((pageUrl) => [
            ...pageUrl,
            {
              page: inspiration.attributes.titre.trim(),
              categorie: "Inspirations",
              url: frontUrl + inspiration.attributes.slug,
            },
          ]);
        }
        sujet += favInspirationsList + "\n";
      }

      if (JSON.parse(localStorage.tiltFav).actualites.length > 0) {
        let favActualiteList = "";
        favActualiteList += "Actualités : \n";
        for (const actualite of JSON.parse(localStorage.tiltFav).actualites) {
          favActualiteList += "- " + actualite.attributes.titre + "\n";
          setPageUrl((pageUrl) => [
            ...pageUrl,
            {
              page: actualite.attributes.titre.trim(),
              categorie: "Actualités",
              url: frontUrl + actualite.attributes.slug,
            },
          ]);
        }
        sujet += favActualiteList + "\n";
      }

      if (JSON.parse(localStorage.tiltFav).sousunivers.length > 0) {
        let favActualiteList = "";
        favActualiteList += "Sous univers : \n";
        for (const sousunivers of JSON.parse(localStorage.tiltFav)
          .sousunivers) {
          favActualiteList += "- " + sousunivers.attributes.titre + "\n";
          setPageUrl((pageUrl) => [
            ...pageUrl,
            {
              page: sousunivers.attributes.titre.trim(),
              categorie: "Sous univers",
              url: frontUrl + sousunivers.attributes.slug,
            },
          ]);
        }
        sujet += favActualiteList + "\n";
      }

      setSujet(sujet);
    }

    // si l'url comporte un parametre "produit", alors la demande viens de la page d'un produit en particulier, il faut donc récupèrer ses infos
    if (searchParams.get("inspirations")) {
      fetch(
        `${API_URL}/api/inspirations?filters[slug][$eq]=${searchParams.get(
          "inspirations"
        )}&populate=*`,
        {
          method: "GET",
          headers: {
            Accept: "Application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((response) => {
          setImageUrl(
            response.data[0].attributes.images.data[0].attributes.url
          );
          setSujet(
            `Bonjour, votre inspiration "${response.data[0].attributes.titre.trim()}" m'intéresse particulièrement. Je souhaiterai obtenir un devis personnalisé.`
          );
          setCategorie("Inspiration");
          setPage(response.data[0].attributes.titre.trim());
          setPageUrl([
            {
              page: response.data[0].attributes.titre.trim(),
              categorie: "Inspiration",
              url: frontUrl + "inspiration/" + response.data[0].attributes.slug,
            },
          ]);
        });
    }

    if (searchParams.get("realisations")) {
      fetch(
        `${API_URL}/api/realisations?filters[slug][$eq]=${searchParams.get(
          "realisations"
        )}&populate=*`,
        {
          method: "GET",
          headers: {
            Accept: "Application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((response) => {
          setImageUrl(
            response.data[0].attributes.couverture.data.attributes.url
          );
          setSujet(
            `Bonjour, votre réalisation "${response.data[0].attributes.Titre.trim()}" m'intéresse particulièrement. Je souhaiterai obtenir un devis personnalisé.`
          );
          setCategorie("Réalisation");
          setPage(response.data[0].attributes.Titre.trim());
          setPageUrl([
            {
              page: response.data[0].attributes.Titre.trim(),
              categorie: "Réalisation",
              url: frontUrl + "realisation/" + response.data[0].attributes.slug,
            },
          ]);
        });
    }

    if (searchParams.get("sr")) {
      setCategorie("SR l'évolutif®");
      setPageUrl([
        {
          page: "SR l'évolutif®",
          categorie: "SR l'évolutif®",
          url: frontUrl + "sr-evolutif",
        },
      ]);
    }

    if (searchParams.get("sous-univers")) {
      fetch(
        `${API_URL}/api/sous-univers?filters[slug][$eq]=${searchParams.get(
          "sous-univers"
        )}&populate=*`,
        {
          method: "GET",
          headers: {
            Accept: "Application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((response) => {
          setImageUrl(
            response.data[0].attributes.images.data[0].attributes.url
          );
          setSujet(
            `Bonjour, votre sous-univers "${response.data[0].attributes.titre.trim()}" m'intéresse particulièrement. Je souhaiterai obtenir un devis personnalisé.`
          );
          setCategorie("Sous-univers");
          setPage(response.data[0].attributes.titre.trim());
          setPageUrl([
            {
              page: response.data[0].attributes.titre.trim(),
              categorie: "Sous-univers",
              url: frontUrl + "sous-univer/" + response.data[0].attributes.slug,
            },
          ]);
        });
    }

    if (searchParams.get("services")) {
      setCategorie("Services");
      setPageUrl([
        {
          page: "Services",
          categorie: "Services",
          url: frontUrl + "services",
        },
      ]);
    }
  }, []);

  function submitContact(e) {
    e.preventDefault();

    // transforme le tableau en chaine pour l'envoyer vers la base
    let stringCategoryProduct = "";
    for (const element of categoryProduct) {
      stringCategoryProduct = stringCategoryProduct + element + ", ";
    }

    // construction de l'objet devis
    const data = {
      client: client,
      nom: nom.toUpperCase(),
      prenom: prenom,
      societe: societe,
      siret: siret,
      societe_en_crea: societeCreate,
      email: email,
      tel: telephone,
      adresse: adresse,
      code_post: codePostal,
      code_postal: codePostal,
      ville: ville,
      comerce_clause: clauseCommerce,
      details_projet: sujet,
      type_produit: stringCategoryProduct,
      secteur_activite: secteur,
      delai: delais,
      imageUrl: imageUrl,
      categorie: categorie,
      page: page,
      pageUrl: pageUrl,
    };

    const formData = new FormData();

    if (file) {
      let limit = 0;
      for (const element of file) {
        // si le fichier fait moins ou égale à 8 MO :
        if (element.size <= 8388608) {
          limit++;
          // limite de nombre de fichier (3)
          if (limit <= 3) {
            formData.append("files.fichier_projet", element, element.name);
          }
        }
      }
    }

    formData.append("data", JSON.stringify(data));

    // enregistrement de la candidature
    fetch(`${API_URL}/api/devis`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        setNom("");
        setPrenom("");
        setSociete("");
        setSocieteCreate("");
        setSiret("");
        setEmail("");
        setTelephone("");
        setAdresse("");
        setCodePostal("");
        setVille("");
        setSecteur("");
        setCategoryProduct([]);
        setSujet("");
        setFile("");
        setDelais("");
        setClauseCommerce("");
        setSendState(true);
      })
      .catch((error) => {
        setSendState(false);
      });
  }

  return (
    <React.Fragment>
      <Ariane arianeItem={arianeItem} />
      <MetaTags>
        <title>Tilt’ Équipement - Devis</title>
        <meta property="og:title" content={"Tilt’ Équipement - Devis"} />
        <meta property="title" content={"Tilt’ Équipement - Devis"} />
        <meta
          name="description"
          content={
            "Prenez contact avec nos équipes pour toute demande de devis via notre formulaire dédié."
          }
        />
        <meta
          name="og:description"
          content={
            "Prenez contact avec nos équipes pour toute demande de devis via notre formulaire dédié."
          }
        />
        <meta name="keywords" content={"Tilt'Équipement, Devis"} />
        <meta name="og:url" content={"https://tilt-equipement.fr/devis"} />
      </MetaTags>
      <Banner
        left
        title={"Dites-nous en plus sur votre projet !"}
        content={
          "Tilt’Equipement vous guident dans la réalisation de votre projet. Pour toute question plus générale concernant nos produits, nos délais, nos modes de livraison, contactez-nous via le formulaire de contact."
        }
        ArrowScrollShow
      />
      <Container maxWidth="xl" className="pb64">
        <Grid container>
          <Grid item lg={12}>
            <div className="devisContainer">
              {!sendState ? (
                <form
                  className="devisForm"
                  onSubmit={(e) => submitContact(e)}
                  autocomplete="nope"
                >
                  <div className="devisField">
                    <label>
                      RENSEIGNEZ VOS COORDONNÉES <span>*</span>
                    </label>
                    <div className="pt32">
                      <label>ÊTES-VOUS DÉJÀ CLIENT ?</label>
                      <div className="devisRadio">
                        <div>
                          <input
                            type="radio"
                            name="client"
                            checked={client}
                            onChange={(e) => setClient(true)}
                          />
                          <label htmlFor="oui">Oui</label>
                        </div>
                        <div>
                          <input
                            type="radio"
                            name="client"
                            checked={!client}
                            onChange={(e) => setClient(false)}
                          />
                          <label htmlFor="non">Non</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="devisField">
                    <label>
                      NOM <span>*</span>
                    </label>
                    <input
                      type="text"
                      value={nom}
                      name="nom"
                      onChange={(e) => setNom(e.target.value.toUpperCase())}
                      required
                    />
                  </div>
                  <div className="devisField">
                    <label>
                      PRÉNOM <span>*</span>
                    </label>
                    <input
                      type="text"
                      value={prenom}
                      name="prenom"
                      onChange={(e) =>
                        setPrenom(
                          e.target.value.charAt(0).toUpperCase() +
                            e.target.value.slice(1)
                        )
                      }
                      required
                    />
                  </div>
                  <div className="devisField">
                    <label>
                      SOCIÉTÉ <span>*</span>
                    </label>
                    <input
                      type="text"
                      name="societe"
                      value={societe}
                      onChange={(e) => setSociete(e.target.value.toUpperCase())}
                      required
                    />
                    <div className="devisCheckBox">
                      <input
                        type="checkbox"
                        value={societeCreate}
                        onChange={(e) => setSocieteCreate(!societeCreate)}
                        checked={societeCreate}
                      ></input>
                      <label>Société en cours de création</label>
                    </div>
                  </div>
                  <div className="devisField">
                    <label>N° DE SIRET</label>
                    <input
                      type="number"
                      name="siret"
                      value={siret}
                      onChange={(e) =>
                        setSiret(Math.abs(e.target.value).toString())
                      }
                      pattern="^\d{14}$"
                    />
                  </div>
                  <div className="devisField">
                    <label>
                      EMAIL <span>*</span>
                    </label>
                    <input
                      type="email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value.toLowerCase())}
                      required
                    ></input>
                  </div>
                  <div className="devisField">
                    <label>
                      TÉLÉPHONE <span>*</span>
                    </label>
                    <input
                      type="number"
                      name="telephone"
                      value={telephone}
                      onChange={(e) => setTelephone(e.target.value)}
                      required
                    ></input>
                  </div>
                  <div className="devisField">
                    <label>ADRESSE POSTALE</label>
                    <input
                      type="text"
                      name="adresse"
                      value={adresse}
                      onChange={(e) => setAdresse(e.target.value)}
                    ></input>
                  </div>
                  <div className="devisField">
                    <label>CODE POSTAL</label>
                    <input
                      type="number"
                      name="codePostal"
                      value={codePostal}
                      onChange={(e) => setCodePostal(e.target.value)}
                    ></input>
                  </div>
                  <div className="devisField">
                    <label>VILLE</label>
                    <input
                      type="text"
                      name="ville"
                      value={ville}
                      onChange={(e) => setVille(e.target.value)}
                    ></input>
                  </div>
                  {categorie !== "Inspiration" && (
                    <div className="devisField">
                      <label>
                        INDIQUEZ-NOUS VOTRE SECTEUR D’ACTIVITÉ <span>*</span>
                      </label>
                      <select
                        className="devisSelect"
                        name="secteur"
                        value={secteur}
                        onChange={(e) => setSecteur(e.target.value)}
                      >
                        <option value="">Sélectionner un univers</option>
                        {!universIsLoading && (
                          <React.Fragment>
                            {univers.map((univer) => (
                              <option
                                value={univer.attributes.titre}
                                defaultValue={
                                  univer.attributes.titre === secteur
                                }
                                key={"5f8g_" + univer.attributes.titre}
                              >
                                {univer.attributes.titre}
                              </option>
                            ))}
                          </React.Fragment>
                        )}
                      </select>
                    </div>
                  )}
                  {categorie !== "Inspiration" && (
                    <div className="devisField">
                      <label>
                        AUTRE SECTEUR D’ACTIVITÉ{" "}
                        <span>* (SI AUTRE EST SÉLECTIONNÉ)</span>
                      </label>
                      <input
                        type="text"
                        value={secteur}
                        onChange={(e) => setSecteur(e.target.value)}
                      ></input>
                    </div>
                  )}
                  {!searchParams.get("favoris") && (
                    <div className="devisField">
                      <label>
                        SPÉCIFIEZ-NOUS LE TYPE DE PRODUIT RECHERCHÉ
                        <span>*</span>
                      </label>
                      <div className="devisCheckBoxMosaique">
                        {!categoryIsLoading && (
                          <React.Fragment>
                            {category.map((item) => (
                              <div key={"5611_" + item.attributes.titre}>
                                <input
                                  type="checkbox"
                                  checked={categoryProduct.find(
                                    (element) =>
                                      element === item.attributes.titre
                                  )}
                                  onClick={(e) => {
                                    !categoryProduct.find(
                                      (element) =>
                                        element === item.attributes.titre
                                    )
                                      ? setCategoryProduct(
                                          (categoryProduct) => [
                                            ...categoryProduct,
                                            e.target.value,
                                          ]
                                        )
                                      : setCategoryProduct(
                                          (categoryProduct) => [
                                            ...categoryProduct.filter(
                                              (element) =>
                                                element !== item.titre
                                            ),
                                          ]
                                        );
                                  }}
                                  value={item.attributes.titre}
                                />
                                <label htmlFor={item.attributes.titre}>
                                  {item.attributes.titre}
                                </label>
                              </div>
                            ))}
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="devisField">
                    <label>
                      EXPLIQUEZ-NOUS VOTRE PROJET <span>*</span>
                    </label>
                    <textarea
                      cols="33"
                      value={sujet}
                      onChange={(e) => setSujet(e.target.value)}
                      required
                    ></textarea>
                  </div>
                  <div className="devisField">
                    <label>TRANSMETTEZ-NOUS VOS FICHIERS</label>
                    <p>Mes croquis, mes plans, mes photos… (max 3 fichiers)</p>
                    <div className="devisFile">
                      <div className="devisFileOverlay">
                        {!file ? (
                          <React.Fragment>
                            <p>cliquer pour télécharger votre fichier</p>
                            <p>Taille de téléchargement maximum : 2.1 Mo</p>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            {file.slice(0, 3).map((item) => (
                              <p key={"6816_" + item.name}>{item.name}</p>
                            ))}
                          </React.Fragment>
                        )}
                      </div>
                      <input
                        multiple
                        type="file"
                        onChange={(e) =>
                          setFile(Array.prototype.slice.call(e.target.files))
                        }
                      ></input>
                    </div>
                  </div>
                  <div className="devisField">
                    <label>
                      PRÉCISEZ VOTRE DÉLAIS <span>*</span>
                    </label>
                    <select
                      className="devisSelect"
                      value={delais}
                      onChange={(e) => setDelais(e.target.value)}
                      required
                    >
                      <option value="">Sélectionner un délais</option>
                      <option value="Immédiat">Immédiat</option>
                      <option value="Sous 2 à 3 semaines">
                        Sous 2 à 3 semaines
                      </option>
                      <option value="Sous 3 mois">Sous 3 mois</option>
                      <option value="Sous 6 mois et plus">
                        Sous 6 mois et plus
                      </option>
                    </select>
                  </div>
                  <div className="devisField">
                    <div className="devisCheckBox">
                      <input
                        type="checkbox"
                        value={clauseCommerce}
                        onChange={(e) => setClauseCommerce(!clauseCommerce)}
                        // required
                        checked={clauseCommerce}
                      ></input>
                      <label>
                        En soumettant ce formulaire j’accepte que les
                        informations saisies soient exploitées dans le cadre de
                        la gestion de la relation commerciale
                      </label>
                    </div>
                  </div>
                  <div className="devisField" id="contactFieldSubmit">
                    <input type={"submit"} value={"Demander mon devis"} />
                    <span>
                      <ExpandCircleDownIcon />
                    </span>
                  </div>
                </form>
              ) : (
                <div className="successSending">
                  <h2>Votre devis a été envoyé avec succès !</h2>
                  <p>
                    L'équipe commerciale de Tilt' Équipement vous recontactera
                    rapidement
                  </p>
                  <ArrowButton
                    color={"grey"}
                    href={"../"}
                    content="Retour à l'accueil"
                  />
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
      <BandeauLocalisation />
    </React.Fragment>
  );
}
