import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import API_URL from "../../Api";
import { AnimationOnScroll } from "react-animation-on-scroll";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Pagination, Navigation } from "swiper";
import ImageOptimizer from "../utils/ImageOptimizer";

// propose une liste de produit mis en avant, de manière aléatoire
export default function ProposeProducts() {
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState();

  function shuffle(Array) {
    return Array.sort(() => Math.random() - 0.5);
  }

  useEffect(() => {
    fetch(
      `${API_URL}/api/produits?populate[0]=slug&populate[1]=images&populate[2]=category&populate[3]=nom&populate[4]=univers&filters[highlighted][$eq]=true`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        // mélange le tableau
        setProducts(shuffle(response.data));
        // si plus de 10 item => rogne le tableau à 10 items
        if (response.length > 10) {
          setProducts(products.slice(9));
        }
        setIsLoading(false);
      });
  }, []);

  return (
    <React.Fragment>
      {!isLoading && products.length > 0 && (
        <section className="proposeProductsContainer pt120 pb120">
          <Container maxWidth="xl">
            <Grid container spacing={1}>
              <Grid item lg={3} md={3} sm={12} xs={12}>
                <AnimationOnScroll animateIn="animate__fadeIn">
                  <h2 className="h2 pt120">
                    <span>
                      <a href="/produits">Découvrez</a>
                    </span>
                    <br />
                    <a href="/produits">nos produits</a>
                  </h2>
                  <a href="/produits" className="links proposeProductsShowMore">
                    En découvrir plus
                  </a>
                </AnimationOnScroll>
              </Grid>
              <Grid item lg={9} md={9} sm={12} xs={12}>
                <Swiper
                  slidesPerView="auto"
                  spaceBetween={15}
                  navigation={true}
                  modules={[Pagination, Navigation]}
                  className="proposeProductSwiper"
                  breakpoints={{
                    "@0.00": {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    "@0.50": {
                      slidesPerView: 2,
                      spaceBetween: 15,
                    },
                    "@0.75": {
                      slidesPerView: 3,
                      spaceBetween: 15,
                    },
                    "@1.00": {
                      slidesPerView: 3,
                      spaceBetween: 15,
                    },
                    "@1.50": {
                      slidesPerView: 4,
                      spaceBetween: 15,
                    },
                  }}
                >
                  {!isLoading && (
                    <React.Fragment>
                      {products.map((product) => (
                        <React.Fragment>
                          {product.attributes.univers.data && (
                            <SwiperSlide
                              key={product.id + " proposeProductsCardCase"}
                              className="proposeProductsCardCase"
                            >
                              <AnimationOnScroll animateIn="animate__fadeIn">
                                <Link
                                  underline="none"
                                  href={"../produit/" + product.attributes.slug}
                                  className="proposeProductsLink"
                                  color="inherit"
                                >
                                  <div className="proposeProductsCard">
                                    {product.attributes.images.data && (
                                      <ImageOptimizer
                                        formats={
                                          product.attributes.images.data[0]
                                            .attributes.formats
                                        }
                                      />
                                    )}
                                    <span>
                                      {product.attributes.category.data &&
                                        product.attributes.category.data
                                          .attributes.titre}
                                    </span>
                                    <p>
                                      {product.attributes.nom.slice(0, 40) +
                                        "..."}
                                    </p>
                                  </div>
                                </Link>
                              </AnimationOnScroll>
                            </SwiperSlide>
                          )}
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  )}
                </Swiper>
              </Grid>
            </Grid>
          </Container>
        </section>
      )}
    </React.Fragment>
  );
}
