import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import ImageOptimizer from "../utils/ImageOptimizer";
import { AnimationOnScroll } from "react-animation-on-scroll";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Pagination, Navigation } from "swiper";

export default function AssociatesUniverProducts({ associateProducts }) {
  return (
    <React.Fragment>
      {associateProducts[0] && (
        <React.Fragment>
          <section className="proposeProductsContainer pt120 pb120">
            <Container maxWidth="xl">
              <Grid container spacing={1}>
                <Grid item lg={3} md={3} sm={12} xs={12}>
                  <AnimationOnScroll animateIn="animate__fadeIn">
                    <h2 className="h2 pt120">
                      Produits
                      <br />
                      <span>'associés'</span> <br />
                      {/* de qualités */}
                    </h2>
                    <a
                      href="/produits"
                      className="links proposeProductsShowMore"
                    >
                      En découvrir plus
                    </a>
                  </AnimationOnScroll>
                </Grid>
                <Grid item lg={9} md={9} sm={12} xs={12}>
                  <Swiper
                    slidesPerView="auto"
                    spaceBetween={15}
                    navigation={true}
                    modules={[Pagination, Navigation]}
                    className="proposeProductSwiper"
                    breakpoints={{
                      "@0.00": {
                        slidesPerView: 1,
                        spaceBetween: 10,
                      },
                      "@0.50": {
                        slidesPerView: 2,
                        spaceBetween: 15,
                      },
                      "@0.75": {
                        slidesPerView: 3,
                        spaceBetween: 15,
                      },
                      "@1.00": {
                        slidesPerView: 3,
                        spaceBetween: 15,
                      },
                      "@1.50": {
                        slidesPerView: 4,
                        spaceBetween: 15,
                      },
                    }}
                  >
                    {associateProducts.map((product) => (
                      <SwiperSlide
                        key={"associateProductCard" + product.id}
                        className="proposeProductsCardCase"
                      >
                        <AnimationOnScroll animateIn="animate__fadeIn">
                          <a
                            underline="none"
                            href={"../produit/" + product.attributes.slug}
                            className="proposeProductsLink"
                            color="inherit"
                          >
                            <div className="proposeProductsCard">
                              {product.attributes.images.data && (
                                <ImageOptimizer
                                  formats={
                                    product.attributes.images.data[0].attributes
                                      .formats
                                  }
                                />
                              )}
                              {product.attributes.category && (
                                <span>
                                  {
                                    product.attributes.category.data.attributes
                                      .titre
                                  }
                                </span>
                              )}
                              {product.attributes.nom && (
                                <p>
                                  {product.attributes.nom.slice(0, 40) + "..."}
                                </p>
                              )}
                            </div>
                          </a>
                        </AnimationOnScroll>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </Grid>
              </Grid>
            </Container>
          </section>
          {/* <div className="associateUniverProductsContainer">
            <section className="associateUniverProductsHeader">
              <h3>Produits associés</h3>
            </section>
            <Swiper
              className="associateProductsSlider"
              spaceBetween={50}
              slidesPerView={4}
            >
              {associateProducts.map((product) => (
                <SwiperSlide
                  className="associateProductCard"
                  key={"associateProductCard" + product.id}
                >
                  <Link className="associateProductsLink">
                    <div className="associateProductsCardInner">
                      <section>
                        {product.attributes.images.data && (
                          <ImageOptimizer
                            formats={
                              product.attributes.images.data[0].attributes
                                .formats
                            }
                          />
                        )}
                      </section>
                      {product.attributes.nom && (
                        <p>{product.attributes.nom.slice(0, 40) + "..."}</p>
                      )}
                    </div>
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          </div> */}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
