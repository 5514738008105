import React from "react";
import API_URL from "../../Api";

export default function ImageOptimizer({ formats }) {

  return (
    <picture loading="lazy">
      {formats.small && (
        <React.Fragment>
          <source
            media="(max-width: 320px)"
            alt={formats.small.name}
            srcSet={API_URL + formats.small.url}
          />
          <source
            media="(max-width: 425px)"
            alt={formats.small.name}
            srcSet={API_URL + formats.small.url}
          />
        </React.Fragment>
      )}
      {formats.medium && (
        <source
          media="(max-width: 981px)"
          alt={formats.medium.name}
          srcSet={API_URL + formats.medium.url}
        />
      )}
      {formats.large && (
        <React.Fragment>
          <source
            media="(max-width: 1440px)"
            alt={formats.large.name}
            srcSet={API_URL + formats.large.url}
          />
          <source
            media="(min-width: 1440px)"
            alt={formats.large.name}
            srcSet={API_URL + formats.large.url}
          />
        </React.Fragment>
      )}
      <img
        src={API_URL + formats.thumbnail.url}
        alt={formats.thumbnail.name}
        loading="lazy"
      />
    </picture>
  );
}
