import React, { useEffect, useState } from "react";
import Ariane from "../components/molecules/Ariane";
import { useParams } from "react-router-dom";
import API_URL from "../Api";
import { Box, Container } from "@mui/material";
import Grid from "@mui/material/Grid";
import ImageOptimizer from "../components/utils/ImageOptimizer";
import PaginationProducts from "../components/products/PaginationProducts";
import ResultsProducts from "../components/recherches/ResultsProducts";
import ResultsRealisations from "../components/recherches/ResultsRealisations";
import ResultsUnivers from "../components/recherches/ResultsUnivers";
import ResultsInspirations from "../components/recherches/ResultsInspirations";

export default function Recherches() {
  const recherche = useParams().recherche;

  const [products, setProducts] = useState([]);
  const [productPageCount, setProductsPageCount] = useState(0);
  const [productPage, setProductsPage] = useState(0);
  const [totalProductsResults, setTotalProductsResults] = useState(0);
  const [resultProductsIsLoading, setResultProductsIsLoading] = useState(true);

  const [realisations, setRealisations] = useState([]);
  const [realisationsPageCount, setRealisationsPageCount] = useState(0);
  const [realisationsPage, setRealisationsPage] = useState(0);
  const [totalRealisationsResults, setTotalRealisationsResults] = useState(0);
  const [resultRealisationsIsLoading, setResultRealisationsIsLoading] =
    useState(true);

  const [univers, setUnivers] = useState([]);
  const [universPageCount, setUniversPageCount] = useState(0);
  const [universPage, setUniversPage] = useState(0);
  const [totalUniversResults, setTotalUniversResults] = useState(0);
  const [resultUniversIsLoading, setResultUniversIsLoading] = useState(true);

  const [inspirations, setInspirations] = useState([]);
  const [inspirationsPageCount, setInspirationsPageCount] = useState(0);
  const [inspirationsPage, setInspirationsPage] = useState(0);
  const [totalInspirationsResults, setTotalInspirationsResults] = useState(0);
  const [resultInspirationsIsLoading, setResultInspirationsIsLoading] =
    useState(true);

  const arianeItem = [
    ["/", "Tilt'Équipement"],
    ["/recherches", "Recherches"],
  ];

  useEffect(() => {
    if (recherche) {
      // produits disponibles
      fetch(
        API_URL +
          `/api/produits?populate[0]=slug&populate[1]=images&populate[2]=nom&filters[$or][0][nom][$containsi]=${recherche}&filters[$or][1][alias][$containsi]=${recherche}&filters[$or][2][ref][$containsi]=${recherche}&pagination[pageSize]=8&pagination[page]=${productPage}`,
        {
          method: "GET",
          headers: {
            Accept: "Application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((response) => {
          setProducts(response.data);
          setProductsPage(response.meta.pagination.page);
          setProductsPageCount(response.meta.pagination.pageCount);
          setTotalProductsResults(response.meta.pagination.total);
          setResultProductsIsLoading(false);
        });
    }
  }, [recherche, productPage]);

  useEffect(() => {
    if (recherche) {
      // realisations disponibles
      fetch(
        API_URL +
          `/api/realisations?populate[0]=slug&populate[1]=couverture&populate[2]=Titre&filters[$or][0][titre][$containsi]=${recherche}&filters[$or][1][description][$containsi]=${recherche}`,
        {
          method: "GET",
          headers: {
            Accept: "Application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((response) => {
          setRealisations(response.data);
          setRealisationsPage(response.meta.pagination.page);
          setRealisationsPageCount(response.meta.pagination.pageCount);
          setTotalRealisationsResults(response.meta.pagination.total);
          setResultRealisationsIsLoading(false);
        });
    }
  }, [recherche, realisationsPage]);

  useEffect(() => {
    if (recherche) {
      // univers disponibles
      fetch(
        API_URL +
          `/api/univers?populate[0]=slug&populate[1]=images&populate[2]=titre&filters[$or][0][titre][$containsi]=${recherche}&filters[$or][1][description][$containsi]=${recherche}`,
        {
          method: "GET",
          headers: {
            Accept: "Application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((response) => {
          setUnivers(response.data);
          setUniversPage(response.meta.pagination.page);
          setUniversPageCount(response.meta.pagination.pageCount);
          setTotalUniversResults(response.meta.pagination.total);
          setResultUniversIsLoading(false);
        });
    }
  }, [recherche, universPage]);

  useEffect(() => {
    if (recherche) {
      // inspirations disponibles
      fetch(
        API_URL +
          `/api/inspirations?populate[0]=slug&populate[1]=images&populate[2]=titre&filters[$or][0][titre][$containsi]=${recherche}&filters[$or][1][description][$containsi]=${recherche}`,
        {
          method: "GET",
          headers: {
            Accept: "Application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((response) => {
          setInspirations(response.data);
          setInspirationsPage(response.meta.pagination.page);
          setInspirationsPageCount(response.meta.pagination.pageCount);
          setTotalInspirationsResults(response.meta.pagination.total);
          setResultInspirationsIsLoading(false);
        });
    }
  }, [recherche, inspirationsPage]);

  return (
    <>
      <Ariane arianeItem={arianeItem} />
      <div className="bannerRecherche">
        <h1>Votre recherche : « {recherche} »</h1>
      </div>
      <Container maxWidth="xl">
        <Box>
          {!resultProductsIsLoading ? (
            <>
              {products[0] && (
                <ResultsProducts
                  products={products}
                  totalProductsResults={totalProductsResults}
                  productPageCount={productPageCount}
                  productPage={productPage}
                  setProductsPage={setProductsPage}
                />
              )}
            </>
          ) : (
            <Grid container spacing={1} className="skeletGrid" mt={2} mb={2}>
              {new Array(8).fill("").map((_, index) => (
                <Grid lg={3} md={3} sm={6} item key={index}>
                  <div style={{ height: "450px" }}></div>
                </Grid>
              ))}
            </Grid>
          )}
          {!resultRealisationsIsLoading ? (
            <>
              {realisations[0] && (
                <ResultsRealisations
                  realisations={realisations}
                  totalRealisationsResults={totalRealisationsResults}
                  realisationsPageCount={realisationsPageCount}
                  realisationsPage={realisationsPage}
                  setRealisationsPage={setRealisationsPage}
                />
              )}
            </>
          ) : (
            <Grid container spacing={1} className="skeletGrid" mt={2} mb={2}>
              {new Array(8).fill("").map((_, index) => (
                <Grid lg={3} md={3} sm={6} item key={index}>
                  <div style={{ height: "450px" }}></div>
                </Grid>
              ))}
            </Grid>
          )}
          {!resultUniversIsLoading ? (
            <>
              {univers[0] && (
                <ResultsUnivers
                  univers={univers}
                  totalUniversResults={totalUniversResults}
                  universPageCount={universPageCount}
                  universPage={universPage}
                  setUniversPage={setUniversPage}
                />
              )}
            </>
          ) : (
            <Grid container spacing={1} className="skeletGrid" mt={2} mb={2}>
              {new Array(8).fill("").map((_, index) => (
                <Grid lg={3} md={3} sm={6} item key={index}>
                  <div style={{ height: "450px" }}></div>
                </Grid>
              ))}
            </Grid>
          )}
          {!resultInspirationsIsLoading ? (
            <>
              {inspirations[0] && (
                <ResultsInspirations
                  inspirations={inspirations}
                  totalInspirationsResults={totalInspirationsResults}
                  inspirationsPageCount={inspirationsPageCount}
                  inspirationsPage={inspirationsPage}
                  setInspirationsPage={setInspirationsPage}
                />
              )}
            </>
          ) : (
            <Grid container spacing={1} className="skeletGrid" mt={2} mb={2}>
              {new Array(8).fill("").map((_, index) => (
                <Grid lg={3} md={3} sm={6} item key={index}>
                  <div style={{ height: "450px" }}></div>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      </Container>
    </>
  );
}
