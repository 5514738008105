import React, { useEffect, useState } from "react";
import categorie from "../../assets/categorie.svg";
import universIcon from "../../assets/univers.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import API_URL from "../../Api";
import SearchProductBar from "../products/SearchProductBar";
import ImageOptimizer from "../utils/ImageOptimizer";

export default function ProductsSearchAndFilter({
  products,
  setProductStep,
  setFilterMaster,
  filterMaster,
}) {
  const [universFilterOpen, setUniversFilterOpen] = useState(false);
  const [categoryFilterOpen, setCategoryFilterOpen] = useState(false);
  const [univers, setUnivers] = useState(null);
  const [universIsLoading, setUniversIsLoading] = useState(true);
  const [categories, setCategories] = useState(null);
  const [categorieIsLoading, setCategorieIsLoading] = useState(true);

  useEffect(() => {
    // récupère la liste des univers disponibles
    // fetch(
    //   API_URL +
    //     "/api/univers?populate[0]=titre&populate[1]=description&populate[2]=icon&populate[3]=description",
    //   {
    //     method: "GET",
    //     headers: {
    //       Accept: "Application/json",
    //     },
    //   }
    // )
    //   .then((res) => res.json())
    //   .then((response) => {
    //     setUnivers(response.data);
    //     setUniversIsLoading(false);
    //   });

    // récupère la liste des catégories disponibles
    fetch(
      API_URL +
        "/api/categories?sort[0]=rank%3Aasc&populate[0]=titre&populate[1]=image&populate[2]=description",
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        setCategories(response.data);
        setCategorieIsLoading(false);
      });
  }, [filterMaster]);

  function openUniversFilter() {
    setUniversFilterOpen(true);
    setCategoryFilterOpen(false);
  }
  function closeUniversFilter() {
    setUniversFilterOpen(false);
  }
  function openCategoryFilter() {
    setCategoryFilterOpen(true);
    setUniversFilterOpen(false);
  }
  function closeCategoryFilter() {
    setCategoryFilterOpen(false);
  }

  // modification du filtre de recherche
  function handleFilter(filter, content, label, details) {
    setFilterMaster([
      {
        content: content,
        filter: filter,
        label: label,
        details: details,
      },
    ]);
    setProductStep(2);
    // stockage de la step en local storage
    // localStorage.setItem(
    //   "tiltStepProduct",
    //   JSON.stringify({
    //     ...JSON.parse(localStorage.getItem("tiltStepProduct")),
    //     step: 2,
    //   })
    // );
    setCategoryFilterOpen(false);
    setUniversFilterOpen(false);
  }

  return (
    <div className="searchProductExplorer">
      <section>
        <SearchProductBar />
        <div className="containerBtnUniversCategories">
          <div
            className="btnUniversFiltersSearch"
            onClick={
              !categoryFilterOpen ? openCategoryFilter : closeCategoryFilter
            }
          >
            <img src={categorie} alt={"Tilt'Équipement"} loading="lazy" />
            <div>
              <p>CATÉGORIE</p>
              {!categoryFilterOpen ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowDownIcon className="turnArrow" />
              )}
            </div>
          </div>
        </div>
      </section>
      {/* <div
        className={
          !universFilterOpen
            ? "universFilterList"
            : "universFilterList universFilterListOpen"
        }
      >
        {!universIsLoading && (
          <React.Fragment>
            {univers.map((univer) => (
              <div
                className="universFilterListCard"
                key={"universFilter" + univer.attributes.titre}
                onClick={() =>
                  handleFilter(
                    "univers",
                    univer.id,
                    univer.attributes.titre,
                    univer.attributes.description
                  )
                }
              >
                <div className="universFilterListCardImg">
                  {univer.attributes.icon.data && (
                    <img
                      src={API_URL + univer.attributes.icon.data.attributes.url}
                      alt={univer.attributes.icon.data.attributes.name}
                      loading="lazy"
                    />
                  )}
                </div>
                <p className="universFilterListTitle">
                  {univer.attributes.titre.toUpperCase()}
                </p>
              </div>
            ))}
          </React.Fragment>
        )}
      </div> */}
      <div
        className={
          !categoryFilterOpen
            ? "categoryFilterList"
            : "categoryFilterList categoryFilterListOpen"
        }
      >
        {!categorieIsLoading && (
          <React.Fragment>
            {categories.map((categorie) => (
              <div
                className="categoryFilterListCard"
                key={"categoryFilter" + categorie.attributes.titre}
                onClick={() =>
                  handleFilter(
                    "category",
                    categorie.id,
                    categorie.attributes.titre,
                    categorie.attributes.description
                  )
                }
              >
                <div className="categoryFilterListCardImg">
                  <ImageOptimizer
                    formats={categorie.attributes.image.data.attributes.formats}
                  />
                </div>
                <p className="categoryFilterListTitle">
                  {categorie.attributes.titre}
                </p>
              </div>
            ))}
          </React.Fragment>
        )}
      </div>
    </div>
  );
}
