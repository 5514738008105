import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Distributeur from "../../pages/Distributeur";
import Home from "../../pages/Home";
import Inspirations from "../../pages/Inspirations";
import NoPage from "../../pages/NoPage";
import Product from "../../pages/Product";
import Products from "../../pages/Products";
import Realisations from "../../pages/Realisations";
import Realisation from "../../pages/Realisation";
import SREvolutif from "../../pages/SREvolutif";
import Univer from "../../pages/Univer";
import Univers from "../../pages/Univers";
import Actualites from "../../pages/Actualites";
import Actualite from "../../pages/Actualite";
import Emploies from "../../pages/Emploies";
import Emploie from "../../pages/Emploie";
import Candidature from "../../pages/Candidature";
import Inspiration from "../../pages/Inspiration";
import SousUniver from "../../pages/SousUniver";
import Catalogues from "../../pages/Catalogues";
import NoticesEtTutos from "../../pages/NoticesEtTutos";
import Contact from "../../pages/Contact";
import Faq from "../../pages/Faq";
import Favoris from "../../pages/Favoris";
import Devis from "../../pages/Devis";
import Service from "../../pages/Service";
import Showroom from "../../pages/Showroom";
import Entreprise from "../../pages/Entreprise";
import PolitiqueConfidentialite from "../../pages/PolitiqueConfidentialite";
import MentionsLegal from "../../pages/MentionsLegal";
import Recherches from "../../pages/Recherches";

export default function index() {
  return (
    <BrowserRouter>
      <Routes>
        <Route>
          {/* Page par défaut "Accueil" */}
          <Route index element={<Home />} />
          {/* Page "Inspiration" */}
          <Route path="inspirations" element={<Inspirations />} />
          {/* Page "Détails Réalisation " */}
          <Route path="inspiration/:slug" element={<Inspiration />} />
          {/* Page "Réalisation" */}
          <Route path="realisations" element={<Realisations />} />
          {/* Page "Détails Réalisation " */}
          <Route path="realisation/:slug" element={<Realisation />} />
          {/* Page "Univers " */}
          <Route path="univers" element={<Univers />} />
          {/* Page "Détails Univers " */}
          <Route path="univer/:slug" element={<Univer />} />
          {/* Page "Détails Sous Univers " */}
          <Route path="sous-univer/:slug" element={<SousUniver />} />
          {/* Page "Produits " */}
          <Route path="produits" element={<Products />} />
          {/* Page "Détails Produit " */}
          <Route path="produit/:slug" element={<Product />} />
          {/* SR l'Évolutif */}
          <Route path="sr-evolutif" element={<SREvolutif />} />
          {/* <Route path="sr-evolutif" element={<Entreprise />} /> */}
          {/* Entreprise */}
          <Route path="entreprise" element={<Entreprise />} />
          {/* Espace revendeur */}
          <Route path="espace-revendeur" element={<Distributeur />} />
          {/* Page "FAQ " */}
          <Route path="faq" element={<Faq />} />
          {/* Page "Service " */}
          <Route path="services" element={<Service />} />
          {/* Page "Visite virtuelle " */}
          <Route path="visite-virtuelle" element={<Showroom />} />
          {/* Page "Actualités " */}
          <Route path="actualites" element={<Actualites />} />
          {/* Page "Détails Actualités " */}
          <Route path="actualite/:slug" element={<Actualite />} />
          {/* Page "Contact" */}
          <Route path="contact" element={<Contact />} />
          {/* Page "Emploies" */}
          <Route path="emploies" element={<Emploies />} />
          {/* Page "Détails Emploie" */}
          <Route path="emploie/:slug" element={<Emploie />} />
          {/* Page "Candidature" */}
          <Route path="candidature" element={<Candidature />} />
          <Route path="candidature/:slug" element={<Candidature />} />
          {/* Page "Devis" */}
          <Route path="devis" element={<Devis />} />
          {/* Page "Devis" */}
          <Route path="recherches/:recherche" element={<Recherches />} />
          {/* Page "Catalogues" */}
          <Route path="catalogues" element={<Catalogues />} />
          {/* Page "Notices et tutos" */}
          <Route path="notices-et-tutos" element={<NoticesEtTutos />} />
          {/* Page "Liste d'envies" */}
          <Route path="favoris" element={<Favoris />} />
          {/* Page "Politique de confidentialité" */}
          <Route
            path="poltique-confidentialite"
            element={<PolitiqueConfidentialite />}
          />
          {/* Page "Mentions légales" */}
          <Route path="mentions-legales" element={<MentionsLegal />} />
          {/* Page 404 */}
          <Route path="*" element={<NoPage />} />
          
          {/* Redirection des anciennes page vers les nouvelles */}
          <Route path="devis-agencement-magasin.php" element={<Devis />} />
          <Route path="contact.php" element={<Contact />} />
          <Route
            path="fournisseur-meubles-professionnels.php"
            element={<Entreprise />}
          />
          <Route path="nos-catalogues.php" element={<Catalogues />} />
          <Route path="realisations_cl1.html" element={<Realisations />} />
          <Route
            path="gondole-metallique_cl1.html"
            element={<Realisations />}
          />
          <Route path="agencement-bois_cl1.html" element={<Realisations />} />
          <Route
            path="comptoir-et-caisse_cl1.html"
            element={<Realisations />}
          />
          <Route path="rack-et-rayonnage_cl1.html" element={<Realisations />} />
          <Route
            path="mobilier-de-bureau_cl1.html"
            element={<Realisations />}
          />
          <Route
            path="vestiaire-et-mobilier-specifique_cl1.html"
            element={<Realisations />}
          />
          <Route path="actualites_al.html" element={<Actualites />} />
          <Route path="candidature.php" element={<Candidature />} />
          <Route path="plan.php" element={<Contact />} />
          <Route path="rappel-telephonique.php" element={<Contact />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
