import React from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import AddToFavorites from "../molecules/AddToFavorites";
import ImageOptimizer from "../utils/ImageOptimizer";

export default function CardInspiration({ inspiration }) {
  return (
    <div className="card-inspiration">
      <div className="bannerAddToFav">
        <AddToFavorites label={"inspirations"} content={inspiration} />
      </div>
      <Link href={"inspiration/" + inspiration.attributes.slug}>
        <div className="container_card_inspiration">
          <ImageOptimizer
            formats={inspiration.attributes.images.data[0].attributes.formats}
          />
          <div className="card-content">
            <Typography gutterBottom variant="body3">
              # {inspiration.attributes.titre}
            </Typography>
            <br />
            <br />
            <Typography
              gutterBottom
              variant="action1"
              // component="a"
              // href={"inspiration/" + inspiration.attributes.slug}
            >
              Découvrir
            </Typography>
          </div>
        </div>
      </Link>
    </div>
  );
}
