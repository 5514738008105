import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
// import ProjetCard from "../../assets/projet-picture.jpg";
import API_URL from "../../Api";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import img from "../../assets/projet-picture.jpg";

export default function ShowUnivers() {
  const [isLoading, setIsLoading] = useState(true);
  const [univers, setUnivers] = useState();
  const [isActive, setIsActive] = useState();
  const [isHover, setIsHover] = useState();

  function handleClick(id) {
    if (id !== isActive) {
      setIsActive(id);
    } else {
      setIsActive();
    }
  }

  function hoverSousUnivers(id) {
    if (id !== isHover) {
      setIsHover(id);
    } else {
      setIsHover();
    }
  }

  useEffect(() => {
    fetch(
      `${API_URL}/api/univers?sort[0]=rank%3Aasc&populate[0]=titre&populate[1]=slug&populate[2]=images&populate[3]=sous_univers`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        setUnivers(response.data);
        setIsHover(response.data[0].id);
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="showUnivers pb120">
      <Container maxWidth="xl">
        <h2 className="h2 textcenter pt120">Découvrez nos univers</h2>
        <Grid container justifyContent="center" alignItems="start">
          <Grid item xs={12}>
            <p className="textcenter">
              Tilt’ Équipement accompagne des secteurs d’activité variés et
              accorde une importance particulière à vous conseiller des
              solutions adaptées.
            </p>
            <a className="link_univers_title" href="/univers">
              Parcourir nos univers
            </a>
            {!isLoading && (
              <ul className="contain-ul-univers pt90 pb120">
                {univers.map((univer) => (
                  <li
                    className="liFirst"
                    key={univer.attributes.titre + "listUnivers"}
                  >
                    <AnimationOnScroll animateIn="animate__fadeIn">
                      <p
                        // onMouseOver={hoverSousUnivers}
                        onClick={() => handleClick(univer.id)}
                        onMouseEnter={() => setIsHover(univer.id)}
                      >
                        {univer.attributes.titre}
                      </p>
                      <ul
                        className={
                          isActive === univer.id
                            ? "ulSousUnivers activeSousUnivers"
                            : "ulSousUnivers"
                        }
                      >
                        {univer.attributes.sous_univers.data.map(
                          (sous_univer) => (
                            <li key={sous_univer.id + "listSousUnivers"}>
                              <a
                                href={
                                  "/sous-univer/" + sous_univer.attributes.slug
                                }
                              >
                                {sous_univer.attributes.titre}
                              </a>
                            </li>
                          )
                        )}
                      </ul>
                      {univer.attributes.images.data &&
                        univer.attributes.images.data[0] &&
                        isHover === univer.id && (
                          <img
                            className={
                              isHover ? "img-univers-1 active" : "img-univers-1"
                            }
                            src={
                              API_URL +
                              univer.attributes.images.data[0].attributes.url
                            }
                            alt={
                              univer.attributes.images.data[0].attributes.name
                            }
                          />
                        )}
                      {univer.attributes.images.data &&
                        univer.attributes.images.data[1] &&
                        isHover === univer.id && (
                          <img
                            className={
                              isHover ? "img-univers-2 active" : "img-univers-2"
                            }
                            src={
                              API_URL +
                              univer.attributes.images.data[1].attributes.url
                            }
                            alt={
                              univer.attributes.images.data[1].attributes.name
                            }
                          />
                        )}
                    </AnimationOnScroll>
                  </li>
                ))}
              </ul>
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
