import React, { useEffect, useState } from "react";
import API_URL from "../Api";
import Ariane from "../components/molecules/Ariane";
import Banner from "../components/molecules/Banner";
import Back from "../components/molecules/Back";
import Container from "@mui/material/Container";
import MetaTags from "react-meta-tags";
import { useParams } from "react-router-dom";
import ImageOptimizer from "../components/utils/ImageOptimizer";

export default function Univer() {
  const slug = useParams().slug;
  const [isLoading, setIsLoading] = useState(true);
  const [univer, setUniver] = useState([]);
  const [arianeItem, setArianeItem] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetch(
      `${API_URL}/api/univers?filters[slug][$eq]=${slug}&populate[0]=sous_univers.images&populate[1]=images`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        setUniver(response.data[0]);
        setArianeItem([
          ["/", "Tilt'Équipement"],
          ["/univers", "Univers"],
          ["/univer/" + response.data[0].id, response.data[0].attributes.titre],
        ]);
        setIsLoading(false);
      });
  }, [slug]);

  return (
    <React.Fragment>
      {!isLoading && (
        <MetaTags>
          {univer.attributes.metaTitre ? (
            <title>{"Tilt’ Équipement - " + univer.attributes.metaTitre}</title>
          ) : (
            <title>{"Tilt’ Équipement - " + univer.attributes.titre}</title>
          )}
          {univer.attributes.metaTitre ? (
            <>
              <meta
                property="og:title"
                content={"Tilt' Équipement - " + univer.attributes.metaTitre}
              />
              <meta
                property="title"
                content={"Tilt' Équipement - " + univer.attributes.metaTitre}
              />
            </>
          ) : (
            <>
              <meta
                property="og:title"
                content={"Tilt' Équipement - " + univer.attributes.titre}
              />
              <meta
                property="title"
                content={"Tilt' Équipement - " + univer.attributes.titre}
              />
            </>
          )}
          {univer.attributes.metaDescription ? (
            <>
              <meta
                name="description"
                content={univer.attributes.metaDescription}
              />
              <meta
                name="og:description"
                content={univer.attributes.metaDescription}
              />
            </>
          ) : (
            <>
              <meta
                name="description"
                content={univer.attributes.description}
              />
              <meta
                name="og:description"
                content={univer.attributes.description}
              />
            </>
          )}
          {univer.attributes.images.data && (
            <meta
              property="og:image"
              content={
                API_URL + univer.attributes.images.data[0].attributes.url
              }
            />
          )}
          <meta
            name="keywords"
            content={"Tilt'Équipement, Univers, " + univer.attributes.titre}
          />
          <meta
            name="og:url"
            content={
              "https://tilt-equipement.fr/univer/" + univer.attributes.slug
            }
          />
        </MetaTags>
      )}
      <Ariane arianeItem={arianeItem} />
      <div className="univerContainer">
        <Back href="/univers" />
        {!isLoading && <Banner title={univer.attributes.titre}></Banner>}
        <Container maxWidth="xl">
          <div className="univerGrid">
            {!isLoading && univer.attributes.sous_univers.data ? (
              <React.Fragment>
                {univer.attributes.sous_univers.data.map((sous_univer) => (
                  <div
                    key={"univerGridCard" + sous_univer.id}
                    className="univerGridCard"
                  >
                    <a href={"/sous-univer/" + sous_univer.attributes.slug}>
                      {sous_univer.attributes.images.data && (
                        <ImageOptimizer
                          formats={
                            sous_univer.attributes.images.data[0].attributes
                              .formats
                          }
                          requireFormat={"medium"}
                        />
                      )}
                      <div>
                        <p>{sous_univer.attributes.titre}</p>
                      </div>
                    </a>
                  </div>
                ))}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="univerGridCard"></div>
                <div className="univerGridCard"></div>
                <div className="univerGridCard"></div>
                <div className="univerGridCard"></div>
                <div className="univerGridCard"></div>
                <div className="univerGridCard"></div>
                <div className="univerGridCard"></div>
              </React.Fragment>
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
}
