import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import ArrowButton from "../components/Atom/ArrowButton";

export default function NoPage() {
  return (
    <div className="container_errorpage">
      <Container maxWidth="xl">
        <Grid
          className="errorPage"
          container
          direction="row"
          alignItems="center"
        >
         
          <Grid item lg={12}>
            <div className="">
              <h1>Oops !</h1>
              <p>
                La page que vous recherchez n'existe pas ou a changé de nom.
              </p>
              <ArrowButton
                content={"Accueil"}
                color="grey"
                href="/"
                style={{ marginTop: "32px" }}
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
