import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import ideeIcon from "../../assets/idee.svg";
import livraisonIcon from "../../assets/livraison.svg";
import planIcon from "../../assets/plan.svg";
import devisIcon from "../../assets/devis.svg";
import API_URL from "../../Api";
import ArrowButton from "../Atom/ArrowButton";
import { AnimationOnScroll } from "react-animation-on-scroll";
import ImageOptimizer from "../utils/ImageOptimizer";

export default function Missions() {
  const [isLoading, setIsLoading] = useState(true);
  const [missions, setMissions] = useState();

  useEffect(() => {
    fetch(`${API_URL}/api/missions?populate=*`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setIsLoading(false);
        setMissions(response.data[0].attributes);
      });
  }, []);

  return (
    <div className="missionContainer">
      <div className="missionBox">
        <Container>
          <Grid container className="missionTop">
            <Grid item xs={12}>
              <AnimationOnScroll animateIn="animate__fadeIn">
                <p className="pretitre color1">Notre mission</p>
                <h2>
                  Accompagner les professionnels
                  <br />
                  dans leur projet d'agencement
                </h2>
              </AnimationOnScroll>
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              className="friseMissions pt90 pb90"
            >
              <AnimationOnScroll animateIn="animate__fadeIn">
                <div className="friseMissionsLine"></div>
                <div className="friseMissionsCards">
                  <div className="friseMissionsCard">
                    <img src={ideeIcon} alt="idée" />
                    <div className="friseMissionsCardRing"></div>
                    <p>Étude personnalisée</p>
                  </div>
                  <div className="friseMissionsCard">
                    <img src={planIcon} alt="idée" />
                    <div className="friseMissionsCardRing"></div>
                    <p>Plan sur demande</p>
                  </div>
                  <div className="friseMissionsCard">
                    <img src={devisIcon} alt="idée" />
                    <div className="friseMissionsCardRing"></div>
                    <p>Devis gratuit</p>
                  </div>
                  <div className="friseMissionsCard">
                    <img src={livraisonIcon} alt="idée" />
                    <div className="friseMissionsCardRing"></div>
                    <p>Livraison rapide</p>
                  </div>
                </div>
              </AnimationOnScroll>
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth="xls" className="missionBottom">
          <Grid container>
            <Grid item xs={12} lg={6} md={6} className="missionBottomLeft">
              <AnimationOnScroll animateIn="animate__fadeIn">
                <div className="missionBottomFakeGridTop">
                  <div>
                    {!isLoading && missions.images.data[0] && (
                      <ImageOptimizer
                        formats={missions.images.data[0].attributes.formats}
                      />
                    )}
                  </div>
                  <div>
                    {!isLoading && missions.images.data[1] && (
                      <ImageOptimizer
                        formats={missions.images.data[1].attributes.formats}
                      />
                    )}
                  </div>
                </div>
              </AnimationOnScroll>
              <AnimationOnScroll animateIn="animate__fadeIn">
                <div className="missionBottomFakeGridBottom">
                  <div>
                    {!isLoading && missions.images.data[2] && (
                      <ImageOptimizer
                        formats={missions.images.data[2].attributes.formats}
                      />
                    )}
                  </div>
                  <div>
                    {!isLoading && missions.images.data[3] && (
                      <ImageOptimizer
                        formats={missions.images.data[3].attributes.formats}
                      />
                    )}
                  </div>
                </div>
              </AnimationOnScroll>
            </Grid>
            <Grid item xs={12} lg={6} md={6} className="missionBottomRight">
              <div className="missionBottomRightBox">
                <div className="missionBottomRightStep">
                  <AnimationOnScroll animateIn="animate__fadeIn">
                    <div className="missionStepLabel">
                      <div className="missionStepLabelNumber">1</div>
                      <div className="missionStepLabelDashed"></div>
                    </div>
                  </AnimationOnScroll>
                  <AnimationOnScroll animateIn="animate__fadeIn">
                    <div className="missionStepContent">
                      {!isLoading && (
                        <React.Fragment>
                          <p>{missions.etape_1_titre}</p>
                          <p>{missions.etape_1_description}</p>
                        </React.Fragment>
                      )}
                    </div>
                  </AnimationOnScroll>
                </div>
                <div className="missionBottomRightStep">
                  <AnimationOnScroll animateIn="animate__fadeIn">
                    <div className="missionStepLabel">
                      <div className="missionStepLabelNumber">2</div>
                      <div className="missionStepLabelDashed"></div>
                    </div>
                  </AnimationOnScroll>
                  <AnimationOnScroll animateIn="animate__fadeIn">
                    <div className="missionStepContent">
                      {!isLoading && (
                        <React.Fragment>
                          <p>{missions.etape_2_titre}</p>
                          <p>{missions.etape_2_description}</p>
                        </React.Fragment>
                      )}
                    </div>
                  </AnimationOnScroll>
                </div>
                <div className="missionBottomRightStep">
                  <AnimationOnScroll animateIn="animate__fadeIn">
                    <div className="missionStepLabel">
                      <div className="missionStepLabelNumber">3</div>
                      <div className="missionStepLabelDashed"></div>
                    </div>
                  </AnimationOnScroll>
                  <AnimationOnScroll animateIn="animate__fadeIn">
                    <div className="missionStepContent">
                      {!isLoading && (
                        <React.Fragment>
                          <p>{missions.etape_3_titre}</p>
                          <p>{missions.etape_3_description}</p>
                        </React.Fragment>
                      )}
                    </div>
                  </AnimationOnScroll>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
        <div className="missionFooter pt24 pb90">
          <AnimationOnScroll animateIn="animate__fadeIn">
            <ArrowButton
              content={"Découvrir nos services"}
              color="grey"
              href={"/services"}
            />
          </AnimationOnScroll>
        </div>
      </div>
    </div>
  );
}
