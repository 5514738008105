import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import BannerProduct from "../components/molecules/BannerProduct";
import SubFooter from "../components/molecules/SubFooter";
import background from "../assets/showroom.jpg";
import backgroundAllProduits from "../assets/tilt_equipement_tasse_bureau_1.png";

import ProductsSearchAndFilter from "../components/molecules/ProductsSearchAndFilter";
import ShowAllProducts from "../components/products/ShowAllProducts";
import ProductsCategories from "../components/products/ProductsCategories";
import API_URL from "../Api";
// import ProductCardSkelet from "../components/skeletons/ProductCardSkelet";
import ProductsFilters from "../components/molecules/ProductsFilters";
import ProductCard from "../components/products/ProductCard";
import Ariane from "../components/molecules/Ariane";
import CloseIcon from "@mui/icons-material/Close";
import { Badge, Button } from "@mui/material";
import PaginationProducts from "../components/products/PaginationProducts";
import { MetaTags } from "react-meta-tags";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

export default function Products() {
  // État du chargement des produits
  const [productStep, setProductStep] = useState(1);
  // filtre de recherche
  const [filter, setFilter] = useState("");
  const [filterMaster, setFilterMaster] = useState([]);
  // contenu du filtre (nom de categorie : sous categorie : type de produit)
  const [filterContent, setFilterContent] = useState("");
  // nom de la categorie séléctionnée
  const [selectedCategorie, setSelectedCategorie] = useState();
  // nom de la sous categorie séléctionnée
  const [selectedSousCategorie, setSelectedSousCategorie] = useState();
  // état de chargement
  const [isLoading, setIsLoading] = useState(true);
  // tableau des produits à afficher
  const [products, setProducts] = useState([]);

  // récupération des filtres via url
  const queryParameters = new URLSearchParams(window.location.search);
  const URLId = queryParameters.get("id");
  const URLFilter = queryParameters.get("filter");
  const URLTitre = queryParameters.get("label");

  // page active
  const [page, setPage] = useState(1);
  // nombre de page
  const [pageCount, setPageCount] = useState();
  // nombre de produits par page
  const [pageSize, setPageSize] = useState(18);

  // total de produits retournés
  const [total, setTotal] = useState();

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("tiltStepProduct"))) {
      setProductStep(JSON.parse(localStorage.getItem("tiltStepProduct")).step);
    }
    if (JSON.parse(localStorage.getItem("tiltFilterProduct"))) {
      window.scrollTo(0, 480);
      setFilterMaster(JSON.parse(localStorage.getItem("tiltFilterProduct")));
    }

    // si un filtre de categorie est transmis via l'url, on l'ajoute
    if (URLId) {
      let actualFilters = filterMaster;

      setFilterMaster((filterMaster) =>
        filterMaster.filter((obj) => obj.filter !== "category")
      );
      actualFilters = actualFilters.filter((obj) => obj.filter !== "category");

      setFilterMaster((filterMaster) =>
        filterMaster.filter((obj) => obj.filter !== "sous_category")
      );
      actualFilters = actualFilters.filter(
        (obj) => obj.filter !== "sous_category"
      );

      setFilterMaster((filterMaster) =>
        filterMaster.filter((obj) => obj.filter !== "types_de_produit")
      );
      actualFilters = actualFilters.filter(
        (obj) => obj.filter !== "types_de_produit"
      );

      // on dirige vers la page contenant les produits
      setProductStep(2);

      // ajoute le nouveau filtre
      setFilterMaster((filterMaster) => [
        ...filterMaster,
        {
          content: URLId,
          filter: URLFilter,
          label: URLTitre,
        },
      ]);
      actualFilters = [
        ...actualFilters,
        { content: URLId, filter: URLFilter, label: URLTitre },
      ];

      localStorage.setItem("tiltFilterProduct", JSON.stringify(actualFilters));
    }
  }, [URLId]);

  // useEffect(() => {
  //   // actualise les filtres en local storage
  //   localStorage.setItem("tiltFilterProduct", JSON.stringify(filterMaster));
  // }, [filterMaster]);

  useEffect(() => {
    // stockage des filtres en local pour les retrouver après
    // localStorage.setItem(
    //   "tiltStepProduct",
    //   JSON.stringify({
    //     ...JSON.parse(localStorage.getItem("tiltStepProduct")),
    //     filters: filterMaster,
    //   })
    // );

    // url de recherche vers l'api
    let url = API_URL + "/api/produits?populate=*&sort[0]=rank%3Adesc";
    if (JSON.parse(localStorage.getItem("tiltFilterProduct")).length > 0) {
      for (const filter of JSON.parse(
        localStorage.getItem("tiltFilterProduct")
      )) {
        // SI RECHERCHE PAR FAMILLE
        if (
          filter.filter === "univers" ||
          filter.filter === "sous_univers" ||
          filter.filter === "category" ||
          filter.filter === "sous_category" ||
          filter.filter === "types_de_produit"
        ) {
          url += `&filters[${filter.filter}][id][$eq]=${filter.content}`;
        }
        // SI RECHERCHE SUR LA NUANCE
        if (filter.filter === "nuances") {
          url += `&filters[parties_modifiables][nuances][nom][$eqi]=${filter.label}`;
        }
        // SI RECHERCHE SUR LA DISPO DU PRODUIT
        if (filter.filter === "dispo") {
          url += `&filters[${filter.filter}][$eqi]=${filter.content}`;
        }
        // SI REHCHERCHE PAR DIMENSION
        if (
          filter.filter === "longueur" ||
          filter.filter === "largeur" ||
          filter.filter === "hauteur" ||
          filter.filter === "profondeur" ||
          filter.filter === "diametre" ||
          filter.filter === "epaisseur" ||
          filter.filter === "poids" ||
          filter.filter === "charge"
        ) {
          url += `&filters[${filter.filter}][reelle][$between]=${filter.content[0]}&filters[${filter.filter}][reelle][$between]=${filter.content[1]}`;
        }
      }
    } else if (filterMaster.length > 0) {
      for (const filter of filterMaster) {
        // SI RECHERCHE PAR FAMILLE
        if (
          filter.filter === "univers" ||
          filter.filter === "sous_univers" ||
          filter.filter === "category" ||
          filter.filter === "sous_category" ||
          filter.filter === "types_de_produit"
        ) {
          url += `&filters[${filter.filter}][id][$eq]=${filter.content}`;
        }
        // SI RECHERCHE SUR LA NUANCE
        if (filter.filter === "nuances") {
          url += `&filters[parties_modifiables][nuances][nom][$eqi]=${filter.label}`;
        }
        // SI RECHERCHE SUR LA DISPO DU PRODUIT
        if (filter.filter === "dispo") {
          url += `&filters[${filter.filter}][$eqi]=${filter.content}`;
        }
        // SI REHCHERCHE PAR DIMENSION
        if (
          filter.filter === "longueur" ||
          filter.filter === "largeur" ||
          filter.filter === "hauteur" ||
          filter.filter === "profondeur" ||
          filter.filter === "diametre" ||
          filter.filter === "epaisseur" ||
          filter.filter === "poids" ||
          filter.filter === "charge"
        ) {
          url += `&filters[${filter.filter}][reelle][$between]=${filter.content[0]}&filters[${filter.filter}][reelle][$between]=${filter.content[1]}`;
        }
      }
    } else {
      // url = API_URL + "/api/page-produit?populate=*";
    }
    url += `&pagination[page]=${page}`;
    url += `&pagination[pageSize]=${pageSize}`;
    url += `&pagination[withCount]=true`;
    url += `&sort[0]=updatedAt%3Adesc`;

    // on fetch l'url d'action déini plus haut
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "Application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setPage(response.meta.pagination.page);
        setPageCount(response.meta.pagination.pageCount);
        // setPageSize(response.meta.pagination.pageSize);
        setTotal(response.meta.pagination.total);
        // si aucun filtre n'est présent et qu'on est sur la première page, alors on va ajouter les produits top
        if (
          JSON.parse(localStorage.getItem("tiltFilterProduct")).length <= 0 &&
          filterMaster.length <= 0 &&
          page === 1
        ) {
          setProducts(response.data);
          // on fetch l'url d'action pour récupérer les top products
          fetch(
            API_URL +
              "/api/page-produit?populate[0]=top_produits.images&populate[1]=top_produits.nom",
            {
              method: "GET",
              headers: {
                Accept: "Application/json",
              },
            }
          )
            .then((res) => res.json())
            .then((response) => {
              setProducts((prevProducts) =>
                response.data.attributes.top_produits.data.concat(prevProducts)
              );
            });
        } else {
          setProducts(response.data);
        }
        setIsLoading(false);
      });
  }, [filterMaster, page, pageSize]);

  function removeFilter(filter) {
    setIsLoading(true);
    setFilterMaster(
      filterMaster.filter(function (obj) {
        return obj.filter !== filter.filter;
      })
    );
    let actualFilters = filterMaster;
    actualFilters = actualFilters.filter((obj) => obj.filter !== filter.filter);
    localStorage.setItem("tiltFilterProduct", JSON.stringify(actualFilters));
  }

  const arianeItem = [
    ["/", "Tilt'Équipement"],
    ["/produits", "Produits"],
  ];

  return (
    <React.Fragment>
      <Ariane arianeItem={arianeItem} />
      <MetaTags>
        <title>Tilt’ Équipement - Produits</title>
        <meta property="og:title" content={"Tilt’ Équipement - Produits"} />
        <meta
          name="description"
          content={
            "Découvrez une large gamme de produits en stock permanent ! Tilt’ Équipement vous propose près de 400 références : gondoles, mobilier, accessoires de magasin, manutention et stockage…"
          }
        />
        <meta name="keywords" content={"Tilt'Équipement, Produits"} />
      </MetaTags>
      <BannerProduct
        title={"Produits"}
        content={"Une large gamme de produits en stock permanent"}
      />
      <Container maxWidth="xl" className="productsExplorerContainer pb120">
        {/* barre de recherche et filtres */}
        <ProductsSearchAndFilter
          products={products}
          filterMaster={filterMaster}
          setFilterMaster={setFilterMaster}
          setProductStep={setProductStep}
        />
        {/* deuxième état, on propose les catégories et les sous-catégories*/}
        {productStep === 1 && (
          <ProductsCategories
            filterMaster={filterMaster}
            setFilterMaster={setFilterMaster}
            setIsLoading={setIsLoading}
            setProductStep={setProductStep}
            setFilter={setFilter}
            setFilterContent={setFilterContent}
            setSelectedCategorie={setSelectedCategorie}
            setSelectedSousCategorie={setSelectedSousCategorie}
          />
        )}
        {/* troisième état, on propose la page produit (adaptée si une catégorie, une sous catégorie ou un filtre à été choisi) */}
        {productStep === 2 && (
          <>
            <div className="back">
              <Container maxWidth="xl">
                <Button
                  onClick={() => {
                    setProductStep(1);
                    // stockage de la step en local storage
                    localStorage.setItem(
                      "tiltStepProduct",
                      JSON.stringify({
                        ...JSON.parse(localStorage.getItem("tiltStepProduct")),
                        step: 1,
                      })
                    );
                  }}
                  startIcon={<ArrowBackIosNewIcon />}
                  aria-label="Retour"
                >
                  Retour
                </Button>
              </Container>
            </div>

            <Grid spacing={1} container className="productsVisualizerContainer">
              {/* si une catégorie ou une sous-catégorie a été séléctionnée, on affiche son intitulé et ses sous item */}
              {/* {filterMaster[0] && filterMaster[0].details && (
              <Grid item lg={12}>
                <p className="descriptionProduct">{filterMaster[0].details}</p>
              </Grid>
            )} */}
              {filterMaster.length > 0 && (
                <Grid item xs={12} className="productsVisualizerLabelsBox">
                  {filterMaster.map((filter) => (
                    <div
                      key={
                        "productsFilterLabel" + filter.content + filter.label
                      }
                    >
                      {filter.filter === "univers" && (
                        <div className="productsFilterLabel">
                          <p>Univers : {filter.label}</p>
                          <Badge color="primary">
                            <CloseIcon
                              color="white"
                              onClick={() => removeFilter(filter)}
                            />
                          </Badge>
                        </div>
                      )}
                      {filter.filter === "dispo" && (
                        <div className="productsFilterLabel">
                          <p>Disponibilité : {filter.label}</p>
                          <Badge color="primary">
                            <CloseIcon
                              color="white"
                              onClick={() => removeFilter(filter)}
                            />
                          </Badge>
                        </div>
                      )}
                      {filter.filter === "sous_univers" && (
                        <div className="productsFilterLabel">
                          <p>Sous-univers : {filter.label}</p>
                          <Badge color="primary">
                            <CloseIcon
                              color="white"
                              onClick={() => removeFilter(filter)}
                            />
                          </Badge>
                        </div>
                      )}
                      {filter.filter === "category" && (
                        <div className="productsFilterLabel">
                          <p>Catégorie : {filter.label}</p>
                          <Badge color="primary">
                            <CloseIcon
                              color="white"
                              onClick={() => removeFilter(filter)}
                            />
                          </Badge>
                        </div>
                      )}
                      {filter.filter === "sous_category" && (
                        <div className="productsFilterLabel">
                          <p>Sous-catégorie : {filter.label}</p>
                          <Badge color="primary">
                            <CloseIcon
                              color="white"
                              onClick={() => removeFilter(filter)}
                            />
                          </Badge>
                        </div>
                      )}
                      {filter.filter === "types_de_produit" && (
                        <div className="productsFilterLabel">
                          <p>Type : {filter.label}</p>
                          <Badge color="primary">
                            <CloseIcon
                              color="white"
                              onClick={() => removeFilter(filter)}
                            />
                          </Badge>
                        </div>
                      )}
                      {filter.filter === "nuances" && (
                        <div className="productsFilterLabel">
                          <p>Nuance : {filter.label}</p>
                          <Badge color="primary">
                            <CloseIcon
                              color="white"
                              onClick={() => removeFilter(filter)}
                            />
                          </Badge>
                        </div>
                      )}
                      {filter.filter === "longueur" && (
                        <div className="productsFilterLabel">
                          <p>Longueur : {filter.label}</p>
                          <Badge color="primary">
                            <CloseIcon
                              color="white"
                              onClick={() => removeFilter(filter)}
                            />
                          </Badge>
                        </div>
                      )}
                      {filter.filter === "largeur" && (
                        <div className="productsFilterLabel">
                          <p>Largeur : {filter.label}</p>
                          <Badge color="primary">
                            <CloseIcon
                              color="white"
                              onClick={() => removeFilter(filter)}
                            />
                          </Badge>
                        </div>
                      )}
                      {filter.filter === "hauteur" && (
                        <div className="productsFilterLabel">
                          <p>Hauteur : {filter.label}</p>
                          <Badge color="primary">
                            <CloseIcon
                              color="white"
                              onClick={() => removeFilter(filter)}
                            />
                          </Badge>
                        </div>
                      )}
                      {filter.filter === "profondeur" && (
                        <div className="productsFilterLabel">
                          <p>Profondeur : {filter.label}</p>
                          <Badge color="primary">
                            <CloseIcon
                              color="white"
                              onClick={() => removeFilter(filter)}
                            />
                          </Badge>
                        </div>
                      )}
                      {filter.filter === "diametre" && (
                        <div className="productsFilterLabel">
                          <p>Diamètre : {filter.label}</p>
                          <Badge color="primary">
                            <CloseIcon
                              color="white"
                              onClick={() => removeFilter(filter)}
                            />
                          </Badge>
                        </div>
                      )}
                      {filter.filter === "epaisseur" && (
                        <div className="productsFilterLabel">
                          <p>Épaisseur : {filter.label}</p>
                          <Badge color="primary">
                            <CloseIcon
                              color="white"
                              onClick={() => removeFilter(filter)}
                            />
                          </Badge>
                        </div>
                      )}
                      {filter.filter === "poids" && (
                        <div className="productsFilterLabel">
                          <p>Poids : {filter.label}</p>
                          <Badge color="primary">
                            <CloseIcon
                              color="white"
                              onClick={() => removeFilter(filter)}
                            />
                          </Badge>
                        </div>
                      )}
                      {filter.filter === "charge" && (
                        <div className="productsFilterLabel">
                          <p>Charge : {filter.label}</p>
                          <Badge color="primary">
                            <CloseIcon
                              color="white"
                              onClick={() => removeFilter(filter)}
                            />
                          </Badge>
                        </div>
                      )}
                    </div>
                  ))}
                </Grid>
              )}
              <Grid
                item
                lg={3}
                md={3}
                sm={12}
                xs={12}
                className="productsFilter"
              >
                <p className="titleFilter">Filtrer ma recherche</p>
                {/* Chargement des produits à afficher dans ce composant */}
                <ProductsFilters
                  filterMaster={filterMaster}
                  setFilterMaster={setFilterMaster}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  filter={filter}
                  setFilter={setFilter}
                  filterContent={filterContent}
                  setFilterContent={setFilterContent}
                />
              </Grid>
              <Grid item lg={9} md={9} sm={12} xs={12} className="productsGrid">
                <p className="titleproducts">
                  {!isLoading && products && (
                    <React.Fragment>
                      {total}
                      {products.length > 1
                        ? " produits trouvés"
                        : " produit trouvé"}
                    </React.Fragment>
                  )}
                </p>
                {!isLoading && products ? (
                  <React.Fragment>
                    {products[0] ? (
                      <React.Fragment>
                        <Grid container spacing={1}>
                          {products.map((product) => (
                            <Grid
                              item
                              lg={4}
                              md={4}
                              sm={12}
                              xs={12}
                              key={"productsGridCard" + product.id}
                            >
                              <ProductCard product={product} />
                            </Grid>
                          ))}
                        </Grid>
                        <PaginationProducts
                          pageCount={pageCount}
                          page={page}
                          setPage={setPage}
                          pageSize={pageSize}
                          setPageSize={setPageSize}
                        />
                      </React.Fragment>
                    ) : (
                      <div className="noProduct">
                        Aucun produit disponible...
                      </div>
                    )}
                  </React.Fragment>
                ) : (
                  <Grid
                    container
                    spacing={1}
                    className="skeletGrid"
                    mt={2}
                    mb={2}
                  >
                    {new Array(9).fill("").map((_, index) => (
                      <Grid lg={4} item key={index}>
                        <div style={{ height: "450px" }}></div>
                      </Grid>
                    ))}
                  </Grid>
                  // <Grid container spacing={1} className="skeletGrid">
                  //   <Grid xs={4} item>
                  //     <div style={{ height: "450px" }}></div>
                  //   </Grid>
                  //   <Grid xs={4} item>
                  //     <div style={{ height: "450px" }}></div>
                  //   </Grid>
                  //   <Grid xs={4} item>
                  //     <div style={{ height: "450px" }}></div>
                  //   </Grid>
                  //   <Grid xs={4} item>
                  //     <div style={{ height: "450px" }}></div>
                  //   </Grid>
                  //   <Grid xs={4} item>
                  //     <div style={{ height: "450px" }}></div>
                  //   </Grid>
                  //   <Grid xs={4} item>
                  //     <div style={{ height: "450px" }}></div>
                  //   </Grid>
                  //   <Grid xs={4} item>
                  //     <div style={{ height: "450px" }}></div>
                  //   </Grid>
                  //   <Grid xs={4} item>
                  //     <div style={{ height: "450px" }}></div>
                  //   </Grid>
                  //   <Grid xs={4} item>
                  //     <div style={{ height: "450px" }}></div>
                  //   </Grid>
                  // </Grid>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </Container>
      {productStep === 0 && (
        <SubFooter
          titleLeft="800m² de salles d’exposition pour découvrir nos produits"
          hrefLeft={"../visite-virtuelle"}
          contentbtnLeft="Visiter en virtuel"
          isRight
          titleRight="Nos catalogues"
          descriptionRight="Tous nos produits au fil des pages"
          hrefRight={"../catalogues"}
          contentbtnRight="Découvrir"
          backgroundCustom={background}
        />
      )}
      {productStep === 1 && (
        <SubFooter
          titleLeft="Besoin d'aide ?"
          descriptionLeft="Nous répondons aux questions les plus frequemment posées"
          hrefLeft={"../faq"}
          contentbtnLeft="FAQ"
          isRight
          titleRight="Nos catalogues"
          descriptionRight="Tous nos produits au fil des pages"
          hrefRight={"../catalogues"}
          contentbtnRight="Découvrir"
          backgroundCustom={backgroundAllProduits}
        />
      )}
      {productStep === 2 && (
        <SubFooter
          titleLeft="800m² de salles d’exposition pour découvrir nos produits"
          hrefLeft={"../visite-virtuelle"}
          contentbtnLeft="Visiter en virtuel"
          isRight
          titleRight="Nos catalogues"
          descriptionRight="Tous nos produits au fil des pages"
          hrefRight={"../catalogues"}
          contentbtnRight="Découvrir"
          backgroundCustom={background}
        />
      )}
    </React.Fragment>
  );
}
