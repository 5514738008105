import React, { useEffect, useState } from "react";
import scroll from "../../assets/scroll.svg";

export default function ScrollTop() {
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  function toTop() {
    window.scroll({
      top: 0,
      left: 100,
      behavior: "smooth",
    });
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <React.Fragment>
      {scrollPosition > 900 && (
        <div className="scrollTop" onClick={toTop}>
          <img src={scroll} alt={"Tilt'Équipement"} loading="lazy" />
        </div>
      )}
    </React.Fragment>
  );
}
