import React, { useEffect, useState } from "react";
import API_URL from "../Api";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import ProposeProducts from "../components/products/ProposeProducts";
import SubFooter from "../components/molecules/SubFooter";
import Ariane from "../components/molecules/Ariane";
import Back from "../components/molecules/Back";
// import { Swiper, SwiperSlide } from "swiper/react";
import MetaTags from "react-meta-tags";
import AddToFavorites from "../components/molecules/AddToFavorites";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/css/navigation";
import { useParams } from "react-router-dom";
import AssociatesUniverProducts from "../components/univers/AssociatesUniverProducts";
import ImageOptimizer from "../components/utils/ImageOptimizer";

export default function SousUniver() {
  const slug = useParams().slug;
  const [isLoading, setIsLoading] = useState(true);
  const [sousUniver, setSousUniver] = useState([]);
  const [arianeItem, setArianeItem] = useState([]);

  const [multipleMosaique, setMultipleMosaique] = useState(3);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetch(
      `${API_URL}/api/sous-univers?filters[slug][$eq]=${slug}&populate[0]=images&populate[1]=univer&populate[2]=produits.images&populate[3]=image_hero`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        setSousUniver(response.data[0]);
        setIsLoading(false);
        setMultipleMosaique(
          Math.floor(response.data[0].attributes.images.data.length / 3) * 3
        );
        setArianeItem([
          ["/", "Tilt'Équipement"],
          ["/univers", "Univers"],
          [
            "/univer/" +
              response.data[0].attributes.univer.data.attributes.slug,
            response.data[0].attributes.univer.data.attributes.titre,
          ],
          [
            "/sous-univer/" + response.data[0].attributes.slug,
            response.data[0].attributes.titre,
          ],
        ]);
      });
  }, [slug]);
  return (
    <React.Fragment>
      {!isLoading && (
        <React.Fragment>
          <MetaTags>
            {sousUniver.attributes.metaTitre ? (
              <title>
                {"Tilt’ Équipement - " + sousUniver.attributes.metaTitre}
              </title>
            ) : (
              <title>
                {"Tilt’ Équipement - " + sousUniver.attributes.titre}
              </title>
            )}
            {sousUniver.attributes.metaTitre ? (
              <>
                <meta
                  property="og:title"
                  content={
                    "Tilt' Équipement - " + sousUniver.attributes.metaTitre
                  }
                />
                <meta
                  property="title"
                  content={
                    "Tilt' Équipement - " + sousUniver.attributes.metaTitre
                  }
                />
              </>
            ) : (
              <>
                <meta
                  property="og:title"
                  content={"Tilt' Équipement - " + sousUniver.attributes.titre}
                />
                <meta
                  property="title"
                  content={"Tilt' Équipement - " + sousUniver.attributes.titre}
                />
              </>
            )}
            {sousUniver.attributes.metaDescription ? (
              <>
                <meta
                  name="description"
                  content={sousUniver.attributes.metaDescription}
                />
                <meta
                  name="og:description"
                  content={sousUniver.attributes.metaDescription}
                />
              </>
            ) : (
              <>
                <meta
                  name="description"
                  content={sousUniver.attributes.description}
                />
                <meta
                  name="og:description"
                  content={sousUniver.attributes.description}
                />
              </>
            )}
            {sousUniver.attributes.images.data && (
              <meta
                property="og:image"
                content={
                  API_URL + sousUniver.attributes.images.data[0].attributes.url
                }
              />
            )}
            <meta
              name="keywords"
              content={
                "Tilt'Équipement, Sous-Univers, " + sousUniver.attributes.titre
              }
            />
            <meta
              name="og:url"
              content={
                "https://tilt-equipement.fr/sous-univer/" +
                sousUniver.attributes.slug
              }
            />
          </MetaTags>
          <Ariane arianeItem={arianeItem} />{" "}
          <Back
            href={
              "/univer/" + sousUniver.attributes.univer.data.attributes.slug
            }
          />
          <div className="sousUniverBanner">
            <Container maxWidth="xl">
              <Grid container className="sousUniverBannerLeftContainer">
                <Grid item xs={12} lg={7} md={7} sm={12} className="">
                  {!isLoading && (
                    <div className="sousUniverBannerLeft">
                      <div className="containBannerAddToFav">
                        <p className="pretitre color1">
                          {sousUniver.attributes.univer.data.attributes.titre}
                        </p>
                        <div className="realisationAddToFav">
                          <AddToFavorites
                            label={"sous univer"}
                            content={sousUniver}
                          />
                          
                        </div>
                      </div>
                      <h1>{sousUniver.attributes.titre}</h1>
                      <p>{sousUniver.attributes.description}</p>
                    </div>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={5}
                  md={5}
                  sm={12}
                  className="sousUniverBannerRight"
                >
                  {sousUniver.attributes.image_hero.data && (
                    <ImageOptimizer
                      formats={
                        sousUniver.attributes.image_hero.data.attributes.formats
                      }
                      requireFormat={"large"}
                    />
                  )}
                </Grid>
              </Grid>
            </Container>
          </div>
        </React.Fragment>
      )}
      <Container maxWidth="xl" className="pt90">
        <Grid container>
          {!isLoading && sousUniver.attributes.infos && (
            <div className="sousUniverDescription">
              <div
                dangerouslySetInnerHTML={{
                  __html: sousUniver.attributes.infos,
                }}
              ></div>
            </div>
          )}
        </Grid>
      </Container>
      {!isLoading && sousUniver.attributes.images.data && (
        <Container maxWidth="xl" className="pt90">
          <Grid container spacing={1} className="sousUniverPics">
            {sousUniver.attributes.images.data
              .slice(0, multipleMosaique)
              .map((item) => (
                <Grid
                  item
                  lg={4}
                  md={4}
                  sm={6}
                  key={"grid" + item.attributes.url}
                >
                  <ImageOptimizer formats={item.attributes.formats} />
                </Grid>
              ))}
          </Grid>
        </Container>
      )}
      {/* <ProposeProducts /> */}
      {!isLoading && (
        <React.Fragment>
          <AssociatesUniverProducts
            associateProducts={sousUniver.attributes.produits.data}
          />
          <SubFooter
            titleLeft="Créez votre agencement"
            descriptionLeft="Demandez votre devis et concevons ensemble votre projet de demain."
            hrefLeft={"../devis?sous-univers=" + sousUniver.attributes.slug}
            contentbtnLeft="Demande de devis"
            hrefRight={"../services"}
            titleRight="Travailler en toute sérénité et en toute simplicité"
            contentbtnRight="Découvrir nos services"
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
