import { Grid, Pagination } from "@mui/material";
import React from "react";
import ImageOptimizer from "../utils/ImageOptimizer";
import DefaultLogo from "../../assets/image_produit_defaut_tiltequipement.svg";

export default function ResultsRealisations({
  realisations,
  totalRealisationsResults,
  realisationsPageCount,
  realisationsPage,
  setRealisationsPage,
}) {
  function handlePagination(e) {
    window.scrollTo(0, 480);
    setRealisationsPage(e.target.textContent);
  }
  return (
    <div style={{ margin: "40px 0" }}>
      <h3>Les réalisations ({totalRealisationsResults})</h3>
      <Grid container spacing={2}>
        {realisations.map((realisation) => (
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <div className="rechercheResultCard">
              <a href={"../realisation/" + realisation.attributes.slug}>
                <section>
                  {realisation.attributes.couverture.data ? (
                    <ImageOptimizer
                      formats={
                        realisation.attributes.couverture.data.attributes
                          .formats
                      }
                    />
                  ) : (
                    <img
                      src={DefaultLogo}
                      alt="Absence de visuel pour ce produit"
                    />
                  )}
                </section>
                <p style={{ marginTop: "10px" }}>
                  {realisation.attributes.Titre}
                </p>
              </a>
            </div>
          </Grid>
        ))}
      </Grid>
      <div className="pagination pt64">
        <Pagination
          onClick={(e) => handlePagination(e)}
          count={realisationsPage}
          variant="outlined"
        />
      </div>
    </div>
  );
}
