import React from "react";
import Container from "@mui/material/Container";
import { Button } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";

// accepte un tableaux contenant alias et url des pages à afficher dans le fil
export default function Back({ href }) {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <React.Fragment>
      <div className="back">
        <Container maxWidth="xl">
          <Button
            // href={href}
            onClick={goBack}
            startIcon={<ArrowBackIosNewIcon />}
            aria-label="Retour"
          >
            Retour
          </Button>
        </Container>
      </div>
    </React.Fragment>
  );
}
