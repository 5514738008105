import API_URL from "../../Api";
import React from "react";

export default function ProductCategorieCard({
  setIsLoading,
  categorie,
  setProductStep,
  setFilterMaster,
}) {
  // selection d'une catégorie particulière
  function handleSelectCategorie(categorie) {
    setIsLoading(true);

    // parametrage du filtre de recherche en fonction de la catégorie séléctionné
    // on filtre par categorie
    setFilterMaster([
      {
        content: categorie.id,
        filter: "category",
        label: categorie.attributes.titre,
      },
    ]);
    // on set l'etat pour passer à la page d'après
    setProductStep(2);
    // stockage de la step en local storage
    localStorage.setItem(
      "tiltStepProduct",
      JSON.stringify({
        ...JSON.parse(localStorage.getItem("tiltStepProduct")),
        step: 2,
      })
    );

    let actualFilters = [
      {
        content: categorie.id,
        filter: "category",
        label: categorie.attributes.titre,
      },
    ];

    localStorage.setItem("tiltFilterProduct", JSON.stringify(actualFilters));
  }
  // selection d'une sous catégorie particulière (saute l'étape de la page catégorie)
  function handleSelectSousCategorie(e, sousCategorie) {
    setIsLoading(true);
    e.stopPropagation();
    // parametrage du filtre de recherche en fonction de la catégorie séléctionné
    // on filtre par categorie
    setFilterMaster([
      {
        content: categorie.id,
        filter: "category",
        label: categorie.attributes.titre,
      },
      {
        content: sousCategorie.id,
        filter: "sous_category",
        label: sousCategorie.attributes.titre,
      },
    ]);
    // on set l'etat pour passer à la page d'après
    setProductStep(2);
    // stockage de la step en local storage
    // localStorage.setItem(
    //   "tiltStepProduct",
    //   JSON.stringify({
    //     ...JSON.parse(localStorage.getItem("tiltStepProduct")),
    //     step: 2,
    //   })
    // );

    let actualFilters = [
      {
        content: categorie.id,
        filter: "category",
        label: categorie.attributes.titre,
      },
      {
        content: sousCategorie.id,
        filter: "sous_category",
        label: sousCategorie.attributes.titre,
      },
    ];

    localStorage.setItem("tiltFilterProduct", JSON.stringify(actualFilters));
  }

  const compareByRank = (a, b) => {
    return a.attributes.rank - b.attributes.rank;
  };

  return (
    <div
      className="productsCategoriesCard"
      onClick={() => handleSelectCategorie(categorie)}
    >
      <div className="productsCategoriesCardImage">
        <img
          key={categorie.attributes.image.data.attributes.name}
          src={API_URL + categorie.attributes.image.data.attributes.url}
          alt={categorie.attributes.image.data.attributes.name}
        />
      </div>
      <p className="productsCategoriesCardTitle">
        {categorie.attributes.titre}
      </p>
      <ul>
        {categorie.attributes.sous_categories.data
          .sort(compareByRank)
          .map((sous_categories) => (
            <li
              key={
                "productsCategoriesCardTitle" + sous_categories.attributes.titre
              }
              onClick={(e) => handleSelectSousCategorie(e, sous_categories)}
            >
              {sous_categories.attributes.titre}
            </li>
          ))}
      </ul>
    </div>
  );
}
