import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import Ariane from "../components/molecules/Ariane";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import ScrollDown from "../components/molecules/ScrollDown";
import imgBanner from "../assets/service_banner.jpg";
import imgSchemas from "../assets/tiltEquipement-service_schemas.png";
import imgSchemasMobile from "../assets/tiltEquipement_Service_Schemas_Mobile.png";
import SubFooter from "../components/molecules/SubFooter";
import ArrowButton from "../components/Atom/ArrowButton";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import API_URL from "../Api";
import ImageOptimizer from "../components/utils/ImageOptimizer";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const styleImg = {
  width: "100%",
  height: "150px",
  objectFit: "cover",
  paddingRight: "8px",
};

const modalFlex = {
  display: "flex",
  width: "100%",
};

export default function Service() {
  const [services, setServices] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [arianeItem, setArianeItem] = useState([]);
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState({});

  function handleOpen(title, content, images) {
    setModal({
      title: title,
      content: content,
      images: images,
    });
    setOpen(true);
  }
  const handleClose = () => setOpen(false);

  useEffect(() => {
    // on fetch les réalisations
    fetch(API_URL + "/api/service?populate=*&populate[0]=service.images", {
      method: "GET",
      headers: {
        Accept: "Application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        // on définit une réalisation à montrer de façon aléatoire dans les réalisations disponibles
        setServices(response.data.attributes.service);
        setIsLoading(false);
      });

    setArianeItem([
      ["/", "Tilt'Équipement"],
      ["/services", "Services"],
    ]);
  }, []);

  return (
    <React.Fragment>
      <Ariane arianeItem={arianeItem} />
      <MetaTags>
        <title>Tilt’ Équipement - Services</title>
        <meta property="og:title" content={"Tilt’ Équipement - Services"} />
        <meta property="title" content={"Tilt’ Équipement - Services"} />
        <meta
          name="description"
          content={
            "Un suivi de projet total, de l’étude à la réalisation. L’équipe de Tilt’ Équipement est à votre écoute pour vous conseiller tout au long de votre projet, du plan et du concept jusqu’à la livraison et l'installation."
          }
        />
        <meta
          name="og:description"
          content={
            "Un suivi de projet total, de l’étude à la réalisation. L’équipe de Tilt’ Équipement est à votre écoute pour vous conseiller tout au long de votre projet, du plan et du concept jusqu’à la livraison et l'installation."
          }
        />
        <meta name="keywords" content={"Tilt'Équipement, Services"} />
        <meta name="og:url" content={"https://tilt-equipement.fr/services"} />
      </MetaTags>

      <section className="bannerService" style={{ textAlign: "left" }}>
        <Container maxWidth="xl" className="h100">
          <Grid
            container
            justifyContent="start"
            className="h100"
            alignItems="center"
          >
            <Grid
              item
              lg={6}
              md={6}
              sm={6}
              xs={12}
              className="position-relative"
            >
              <p className="pretitre color1 pb16">Notre mission</p>
              <h1>Suivi de projet, de l'étude à la réalisation</h1>
              <p style={{ textAlign: "left" }}>
                L'équipe de Tilt'Équipement est à votre écoute pour vous
                conseiller tout au long de votre projet, depuis l'étude (plan,
                concept...) jusqu'à la livraison ou l'installation.
              </p>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              sm={6}
              xs={12}
              className="position-relative h100"
            >
              <img className="imgBanner" src={imgBanner} alt={"Nuancier"} />
            </Grid>
          </Grid>
        </Container>
        <ScrollDown />
      </section>
      <Container maxWidth="xl">
        <Grid
          container
          justifyContent="center"
          className=""
          alignItems="center"
        >
          <AnimationOnScroll animateIn="animate__fadeIn">
            <img
              className="imgSchemas hideMobile"
              src={imgSchemas}
              alt={"Schémas des services"}
            />
            <img
              className="imgSchemas hideDesktop"
              src={imgSchemasMobile}
              alt={"Schémas des services"}
            />
          </AnimationOnScroll>
        </Grid>
        <Grid container className="pt120 pb120" alignItems="center">
          <Grid item lg={6} md={6} xs={12} className="position-relative">
            <AnimationOnScroll animateIn="animate__fadeIn">
              <h2 className="pb48">Efficacité et simplicité</h2>
              <p>
                Une question au sujet d'un produit ? Un conseil technique ? Un
                renseignement au sujet de la livraison ou du montage
                <br />
                <br />
                Notre équipe dynamique et expérimentée vous accompagne à toutes
                les étapes.
              </p>
            </AnimationOnScroll>
          </Grid>
        </Grid>
        {!isLoading && (
          <Grid container spacing={1} className="pb90" alignItems="center">
            {services.map((service) => (
              <Grid
                item
                lg={4}
                md={4}
                sm={6}
                xs={12}
                className="position-relative"
              >
                <AnimationOnScroll animateIn="animate__fadeIn">
                  <div
                    className="boxCardService"
                    onClick={() =>
                      handleOpen(
                        service.titre,
                        service.description,
                        service.images.data
                      )
                    }
                  >
                    <p className="titreBoxCardService">{service.titre}</p>
                    <p>{service.resume}</p>
                  </div>
                </AnimationOnScroll>
              </Grid>
            ))}
          </Grid>
        )}
        <Grid container className="pb120" justifyContent="center">
          <AnimationOnScroll animateIn="animate__fadeIn">
            <ArrowButton
              content={"Vous souhaitez être accompagné"}
              color="grey"
              href={"/contact"}
            />
          </AnimationOnScroll>
        </Grid>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="modalService"
        >
          <div className="boxModalService">
            <IconButton
              aria-label="fermer"
              className="ButtonSwiperClose"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
            <div className="contentModal">
              {modal.images && (
                <div className="contentModalImg">
                  {modal.images.map((image) => (
                    <ImageOptimizer formats={image.attributes.formats} />
                  ))}
                </div>
              )}
              <div className="contentModalText">
                {modal.title && (
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {modal.title}
                  </Typography>
                )}
                {modal.content && (
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {modal.content}
                  </Typography>
                )}
              </div>
            </div>
          </div>
        </Modal>
      </Container>
      <SubFooter
        titleLeft="Créez votre agencement"
        descriptionLeft="Demandez votre devis et concevons ensemble votre projet de demain."
        hrefLeft="../devis"
        contentbtnLeft="Demande de devis"
        isRight
        titleRight="Près de 25 ans d’expérience"
        descriptionRight="Agenceur magasin, fournisseur de meubles professionnels"
        contentbtnRight="L'entreprise"
        hrefRight="../entreprise"
      />
    </React.Fragment>
  );
}
