import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Image from "../../assets/projet-picture.jpg";
import API_URL from "../../Api";
import AddToFavorites from "./AddToFavorites";
import ImageOptimizer from "../utils/ImageOptimizer";

export default function BannerRealisation({
  pretitre,
  title,
  content,
  src,
  realisation,
}) {
  return (
    <section className="realisationContainer">
      <Container maxWidth="xl" className="">
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} lg={7} md={7} sm={12} className="realisationBannerContent">
            <div className="containBannerAddToFav">
              <p className="pretitre color1">{pretitre}</p>
              <div className="realisationAddToFav">
                <AddToFavorites label={"realisations"} content={realisation} />
              </div>
            </div>
            <h1>{title && title}</h1>
            {content && <p>{content}</p>}
          </Grid>
          <Grid item xs={12} lg={5} md={5} sm={12} className="realisationBannerImage">
            <Swiper
              loop={true}
              modules={[Autoplay, Pagination, Navigation]}
              pagination={{
                clickable: true,
              }}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              className="heroSwiper"
            >
              {realisation.attributes.carrousel.data && (
                <React.Fragment>
                  {realisation.attributes.carrousel.data.map((item) => (
                    <SwiperSlide style={{ width: "100%" }}>
                      <ImageOptimizer formats={item.attributes.formats} />
                    </SwiperSlide>
                  ))}
                </React.Fragment>
              )}
            </Swiper>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}
