import React, { useEffect, useState } from "react";
import API_URL from "../../Api";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

export default function BandeauLocalisation() {
  const [contact, setContact] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch(`${API_URL}/api/fiche-contact?populate=*`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setContact(response.data.attributes);
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="bandeauLocalisation">
      <div className="bandeauLocalisationLeft">
        <div>
          {!isLoading && (
            <div className="bandeauLocalisationFlex">
              <FmdGoodIcon />
              <div>
                <p>{contact.adresse}</p>
                <p>
                  {contact.code_postal} {contact.ville}
                </p>
              </div>
            </div>
          )}
          {!isLoading && (
            <div className="bandeauLocalisationFlex">
              <LocalPhoneIcon />
              <a href="tel:+33243078529">{contact.tel_principal}</a>
            </div>
          )}
        </div>
      </div>
      <div className="bandeauLocalisationRight">
        <iframe
          title="Google Maps Siège"
          width="100%"
          height="300"
          style={{ border: 0 }}
          src="https://www.google.com/maps/embed/v1/place?q=10-rue-jeanne-vivez-chateau-gontier-sur-mayenne-53200&key=AIzaSyBsNNTQ0luoooUmLYu1uvgsJ25UrKJAE9g"
          allowFullScreen=""
        ></iframe>
      </div>
    </div>
  );
}
