import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import Ariane from "../components/molecules/Ariane";
import SearchProductBar from "../components/products/SearchProductBar";
import categorie from "../assets/categorie.svg";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import ImageOptimizer from "../components/utils/ImageOptimizer";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { IconButton } from "@mui/material";

export default function Favoris() {
  const [favorites, setFavorites] = useState();

  useEffect(() => {
    if (
      JSON.parse(localStorage.tiltFav).actualites.length > 0 ||
      JSON.parse(localStorage.tiltFav).inspirations.length > 0 ||
      JSON.parse(localStorage.tiltFav).products.length > 0 ||
      JSON.parse(localStorage.tiltFav).realisations.length > 0 ||
      JSON.parse(localStorage.tiltFav).sousunivers.length > 0
    ) {
      setFavorites(JSON.parse(localStorage.tiltFav));
    } else {
      setFavorites();
    }
  }, []);

  function removeFav(label, favori) {
    let actualFav;
    switch (label) {
      case "products":
        actualFav = JSON.parse(localStorage.tiltFav);
        actualFav.products = actualFav.products.filter(
          (element) => element.id !== favori.id
        );
        localStorage.setItem("tiltFav", JSON.stringify(actualFav));
        setFavorites(JSON.parse(localStorage.tiltFav));
        break;
      case "realisations":
        actualFav = JSON.parse(localStorage.tiltFav);
        actualFav.realisations = actualFav.realisations.filter(
          (element) => element.id !== favori.id
        );
        localStorage.setItem("tiltFav", JSON.stringify(actualFav));
        setFavorites(JSON.parse(localStorage.tiltFav));
        break;
      case "inspirations":
        actualFav = JSON.parse(localStorage.tiltFav);
        actualFav.inspirations = actualFav.inspirations.filter(
          (element) => element.id !== favori.id
        );
        localStorage.setItem("tiltFav", JSON.stringify(actualFav));
        setFavorites(JSON.parse(localStorage.tiltFav));
        break;
      case "actualites":
        actualFav = JSON.parse(localStorage.tiltFav);
        actualFav.actualites = actualFav.actualites.filter(
          (element) => element.id !== favori.id
        );
        localStorage.setItem("tiltFav", JSON.stringify(actualFav));
        setFavorites(JSON.parse(localStorage.tiltFav));
        break;
      case "sous univers":
        actualFav = JSON.parse(localStorage.tiltFav);
        actualFav.sousunivers = actualFav.sousunivers.filter(
          (element) => element.id !== favori.id
        );
        localStorage.setItem("tiltFav", JSON.stringify(actualFav));
        setFavorites(JSON.parse(localStorage.tiltFav));
        break;
      default:
    }
  }

  const arianeItem = [
    ["/", "Tilt'Équipement"],
    ["/favoris", "Favoris"],
  ];

  return (
    <React.Fragment>
      <MetaTags>
        <title>Tilt’ Équipement - Favoris</title>
        <meta property="og:title" content={"Tilt’ Équipement - Favoris"} />
        <meta property="title" content={"Tilt’ Équipement - Favoris"} />
        <meta
          name="description"
          content={
            "Des produits vous intéressent ? Mettez-les dans votre liste de favoris dans l’espace dédié et consultez-les ultérieurement."
          }
        />
        <meta
          name="og:description"
          content={
            "Des produits vous intéressent ? Mettez-les dans votre liste de favoris dans l’espace dédié et consultez-les ultérieurement."
          }
        />
        <meta name="keywords" content={"Tilt'Équipement, Favoris"} />
        <meta name="og:url" content={"https://tilt-equipement.fr/favoris"} />
      </MetaTags>
      <Ariane arianeItem={arianeItem} />
      <section className="BannerFavoris">
        <Container maxWidth="xl">
          <Grid
            container
            justifyContent={"start"}
            className="h100"
            alignItems="center"
          >
            <Grid item xs={12}>
              <h1>Liste de mes favoris</h1>
            </Grid>
            <Grid item xs={12}>
              <div className="searchProductExplorer">
                <section>
                  <SearchProductBar />
                  <div className="containerBtnUniversCategories">
                    <div
                      className="btnUniversFiltersSearch"
                      // onClick={
                      //   !categoryFilterOpen
                      //     ? openCategoryFilter
                      //     : closeCategoryFilter
                      // }
                    >
                      <img
                        src={categorie}
                        alt={"Tilt'Équipement"}
                        loading="lazy"
                      />
                      <div>
                        <p>CATÉGORIE</p>
                        {/* {!categoryFilterOpen ? (
                          <KeyboardArrowDownIcon />
                        ) : (
                          <KeyboardArrowDownIcon className="turnArrow" />
                        )} */}
                      </div>
                    </div>
                  </div>
                </section>
                {/* <div
                  className={
                    !categoryFilterOpen
                      ? "categoryFilterList"
                      : "categoryFilterList categoryFilterListOpen"
                  }
                >
                  {!categorieIsLoading && (
                    <React.Fragment>
                      {categories.map((categorie) => (
                        <div
                          className="categoryFilterListCard"
                          key={"categoryFilter" + categorie.titre}
                          onClick={() =>
                            handleFilter(
                              "category",
                              categorie.id,
                              categorie.titre,
                              categorie.description
                            )
                          }
                        >
                          <div className="categoryFilterListCardImg">
                            <img
                              src={API_URL + categorie.image.url}
                              alt={categorie.image.name}
                              loading="lazy"
                            />
                          </div>
                          <p className="categoryFilterListTitle">
                            {categorie.titre}
                          </p>
                        </div>
                      ))}
                    </React.Fragment>
                  )}
                </div> */}
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>
      <div className="favorisContainer">
        {favorites && (
          <div className="favorisDevis">
            <h4>Vous souhaitez générer un devis regroupant vos favoris ?</h4>
            <a href="../devis?favoris=true">DEMANDER MON DEVIS</a>
          </div>
        )}
        <div className="favorisBox">
          {favorites ? (
            <React.Fragment>
              {favorites.products.length > 0 && (
                <div className="favorisSection">
                  <h3>Produits favoris</h3>
                  <Grid container spacing={2}>
                    {favorites.products.map((favoris) => (
                      <Grid
                        item
                        lg={4}
                        md={4}
                        sm={12}
                        xs={12}
                        key={"favorisGridCard" + favoris.id}
                      >
                        <div className="favorisCard">
                          <a href={"../produit/" + favoris.attributes.slug}>
                            <ImageOptimizer
                              formats={
                                favoris.attributes.images.data[0].attributes
                                  .formats
                              }
                            />
                            <p>{favoris.attributes.nom}</p>
                            <IconButton
                              title="Supprimer de ma liste d'envie"
                              aria-label="Supprimer de ma liste d'envie"
                              onClick={(e) => {
                                e.preventDefault();
                                removeFav("products", favoris);
                              }}
                              className="favorisDeleteBtn"
                            >
                              <FavoriteIcon />
                            </IconButton>
                          </a>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              )}
              {favorites.realisations.length > 0 && (
                <div className="favorisSection">
                  <h3>Réalisations favoris</h3>
                  <Grid container spacing={2}>
                    {favorites.realisations.map((favoris) => (
                      <Grid
                        item
                        lg={4}
                        md={4}
                        sm={12}
                        xs={12}
                        key={"favorisGridCard" + favoris.id}
                      >
                        <div className="favorisCard">
                          <a href={"../realisation/" + favoris.attributes.slug}>
                            <ImageOptimizer
                              formats={
                                favoris.attributes.couverture.data.attributes
                                  .formats
                              }
                            />
                            <p>{favoris.attributes.Titre}</p>
                            <IconButton
                              title="Supprimer de ma liste d'envie"
                              aria-label="Supprimer de ma liste d'envie"
                              onClick={(e) => {
                                e.preventDefault();
                                removeFav("realisations", favoris);
                              }}
                              className="favorisDeleteBtn"
                            >
                              <FavoriteIcon />
                            </IconButton>
                          </a>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                  {/* {favorites.realisations.map((favoris) => (
                    <div className="favorisLine">
                      <a href={"../realisation/" + favoris.attributes.slug}>
                        <p>{favoris.attributes.Titre}</p>
                      </a>
                      <button
                        onClick={(e) => removeFav("realisations", favoris)}
                        className="favorisDeleteBtn"
                        type="button"
                        aria-label="Supprimer"
                      >
                        SUPPRIMER
                      </button>
                    </div>
                  ))} */}
                </div>
              )}
              {favorites.inspirations.length > 0 && (
                <div className="favorisSection">
                  <h3>Inspirations favoris</h3>
                  <Grid container spacing={2}>
                    {favorites.inspirations.map((favoris) => (
                      <Grid
                        item
                        lg={4}
                        md={4}
                        sm={12}
                        xs={12}
                        key={"favorisGridCard" + favoris.id}
                      >
                        <div className="favorisCard">
                          <a href={"../inspiration/" + favoris.attributes.slug}>
                            <ImageOptimizer
                              formats={
                                favoris.attributes.images.data[0].attributes
                                  .formats
                              }
                            />
                            <p>{favoris.attributes.titre}</p>
                            <IconButton
                              title="Supprimer de ma liste d'envie"
                              aria-label="Supprimer de ma liste d'envie"
                              onClick={(e) => {
                                e.preventDefault();
                                removeFav("inspirations", favoris);
                              }}
                              className="favorisDeleteBtn"
                            >
                              <FavoriteIcon />
                            </IconButton>
                          </a>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              )}
              {favorites.actualites.length > 0 && (
                <div className="favorisSection">
                  <h3>Actualites favoris</h3>
                  <Grid container spacing={2}>
                    {favorites.actualites.map((favoris) => (
                      <Grid
                        item
                        lg={4}
                        md={4}
                        sm={12}
                        xs={12}
                        key={"favorisGridCard" + favoris.id}
                      >
                        <div className="favorisCard">
                          <a href={"../actualite/" + favoris.attributes.slug}>
                            <ImageOptimizer
                              formats={
                                favoris.attributes.images.data[0].attributes
                                  .formats
                              }
                            />
                            <p>{favoris.attributes.titre}</p>
                            <IconButton
                              title="Supprimer de ma liste d'envie"
                              aria-label="Supprimer de ma liste d'envie"
                              onClick={(e) => {
                                e.preventDefault();
                                removeFav("actualites", favoris);
                              }}
                              className="favorisDeleteBtn"
                            >
                              <FavoriteIcon />
                            </IconButton>
                          </a>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              )}
              {favorites.sousunivers.length > 0 && (
                <div className="favorisSection">
                  <h3>Sous univers favoris</h3>
                  <Grid container spacing={2}>
                    {favorites.sousunivers.map((favoris) => (
                      <Grid
                        item
                        lg={4}
                        md={4}
                        sm={12}
                        xs={12}
                        key={"favorisGridCard" + favoris.id}
                      >
                        <div className="favorisCard">
                          <a href={"../sous-univer/" + favoris.attributes.slug}>
                            <ImageOptimizer
                              formats={
                                favoris.attributes.images.data[0].attributes
                                  .formats
                              }
                            />
                            <p>{favoris.attributes.titre}</p>
                            <IconButton
                              title="Supprimer de ma liste d'envie"
                              aria-label="Supprimer de ma liste d'envie"
                              onClick={(e) => {
                                e.preventDefault();
                                removeFav("sous univers", favoris);
                              }}
                              className="favorisDeleteBtn"
                            >
                              <FavoriteIcon />
                            </IconButton>
                          </a>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              )}
            </React.Fragment>
          ) : (
            <p>Aucun favoris disponible</p>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
