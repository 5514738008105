import React, { useEffect, useState } from "react";
import API_URL from "../Api";
import Ariane from "../components/molecules/Ariane";
import Banner from "../components/molecules/Banner";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MetaTags } from "react-meta-tags";

export default function Faq() {
  const [isLoading, setIsLoading] = useState(true);
  const [faq, setFaq] = useState();
  const [themes, setThemes] = useState([]);
  const [url, setUrl] = useState(`${API_URL}/api/theme-faqs?populate=*`);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "Application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setFaq(response.data);
        setThemes(response.data);
        setIsLoading(false);
      });
  }, [url]);

  // traitement de la recherche lorsque l'utilisateur entre une nouvelle recherche
  function searchTreat(search) {
    if (search.length > 1) {
      setUrl(
        `${API_URL}/api/theme-faqs?populate=*` +
          `&filters[$or][0][titre_theme][$containsi]=${search}&filters[$or][1][faqs][question][$containsi]=${search}`
      );
    } else {
      setUrl(`${API_URL}/api/theme-faqs?populate=*`);
    }
  }

  const arianeItem = [
    ["/", "Tilt'Équipement"],
    ["/faq", "FAQ"],
  ];

  return (
    <React.Fragment>
      <Ariane arianeItem={arianeItem} />
      <Banner title={"FAQ"} />
      <MetaTags>
        <title>Tilt’ Équipement - Foire aux questions</title>
        <meta
          property="og:title"
          content={"Tilt’ Équipement - Foire aux questions"}
        />
        <meta
          property="title"
          content={"Tilt’ Équipement - Foire aux questions"}
        />
        <meta
          name="description"
          content={
            "Retrouvez toutes les réponses aux questions les plus fréquentes ainsi que les conseils de nos équipes spécialisées pour vous accompagner au mieux dans la réalisation de votre projet."
          }
        />
        <meta
          name="og:description"
          content={
            "Retrouvez toutes les réponses aux questions les plus fréquentes ainsi que les conseils de nos équipes spécialisées pour vous accompagner au mieux dans la réalisation de votre projet."
          }
        />
        <meta name="og:url" content={"https://tilt-equipement.fr/faq"} />
        <meta name="keywords" content={"Tilt'Équipement, FAQ"} />
      </MetaTags>
      <div className="faqContainer">
        <div className="faqSearchBar pb56">
          <section>
            <div className="searchfaqBarBox">
              <input
                className="searchfaqBarInput"
                onInput={(e) => searchTreat(e.target.value)}
                onKeyUp={(e) => searchTreat(e.target.value)}
                onChange={(e) => searchTreat(e.target.value)}
                type="search"
                placeholder="Une question ..?"
              ></input>
            </div>
          </section>
        </div>
        {!isLoading && (
          <React.Fragment>
            {themes.map((theme) => (
              <React.Fragment>
                {theme.attributes.faqs.data && (
                  <div className="faqBox pb56" key={"themeFaq" + theme}>
                    <h3>{theme.attributes.titre_theme}</h3>
                    {theme.attributes.faqs.data.map((faq) => (
                      <Accordion key={"fg85_" + faq.id} className="pt32">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography variant="h3">
                            {faq.attributes.question}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: faq.attributes.reponse,
                            }}
                          ></div>
                          {/* <Typography>{faq.attributes.reponse}</Typography>
                          {faq.attributes.lien && (
                            <a href={faq.attributes.lien}>
                              {faq.attributes.lien}
                            </a>
                          )} */}
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </div>
                )}
              </React.Fragment>
            ))}
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
}
