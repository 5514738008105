import { Button } from "@mui/material";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import React from "react";

export default function ArrowButton({ href, color, content, style, target, onClick }) {
  return (
    <Button
      className="arrowButton"
      variant="contained"
      href={href}
      color={color}
      endIcon={<ExpandCircleDownIcon />}
      style={style}
      aria-label="Button de navigation"
      target={target}
      onClick={onClick}
    >
      {content}
    </Button>
  );
}
