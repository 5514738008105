import React, { useEffect, useRef, useState } from "react";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
// import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import logo from "../../assets/logo.svg";
import ContactIcon from "../nucleons/icons/ContactIcon";
import ExpoIcon from "../nucleons/icons/ExpoIcon";
import Favoris from "../nucleons/icons/FavorisIcon";
import SearchBar from "./SearchBar";
import API_URL from "../../Api";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import ArrowButton from "../Atom/ArrowButton";

function ResponsiveAppBar({ burger, setBurger }) {
  const scrollPositionRef = useRef(0);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [searchBoxActive, setSearchBoxActive] = useState(false);
  const [openUnivers, setOpenUnivers] = useState(false);
  const [contact, setContact] = useState();
  const [univers, setUnivers] = useState();
  const [universIsLoading, setUniversIsLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [showNav, setShowNav] = useState(true);

  useEffect(() => {
    fetch(`${API_URL}/api/fiche-contact?populate=*`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setContact(response.data.attributes);
        setIsLoading(false);
      });

    // récupère la liste des univers disponibles
    fetch(
      API_URL +
        "/api/univers?sort[0]=rank%3Aasc&populate[0]=titre&populate[1]=icon&populate[2]=slug",
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        setUnivers(response.data);
        setUniversIsLoading(false);
      });

    const onScroll = (e) => {
      setScrollPosition(window.pageYOffset);
      const currentPosition = window.pageYOffset;

      // Vérifier si l'utilisateur a fait défiler vers le bas (scroll vers le bas)
      if (currentPosition > scrollPositionRef.current) {
        setShowNav(false);
      } else {
        setShowNav(true);
      }

      // Mettre à jour scrollPositionRef avec la nouvelle valeur de currentPosition
      scrollPositionRef.current = currentPosition;
    };

    // function checkFavState() {
    //   if (localStorage.getItem("tiltFav")) {
    //     let actuFav = JSON.parse(localStorage.getItem("tiltFav"))
    //       .actualites[0];
    //     let inspiFav = JSON.parse(localStorage.getItem("tiltFav"))
    //       .inspirations[0];
    //     let productFav = JSON.parse(localStorage.getItem("tiltFav"))
    //       .products[0];
    //     let realFav = JSON.parse(localStorage.getItem("tiltFav"))
    //       .realisations[0];
    //     if (actuFav || inspiFav || productFav || realFav) {
    //       setShowFavBtn(true);
    //     }
    //   }
    // }
    // window.addEventListener("storage", checkFavState);

    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
      // window.removeEventListener("storage", checkFavState);
    };
  }, []);

  return (
    <React.Fragment>
      {window.location.href.split("/")[3] !== "espace-revendeur" && (
        <header
          className={scrollPosition === 0 ? "header" : "header headerFixed"}
          id="header"
        >
          <div className="contain_info_header">
            <p>Le site est en cours de mise à jour et d'optimisation.</p>
          </div>
          <Container
            maxWidth="xl"
            className={
              !searchBoxActive ? "header-inner" : "header-inner searchBoxActive"
            }
          >
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
              className="headerBox"
            >
              <Grid item lg={7} md={7} sm={8} xs={8}>
                <div className="logoEtSearchBar">
                  <IconButton href="/" color="primary" aria-label="Accueil">
                    <img src={logo} alt={"Tilt'Équipement"} loading="lazy" />
                  </IconButton>
                  <div className="headerNav">
                    <SearchBar setSearchBoxActive={setSearchBoxActive} />
                    {showNav ? (
                      <nav>
                        <a href="/inspirations">Inspirations</a>
                        <a href="/realisations">Réalisations</a>
                        <div>
                          <a
                            href="/univers"
                            onMouseEnter={() => setOpenUnivers(true)}
                          >
                            Univers
                          </a>
                          {openUnivers ? (
                            <KeyboardArrowDownIcon
                              style={{ transform: "rotate(180deg)" }}
                            />
                          ) : (
                            <KeyboardArrowDownIcon
                              onMouseEnter={() => setOpenUnivers(true)}
                            />
                          )}
                        </div>
                        <a href="/produits">Produits</a>
                        <a href="/sr-evolutif">SR l'évolutif®</a>
                      </nav>
                    ) : null}
                  </div>
                </div>
              </Grid>
              <Grid
                item
                lg={5}
                md={5}
                sm={4}
                xs={2}
                className="aideContactMenu"
              >
                <div className="containAideContactEtMenu">
                  <div className="expoBtn">
                    <a href="../visite-virtuelle" aria-label="Visite virtuelle">
                      <ExpoIcon />
                      {showNav && (
                        <p>
                          Salles
                          <br />
                          d'exposition
                        </p>
                      )}
                    </a>
                  </div>
                  <div className="aideEtContactBtn">
                    <ContactIcon />
                    {showNav && (
                      <p>
                        Besoin
                        <br />
                        d'aide
                      </p>
                    )}
                    <ul className="nav-subheader">
                      <li className="hideDesktop">
                        <p>Nous contactez</p>
                        {!isLoading && (
                          <a
                            href={
                              "tel:" + contact.tel_principal.replace(/\s/g, "")
                            }
                          >
                            {contact.tel_principal}
                          </a>
                        )}
                      </li>
                      <li>
                        <p>Être rappelé</p>
                        <a href="../contact">Demande de rappel gratuit</a>
                      </li>
                      <li>
                        <p>FAQ</p>
                        <a href="../faq">Questions fréquentes</a>
                      </li>
                      <li>
                        <p>Vous avez un projet ?</p>
                        <a href="../devis">Demander votre devis gratuit</a>
                      </li>
                    </ul>
                  </div>
                  {/* Si minimum 1 favoris */}
                  <div className="favorisBtn">
                    <a href="../favoris" aria-label="Favoris">
                      <Favoris />
                      {showNav && <p>Favoris</p>}
                    </a>
                  </div>
                  <div className="headerPhone">
                    {!isLoading && (
                      <a
                        href={"tel:" + contact.tel_principal.replace(/\s/g, "")}
                      >
                        {contact.tel_principal}
                      </a>
                    )}
                  </div>
                  <div
                    className="menuButton"
                    onClick={() => {
                      setBurger(!burger);
                      document.documentElement.classList.add("noScroll");
                    }}
                    aria-label="Menu de navigation"
                  >
                    <IconButton
                      size="large"
                      color="inherit"
                      title="Menu de navigation"
                    >
                      <MenuIcon />
                    </IconButton>
                    {showNav && <p>MENU</p>}
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
          {!universIsLoading && (
            <div
              className={openUnivers ? "universOverlay open" : "universOverlay"}
            >
              <Container maxWidth="xxl">
                <div
                  className="containerUniversHeader"
                  onMouseLeave={() => setOpenUnivers(false)}
                >
                  {univers.map((univer) => (
                    <a
                      href={"../univer/" + univer.attributes.slug}
                      key={"universFilterHeader" + univer.attributes.titre}
                    >
                      {univer.attributes.icon.data && (
                        <img
                          src={
                            API_URL + univer.attributes.icon.data.attributes.url
                          }
                          alt={univer.attributes.icon.data.attributes.name}
                          loading="lazy"
                        />
                      )}
                      <p>{univer.attributes.titre}</p>
                    </a>
                  ))}
                </div>
              </Container>
            </div>
          )}
        </header>
      )}
    </React.Fragment>
  );
}
export default ResponsiveAppBar;
