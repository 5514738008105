import React from "react";
import Container from "@mui/material/Container";

export default function ScrollDown() {
  return (
    <Container maxWidth="xl">
      <div className="arrowScroll">
        <div className="arrow-down" id="arrowDown"></div>
        <div className="arrow-up" id="arrowUp"></div>
      </div>
    </Container>
  );
}
