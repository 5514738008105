// barre de recherche globale sur le site

import React, { useEffect, useState } from "react";
import API_URL from "../../Api";
// import SearchIcon from "@mui/icons-material/Search";
// import { Icon } from "@mui/material";
// import ArrowButton from "../Atom/ArrowButton";

export default function SearchBar({ setSearchBoxActive }) {
  const [overlay, setOverlay] = useState(false);

  // nombre de colonne dans les résultats, en fonction de la consistance des résultats de recherche
  const [columns, setColumns] = useState(3);

  // expression recherchée
  const [search, setSearch] = useState();

  const [products, setProducts] = useState([]);
  const [totalProductsResults, setTotalProductsResults] = useState(0);

  const [categories, setCategories] = useState([]);
  const [totalCategorieResults, setTotalCategorieResults] = useState(0);

  const [sousCategories, setSousCategories] = useState([]);
  const [totalSousCategoriesResults, setTotalSousCategoriesResults] =
    useState(0);

  const [typeProduits, setTypeProduits] = useState([]);
  const [totalTypeProduitResults, setTotalTypeProduitResults] = useState(0);

  const [realisations, setRealisations] = useState([]);
  const [totalRealisationsResults, setTotalRealisationsProductsResults] =
    useState(0);

  const [univers, setUnivers] = useState([]);
  const [totalUniversResults, setTotalUniversResults] = useState(0);

  const [inspirations, setInspirations] = useState([]);
  const [totalInspirationsResults, setTotalInspirationsResults] = useState(0);

  const [notices, setNotices] = useState([]);
  const [totalNoticesResults, setTotalNoticesResults] = useState(0);

  const [rapidAccess, setRapidAccess] = useState([]);

  window.addEventListener("keypress", function (e) {
    if (e.key === "Enter" && search.length > 1 && overlay) {
      window.location.replace("../recherches/" + search);
    }
  });

  // window.addEventListener("scroll", function (e) {
  //   setOverlay(false);
  // });
  // incrémentation des colonnes de résultat
  var colonne = 0;

  useEffect(() => {
    // url de recherche vers l'api
    if (search) {
      // produits disponibles
      fetch(
        API_URL +
          `/api/produits?populate[0]=nom&populate[1]=slug&filters[$or][0][nom][$containsi]=${search}&filters[$or][1][alias][$containsi]=${search}&filters[$or][2][ref][$containsi]=${search}`,
        {
          method: "GET",
          headers: {
            Accept: "Application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((response) => {
          // si au moins un résultat est retourné pour cette resource, alors on incrémente le nombre de colonne de 1
          if (response.data.length > 0) {
            colonne = colonne + 1;
          }

          // calculer la pertinence de chaque résultat
          const relevanceMap = new Map();
          response.data.forEach((result) => {
            const position = result.attributes.nom
              .toLowerCase()
              .indexOf(search.toLowerCase());
            relevanceMap.set(result.id, position);
          });

          // trier les résultats par pertinence croissante
          const sortedResults = response.data.sort((a, b) => {
            const relevanceA = relevanceMap.get(a.id);
            const relevanceB = relevanceMap.get(b.id);
            return relevanceA - relevanceB;
          });

          setProducts(sortedResults);
          setTotalProductsResults(response.meta.pagination.total);
        });

      fetch(
        API_URL +
          `/api/categories?populate[0]=titre&populate[1]=slug&filters[titre][$containsi]=${search}`,
        {
          method: "GET",
          headers: {
            Accept: "Application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((response) => {
          // si au moins un résultat est retourné pour cette resource, alors on incrémente le nombre de colonne de 1
          if (response.data.length > 0) {
            colonne = colonne + 1;
          }
          // calculer la pertinence de chaque résultat
          const relevanceMap = new Map();
          response.data.forEach((result) => {
            const position = result.attributes.titre
              .toLowerCase()
              .indexOf(search.toLowerCase());
            relevanceMap.set(result.id, position);
          });

          // trier les résultats par pertinence croissante
          const sortedResults = response.data.sort((a, b) => {
            const relevanceA = relevanceMap.get(a.id);
            const relevanceB = relevanceMap.get(b.id);
            return relevanceA - relevanceB;
          });

          setCategories(sortedResults);
          setTotalCategorieResults(response.meta.pagination.total);
        });

      fetch(
        API_URL +
          `/api/sous-categories?populate[0]=titre&populate[1]=slug&filters[titre][$containsi]=${search}`,
        {
          method: "GET",
          headers: {
            Accept: "Application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((response) => {
          // si au moins un résultat est retourné pour cette resource, alors on incrémente le nombre de colonne de 1
          if (response.data.length > 0) {
            colonne = colonne + 1;
          }

          // calculer la pertinence de chaque résultat
          const relevanceMap = new Map();
          response.data.forEach((result) => {
            const position = result.attributes.titre
              .toLowerCase()
              .indexOf(search.toLowerCase());
            relevanceMap.set(result.id, position);
          });

          // trier les résultats par pertinence croissante
          const sortedResults = response.data.sort((a, b) => {
            const relevanceA = relevanceMap.get(a.id);
            const relevanceB = relevanceMap.get(b.id);
            return relevanceA - relevanceB;
          });

          setSousCategories(sortedResults);
          setTotalSousCategoriesResults(response.meta.pagination.total);
        });

      fetch(
        API_URL +
          `/api/produit-types?populate[0]=titre&populate[1]=slug&filters[titre][$containsi]=${search}`,
        {
          method: "GET",
          headers: {
            Accept: "Application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((response) => {
          // si au moins un résultat est retourné pour cette resource, alors on incrémente le nombre de colonne de 1
          if (response.data.length > 0) {
            colonne = colonne + 1;
          }

          // calculer la pertinence de chaque résultat
          const relevanceMap = new Map();
          response.data.forEach((result) => {
            const position = result.attributes.titre
              .toLowerCase()
              .indexOf(search.toLowerCase());
            relevanceMap.set(result.id, position);
          });

          // trier les résultats par pertinence croissante
          const sortedResults = response.data.sort((a, b) => {
            const relevanceA = relevanceMap.get(a.id);
            const relevanceB = relevanceMap.get(b.id);
            return relevanceA - relevanceB;
          });

          setTypeProduits(sortedResults);
          setTotalTypeProduitResults(response.meta.pagination.total);
        });

      // univers disponibles
      fetch(
        API_URL +
          `/api/univers?populate[0]=titre&populate[1]=slug&filters[$or][0][titre][$containsi]=${search}&filters[$or][1][description][$containsi]=${search}`,
        {
          method: "GET",
          headers: {
            Accept: "Application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((response) => {
          // si au moins un résultat est retourné pour cette resource, alors on incrémente le nombre de colonne de 1
          if (response.data.length > 0) {
            colonne = colonne + 1;
          }
          // calculer la pertinence de chaque résultat
          const relevanceMap = new Map();
          response.data.forEach((result) => {
            const position = result.attributes.titre
              .toLowerCase()
              .indexOf(search.toLowerCase());
            relevanceMap.set(result.id, position);
          });

          // trier les résultats par pertinence croissante
          const sortedResults = response.data.sort((a, b) => {
            const relevanceA = relevanceMap.get(a.id);
            const relevanceB = relevanceMap.get(b.id);
            return relevanceA - relevanceB;
          });

          setUnivers(response.data);
          setTotalUniversResults(response.meta.pagination.total);
        });
        
      // realisations disponibles
      fetch(
        API_URL +
          `/api/realisations?populate[0]=Titre&populate[1]=slug&filters[$or][0][titre][$containsi]=${search}&filters[$or][1][description][$containsi]=${search}`,
        {
          method: "GET",
          headers: {
            Accept: "Application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((response) => {
          // si au moins un résultat est retourné pour cette resource, alors on incrémente le nombre de colonne de 1
          if (response.data.length > 0) {
            colonne = colonne + 1;
          }
          setRealisations(response.data);
          setTotalInspirationsResults(response.meta.pagination.total);
        });

      // inspirations disponibles
      fetch(
        API_URL +
          `/api/inspirations?populate[0]=titre&populate[1]=slug&filters[$or][0][titre][$containsi]=${search}&filters[$or][1][description][$containsi]=${search}`,
        {
          method: "GET",
          headers: {
            Accept: "Application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((response) => {
          // si au moins un résultat est retourné pour cette resource, alors on incrémente le nombre de colonne de 1
          if (response.data.length > 0) {
            colonne = colonne + 1;
          }
          setInspirations(response.data);
          setTotalRealisationsProductsResults(response.meta.pagination.total);
        });

      // notices disponibles
      fetch(
        API_URL +
          `/api/notices?populate[0]=titre&populate[1]=fichier&filters[titre][$containsi]=${search}`,
        {
          method: "GET",
          headers: {
            Accept: "Application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((response) => {
          // si au moins un résultat est retourné pour cette resource, alors on incrémente le nombre de colonne de 1
          if (response.data.length > 0) {
            colonne = colonne + 1;
          }

          // si au moins un résultat est retourné pour cette resource, alors on incrémente le nombre de colonne de 1
          if (response.data.length > 0) {
            colonne = colonne + 1;
          }

          // calculer la pertinence de chaque résultat
          const relevanceMap = new Map();
          response.data.forEach((result) => {
            const position = result.attributes.titre
              .toLowerCase()
              .indexOf(search.toLowerCase());
            relevanceMap.set(result.id, position);
          });

          // trier les résultats par pertinence croissante
          const sortedResults = response.data.sort((a, b) => {
            const relevanceA = relevanceMap.get(a.id);
            const relevanceB = relevanceMap.get(b.id);
            return relevanceA - relevanceB;
          });
          setNotices(response.data);
          setTotalNoticesResults(response.meta.pagination.total);
        });

      setRapidAccess([]);
      if (search.toLowerCase().includes("inspiration")) {
        setRapidAccess((rapidAccess) => [
          ...rapidAccess,
          { label: "Inspirations", url: "../inspirations" },
        ]);
      }
      if (
        search.toLowerCase().includes("realisation") ||
        search.toLowerCase().includes("réalisation")
      ) {
        setRapidAccess((rapidAccess) => [
          ...rapidAccess,
          { label: "Réalisations", url: "../realisations" },
        ]);
      }
      if (search.toLowerCase().includes("produit")) {
        setRapidAccess((rapidAccess) => [
          ...rapidAccess,
          { label: "Produits", url: "../produits" },
        ]);
      }
      if (search.toLowerCase().includes("notice")) {
        setRapidAccess((rapidAccess) => [
          ...rapidAccess,
          { label: "Notices", url: "../notices-et-tutos" },
        ]);
      }
    }
  }, [search]);

  // fonction de recherche
  // search => chaîne de caractère proposée
  function handleSearchTerm(search) {
    if (search.length > 1) {
      setSearch(search);
      openSearchBar();
    } else {
      setSearch("");
      closeSearchBar();
    }
    if (search.length === 0) {
      setSearch("");
      closeSearchBar();
    }
  }

  function openSearchBar() {
    setOverlay(true);
    document.documentElement.classList.add("noScroll");
  }

  function openSearchOnClick() {
    if (search && search.length > 1) setOverlay(true);
    document.documentElement.classList.add("noScroll");
  }

  function closeSearchBar() {
    setOverlay(false);
    document.documentElement.classList.remove("noScroll");
  }

  return (
    <div className="searchBox">
      <div className="searchBoxIcon">
        <input
          onChange={(e) => handleSearchTerm(e.target.value)}
          onClick={() => openSearchOnClick()}
          type="text"
          aria-label="Rechercher un produit sur le site"
          placeholder="Rechercher un produit, un type de magasin, une notice de montage..."
        ></input>
      </div>
      <div
        className={
          !overlay
            ? "resultOverlay resultOverlayClose"
            : "resultOverlay resultOverlayOpen"
        }
        onClick={(e) => closeSearchBar(e)}
      >
        <div
          className="resultBox"
          onClick={(e) => e.stopPropagation()}
          style={{
            height:
              totalCategorieResults +
                totalProductsResults +
                totalSousCategoriesResults +
                totalTypeProduitResults +
                totalRealisationsResults +
                totalUniversResults +
                totalInspirationsResults +
                totalNoticesResults !==
              0
                ? "75%"
                : "35%",
          }}
        >
          {rapidAccess.length > 0 && (
            <div className="rapidAccess">
              <p>Accès rapides : </p>
              {rapidAccess.map((button, index) => (
                <a key={index} href={button.url}>
                  {button.label}
                </a>
              ))}
            </div>
          )}
          {totalCategorieResults +
            totalProductsResults +
            totalSousCategoriesResults +
            totalTypeProduitResults +
            totalRealisationsResults +
            totalUniversResults +
            totalInspirationsResults +
            totalNoticesResults !==
          0 ? (
            <div className="resultList">
              <div className="resultListTypeproducts">
                {categories.length > 0 && (
                  <div className="resultCard">
                    <p className="resultCardTitle">CATÉGORIES</p>
                    {categories.map((categorie) => (
                      <a
                        key={"resultCard" + categorie.id}
                        href={
                          "/produits?id=" +
                          categorie.id +
                          "&filter=category" +
                          "&label=" +
                          categorie.attributes.titre
                        }
                      >
                        {categorie.attributes.titre}
                      </a>
                    ))}
                  </div>
                )}
                {sousCategories.length > 0 && (
                  <div className="resultCard">
                    <p className="resultCardTitle">SOUS CATÉGORIES</p>
                    {sousCategories.map((sousCategorie) => (
                      <a
                        key={"resultCard" + sousCategorie.id}
                        href={
                          "/produits?id=" +
                          sousCategorie.id +
                          "&filter=sous_category" +
                          "&label=" +
                          sousCategorie.attributes.titre
                        }
                      >
                        {sousCategorie.attributes.titre}
                      </a>
                    ))}
                  </div>
                )}
                {typeProduits.length > 0 && (
                  <div className="resultCard">
                    <p className="resultCardTitle">TYPES DE PRODUITS</p>
                    {typeProduits.map((typeProduit) => (
                      <a
                        key={"resultCard" + typeProduit.id}
                        href={
                          "/produits?id=" +
                          typeProduit.id +
                          "&filter=types_de_produit" +
                          "&label=" +
                          typeProduit.attributes.titre
                        }
                      >
                        {typeProduit.attributes.titre}
                      </a>
                    ))}
                  </div>
                )}
              </div>
              <div className="resultListUniversProduct">
                {univers.length > 0 && (
                  <div className="resultCard">
                    <a href="../univers" className="resultCardTitle">
                      UNIVERS
                    </a>
                    {univers.map((result) => (
                      <a
                        key={"resultCardTitle" + result.attributes.titre}
                        href={"/univer/" + result.attributes.slug}
                      >
                        {/* <img
                          src={
                            API_URL + result.attributes.icon.data.attributes.url
                          }
                          alt={result.attributes.titre}
                        /> */}
                        {result.attributes.titre}
                      </a>
                    ))}
                  </div>
                )}
                {products.length > 0 && (
                  <div className="resultCard">
                    <a href="../produits" className="resultCardTitle">
                      PRODUITS
                    </a>
                    {products.map((product) => (
                      <a
                        key={"resultCard" + product.id}
                        href={"/produit/" + product.attributes.slug}
                      >
                        <p className="resultCardRef">
                          {product.attributes.ref}
                        </p>
                        <p className="resultCardNom">
                          {product.attributes.nom}
                        </p>
                      </a>
                    ))}
                  </div>
                )}
              </div>
              <div className="resultListReaInspi">
                {realisations.length > 0 && (
                  <div className="resultCard">
                    <a href="../realisations" className="resultCardTitle">
                      RÉALISATIONS
                    </a>
                    {realisations.map((result) => (
                      <a
                        key={"resultCardTitle" + result.attributes.Titre}
                        href={"/realisation/" + result.attributes.slug}
                      >
                        {result.attributes.Titre}
                      </a>
                    ))}
                  </div>
                )}
                {inspirations.length > 0 && (
                  <div className="resultCard">
                    <a href="../inspirations" className="resultCardTitle">
                      INSPIRATIONS
                    </a>
                    {inspirations.map((result) => (
                      <a
                        key={"resultCardTitle" + result.attributes.titre}
                        href={"/inspiration/" + result.attributes.slug}
                      >
                        {result.attributes.titre}
                      </a>
                    ))}
                  </div>
                )}
                {notices.length > 0 && (
                  <div className="resultCard">
                    <a href="../notices-et-tutos" className="resultCardTitle">
                      NOTICES
                    </a>
                    {notices.map((result) => (
                      <a
                        key={"resultCardTitle" + result.attributes.titre}
                        target="_blank"
                        href={
                          API_URL +
                          result.attributes.fichier.data[0].attributes.url
                        }
                      >
                        {result.attributes.titre}
                      </a>
                    ))}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="noResult">
              <p>Aucun résultat pour cette recherche...</p>
            </div>
          )}
          <div className="showAllResult">
            <a href={"../recherches/" + search}>
              VOIR TOUT (
              {totalCategorieResults +
                totalProductsResults +
                totalSousCategoriesResults +
                totalTypeProduitResults +
                totalRealisationsResults +
                totalUniversResults +
                totalInspirationsResults +
                totalNoticesResults}
              &nbsp;RÉSULTATS)
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
