import { Grid, Pagination } from "@mui/material";
import React from "react";
import ImageOptimizer from "../utils/ImageOptimizer";
import DefaultLogo from "../../assets/image_produit_defaut_tiltequipement.svg";

export default function ResultsInspirations({
  inspirations,
  totalInspirationsResults,
  inspirationsPageCount,
  inspirationsPage,
  setInspirationsPage,
}) {
  function handlePagination(e) {
    window.scrollTo(0, 480);
    setInspirationsPage(e.target.textContent);
  }
  return (
    <div style={{ margin: "40px 0" }}>
      <h3>Les inspirations ({totalInspirationsResults})</h3>
      <Grid container spacing={2}>
        {inspirations.map((inspiration) => (
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <div className="rechercheResultCard">
              <a href={"../inspiration/" + inspiration.attributes.slug}>
                <section>
                  {inspiration.attributes.images.data ? (
                    <ImageOptimizer
                      formats={
                        inspiration.attributes.images.data[0].attributes.formats
                      }
                    />
                  ) : (
                    <img
                      src={DefaultLogo}
                      alt="Absence de visuel pour ce produit"
                    />
                  )}
                </section>
                <p style={{ marginTop: "10px" }}>
                  {inspiration.attributes.titre}
                </p>
              </a>
            </div>
          </Grid>
        ))}
      </Grid>
      <div className="pagination pt64">
        <Pagination
          onClick={(e) => handlePagination(e)}
          count={inspirationsPageCount}
          variant="outlined"
        />
      </div>
    </div>
  );
}
