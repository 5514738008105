import React from "react";
import { SvgIcon } from "@mui/material";

export const FavorisIcon = () => {
  return (
    <SvgIcon viewBox={"0 0 36 36"}>
      <path
        id="Ellipse_58"
        data-name="Ellipse 58"
        d="M18,1.3A16.7,16.7,0,0,0,6.191,29.809,16.7,16.7,0,1,0,29.809,6.191,16.591,16.591,0,0,0,18,1.3M18,0A18,18,0,1,1,0,18,18,18,0,0,1,18,0Z"
        transform="translate(4.577)"
      />
      <path
        id="Icon_ionic-md-heart-empty"
        data-name="Icon ionic-md-heart-empty"
        d="M17.33,4.5A5.693,5.693,0,0,0,13,6.531,5.693,5.693,0,0,0,8.668,4.5,5.233,5.233,0,0,0,3.375,9.826c0,3.678,3.271,6.63,8.227,11.183L13,22.267l1.4-1.259c4.955-4.553,8.227-7.5,8.227-11.183A5.233,5.233,0,0,0,17.33,4.5ZM13.591,19.736l-.194.18-.4.361-.4-.361-.194-.18A58.811,58.811,0,0,1,6.73,14.092,6.789,6.789,0,0,1,4.86,9.826a3.849,3.849,0,0,1,1.1-2.744,3.766,3.766,0,0,1,2.711-1.1,4.244,4.244,0,0,1,3.2,1.5L13,8.831l1.134-1.346a4.228,4.228,0,0,1,3.2-1.5,3.784,3.784,0,0,1,2.716,1.1,3.856,3.856,0,0,1,1.1,2.744,6.824,6.824,0,0,1-1.869,4.266A58.93,58.93,0,0,1,13.591,19.736Z"
        transform="translate(9.002 5.7)"
      />
    </SvgIcon>
  );
};

export default FavorisIcon;
