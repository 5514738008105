import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import API_URL from "../../Api";
import ProductCategorieCard from "./ProductCategorieCard";
import ArrowButton from "../Atom/ArrowButton";

export default function ProductsCategories({
  setIsLoading,
  setProductStep,
  filterMaster,
  setFilterMaster,
}) {
  const [categoriesAreLoading, setCategoriesAreLoading] = useState(true);
  const [categories, setCategories] = useState(null);

  useEffect(() => {
    // on fetch l'url d'action déini plus haut
    fetch(API_URL + "/api/categories?sort[0]=rank%3Aasc&populate=*", {
      method: "GET",
      headers: {
        Accept: "Application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setCategories(response.data);
        setCategoriesAreLoading(false);
      });
  }, []);

  return (
    <div className="productsCategories">
      <Container maxWidth="xl" className="productsCategoriesBox">
        <div className="back">
          <Container maxWidth="xl" sx={{ marginBottom: "20px" }}>
            {/* <Button
              onClick={() => {
                setProductStep(0);
                // stockage de la step en local storage
                // localStorage.setItem(
                //   "tiltStepProduct",
                //   JSON.stringify({
                //     ...JSON.parse(localStorage.getItem("tiltStepProduct")),
                //     step: 1,
                //   })
                // );
              }}
              startIcon={<ArrowBackIosNewIcon />}
              aria-label="Retour"
            >
              Retour
            </Button> */}
          </Container>
        </div>
        {!categoriesAreLoading && (
          <Grid container spacing={1}>
            {categories.map((categorie) => (
              <Grid
                item
                lg={4}
                md={4}
                sm={6}
                xs={12}
                key={"productsCategoriesBox" + categorie.id}
              >
                <ProductCategorieCard
                  setIsLoading={setIsLoading}
                  categorie={categorie}
                  filterMaster={filterMaster}
                  setFilterMaster={setFilterMaster}
                  setProductStep={setProductStep}
                />
              </Grid>
            ))}
          </Grid>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ArrowButton
            onClick={() => setProductStep(2)}
            content={"Découvrir tous les produits"}
            color="grey"
          />
        </div>
      </Container>
    </div>
  );
}
