import React, { useEffect, useState } from "react";
import API_URL from "../Api";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import ArrowButton from "../components/Atom/ArrowButton";
import Banner from "../components/molecules/Banner";
// import Avis from "../components/molecules/Avis";
import SubFooter from "../components/molecules/SubFooter";
import BesoinAide from "../components/molecules/BesoinAide";
import Ariane from "../components/molecules/Ariane";
import { MetaTags } from "react-meta-tags";
import PaginationProducts from "../components/products/PaginationProducts";
// import background from "../assets/showroom.jpg";
import AddToFavorites from "../components/molecules/AddToFavorites";
import ImageOptimizer from "../components/utils/ImageOptimizer";

export default function Realisations() {
  const [topRealisations, setTopRealisations] = useState();
  const [topRealisationsIsLoading, setTopRealisationsIsLoading] =
    useState(true);
  const [mozaique, setMozaique] = useState();
  const [realisations, setRealisations] = useState();
  const [realisationsIsLoading, setRealisationsIsLoading] = useState(true);
  const [univers, setUnivers] = useState();
  const [universIsLoading, setUniversIsLoading] = useState(true);

  // filtre d'affichage des réalisations
  const [filter, setFilter] = useState();

  // page active
  const [page, setPage] = useState(0);
  // nombre de page
  const [pageCount, setPageCount] = useState();
  // nombre de produits par page
  const [pageSize, setPageSize] = useState();
  // total de produits retournés
  const [total, setTotal] = useState();

  useEffect(() => {
    // on fetch les univers
    fetch(
      API_URL +
        "/api/realisations?populate[0]=Titre&populate[1]=slug&populate[2]=couverture&populate[3]=univer&populate[4]=description&sort[0]=updatedAt%3Adesc&pagination[page]=1&pagination[pageSize]=9",
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        setMozaique(response.data);
        setTopRealisations(response.data[0]);
        setTopRealisationsIsLoading(false);
      });
    // on fetch les réalisations
    let url =
      API_URL +
      `/api/realisations?populate[0]=Titre&populate[1]=slug&populate[2]=couverture&populate[3]=univer&sort[0]=createdAt%3Adesc&pagination[page]=${page}&pagination[pageSize]=9&pagination[withCount]=true`;
    if (filter) {
      url =
        API_URL +
        `/api/realisations?populate[0]=Titre&populate[1]=slug&populate[2]=couverture&populate[3]=univer&sort[0]=createdAt%3Adesc&pagination[page]=${page}&pagination[pageSize]=9&pagination[withCount]=true&filters[univer][titre][$eqi]=${filter}`;
    }
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "Application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setRealisations(response.data);
        setPage(response.meta.pagination.page);
        setPageCount(response.meta.pagination.pageCount);
        setPageSize(response.meta.pagination.pageSize);
        setTotal(response.meta.pagination.total);
        setRealisationsIsLoading(false);
      });
    // on fetch les univers
    fetch(
      API_URL +
        "/api/univers?sort[0]=rank%3Aasc&populate[0]=titre&populate[1]=icon",
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        setUnivers(response.data);
        setUniversIsLoading(false);
      });
  }, [filter, page]);

  const arianeItem = [
    ["/", "Tilt'Équipement"],
    ["/realisations", "Réalisations"],
  ];

  return (
    <React.Fragment>
      <Ariane arianeItem={arianeItem} />
      <MetaTags>
        <title>Tilt’ Équipement - Réalisations</title>
        <meta property="og:title" content={"Tilt’ Équipement - Réalisations"} />
        <meta property="title" content={"Tilt’ Équipement - Réalisations"} />
        <meta
          name="description"
          content={
            "Découvrez des aménagements réalisés par Tilt’Équipement, dont l’agencement d’un bureau tabac-presse, d’une épicerie, d’une pharmacie et de divers espaces d’accueil."
          }
        />
        <meta name="keywords" content={"Tilt'Équipement, Réalisations"} />
      </MetaTags>
      <div className="realisationsContainer">
        <div className="realisationBannerContainer">
          <Banner
            title={"Réalisations"}
            content={
              "Tilt’ Équipement vous présente quelques exemples de réalisations"
            }
          />
          {!topRealisationsIsLoading && (
            <React.Fragment>
              <div className="contentAbsoluteReaBanner bannerLeft">
                <div className="divContentAbsoluteBanner">
                  {mozaique[0] && (
                    <picture className="square">
                      <img
                        className="imgRealisation imgRea1"
                        src={
                          API_URL +
                          mozaique[0].attributes.couverture.data.attributes
                            .formats.small.url
                        }
                        alt={
                          mozaique[0].attributes.couverture.data.attributes.name
                        }
                      />
                    </picture>
                  )}
                  {mozaique[1] && (
                    <picture className="square">
                      <img
                        className="imgRealisation imgRea2"
                        src={
                          API_URL +
                          mozaique[1].attributes.couverture.data.attributes
                            .formats.small.url
                        }
                        alt={
                          mozaique[1].attributes.couverture.data.attributes.name
                        }
                      />
                    </picture>
                  )}
                </div>
                <div className="divContentAbsoluteBanner">
                  {mozaique[2] && (
                    <picture className="square">
                      <img
                        className="imgRealisation imgRea3"
                        src={
                          API_URL +
                          mozaique[2].attributes.couverture.data.attributes
                            .formats.small.url
                        }
                        alt={
                          mozaique[2].attributes.couverture.data.attributes.name
                        }
                      />
                    </picture>
                  )}
                  {mozaique[3] && (
                    <picture className="square">
                      <img
                        className="imgRealisation imgRea4"
                        src={
                          API_URL +
                          mozaique[3].attributes.couverture.data.attributes
                            .formats.small.url
                        }
                        alt={
                          mozaique[3].attributes.couverture.data.attributes.name
                        }
                      />
                    </picture>
                  )}
                </div>
              </div>
              <div className="contentAbsoluteReaBanner bannerRight">
                <div className="divContentAbsoluteBanner">
                  {mozaique[4] && (
                    <picture className="square">
                      <img
                        className="imgRealisation imgRea5"
                        src={
                          API_URL +
                          mozaique[4].attributes.couverture.data.attributes
                            .formats.small.url
                        }
                        alt={
                          mozaique[4].attributes.couverture.data.attributes.name
                        }
                      />
                    </picture>
                  )}
                  {mozaique[5] && (
                    <picture className="square">
                      <img
                        className="imgRealisation imgRea6"
                        src={
                          API_URL +
                          mozaique[5].attributes.couverture.data.attributes
                            .formats.small.url
                        }
                        alt={
                          mozaique[5].attributes.couverture.data.attributes.name
                        }
                      />
                    </picture>
                  )}
                </div>
                <div className="divContentAbsoluteBanner">
                  {mozaique[6] && (
                    <picture className="square">
                      <img
                        className="imgRealisation imgRea7"
                        src={
                          API_URL +
                          mozaique[6].attributes.couverture.data.attributes
                            .formats.small.url
                        }
                        alt={
                          mozaique[6].attributes.couverture.data.attributes.name
                        }
                      />
                    </picture>
                  )}
                  {mozaique[7] && (
                    <picture className="square">
                      <img
                        className="imgRealisation imgRea8"
                        src={
                          API_URL +
                          mozaique[7].attributes.couverture.data.attributes
                            .formats.small.url
                        }
                        alt={
                          mozaique[7].attributes.couverture.data.attributes.name
                        }
                      />
                    </picture>
                  )}
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
        <section className="firstRealisationBox">
          <Container maxWidth="xl">
            <Grid container>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <div className="firstRealisationLeft">
                  {!topRealisationsIsLoading && (
                    <div className="firstRealisationLeftDiv">
                      <div className="containBannerAddToFav">
                        <p className="pretitre color1">Dernière réalisation</p>
                        <div className="bannerAddToFav">
                          <AddToFavorites
                            label={"realisations"}
                            content={topRealisations}
                          />
                        </div>
                      </div>
                      <h2>{topRealisations.attributes.Titre}</h2>
                      <p className="firstRealisationLeftDescription">
                        {topRealisations.attributes.description}
                      </p>
                      <ArrowButton
                        href={"/realisation/" + topRealisations.attributes.slug}
                        content="Découvrir"
                      />
                    </div>
                  )}
                </div>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <div className="firstRealisationRight">
                  {!topRealisationsIsLoading && (
                    <ImageOptimizer
                      formats={
                        topRealisations.attributes.couverture.data.attributes
                          .formats
                      }
                      requireFormat="large"
                    />
                  )}
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>
        <Container maxWidth="xl" className="realisationsUniversFilterBox">
          <div className="realisationsUniversFilter">
            {!universIsLoading && (
              <React.Fragment>
                {univers.map((univer) => (
                  <div
                    className={
                      univer.attributes.titre === filter
                        ? "realisationsUniversFilterCard selectedUniverFilter"
                        : "realisationsUniversFilterCard"
                    }
                    key={"FilterCard" + univer.id}
                    onClick={() =>
                      univer.attributes.titre === filter
                        ? setFilter()
                        : setFilter(univer.attributes.titre)
                    }
                  >
                    <img
                      src={API_URL + univer.attributes.icon.data.attributes.url}
                      alt={univer.attributes.titre}
                    />
                    <p>{univer.attributes.titre}</p>
                  </div>
                ))}
              </React.Fragment>
            )}
          </div>
        </Container>

        <Container
          maxWidth="xl"
          className="realisationsUniversGaleryBox"
          sx={{ marginBottom: "40px" }}
        >
          <Grid spacing={1} container className="realisationsUniversGalery">
            {!realisationsIsLoading && (
              <React.Fragment>
                {realisations ? (
                  <React.Fragment>
                    {realisations.map((realisation) => (
                      <Grid
                        item
                        lg={4}
                        md={4}
                        sm={6}
                        xs={12}
                        className="realisationsUniversGaleryCard"
                        key={"UniversGaleryCard" + realisation.id}
                      >
                        <div className="bannerAddToFav">
                          <AddToFavorites
                            label={"realisations"}
                            content={realisation}
                          />
                        </div>
                        <a href={"/realisation/" + realisation.attributes.slug}>
                          <div className="realisationsUniversGaleryCardImg">
                            <ImageOptimizer
                              formats={
                                realisation.attributes.couverture.data
                                  .attributes.formats
                              }
                              requireFormat="medium"
                            />
                          </div>
                          <div className="realisationsUniversGaleryCardText">
                            {realisation.attributes.univer.data && (
                              <span>
                                {
                                  realisation.attributes.univer.data.attributes
                                    .titre
                                }
                              </span>
                            )}
                            <p>{realisation.attributes.Titre}</p>
                          </div>
                        </a>
                      </Grid>
                    ))}
                  </React.Fragment>
                ) : (
                  <p>Aucune réalisation disponible...</p>
                )}
              </React.Fragment>
            )}
          </Grid>
          <PaginationProducts
            pageCount={pageCount}
            page={page}
            setPage={setPage}
          />
        </Container>
        {/* <section className="realisationsAvisBox">
          <div className="realisationsAvis">
            <Avis />
          </div>
        </section> */}
        <SubFooter
          titleLeft="Travailler en toute sérénité"
          descriptionLeft="Des rapports simples avec notre clientèle et une efficacité au quotidien"
          contentbtnLeft="Voir nos services"
          hrefRight={"../visite-virtuelle"}
          hrefLeft={"../services"}
          isRight
          titleRight="Visite virtuelle"
          descriptionRight="Nos salles d’exposition à parcourir depuis votre ordinateur"
          contentbtnRight="Découvrir"
        />
        <BesoinAide />
      </div>
    </React.Fragment>
  );
}
