import React from "react";
import Grid from "@mui/material/Grid";
import ArrowButton from "../Atom/ArrowButton";
import { AnimationOnScroll } from "react-animation-on-scroll";
import background from "../../assets/projet-picture.jpg";

export default function SubFooter({
  titleLeft,
  descriptionLeft,
  hrefLeft,
  contentbtnLeft,
  citation,
  titleRight,
  descriptionRight,
  hrefRight,
  contentbtnRight,
  isRight,
  backgroundCustom,
}) {
  return (
    <AnimationOnScroll animateIn="animate__fadeIn" className="subFooter">
      <Grid container>
        <Grid
          item
          lg={isRight || citation ? 8 : 12}
          md={isRight || citation ? 8 : 12}
          sm={12}
          xs={12}
          className="subFooterLeft"
          style={
            backgroundCustom
              ? { backgroundImage: `url(${backgroundCustom})` }
              : { backgroundImage: `url(${background})` }
          }
        >
          <div className="subFooterIndex">
            <h2>{titleLeft}</h2>
            <p>{descriptionLeft}</p>
            <ArrowButton
              href={hrefLeft}
              color={"grey"}
              content={contentbtnLeft}
            />
          </div>
        </Grid>
        {citation && (
          <Grid
            item
            lg={4}
            md={4}
            sm={12}
            xs={12}
            className="subFooterRightCitation"
          >
            <div className="subFooterIndex">
              <p>
                « Il n’y a pas de petit projet. Chaque client a son
                importance. »
              </p>
            </div>
          </Grid>
        )}
        {isRight && (
          <Grid item lg={4} md={4} sm={12} xs={12} className="subFooterRight">
            <div className="subFooterIndex">
              <h2>{titleRight}</h2>
              <p>{descriptionRight}</p>
              <ArrowButton
                href={hrefRight}
                color={"grey"}
                content={contentbtnRight}
              />
            </div>
          </Grid>
        )}
      </Grid>
    </AnimationOnScroll>
  );
}
