import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { IconButton } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import API_URL from "../../Api";
import AssociatesProductsSkelet from "../skeletons/AssociatesProductsSkelet";
import AssociateProductCard from "./AssociateProductCard";

export default function AssociateProducts({ products }) {

  return (
    <React.Fragment>
      <div className="associateProductsContainer">
        <section className="associateProductsHeader">
          <h3>Produits associés</h3>
        </section>
        <Swiper
          className="associateProductsSlider"
          spaceBetween={30}
          slidesPerView={4}
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            425: {
              slidesPerView: 2,
            },
            576: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            981: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
            1440: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
          }}
        >
          {products.map((product) => (
            <SwiperSlide
              className="associateProductCard"
              key={"associateProductCard" + product.id}
            >
              <AssociateProductCard product={product} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </React.Fragment>
  );
}
