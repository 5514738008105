import React, { useEffect, useState } from "react";
import API_URL from "../Api";
import SubFooter from "../components/molecules/SubFooter";
import ProductView from "../components/products/ProductView";
import ProductViewSkelet from "../components/skeletons/ProductViewSkelet";
import MetaTags from "react-meta-tags";
import { useParams } from "react-router-dom";
import MetaMaster from "../components/utils/MetaMaster";

export default function Product() {
  const slug = useParams().slug;
  const [isLoading, setIsLoading] = useState(true);
  const [product, setProduct] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetch(
      `${API_URL}/api/produits?filters[slug][$eq]=${slug}&populate[0]=notices.fichier&populate[1]=images&populate[2]=nuances.visuel&populate[3]=nuances_1.visuel&populate[4]=nuances_2.visuel&populate[5]=nuances_3.visuel&populate[6]=nuances_4.visuel&populate[7]=univers&populate[8]=category&populate[9]=tutos.vignette&populate[10]=catalogues.fichier&populate[11]=produits_associes.images&populate[12]=catalogues.couverture&populate[13]=declinaisons.caracteristiques&populate[14]=sous_category&populate[15]=produits_associes.sous_category&populate[16]=partie_1&populate[17]=partie_2&populate[18]=caracteristiques&populate[19]=parties_modifiables.nuances.visuel&populate[20]=notices.couverture&populate[21]=hauteur&populate[22]=longueur&populate[23]=largeur&populate[24]=profondeur&populate[25]=diametre&populate[26]=epaisseur&populate[27]=poids&populate[28]=charge&populate[29]=nuanciers.fichier&populate[30]=detail&populate[31]=attribut_sup&populate[32]=declinaisons.largeur&populate[33]=declinaisons.hauteur&populate[34]=declinaisons.longueur&populate[35]=nuanciers.titre&populate[36]=nuanciers.couverture`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        setProduct(response.data[0]);
        setIsLoading(false);
      });
  }, [slug]);

  return (
    <React.Fragment>
      {!isLoading && (
        <MetaTags>
          {product.attributes.metaTitre ? (
            <title>
              {"Tilt’ Équipement - " + product.attributes.metaTitre}
            </title>
          ) : (
            <title>{"Tilt’ Équipement - " + product.attributes.nom}</title>
          )}
          {product.attributes.metaTitre ? (
            <>
              <meta
                property="og:title"
                content={"Tilt' Équipement - " + product.attributes.metaTitre}
              />
              <meta
                property="title"
                content={"Tilt' Équipement - " + product.attributes.metaTitre}
              />
            </>
          ) : (
            <>
              <meta
                property="og:title"
                content={"Tilt' Équipement - " + product.attributes.nom}
              />
              <meta
                property="title"
                content={"Tilt' Équipement - " + product.attributes.nom}
              />
            </>
          )}
          {product.attributes.metaDescription && (
            <>
              <meta
                name="description"
                content={product.attributes.metaDescription}
              />
              <meta
                name="og:description"
                content={product.attributes.metaDescription}
              />
            </>
          )}
          {product.attributes.images.data && (
            <meta
              property="og:image"
              content={
                API_URL + product.attributes.images.data[0].attributes.url
              }
            />
          )}
          <meta
            name="keywords"
            content={
              "Tilt'Équipement, Produits, Produit, " + product.attributes.nom
            }
          />
          <meta
            name="og:url"
            content={
              "https://tilt-equipement.fr/produit/" + product.attributes.slug
            }
          />
        </MetaTags>
      )}

      {isLoading ? <ProductViewSkelet /> : <ProductView product={product} />}
      <SubFooter
        titleLeft="Créez votre agencement"
        descriptionLeft="Demandez votre devis et concevons ensemble votre projet de demain."
        hrefLeft={"../devis"}
        contentbtnLeft="Demande de devis"
        isRight
        titleRight="Une question ?"
        descriptionRight="Trouvez les réponses aux questions les plus fréquemment posées"
        hrefRight={"../faq"}
        contentbtnRight="FAQ"
      />
    </React.Fragment>
  );
}
