import React, { useEffect, useState } from "react";
import API_URL from "../Api";
import Ariane from "../components/molecules/Ariane";
import Banner from "../components/molecules/Banner";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import universIcon from "../assets/categorie.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { MetaTags } from "react-meta-tags";
import ImageOptimizer from "../components/utils/ImageOptimizer";

export default function Catalogues() {
  const [isLoading, setIsLoading] = useState(true);
  const [catalogues, setCatalogues] = useState();
  const [arianeItem, setArianeItem] = useState([]);

  // barre de recherche
  const [typeFilterOpen, setTypeFilterOpen] = useState(false);

  // liste des résultats de recherche
  const [results, setResults] = useState([]);

  useEffect(() => {
    fetch(
      `${API_URL}/api/catalogues?sort[0]=rank%3Aasc&populate=*&filters[cible][$eq]=client`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        setCatalogues(response.data);
        setResults(response.data);
        setIsLoading(false);
        setArianeItem([
          ["/", "Tilt'Équipement"],
          ["/catalogues", "Catalogues"],
        ]);
      });

    // récupère la liste des univers disponibles
    // fetch(
    //   API_URL +
    //     "/api/univers?sort[0]=rank%3Aasc&populate[0]=titre&populate[1]=icon",
    //   {
    //     method: "GET",
    //     headers: {
    //       Accept: "Application/json",
    //     },
    //   }
    // )
    //   .then((res) => res.json())
    //   .then((response) => {
    //     setUnivers(response.data);
    //     setUniversIsLoading(false);
    //   });

    // récupère la liste des catégories disponibles
    // fetch(API_URL + "/api/categories?sort[0]=rank%3Aasc", {
    //   method: "GET",
    //   headers: {
    //     Accept: "Application/json",
    //   },
    // })
    //   .then((res) => res.json())
    //   .then((response) => {
    //     setCategorieIsLoading(false);
    //     setCategories(response);
    //   });
  }, []);

  function openUniversFilter() {
    setTypeFilterOpen(true);
  }
  function closeUniversFilter() {
    setTypeFilterOpen(false);
  }

  // traitement de la recherche lorsque l'utilisateur entre une nouvelle recherche
  function searchTreat(search) {
    // on lance la recherche au bout de 2 caractère proposés
    if (search.length > 1) {
      var tempo = catalogues.filter((item) => {
        // recherche en minuscule pour ignorer la casse
        return item.attributes.titre
          .toLowerCase()
          .includes(search.toLowerCase());
      });
      // incrémente le résultat
      setResults(tempo);
    } else {
      setResults(catalogues);
    }
  }

  // modification du filtre de recherche
  function handleFilter(filter, content) {
    switch (content) {
      case "Catalogue généraux":
        setResults(
          catalogues.filter(
            (catalogue) => catalogue.attributes.type === "général"
          )
        );
        break;
      case "Catalogue produits":
        setResults(
          catalogues.filter(
            (catalogue) => catalogue.attributes.type === "produits"
          )
        );
        break;
      case "Catalogue univers":
        setResults(
          catalogues.filter(
            (catalogue) => catalogue.attributes.type === "univers"
          )
        );
        break;
      default:
        setResults(catalogues);
    }
    setTypeFilterOpen(false);
  }

  return (
    <React.Fragment>
      {!isLoading && (
        <React.Fragment>
          <MetaTags>
            <title>Tilt’ Équipement - Catalogues</title>
            <meta
              property="og:title"
              content={"Tilt’ Équipement - Catalogues"}
            />
            <meta property="title" content={"Tilt’ Équipement - Catalogues"} />
            <meta
              name="description"
              content={
                "Retrouvez nos différents catalogues en accès libre et téléchargeables. Feuilletez nos  catalogues généraux, nos catalogues produits et nos catalogues univers."
              }
            />
            <meta
              name="og:description"
              content={
                "Retrouvez nos différents catalogues en accès libre et téléchargeables. Feuilletez nos  catalogues généraux, nos catalogues produits et nos catalogues univers."
              }
            />
            <meta name="keywords" content={"Tilt'Équipement, Catalogues"} />
            <meta
              name="og:url"
              content={"https://tilt-equipement.fr/catalogues"}
            />
          </MetaTags>
          <Ariane arianeItem={arianeItem} />
          <Banner title="Nos catalogues" />
        </React.Fragment>
      )}
      <Container maxWidth="xl" className="">
        <div className="cataloguesContainer pb120">
          <Grid container>
            <div className="catalogueSearchBar">
              <section>
                <div className="searchCatalogueBarBox">
                  <input
                    className="searchCatalogueBarInput"
                    onInput={(e) => searchTreat(e.target.value)}
                    onKeyUp={(e) => searchTreat(e.target.value)}
                    onChange={(e) => searchTreat(e.target.value)}
                    type="search"
                    placeholder="Rechercher un produit, une référence, une nuance..."
                  ></input>
                </div>
                <div className="containerBtnUniversCategories">
                  <div
                    className="btnUniversFiltersSearch"
                    onClick={
                      !typeFilterOpen ? openUniversFilter : closeUniversFilter
                    }
                  >
                    <img
                      className="imgUniversIcon"
                      src={universIcon}
                      alt={"Tilt'Équipement"}
                      loading="lazy"
                    />
                    <div>
                      <p>TYPE</p>
                      {!typeFilterOpen ? (
                        <KeyboardArrowDownIcon />
                      ) : (
                        <KeyboardArrowDownIcon className="turnArrow" />
                      )}
                    </div>
                  </div>
                </div>
              </section>
              <div
                className={
                  !typeFilterOpen
                    ? "universFilterList"
                    : "universFilterList universFilterListOpen"
                }
              >
                <div
                  className="universFilterListCard"
                  onClick={() => handleFilter("type", "Catalogue généraux")}
                >
                  <p className="universFilterListTitle">Catalogue généraux</p>
                </div>
                <div
                  className="universFilterListCard"
                  onClick={() => handleFilter("type", "Catalogue produits")}
                >
                  <p className="universFilterListTitle">Catalogues produits</p>
                </div>
                <div
                  className="universFilterListCard"
                  onClick={() => handleFilter("type", "Catalogue univers")}
                >
                  <p className="universFilterListTitle">Catalogues univers</p>
                </div>
                {/* {!universIsLoading && (
                  <React.Fragment>
                    {univers.map((univer) => (
                      <div
                        className="universFilterListCard"
                        key={"universFilter" + univer.attributes.titre}
                        onClick={() => handleFilter("univer", univer)}
                      >
                        <div className="universFilterListCardImg">
                          {univer.attributes.icon.data && (
                            <img
                              src={
                                API_URL +
                                univer.attributes.icon.data.attributes.url
                              }
                              alt={univer.attributes.icon.data.attributes.name}
                            />
                          )}
                        </div>
                        <p className="universFilterListTitle">
                          {univer.attributes.titre.toUpperCase()}
                        </p>
                      </div>
                    ))}
                  </React.Fragment>
                )} */}
              </div>
            </div>
          </Grid>
          <Grid container>
            {!isLoading && (
              <React.Fragment>
                {results.find(
                  (result) => result.attributes.type === "mise en avant"
                ) && (
                  <Grid item xs={12}>
                    <div className="cataloguesTypeBox cataloguesTypeBoxMiseAvant">
                      <Grid container className="cataloguesGrid">
                        {results.map((catalogue) => (
                          <React.Fragment>
                            {catalogue.attributes.type === "mise en avant" && (
                              <Grid
                                item
                                lg={4}
                                md={4}
                                sm={6}
                                xs={12}
                                className="pt24 pb24"
                              >
                                <Link
                                  href={
                                    API_URL +
                                    catalogue.attributes.fichier.data[0]
                                      .attributes.url
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <div>
                                    <ImageOptimizer
                                      formats={
                                        catalogue.attributes.couverture.data
                                          .attributes.formats
                                      }
                                    />
                                    <span>
                                      {catalogue.attributes.soustitre.toUpperCase()}
                                    </span>
                                    <p className="bold">
                                      {catalogue.attributes.titre}
                                    </p>
                                  </div>
                                </Link>
                              </Grid>
                            )}
                          </React.Fragment>
                        ))}
                      </Grid>
                    </div>
                  </Grid>
                )}
              </React.Fragment>
            )}
          </Grid>
          <Grid container>
            {results[0] ? (
              <React.Fragment>
                {!isLoading && (
                  <React.Fragment>
                    {results.find(
                      (result) => result.attributes.type === "général"
                    ) && (
                      <Grid item xs={12}>
                        <div className="cataloguesTypeBox">
                          <h3>Catalogue généraux</h3>
                          <Grid container className="cataloguesGrid">
                            {results.map((catalogue) => (
                              <React.Fragment>
                                {catalogue.attributes.type === "général" && (
                                  <Grid
                                    item
                                    lg={3}
                                    md={3}
                                    sm={6}
                                    xs={12}
                                    className="pt24 pb24"
                                  >
                                    <Link
                                      href={
                                        API_URL +
                                        catalogue.attributes.fichier.data[0]
                                          .attributes.url
                                      }
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <div>
                                        <ImageOptimizer
                                          formats={
                                            catalogue.attributes.couverture.data
                                              .attributes.formats
                                          }
                                        />
                                        <span>
                                          {catalogue.attributes.soustitre.toUpperCase()}
                                        </span>
                                        <p className="bold">
                                          {catalogue.attributes.titre}
                                        </p>
                                      </div>
                                    </Link>
                                  </Grid>
                                )}
                              </React.Fragment>
                            ))}
                          </Grid>
                        </div>
                      </Grid>
                    )}
                  </React.Fragment>
                )}
                {!isLoading && (
                  <React.Fragment>
                    {results.find(
                      (result) => result.attributes.type === "produits"
                    ) && (
                      <Grid item xs={12}>
                        <div className="cataloguesTypeBox">
                          <h3>Catalogues produits</h3>
                          <Grid container className="cataloguesGrid">
                            {results.map((catalogue) => (
                              <React.Fragment>
                                {catalogue.attributes.type === "produits" && (
                                  <Grid
                                    item
                                    lg={3}
                                    md={3}
                                    sm={6}
                                    xs={12}
                                    className="pt24 pb24"
                                  >
                                    <Link
                                      href={
                                        API_URL +
                                        catalogue.attributes.fichier.data[0]
                                          .attributes.url
                                      }
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <div>
                                        <ImageOptimizer
                                          formats={
                                            catalogue.attributes.couverture.data
                                              .attributes.formats
                                          }
                                        />
                                        <span>
                                          {catalogue.attributes.soustitre.toUpperCase()}
                                        </span>
                                        <p className="bold">
                                          {catalogue.attributes.titre}
                                        </p>
                                      </div>
                                    </Link>
                                  </Grid>
                                )}
                              </React.Fragment>
                            ))}
                          </Grid>
                        </div>
                      </Grid>
                    )}
                  </React.Fragment>
                )}
                {!isLoading && (
                  <React.Fragment>
                    {results.find(
                      (result) => result.attributes.type === "univers"
                    ) && (
                      <Grid item xs={12}>
                        <div className="cataloguesTypeBox">
                          <h3>Catalogues univers</h3>

                          <Grid container className="cataloguesGrid">
                            {results.map((catalogue) => (
                              <React.Fragment>
                                {catalogue.attributes.type === "univers" && (
                                  <Grid
                                    item
                                    lg={3}
                                    md={3}
                                    sm={6}
                                    xs={12}
                                    className="pt24 pb24"
                                  >
                                    <Link
                                      href={
                                        API_URL +
                                        catalogue.attributes.fichier.data[0]
                                          .attributes.url
                                      }
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <div>
                                        <ImageOptimizer
                                          formats={
                                            catalogue.attributes.couverture.data
                                              .attributes.formats
                                          }
                                        />
                                        <span>
                                          {catalogue.attributes.soustitre.toUpperCase()}
                                        </span>
                                        <p className="bold">
                                          {catalogue.attributes.titre}
                                        </p>
                                      </div>
                                    </Link>
                                  </Grid>
                                )}
                              </React.Fragment>
                            ))}
                          </Grid>
                        </div>
                      </Grid>
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            ) : (
              <p style={{ padding: "124px 0" }}>Aucun résultat...</p>
            )}
          </Grid>
        </div>
      </Container>
    </React.Fragment>
  );
}
