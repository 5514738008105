import React, { useEffect, useState } from "react";
import API_URL from "../Api";
import Ariane from "../components/molecules/Ariane";
import Banner from "../components/molecules/Banner";
import CloseIcon from "@mui/icons-material/Close";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import categorie from "../assets/categorie.svg";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import universIcon from "../assets/univers.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { MetaTags } from "react-meta-tags";
import ImageOptimizer from "../components/utils/ImageOptimizer";

export default function NoticesEtTutos() {
  const [notices, setNotices] = useState();
  const [noticesAreLoading, setNoticesAreLoading] = useState(true);
  const [showNotices, setShowNotices] = useState(true);
  const [tutos, setTutos] = useState();
  const [tutosAreLoading, setTutosAreLoading] = useState(true);
  const [showTutos, setShowTutos] = useState(true);

  const [arianeItem, setArianeItem] = useState([]);

  // affichage de l'iframe de vidéo
  const [showVideo, setShowVideo] = useState(false);
  const [videoId, setVideoId] = useState();

  // barre de recherche
  const [universFilterOpen, setUniversFilterOpen] = useState(false);
  const [categoryFilterOpen, setCategoryFilterOpen] = useState(false);
  const [univers, setUnivers] = useState(null);
  const [universIsLoading, setUniversIsLoading] = useState(true);
  const [categories, setCategories] = useState(null);
  const [categorieIsLoading, setCategorieIsLoading] = useState(true);

  const [filter, setFilter] = useState();
  const [searchTerms, setSearchTerms] = useState();

  // liste des résultats de recherche
  const [resultsNotices, setResultsNotices] = useState([]);
  const [resultsTutos, setResultsTutos] = useState([]);

  useEffect(() => {
    // on récupère les notices de montage
    let urlNotices = `${API_URL}/api/notices?sort[0]=rank%3Aasc&populate=*&filters[cible][$eq]=client`;
    if (filter) {
      urlNotices += `&filters[category][titre][$contains]=${filter.content}`;
    }
    if (searchTerms) {
      urlNotices += `&filters[titre][$containsi]=${searchTerms}`;
    }
    fetch(urlNotices, {
      method: "GET",
      headers: {
        Accept: "Application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setNotices(response.data);
        setResultsNotices(response.data);
        setNoticesAreLoading(false);
      });

    // on récupère les tutos de montage
    let urlTutos = `${API_URL}/api/tutos?sort[0]=rank%3Aasc&populate=*&filters[cible][$eq]=client`;
    if (filter) {
      urlTutos += `&filters[categories][titre][$contains]=${filter.content}`;
    }
    if (searchTerms) {
      urlNotices += `&filters[titre][$containsi]=${searchTerms}`;
    }
    fetch(urlTutos, {
      method: "GET",
      headers: {
        Accept: "Application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setTutos(response.data);
        setResultsTutos(response.data);
        setTutosAreLoading(false);
        setArianeItem([
          ["/", "Tilt'Équipement"],
          ["/notices-et-tutos", "Notices de montage et tutos"],
        ]);
      });

    // récupère la liste des catégories disponibles
    fetch(API_URL + "/api/categories?sort[0]=rank%3Aasc", {
      method: "GET",
      headers: {
        Accept: "Application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setCategorieIsLoading(false);
        setCategories(response.data);
      });
  }, [filter, searchTerms]);

  function openUniversFilter() {
    setUniversFilterOpen(true);
    setCategoryFilterOpen(false);
  }
  function closeUniversFilter() {
    setUniversFilterOpen(false);
  }
  function openCategoryFilter() {
    setCategoryFilterOpen(true);
    setUniversFilterOpen(false);
  }
  function closeCategoryFilter() {
    setCategoryFilterOpen(false);
  }

  // traitement de la recherche lorsque l'utilisateur entre une nouvelle recherche
  function searchTreat(search) {
    // on lance la recherche au bout de 2 caractère proposés
    if (search.length > 1) {
      setSearchTerms(search);
    } else {
      setSearchTerms();
    }
  }

  // modification du filtre de recherche
  function handleFilter(filter, content) {
    setFilter({
      filter: "categories",
      content,
    });
  }

  // iframe video
  function handleIframe(url) {
    setVideoId(url.split("/").reverse()[0]);
    setShowVideo(true);
  }

  function closeIframe() {
    setVideoId();
    setShowVideo(false);
  }

  return (
    <React.Fragment>
      <Ariane arianeItem={arianeItem} />
      <Banner title="Notices de montage et tutos" />
      <MetaTags>
        <title>Tilt’ Équipement - Notices de montage et tutos</title>
        <meta
          property="og:title"
          content={"Tilt’ Équipement - Notices de montage et tutos"}
        />
        <meta
          property="title"
          content={"Tilt’ Équipement - Notices de montage et tutos"}
        />
        <meta
          name="description"
          content={
            "Tilt’ Équipement met à votre disposition les notices de montage de ces produits et vous propose des tutoriels d’installation et d’utilisation."
          }
        />
        <meta
          name="og:description"
          content={
            "Tilt’ Équipement met à votre disposition les notices de montage de ces produits et vous propose des tutoriels d’installation et d’utilisation."
          }
        />
        <meta
          name="keywords"
          content={"Tilt'Équipement, Devis, Notices, Montage, Tutos"}
        />
        <meta
          name="og:url"
          content={"https://tilt-equipement.fr/notices-et-tutos"}
        />
      </MetaTags>
      <Container maxWidth="xl" className="">
        <div className="NoticesTutosContainer pb120">
          <Grid container spacing={2}>
            <div className="NoticesTutosSearchBar">
              <section>
                <div className="searchNoticesTutosBarBox">
                  <input
                    className="searchNoticesTutosBarInput"
                    onInput={(e) => searchTreat(e.target.value)}
                    onKeyUp={(e) => searchTreat(e.target.value)}
                    onChange={(e) => searchTreat(e.target.value)}
                    type="search"
                    placeholder="Rechercher un produit, une référence, une nuance..."
                  ></input>
                </div>
                <div className="containerBtnUniversCategories">
                  <div
                    className="btnUniversFiltersSearch"
                    onClick={
                      !categoryFilterOpen
                        ? openCategoryFilter
                        : closeCategoryFilter
                    }
                  >
                    <img
                      className="imgUniversIcon"
                      src={categorie}
                      alt={"Tilt'Équipement"}
                      loading="lazy"
                    />
                    <div>
                      <p>TYPE</p>
                      {!categoryFilterOpen ? (
                        <KeyboardArrowDownIcon />
                      ) : (
                        <KeyboardArrowDownIcon className="turnArrow" />
                      )}
                    </div>
                  </div>
                </div>
              </section>
              <div
                className={
                  !categoryFilterOpen
                    ? "universFilterList"
                    : "universFilterList universFilterListOpen"
                }
              >
                {!categorieIsLoading && (
                  <React.Fragment>
                    {categories.map((categorie, index) => (
                      <div
                        key={index}
                        className="universFilterListCard"
                        onClick={() =>
                          showTutos
                            ? handleFilter("notice", categorie.attributes.titre)
                            : handleFilter("", "")
                        }
                        style={{
                          background: showNotices && "#f9f7f4",
                          borderRadius: showNotices && 8,
                          padding: "0 20px",
                        }}
                      >
                        <p className="universFilterListTitle">
                          {categorie.attributes.titre}
                        </p>
                      </div>
                    ))}
                  </React.Fragment>
                )}
              </div>
            </div>
          </Grid>
          <Grid container spacing={2} className="position-relative">
            {!noticesAreLoading && resultsNotices[0] && showNotices && (
              <Grid item xs={12}>
                <div className="NoticesTypeBox">
                  <h3>Notices de montage</h3>
                  <Grid container spacing={2} className="NoticesGrid">
                    {resultsNotices.map((notice) => (
                      <Grid
                        item
                        lg={4}
                        md={4}
                        sm={6}
                        xs={12}
                        key={"notice" + notice.attributes.titre + notice.id}
                      >
                        <a
                          download
                          target="_blank"
                          href={
                            API_URL +
                            notice.attributes.fichier.data[0].attributes.url
                          }
                        >
                          <div>
                            <div
                              style={{ border: "2px solid #f9f7f4", margin: 0 }}
                            >
                              <ImageOptimizer
                                formats={
                                  notice.attributes.couverture.data.attributes
                                    .formats
                                }
                              />
                            </div>

                            {/* <span>NOTICE DE MONTAGE</span> */}
                            <p>
                              {/* {notice.attributes.titre.slice(0, 30) + "..."} */}
                              {notice.attributes.titre}
                            </p>
                          </div>
                        </a>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              </Grid>
            )}
            {!tutosAreLoading && resultsTutos && showTutos && (
              <Grid item xs={12}>
                <div className="NoticesTypeBox NoticesTypeBoxTuto">
                  <h3>Tutos</h3>
                  <Grid container spacing={2} className="NoticesGrid">
                    {resultsTutos.map((tuto) => (
                      <Grid
                        item
                        lg={4}
                        md={4}
                        sm={6}
                        xs={12}
                        key={"tuto" + tuto.attributes.titre + tuto.id}
                        onClick={() => handleIframe(tuto.attributes.url)}
                      >
                        {tuto.attributes.vignette.data ? (
                          <ImageOptimizer
                            formats={
                              tuto.attributes.vignette.data.attributes.formats
                            }
                            requireFormat="small"
                          />
                        ) : (
                          <div className="tutoPlayerIcon">
                            <PlayArrowIcon />
                          </div>
                        )}
                        {/* <span>TUTOS</span> */}
                        {/* <p>{tuto.attributes.titre.slice(0, 30) + "..."}</p> */}
                        <p>{tuto.attributes.titre}</p>
                      </Grid>
                    ))}
                    <Grid item lg={12} md={12} sm={12} xs={12}></Grid>
                  </Grid>
                </div>
              </Grid>
            )}
          </Grid>
        </div>
        <div
          className={
            showVideo && videoId
              ? "videoIframeOverlay showIframe"
              : "videoIframeOverlay "
          }
          onClick={(e) => closeIframe(e)}
        >
          <iframe
            src={
              "https://www.youtube.com/embed/" +
              videoId +
              "?autoplay=1&modestbranding=1&color=white&rel=0"
            }
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <div className="closeVideoIframe" onClick={closeIframe}>
            <CloseIcon />
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
}
