import React, { useEffect, useState } from "react";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { Button, IconButton } from "@mui/material";
import facebook from "../../assets/icon-feather-facebook.svg";
import linkedin from "../../assets/icon-feather-linkedin.svg";
import youtube from "../../assets/icon-feather-youtube.svg";
import { AnimationOnScroll } from "react-animation-on-scroll";
import API_URL from "../../Api";

export default function Footer() {
  const [contact, setContact] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch(`${API_URL}/api/fiche-contact?populate=*`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setContact(response.data.attributes);
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="footer">
      <Container maxWidth="xl">
        {window.location.href.split("/")[3] !== "espace-revendeur" && (
          <Box sx={{ flexGrow: 1, marginBottom: "16px" }}>
            <Grid
              container
              justifyContent="space-between"
              spacing={2}
              className="container-grid-footer"
            >
              <Grid item xs={12} lg={3} md={3} sm={6}>
                <AnimationOnScroll animateIn="animate__fadeIn">
                  <div>
                    <p className="titleFooter">COORDONNÉES</p>
                    {!isLoading && (
                      <React.Fragment>
                        <a
                          href={contact.map_url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {contact.adresse}
                        </a>
                        <br />
                        <a
                          href={contact.map_url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {contact.code_postal + " " + contact.ville}
                        </a>
                        <br />
                        <a
                          href={
                            "tel:" + contact.tel_principal.replace(/\s/g, "")
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          {contact.tel_principal}
                        </a>
                        <br />
                        <a
                          href={"mailto:" + contact.email_principale}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {contact.email_principale}
                        </a>
                      </React.Fragment>
                    )}
                  </div>
                  <div>
                    <p className="titleFooter">HORAIRES D'OUVERTURE</p>
                    <p>
                      Nos bureaux et salles d’exposition <br />
                      Lundi à jeudi <br />
                      8h30 - 12h30 | 14h00 - 18h00 <br />
                      Vendredi <br />
                      8h30 - 12h30 | 14h00 - 17h00
                    </p>
                  </div>
                </AnimationOnScroll>
              </Grid>
              <Grid item xs={12} lg={3} md={3} sm={6}>
                <AnimationOnScroll animateIn="animate__fadeIn">
                  <div className="">
                    <p className="titleFooter">OFFRES</p>
                    <ul>
                      {/* <li>
                        <Link href="../sr-evolutif" variant="body2">
                          SR l’évolutif®
                        </Link>
                      </li> */}
                      <li>
                        <Link href="../inspirations" variant="body2">
                          Inspirations
                        </Link>
                      </li>
                      <li>
                        <Link href="../realisations" variant="body2">
                          Réalisations
                        </Link>
                      </li>
                      <li>
                        <Link href="../univers" variant="body2">
                          Univers
                        </Link>
                      </li>
                      <li>
                        <Link href="../produits" variant="body2">
                          Produits
                        </Link>
                      </li>
                      <li>
                        <Link href="../services" variant="body2">
                          Services
                        </Link>
                      </li>
                      <li>
                        <Link href="../devis" variant="body2">
                          Devis
                        </Link>
                      </li>
                    </ul>
                  </div>
                </AnimationOnScroll>
              </Grid>
              <Grid item xs={12} lg={3} md={3} sm={6}>
                <AnimationOnScroll animateIn="animate__fadeIn">
                  <div className="foooterColumn">
                    <p className="titleFooter">A PROPOS</p>
                    <ul>
                      <li>
                        <Link href="../entreprise" variant="body2">
                          L'entreprise
                        </Link>
                      </li>
                      <li>
                        <Link href="../actualites" variant="body2">
                          Actualités
                        </Link>
                      </li>
                      <li>
                        <Link href="../catalogues" variant="body2">
                          Catalogues
                        </Link>
                      </li>
                      <li>
                        <Link href="../notices-et-tutos" variant="body2">
                          Notices de montage et tutos
                        </Link>
                      </li>
                      <li>
                        <Link href="../faq" variant="body2">
                          FAQ
                        </Link>
                      </li>
                    </ul>
                  </div>
                </AnimationOnScroll>
              </Grid>
              <Grid item xs={12} lg={3} md={3} sm={6}>
                <AnimationOnScroll animateIn="animate__fadeIn">
                  <div className="foooterColumn">
                    <p className="titleFooter">SUIVEZ-NOUS</p>
                    <div className="reseau">
                      {!isLoading && (
                        <React.Fragment>
                          <IconButton
                            href={contact.facebook_url}
                            target="_blank"
                            rel="noreferrer"
                            color="primary"
                            aria-label="add to shopping cart"
                          >
                            <img
                              src={facebook}
                              alt={"Facebook"}
                              loading="lazy"
                            />
                          </IconButton>
                          <IconButton
                            href={contact.linkedin_url}
                            target="_blank"
                            rel="noreferrer"
                            color="primary"
                            aria-label="add to shopping cart"
                          >
                            <img
                              src={linkedin}
                              alt={"LinkedIn"}
                              loading="lazy"
                            />
                          </IconButton>
                          <IconButton
                            href={contact.youtube_url}
                            target="_blank"
                            rel="noreferrer"
                            color="primary"
                            aria-label="add to shopping cart"
                          >
                            <img src={youtube} alt={"Youtube"} loading="lazy" />
                          </IconButton>
                        </React.Fragment>
                      )}
                    </div>
                    <Button
                      variant="outlined"
                      color="white"
                      href="../espace-revendeur"
                      className="btnOutlinedFooter"
                      aria-label="Espace revendeur"
                    >
                      Espace revendeur
                    </Button>
                    <br />
                    <Button
                      variant="outlined"
                      color="white"
                      href="../contact"
                      className="btnOutlinedFooter"
                      aria-label="Nous contacter"
                    >
                      Nous contacter
                    </Button>
                    <br />
                    <Button
                      variant="outlined"
                      color="white"
                      href="../emploies"
                      className="btnOutlinedFooter"
                      aria-label="Nous rejoindre"
                    >
                      Nous rejoindre
                    </Button>
                    <br />
                    <Button
                      variant="outlined"
                      color="white"
                      rel="noreferrer"
                      href="../visite-virtuelle"
                      className="btnOutlinedFooter"
                      aria-label="Visite virtuelle"
                    >
                      Visite virtuelle
                    </Button>
                  </div>
                </AnimationOnScroll>
              </Grid>
            </Grid>
          </Box>
        )}
        <Box sx={{ flexGrow: 2 }} className="border-footer">
          <Grid item xs={12}>
            <div>
              <div className="downFooter">
                <a href="../mentions-legales">Mentions légales</a>
                <p>&nbsp;-&nbsp;</p>
                <a href="../poltique-confidentialite">Confidentialité</a>
              </div>
            </div>
          </Grid>
        </Box>
      </Container>
    </div>
  );
}
