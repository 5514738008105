import React from "react";
import echangeIcon from "../../assets/tel.svg";
import livraisonIcon from "../../assets/camion.svg";
import faqIcon from "../../assets/faq.svg";
import noticeIcon from "../../assets/livre.svg";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { AnimationOnScroll } from "react-animation-on-scroll";

export default function BesoinAide() {
  return (
    <div className="besoinAideBox pb120">
      <Container maxWidth="xl">
        <AnimationOnScroll animateIn="animate__fadeInDown">
          <h2 className="h2 textcenter pt120 pb120">
            Vous avez besoin d'aide ?
          </h2>
        </AnimationOnScroll>
        <Grid
          container
          justifyContent="center"
          alignItems="start"
          rowSpacing={{ xs: 3, sm: 3, md: 4 }}
        >
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <AnimationOnScroll animateIn="animate__fadeIn">
              <div className="besoinAideCard">
                <picture className="picture-box">
                  <img src={echangeIcon} alt={"On échange"} />
                </picture>
                <p>On échange</p>
                <a href="../contact" className="links">
                  CONTACTEZ-NOUS
                </a>
              </div>
            </AnimationOnScroll>
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <AnimationOnScroll animateIn="animate__fadeIn">
              <div className="besoinAideCard">
                <picture className="picture-box">
                  <img src={livraisonIcon} alt={"Montage et livraison"} />
                </picture>
                <p>Montage et livraison</p>
                <a href="../services" className="links">
                  EN SAVOIR PLUS
                </a>
              </div>
            </AnimationOnScroll>
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <AnimationOnScroll animateIn="animate__fadeIn">
              <div className="besoinAideCard">
                <picture className="picture-box">
                  <img src={faqIcon} alt={"FAQ"} />
                </picture>
                <p>FAQ</p>
                <a href="../faq" className="links">
                  EN SAVOIR PLUS
                </a>
              </div>
            </AnimationOnScroll>
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <AnimationOnScroll animateIn="animate__fadeIn">
              <div className="besoinAideCard">
                <picture className="picture-box">
                  <img src={noticeIcon} alt={"Notices de montage & tutos"} />
                </picture>
                <p>Notices de montage & tutos</p>
                <a href="../notices-et-tutos" className="links">
                  CONSULTEZ
                </a>
              </div>
            </AnimationOnScroll>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
