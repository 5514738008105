import { Grid, Pagination } from "@mui/material";
import React from "react";
import ImageOptimizer from "../utils/ImageOptimizer";
import DefaultLogo from "../../assets/image_produit_defaut_tiltequipement.svg";

export default function ResultsProducts({
  products,
  totalProductsResults,
  productPageCount,
  productPage,
  setProductsPage,
}) {
  function handlePagination(e) {
    window.scrollTo(0, 480);
    setProductsPage(e.target.textContent);
  }
  return (
    <div style={{ margin: "40px 0" }}>
      <h3>Les produits ({totalProductsResults})</h3>
      <Grid container spacing={2}>
        {products.map((product) => (
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <div className="rechercheResultCard">
              <a href={"../produit/" + product.attributes.slug}>
                <section>
                  {product.attributes.images.data ? (
                    <ImageOptimizer
                      formats={
                        product.attributes.images.data[0].attributes.formats
                      }
                    />
                  ) : (
                    <img
                      src={DefaultLogo}
                      alt="Absence de visuel pour ce produit"
                    />
                  )}
                </section>
                <p style={{ marginTop: "10px" }}>{product.attributes.nom}</p>
              </a>
            </div>
          </Grid>
        ))}
      </Grid>
      <div className="pagination pt64">
        <Pagination
          onClick={(e) => handlePagination(e)}
          count={productPageCount}
          variant="outlined"
        />
      </div>
    </div>
  );
}
