import React from 'react';
import { SvgIcon } from '@mui/material';

export const ContactIcon = () => {
  return (
    <SvgIcon viewBox={'0 0 36.929 37.263'}>
      <path id="Tracé_10270" dataname="Tracé 10270" d="M25.018,0H4.168A4.168,4.168,0,0,0,0,4.168V20.855a4.168,4.168,0,0,0,4.168,4.168H5.1c.513,0,.486.4.486.4v3.834l4.347-4.239H25.018a4.168,4.168,0,0,0,4.168-4.168V4.168A4.168,4.168,0,0,0,25.018,0" transform="translate(1.398 1.402)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
      <path id="Tracé_10271" dataname="Tracé 10271" d="M68.628,57.284a.149.149,0,0,1-.106-.044l-5.282-5.282H51.986a.15.15,0,0,1,0-.3H63.3a.15.15,0,0,1,.106.044l5.069,5.069V51.808a.15.15,0,0,1,.162-.15,4.874,4.874,0,0,0,1.921-.346,6.092,6.092,0,0,0,3.292-3.222,8.764,8.764,0,0,0,.837-3.767V28.642a.15.15,0,0,1,.3,0V44.324a9.065,9.065,0,0,1-.867,3.9,6.383,6.383,0,0,1-3.47,3.378,5.37,5.37,0,0,1-1.866.365h-.011v5.171a.15.15,0,0,1-.15.15" transform="translate(-40.063 -22.021)" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5"/>
      <path id="Tracé_10272" dataname="Tracé 10272" d="M49.324,30.457a1.025,1.025,0,0,1-.516-.139C40.683,25.586,39.321,16.88,39.192,15.9a.911.911,0,0,1-.009-.145c.037-1.358,2.1-2.611,3.323-3.237a1.021,1.021,0,0,1,1.352.4,8.8,8.8,0,0,1,1.275,4.187,1.028,1.028,0,0,1-.417.838,3.414,3.414,0,0,0-.911.954.763.763,0,0,0-.055.677,16.687,16.687,0,0,0,3.366,5.084c.212.163.942.007,1.9-.409a1.024,1.024,0,0,1,.952.076A15.372,15.372,0,0,1,52.936,27.1a1.024,1.024,0,0,1,.073,1.235,6.053,6.053,0,0,1-3.382,2.08,1.027,1.027,0,0,1-.3.045M42.971,12.665a.761.761,0,0,0-.348.084c-.945.483-3.148,1.744-3.183,3.015a.661.661,0,0,0,.007.105,20.389,20.389,0,0,0,1.767,5.667A19.093,19.093,0,0,0,48.938,30.1a.771.771,0,0,0,.612.07A6.522,6.522,0,0,0,52.8,28.188a.766.766,0,0,0-.055-.923,15.126,15.126,0,0,0-2.905-2.718.768.768,0,0,0-.713-.057c-1.128.487-1.856.614-2.164.377a16.688,16.688,0,0,1-3.447-5.191,1.021,1.021,0,0,1,.072-.906,3.671,3.671,0,0,1,.978-1.028.77.77,0,0,0,.313-.628,8.54,8.54,0,0,0-1.24-4.063.765.765,0,0,0-.664-.384" transform="translate(-29.718 -7.59)" fill="none" stroke="#000" strokeWidth="0.8"/>
    </SvgIcon>
  );
};

export default ContactIcon;
